import React, { useEffect, useState } from 'react';
import { Table, Pagination, Alert, Col, Select, Row } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getAuditLogs } from './audit-log.reducer';
import dayjs from 'dayjs';

const { Option } = Select;

export const AuditLog = () => {
  const dispatch = useAppDispatch();
  const [paginationState, setPaginationState] = useState({
    activePage: 1,
    itemsPerPage: 10,
    sort: 'id',
    order: 'desc',
  });

  const auditLogList = useAppSelector(state => state.auditLog.entities);
  const loading = useAppSelector(state => state.auditLog.loading);
  const totalItems = useAppSelector(state => state.auditLog.totalItems);
  const entityName = ['Eleve', 'Prof', 'Tuteur', 'Recu', 'Caisse', 'Tarif'];
  const [selectedEntity, setSelectedEntity] = useState(null);

  useEffect(() => {
    let query = '';
    if (selectedEntity) {
      query += `entityName.equals=${selectedEntity}`;
    }
    dispatch(
      getAuditLogs({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: query,
      }),
    );
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedEntity]);

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Entité',
      dataIndex: 'entityName',
      key: 'entityName',
    },
    {
      title: 'ID',
      dataIndex: 'entityId',
      key: 'entityId',
    },
    {
      title: 'Type opération',
      dataIndex: 'operationType',
      key: 'operationType',
    },
    {
      title: 'Changé par',
      dataIndex: 'changedBy',
      key: 'changedBy',
    },
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: timestamp => dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: 'Ancienne Valeur',
      dataIndex: 'oldValue',
      key: 'oldValue',
    },
    {
      title: 'Nouvelle Value',
      dataIndex: 'newValue',
      key: 'newValue',
    },
  ];

  return (
    <>
      <h2 id="absence-eleve-heading" className="mt-5" data-cy="AbsenceEleveHeading">
        Audit Log
      </h2>
      <Row gutter={[16, 16]} justify="center">
        <Col span={8}>
          <Select
            allowClear={true}
            placeholder="Code"
            onChange={setSelectedEntity}
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {entityName.map(ressource => (
              <Option key={ressource} value={ressource}>
                {ressource}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      <div className="table-responsive mt-4" style={{ overflow: 'hidden' }}>
        {loading ? (
          <div>Loading...</div>
        ) : auditLogList && auditLogList.length > 0 ? (
          <>
            <Table columns={columns} dataSource={auditLogList} rowKey="id" pagination={false} scroll={{ x: 'max-content' }} />
            <div className="d-flex justify-content-center mt-4">
              <Pagination
                current={paginationState.activePage}
                onChange={handlePagination}
                total={totalItems}
                pageSize={paginationState.itemsPerPage}
                showSizeChanger={false}
              />
            </div>
          </>
        ) : (
          <Alert message="No audit logs found" type="warning" showIcon />
        )}
      </div>
    </>
  );
};

export default AuditLog;
