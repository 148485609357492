import { INiveau } from 'app/shared/model/niveau.model';
import { IEmploiTemps } from 'app/shared/model/emploi-temps.model';
import { ISalle } from 'app/shared/model/salle.model';
import { IMatiere } from 'app/shared/model/matiere.model';

export interface IGroupe {
  id?: number;
  nom?: string | null;
  code?: string | null;
  capaciteGroupe?: number | null;
  niveau?: INiveau | null;
  emploisTemps?: IEmploiTemps | null;
  salles?: ISalle[] | null;
  matieres?: IMatiere[] | null;
}

export const defaultValue: Readonly<IGroupe> = {};
