import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, message, Popconfirm, Select, Space, Table, Tag } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import CreateEtablissementServiceModal from 'app/modules/modals/CreateEtablissementServiceModal';
import {
  deleteServiceEtablissementEntity,
  getServiceEtablissementEntities,
} from 'app/entities/service-etablisement/service-etablisement.reducer';
import { getPaginationState } from 'react-jhipster';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';

const { Option } = Select;

export const ServiceEtablisement = () => {
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState(false);
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [currentidEtablissement, setCurrentidEtablissement] = useState<number | null>(null);
  const [currentEtablissement, setCurrentEtablissement] = useState<IServiceEtablisement | null>(null);
  const [eleveFilter, setEleveFilter] = useState<string | null>(null);

  const handleOpenModal = () => {
    setCurrentEtablissement(null);
    setShowModal(true);
  };

  const handleEditModal = serviceEtablissement => {
    setCurrentEtablissement(serviceEtablissement);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentEtablissement(null);
    setShowModal(false);
  };

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const serviceEtablisementList = useAppSelector(state => state.serviceEtablisement.entities);
  const loading = useAppSelector(state => state.serviceEtablisement.loading);
  const totalItems = useAppSelector(state => state.serviceEtablisement.totalItems);

  const confirm = () => {
    if (currentidEtablissement) {
      dispatch(deleteServiceEtablissementEntity(currentidEtablissement));
      message.success('Supprimé avec succès');
    }
  };

  const cancel = () => {
    message.error('Suppression annulée');
  };

  const getAllEntities = () => {
    const filter = eleveFilter ? `eleveId.equals=${eleveFilter}` : '';
    const params = {
      page: paginationState.activePage - 1,
      size: paginationState.itemsPerPage,
      sort: `${paginationState.sort},${paginationState.order}`,
      query: filter,
    };
    dispatch(getServiceEtablissementEntities(params));
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, eleveFilter]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: (text, record) => (
        <Button type="link" href={`/service-etablisement/${record.id}`}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      render: (text, record) => (
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="pink">
          {text}
        </Tag>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => handleEditModal(record)}
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
            data-cy="entityEditButton"
          ></Button>
          <Popconfirm
            title="Supprimer le service"
            description="Êtes-vous sûr de vouloir supprimer ce service?"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" danger onClick={() => setCurrentidEtablissement(record.id)} icon={<FontAwesomeIcon icon={faTrash} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="service-etablisement-heading" data-cy="ServiceEtablisementHeading">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="pink">
          Service Établissement
        </Tag>
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button type="primary" onClick={handleOpenModal} style={{ marginRight: '10px' }}>
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Créer un nouveau Service
          </Button>
          <Button type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
        </div>
        <CreateEtablissementServiceModal etablissementService={currentEtablissement} show={showModal} handleClose={handleCloseModal} />{' '}
      </h2>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={serviceEtablisementList}
          rowKey="id"
          pagination={{
            current: paginationState.activePage,
            pageSize: paginationState.itemsPerPage,
            total: totalItems,
            showSizeChanger: true,
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default ServiceEtablisement;
