import React, { useEffect, useState } from 'react';
import './styling.scss';
import { Button, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Table, Typography } from 'antd';
import { DeleteOutlined } from '@mui/icons-material';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IDevoirSurvRequest, IDevoirSurvResponse } from 'app/shared/model/gestionNote/devoir-surv.model';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import {
  addDevoirSurv,
  deleteDevoirSurv,
  getDevoirSurvs,
  getOneDevoirSurv,
  reset,
  updateDevoirSurv,
  updateStatusForm,
} from 'app/entities/gestion-notes/devoirSurv.reducer';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IProf } from 'app/shared/model/prof.model';
import { IMatiere } from 'app/shared/model/matiere.model';
import { getEntities as getMatieres } from 'app/entities/matiere/matiere.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getProfs } from 'app/entities/prof/prof.reducer';
import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Badge } from 'reactstrap';

const { Option } = Select;
const inputStyle = {
  backgroundColor: '#F6F5F5',
  padding: '6px 6px',
};
const DevoirSurv = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const devoirsSurv = useAppSelector(state => state.devoirSurv.entities) as IDevoirSurvResponse[];
  const oneDevoirSurv = useAppSelector(state => state.devoirSurv.entity) as IDevoirSurvResponse;
  const loadUpdateDevoirSurv = useAppSelector(state => state.devoirSurv.loadUpdateDevoirSurv) as boolean;
  const loadDevoirSurvs = useAppSelector(state => state.devoirSurv.loadDevoirSurvs) as boolean;
  const updateDevoirSurvSuccess = useAppSelector(state => state.devoirSurv.updateDevoirSurvSuccess) as boolean;
  const statusForm = useAppSelector(state => state.devoirSurv.updateForm) as boolean;
  const [devoirList, setDevoirList] = useState<IDevoirSurvResponse[]>();
  const matieres = useAppSelector(state => state.matiere.entities) as IMatiere[];
  const groupes = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const profs = useAppSelector(state => state.prof.entities) as IProf[];

  const [isSpinning, setIsSpinning] = useState(false);
  // filters

  const [selectedTitre, setSelectedTitre] = useState(null);
  const [selectedGroupe, setSelectedGroupe] = useState(null);
  const [selectedMatiere, setSelectedMatiere] = useState(null);
  const [selectedProf, setSelectedProf] = useState(null);
  const [selectedDateDebut, setSelectedDateDebut] = useState(null);
  const [selectedDateFin, setSelectedDateFin] = useState(null);

  useEffect(() => {
    const withRealAnneeScolaire = false;
    dispatch(
      getDevoirSurvs({
        query: `withRealAnneeScolaire=${withRealAnneeScolaire}`,
      }),
    );
    dispatch(getMatieres({}));
    dispatch(getGroupes({}));
    dispatch(getProfs({}));
  }, []);

  useEffect(() => {
    if (updateDevoirSurvSuccess) {
      dispatch(reset());
      form.resetFields();
    }
  }, [updateDevoirSurvSuccess]);

  useEffect(() => {
    if (!updateDevoirSurvSuccess) {
      if (statusForm && oneDevoirSurv) {
        form.setFieldsValue({
          titre: oneDevoirSurv.titre,
          dateLimite: oneDevoirSurv.dateLimite ? dayjs(oneDevoirSurv.dateLimite) : null,
          matiere: oneDevoirSurv?.matiere?.id,
          profId: oneDevoirSurv?.prof?.id,
          groupes: oneDevoirSurv?.groupes?.map(groupe => groupe.id),
        });
      }
    }
  }, [statusForm, oneDevoirSurv, form]);

  useEffect(() => {
    if (devoirsSurv.length <= 0) {
      return;
    }

    let filteredDevoirs = devoirsSurv;

    if (selectedTitre) {
      filteredDevoirs = filteredDevoirs.filter(devoir => devoir.id === selectedTitre);
    }
    if (selectedGroupe) {
      filteredDevoirs = filteredDevoirs.filter(devoir => devoir.groupes.some(groupe => groupe.id === selectedGroupe));
    }
    if (selectedMatiere) {
      filteredDevoirs = filteredDevoirs.filter(devoir => devoir.matiere.id === selectedMatiere);
    }
    if (selectedProf) {
      filteredDevoirs = filteredDevoirs.filter(devoir => devoir?.prof?.id === selectedProf);
    }
    if (selectedDateDebut) {
      filteredDevoirs = filteredDevoirs.filter(devoir => dayjs(devoir.dateLimite).isAfter(dayjs(selectedDateDebut), 'day'));
    }
    if (selectedDateFin) {
      filteredDevoirs = filteredDevoirs.filter(devoir => dayjs(devoir.dateLimite).isBefore(dayjs(selectedDateFin), 'day'));
    }

    setDevoirList(filteredDevoirs);
  }, [selectedTitre, selectedGroupe, selectedMatiere, selectedProf, selectedDateDebut, selectedDateFin, devoirsSurv]);

  const onFinish = values => {
    const devoirSurv: IDevoirSurvRequest = {
      titre: values.titre,
      dateLimite: values.dateLimite ? dayjs(values.dateLimite) : null,
      matiereId: values.matiere,
      profId: values?.profId,
      groupeIds: values.groupes,
    };

    if (statusForm) {
      const updatedDevoirSurv = { ...devoirSurv, id: oneDevoirSurv.id };
      dispatch(updateDevoirSurv(updatedDevoirSurv));
    } else {
      dispatch(addDevoirSurv(devoirSurv));
    }
    form.resetFields();
  };

  const handleIconClick = () => {
    setIsSpinning(true);
    setTimeout(() => setIsSpinning(false), 1000);
    dispatch(updateStatusForm(false));
    form.resetFields();
  };

  const columns = [
    {
      title: 'Titre',
      dataIndex: 'titre',
      render: text => <Button type="link">{text.length > 15 ? `${text.substring(0, 15)}...` : text}</Button>,
    },
    {
      title: 'Date',
      dataIndex: 'dateLimite',
      render: date => dayjs(date).format('YYYY-MM-DD'),
    },
    {
      title: 'Matiere',
      dataIndex: 'matiere',
      render: (matiere: IMatiere) => matiere.nom,
    },
    {
      title: 'Profs',
      dataIndex: 'prof',
      render: (prof: IProf) =>
        prof ? (
          `${prof.internalUser?.lastName} ${prof.internalUser?.firstName}`
        ) : (
          <Badge color="warning">
            <Typography.Text style={{ fontSize: '12px' }}>Global</Typography.Text>
          </Badge>
        ),
    },
    {
      title: 'Groupes',
      dataIndex: 'groupes',
      render: (groupes: IGroupe[]) =>
        groupes
          .map(groupe => groupe.nom)
          .filter(nom => nom !== null)
          .join(', '),
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Space>
          <a
            onClick={() => {
              navigate(`/devoirsurv/${record.id}`);
            }}
          >
            <EyeOutlined style={{ color: 'orange' }} />
          </a>
          <a
            onClick={() => {
              dispatch(getOneDevoirSurv(record.id));
              dispatch(updateStatusForm(true));
            }}
          >
            <EditOutlined style={{ color: 'blue' }} />
          </a>
          <a
            onClick={() => {
              dispatch(deleteDevoirSurv(record.id));
            }}
          >
            <DeleteOutlined style={{ color: 'red', fontSize: '18px', marginBottom: '3px' }} />
          </a>
        </Space>
      ),
    },
  ];

  return (
    <div className="container-devoirSurv">
      <div
        className="element1"
        style={{
          backgroundColor: 'white',
          filter: 'drop-shadow(0 0 0.43rem #eed5db)',
          opacity: loadUpdateDevoirSurv ? '60%' : '100%',
          pointerEvents: loadUpdateDevoirSurv ? 'none' : 'auto',
        }}
      >
        {loadUpdateDevoirSurv && <LinearProgress />}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ fontSize: '24px' }}>{statusForm ? 'Modifier un devoir surveillé' : 'Ajouter un devoir surveillé'}</h3>
          <AutorenewIcon
            onClick={handleIconClick}
            style={{
              cursor: 'pointer',
              color: 'green',
              animation: isSpinning ? 'spin 1s linear infinite' : 'none',
            }}
          />
        </div>
        <Divider></Divider>
        <Form form={form} layout="vertical" onFinish={onFinish} initialValues={{}} requiredMark={false}>
          <Form.Item label="Titre" name="titre" rules={[{ required: true, message: 'Please enter the titre!' }]}>
            <Input placeholder="titre" style={inputStyle} />
          </Form.Item>

          <Form.Item label="Date" name="dateLimite" rules={[{ required: true, message: 'Please select the date!' }]}>
            <DatePicker format="YYYY-MM-DD" style={{ ...inputStyle, width: '100%' }} />
          </Form.Item>

          <Form.Item label="Matiere" name="matiere" rules={[{ required: true, message: 'Please select the matiere!' }]}>
            <Select
              placeholder="matiere"
              className="custom-select"
              showSearch
              filterOption={(input, option) => option.children.toString().includes(input)}
            >
              {matieres.map(matiere => (
                <Option key={matiere.id} value={matiere.id}>
                  {matiere.nom}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label="Groupes" name="groupes" rules={[{ required: true, message: 'Please select a groupe !' }]}>
            <Select
              mode="multiple"
              placeholder="groupe"
              className="custom-select"
              showSearch
              filterOption={(input, option) => option.children.toString().includes(input)}
            >
              {groupes.map(groupe => (
                <Option key={groupe.id} value={groupe.id}>
                  {groupe.nom}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Prof" name="profId">
            <Select
              placeholder="prof"
              className="custom-select"
              showSearch
              filterOption={(input, option) => option.children.toString().includes(input)}
            >
              {profs.map(prof => (
                <Option key={prof.id} value={prof.id}>
                  {prof?.firtName} {prof?.lastName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ backgroundColor: statusForm ? 'green' : '', borderColor: statusForm ? 'green' : '' }}
            >
              {statusForm ? 'Modifier' : 'Ajouter'}
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="card-container element2">
        <Row gutter={[16, 16]} className="card-header">
          {/* First Select for Titre */}
          <Col span={8}>
            <Select
              allowClear={true}
              placeholder="Titre"
              onChange={setSelectedTitre}
              showSearch
              filterOption={(input, option) => option.children.toString().includes(input)}
              style={{ width: '100%', fontWeight: 'lighter' }}
            >
              {devoirsSurv.map(devoirsurv => (
                <Option key={devoirsurv.id} value={devoirsurv.id}>
                  {devoirsurv.titre}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Second Select for Groupe */}
          <Col span={8}>
            <Select
              allowClear={true}
              placeholder="Groupe"
              showSearch
              filterOption={(input, option) => option.children.toString().includes(input)}
              onChange={setSelectedGroupe}
              style={{ width: '100%', fontWeight: 'lighter' }}
            >
              {groupes.map(groupe => (
                <Option key={groupe.id} value={groupe.id}>
                  {groupe.nom}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Third Select for Matiere */}
          <Col span={8}>
            <Select
              allowClear={true}
              placeholder="Matiere"
              showSearch
              filterOption={(input, option) => option.children.toString().includes(input)}
              onChange={setSelectedMatiere}
              style={{ width: '100%', fontWeight: 'lighter' }}
            >
              {matieres.map(matiere => (
                <Option key={matiere.id} value={matiere.id}>
                  {matiere.nom}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Fourth Select for Prof */}
          <Col span={8}>
            <Select allowClear={true} onChange={setSelectedProf} placeholder="Prof" style={{ width: '100%', fontWeight: 'lighter' }}>
              {profs.map(prof => (
                <Option key={prof.id} value={prof.id}>
                  {prof?.firtName} {prof?.lastName}
                </Option>
              ))}
            </Select>
          </Col>

          {/* Date de Debut */}
          <Col span={8}>
            <DatePicker placeholder="Date de Début" onChange={setSelectedDateDebut} style={{ width: '100%', fontWeight: 'lighter' }} />
          </Col>

          {/* Date de Fin */}
          <Col span={8}>
            <DatePicker placeholder="Date de Fin" onChange={setSelectedDateFin} style={{ width: '100%', fontWeight: 'lighter' }} />
          </Col>
        </Row>

        <div className="card-body">
          <Table
            columns={columns}
            dataSource={devoirList}
            rowKey="id"
            loading={loadDevoirSurvs}
            pagination={false}
            scroll={{ y: 400 }} // Scrollable table body
          />
        </div>
      </div>
    </div>
  );
};
export default DevoirSurv;
