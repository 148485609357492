import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './tarif.reducer';

export const TarifDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tarifEntity = useAppSelector(state => state.tarif.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="tarifDetailsHeading">Tarif</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{tarifEntity.id}</dd>
          <dt>
            <span id="prix">Prix</span>
          </dt>
          <dd>{tarifEntity.prix}</dd>
          <dt>Niveau</dt>
          <dd>{tarifEntity.niveau ? tarifEntity.niveau.id : ''}</dd>
          <dt>Service Etablisement</dt>
          <dd>{tarifEntity.serviceEtablisement ? tarifEntity.serviceEtablisement.id : ''}</dd>
          <dt>Eleve</dt>
          <dd>{tarifEntity.eleve ? tarifEntity.eleve.id : ''}</dd>
          <dt>Recus</dt>
          <dd>
            {tarifEntity.recuses
              ? tarifEntity.recuses.map((val, i) => (
                <span key={val.id}>
                    <a>{val.id}</a>
                  {tarifEntity.recuses && i === tarifEntity.recuses.length - 1 ? '' : ', '}
                  </span>
              ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/tarif" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/tarif/${tarifEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default TarifDetail;
