import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Row, Col, Avatar } from 'antd';
import { createEntity, reset, updateEntity } from 'app/entities/tuteur/tuteur.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { addTuteur, getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { mapIdList } from 'app/shared/util/entity-utils';
import { getAllUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { IUser } from 'app/shared/model/user.model';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';

const images = {
  mere: '/content/images/mere.png',
  pere: '/content/images/pere.png',
};

const CreateTuteurModal = ({ show, handleClose }) => {
  const dispatch = useAppDispatch();
  const [formPrincipale] = Form.useForm();
  const [formSecondaire] = Form.useForm();
  const NewCustomTuteur = useAppSelector(state => state.tuteur.entity) as ITuteur;
  const users = useAppSelector(state => state.userManagement.users) as IUser[];
  const updateSuccess = useAppSelector(state => state.tuteur.updateSuccess) as boolean;
  const tuteurEntity = useAppSelector(state => state.tuteur.entity);
  const updating = useAppSelector(state => state.tuteur.updating);
  const [password, setPassword] = useState('');

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getEleves({}));
  }, [dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      dispatch(reset());
      dispatch(addTuteur(NewCustomTuteur));
    }
  }, [updateSuccess, dispatch, NewCustomTuteur]);

  const saveEntity = valuesList => {
    valuesList.forEach(values => {
      const entity = {
        ...tuteurEntity,
        ...values,
        login: values.login ? values.login : '',
        password: values.password ? values.password : '',
        principale: values.type === 'principale',
        internalUser: {
          ...tuteurEntity.internalUser,
          firstName: values.firstName,
          login: values.login ? values.login : '',
          password: values.password ? values.password : '',
          activated: values.activated,
        },
        activated: values.activated,
        enfants: values.enfants ? mapIdList(values.enfants) : [],
        relationAvecEleve: values.type === 'principale' ? 'pere' : 'mere',
      };

      if (tuteurEntity.id) {
        dispatch(updateEntity(entity));
      } else {
        dispatch(createEntity(entity));
      }
    });
    handleClose();
  };

  const handleSave = async () => {
    try {
      const valuesPrincipale = await formPrincipale.validateFields();
      const valuesSecondaire = await formSecondaire.getFieldsValue();

      const formattedPrincipale = {
        ...valuesPrincipale,
        activated: true,
        type: 'principale',
      };

      const isSecondaireFilled = Object.values(valuesSecondaire).some(value => value);

      const formattedSecondaire = {
        ...valuesSecondaire,
        activated: false,
        type: 'secondaire',
      };

      const entitiesToSave = isSecondaireFilled ? [formattedPrincipale, formattedSecondaire] : [formattedPrincipale];

      saveEntity(entitiesToSave);
    } catch (errorInfo) {
      console.error('Failed to save:', errorInfo);
    }
  };

  const userLogins = users.map(user => user.login);

  const checkUniqueField = async (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }
    const existingItem = userLogins.find(item => item?.toString() === value.toString());
    if (existingItem) {
      return Promise.reject(`Login already exists`);
    }
    return Promise.resolve();
  };

  const checkTelNotEqual = (rule, value, callback, compareWith) => {
    if (value && value.toString() === compareWith?.toString()) {
      callback('Tel of Pere and Mere should not be the same');
    } else {
      callback();
    }
  };

  const handleTelChange = e => {
    const tel = e.target.value;
    formPrincipale.setFieldsValue({
      login: tel,
      password: tel,
    });
  };

  const updatePassword = event => setPassword(event.target.value);

  return (
    <Modal width={'80%'} title="Créer un tuteur" visible={show} onCancel={handleClose} footer={null}>
      <Row gutter={16}>
        <Col span={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={images.pere} size={64} style={{ marginRight: 8 }} />
            <h3>Père</h3>
          </div>
          <Form form={formPrincipale} name="principale" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="Nom complet" name="firstName" rules={[{ required: true, message: 'Veuillez entrer le nom complet!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Tel"
              name="tel"
              rules={[
                { required: true, message: 'Veuillez entrer le numéro de téléphone!' },
                { pattern: /^\d{10}$/, message: 'Veuillez entrer un numéro de téléphone valide!' },
                { validator: (rule, value, callback) => checkUniqueField(rule, value) },
                { validator: (rule, value, callback) => checkTelNotEqual(rule, value, callback, formSecondaire.getFieldValue('tel')) },
              ]}
            >
              <Input onChange={handleTelChange} />
            </Form.Item>
            <Form.Item label="Login" name="login" rules={[{ validator: (rule, value, callback) => checkUniqueField(rule, value) }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: 'Votre mot de passe est requis.' },
                { min: 4, message: 'Votre mot de passe doit comporter au moins 4 caractères.' },
                { max: 50, message: 'Votre mot de passe ne doit pas comporter plus de 50 caractères.' },
              ]}
            >
              <Input.Password onChange={updatePassword} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <PasswordStrengthBar password={password} />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Veuillez entrer un email valide!' }]}>
              <Input />
            </Form.Item>
            <Form.Item label="Observation" name="observation">
              <Input />
            </Form.Item>
            <Form.Item label="CIN" name="cin">
              <Input />
            </Form.Item>
            <Form.Item label="Adresse" name="adresse">
              <Input />
            </Form.Item>
          </Form>
        </Col>
        <Col span={12}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={images.mere} size={64} style={{ marginRight: 8 }} />
            <h3>Mère</h3>
          </div>
          <Form form={formSecondaire} name="secondaire" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} autoComplete="off">
            <Form.Item label="Nom complet" name="firstName" rules={[{ message: 'Veuillez entrer le nom complet!' }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Tel"
              name="tel"
              rules={[
                { required: true, message: 'Veuillez entrer le numéro de téléphone!' },
                { pattern: /^\d{10}$/, message: 'Veuillez entrer un numéro de téléphone valide!' },
                { validator: (rule, value, callback) => checkUniqueField(rule, value) },
                { validator: (rule, value, callback) => checkTelNotEqual(rule, value, callback, formPrincipale.getFieldValue('tel')) },
              ]}
            >
              <Input />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row justify="end" gutter={16}>
        <Col>
          <Button onClick={handleClose}>Annuler</Button>
        </Col>
        <Col>
          <Button type="primary" loading={updating} onClick={handleSave}>
            Sauvegarder
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default CreateTuteurModal;
