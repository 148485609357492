import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { MessageSender } from 'app/shared/model/enumerations/MessageSender';

export interface IMessage {
  id?: number;
  contenu?: string | null;
  dateEnvoi?: dayjs.Dayjs | null;
  user?: IUser | null;
  isRead?: boolean;
  messageSender?: MessageSender;
}

export const defaultValue: Readonly<IMessage> = {};
