import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IFichePaiement } from 'app/shared/model/paiementModel/fiche-paiement.model';
import { IFichePaiementGlobal } from 'app/shared/model/paiementModel/fiche-paiement-global.model';
import { ITarif } from 'app/shared/model/tarif.model';
import { ICheque } from 'app/shared/model/paiementModel/cheque.model';

interface AddItemsPayload {
  cin: string;
  item: IFichePaiement;
  checked: boolean;
}

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  cheque: {
    numCheque: 0,
    file: '',
    fileContentType: '',
    montantCheque: 0,
    dateDeRemise: '',
    observation: '',
  },
  itemsSelected: [],
  totalMontantAPayer: 0,
  avance: 0,
  rest: 0,
  amountVirement: null,
  amountEspece: null,
  amountCheque: null,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/eleves';

// Actions

export const GetFichePaimentsGlobal = createAsyncThunk('fichepaiement/fetch_entity_list', async ({ keyword, fetchOne }: IQueryParams) => {
  const requestUrl = `${apiUrl}/fichepaiement?${keyword ? `keyword=${keyword}&` : ''}fetchOne=${fetchOne}&cacheBuster=${new Date().getTime()}`;
  return axios.get<IFichePaiementGlobal[]>(requestUrl);
});

export const FichePaiementSlice = createEntitySlice({
  name: 'fichepaiement',
  initialState,
  reducers: {
    addItemsSelected: (state: any, action: PayloadAction<AddItemsPayload>) => {
      const { cin, item, checked } = action.payload;
      const newItem = { ...item, cin };
      if (checked) {
        state.itemsSelected.push(newItem);
      } else {
        state.itemsSelected = state.itemsSelected.filter(i => i.rubrique !== newItem.rubrique);
      }
      state.totalMontantAPayer = state.itemsSelected.reduce((total, item) => total + item.rest, 0);
      state.avance = state.totalMontantAPayer;
    },

    handleRest: (state: any, action: PayloadAction<number>) => {
      if (action.payload > state.totalMontantAPayer) {
        state.avance = state.totalMontantAPayer;
      } else {
        state.avance = action.payload;
      }
      state.rest = state.totalMontantAPayer - state.avance;
    },
    handleVirement: (state: any, action: PayloadAction<number>) => {
      if (state.avance < action.payload) {
        state.amountVirement = state.avance;
      } else {
        state.amountVirement = action.payload;
      }
      state.amountEspece = state.avance - state?.amountVirement - state?.amountCheque;
    },
    handleAmountCheque: (state: any, action: PayloadAction<number>) => {
      if (state.avance < action.payload) {
        state.amountCheque = state.avance - state?.amountVirement;
      } else {
        state.amountCheque = action.payload;
      }
      state.amountEspece = state.avance - state?.amountVirement - state?.amountCheque;
    },
    addCheque: (state: any, action: PayloadAction<ICheque>) => {
      state.cheque = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(GetFichePaimentsGlobal.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(GetFichePaimentsGlobal.pending, state => {
        state.loading = true;
      });
  },
});

export const { reset, addItemsSelected, handleRest, handleVirement, addCheque, handleAmountCheque } = FichePaiementSlice.actions;

// Reducer
export default FichePaiementSlice.reducer;
