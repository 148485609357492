import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './niveau.reducer';

export const NiveauDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const niveauEntity = useAppSelector(state => state.niveau.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="niveauDetailsHeading">Niveau</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{niveauEntity.id}</dd>
          <dt>
            <span id="libelle">Libelle</span>
          </dt>
          <dd>{niveauEntity.libelle}</dd>
          <dt>
            <span id="option">Option</span>
          </dt>
          <dd>{niveauEntity.option}</dd>
          <dt>
            <span id="idMassar">Id Massar</span>
          </dt>
          <dd>{niveauEntity.idMassar}</dd>
          <dt>Annee Scolaire</dt>
          <dd>{niveauEntity.anneeScolaire ? niveauEntity.anneeScolaire.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/niveau" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/niveau/${niveauEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default NiveauDetail;
