import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Eleve from './eleve';
import Utilisateur from './utilisateur';
import EtatServiceMois from './etat-service-mois';
import MoisEleve from './mois-eleve';
import AbsenceEleve from './absence-eleve';
import Devoir from './devoir';
import Salle from './salle';
import Etablissement from './etablissement';
import ImportEleve from './import-eleve';
import Tarif from './tarif';
import EmploiTemps from './emploi-temps';
import EmploiTempsLigne from './emploi-temps-ligne';
import Controle from './controle';
import ServiceEtablisement from './service-etablisement';
import AnneeScolaire from './annee-scolaire';
import Groupe from './groupe';
import Matiere from './matiere';
import Niveau from './niveau';
import Caisse from './caisse';
import Message from './message';
import Annonce from './annonce';
import Tuteur from './tuteur';
import Prof from './prof';
import Recu from './recu';
import Quiz from './quiz';
import Galerie from './galerie';
import Ressource from './ressource';
import Tresorerie from 'app/modules/tresorerie/tresorerie';
import PayeeImpayee from 'app/modules/tresorerie/PayeeImpayee';
import Garderie from 'app/entities/garderie/Garderie';
import Historique from 'app/entities/garderie/Historique';
import Screen from 'app/entities/garderie/Screen';
import DevoirSurv from 'app/entities/gestion-notes/DevoirSurv';
import Notes from 'app/entities/gestion-notes/Notes';
import DevoirNote from 'app/entities/gestion-notes/DevoirNote';
import AuditLog from './audit-log';
import CahierTransmission from 'app/entities/cahier-transmission';
import PageMobile from 'app/entities/page-mobile';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="eleve/*" element={<Eleve />} />
        <Route path="utilisateur/*" element={<Utilisateur />} />
        <Route path="etat-service-mois/*" element={<EtatServiceMois />} />
        <Route path="mois-eleve/*" element={<MoisEleve />} />
        <Route path="absence-eleve/*" element={<AbsenceEleve />} />
        <Route path="devoir/*" element={<Devoir />} />
        <Route path="salle/*" element={<Salle />} />
        <Route path="etablissement/*" element={<Etablissement />} />
        <Route path="import-eleve/*" element={<ImportEleve />} />
        <Route path="tarif/*" element={<Tarif />} />
        <Route path="emploi-temps/*" element={<EmploiTemps />} />
        <Route path="emploi-temps-ligne/*" element={<EmploiTempsLigne />} />
        <Route path="controle/*" element={<Controle />} />
        <Route path="service-etablisement/*" element={<ServiceEtablisement />} />
        <Route path="annee-scolaire/*" element={<AnneeScolaire />} />
        <Route path="groupe/*" element={<Groupe />} />
        <Route path="matiere/*" element={<Matiere />} />
        <Route path="niveau/*" element={<Niveau />} />
        <Route path="caisse/*" element={<Caisse />} />
        <Route path="message/*" element={<Message />} />
        <Route path="annonce/*" element={<Annonce />} />
        <Route path="tuteur/*" element={<Tuteur />} />
        <Route path="prof/*" element={<Prof />} />
        <Route path="recu/*" element={<Recu />} />
        <Route path="quiz/*" element={<Quiz />} />
        <Route path="galerie/*" element={<Galerie />} />
        <Route path="ressource/*" element={<Ressource />} />
        <Route path="tresorerie" element={<Tresorerie />} />
        <Route path="payeeimpayee" element={<PayeeImpayee />} />
        <Route path="garderie" element={<Garderie />} />
        <Route path="historique" element={<Historique />} />
        <Route path="screen" element={<Screen />} />
        <Route path="devoirSurv/*" element={<DevoirSurv />} />
        <Route path="devoirSurv/:id" element={<DevoirNote />} />
        <Route path="notes/*" element={<Notes />} />
        <Route path="audit-log/*" element={<AuditLog />} />
        <Route path="cahier_transmission/*" element={<CahierTransmission />} />
        <Route path="page-mobile" element={<PageMobile />} />

        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
