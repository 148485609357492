import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { byteSize, openFile, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './eleve-detail.css'; // Import custom CSS
import { Row, Col, Card, CardBody, CardTitle, Button, Spinner, ModalBody, Modal } from 'reactstrap';
import PhotoAlbum from 'react-photo-album'; // Import PhotoAlbum
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './eleve.reducer';
import { IEleve } from 'app/shared/model/eleve.model';

export const EleveDetail = () => {
  const dispatch = useAppDispatch();
  const eleveEntity = useAppSelector(state => state.eleve.entity) as IEleve;
  const [loading, setLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { id } = useParams<'id'>();

  useEffect(() => {
    // Fetch the entity and then set loading to false
    dispatch(getEntity(id)).then(() => setLoading(false));
  }, [dispatch, id]);

  useEffect(() => {
    if (eleveEntity && eleveEntity.galeries) {
      if (eleveEntity.galeries.length > 0) {
      } else {
      }
    } else {
    }
  }, [eleveEntity]);

  if (loading) {
    return (
      <Row className="justify-content-center">
        <Spinner style={{ width: '3rem', height: '3rem' }} color="primary" />
      </Row>
    );
  }

  const openImageModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };
  const photos = eleveEntity.galeries?.map(galerie => ({
    src: `data:${galerie.imageContentType};base64,${galerie.image}`,
    width: 11,
    height: 5,
  }));

  return (
    <Row className="justify-content-center">
      <Col md="8">
        <Card className="eleve-details-card">
          <CardBody>
            <CardTitle tag="h2" data-cy="eleveDetailsHeading" style={{ marginBottom: '25px' }}>
              Détails de l'éléve
            </CardTitle>
            <div className="eleve-photo">
              {eleveEntity.photo && eleveEntity.photoContentType ? (
                <div>
                  <a onClick={() => openFile(eleveEntity.photoContentType, eleveEntity.photo)}>
                    <img src={`data:${eleveEntity.photoContentType};base64,${eleveEntity.photo}`} alt="eleve" />
                  </a>
                  <span>
                    {eleveEntity.photoContentType}, {byteSize(eleveEntity.photo)}
                  </span>
                </div>
              ) : (
                <img src="/path/to/default-avatar.png" alt="default avatar" /> // Fallback image
              )}
            </div>
            <dl className="row eleve-info justify-content-center">
              <dt className="col-sm-3">ID :</dt>
              <dd className="col-sm-9">{eleveEntity.id}</dd>
              <dt className="col-sm-3">Nom :</dt>
              <dd className="col-sm-9">{eleveEntity.nom}</dd>
              <dt className="col-sm-3">Prénom :</dt>
              <dd className="col-sm-9">{eleveEntity.prenom}</dd>
              <dt className="col-sm-3">Date Naissance :</dt>
              <dd className="col-sm-9">
                {eleveEntity.dateNaissance ? (
                  <TextFormat value={eleveEntity.dateNaissance.toString()} type="date" format={APP_LOCAL_DATE_FORMAT} />
                ) : null}
              </dd>
              <dt className="col-sm-3">Lieu Naissance :</dt>
              <dd className="col-sm-9">{eleveEntity.lieuNaissance}</dd>
              <dt className="col-sm-3">Code Massar :</dt>
              <dd className="col-sm-9">{eleveEntity.codeMassar}</dd>
              <dt className="col-sm-3">Sexe :</dt>
              <dd className="col-sm-9">{eleveEntity.sexe}</dd>
              <dt className="col-sm-3">CIN :</dt>
              <dd className="col-sm-9">{eleveEntity.cin}</dd>

              <dt className="col-sm-3">Observation :</dt>
              <dd className="col-sm-9">{eleveEntity.observation}</dd>
              <dt className="col-sm-3">Internal User :</dt>
              <dd className="col-sm-9">{eleveEntity.internalUser ? eleveEntity.internalUser.login : ''}</dd>
              <dt className="col-sm-3">Groupe :</dt>
              <dd className="col-sm-9">{eleveEntity.groupe ? eleveEntity.groupe.nom : ''}</dd>
            </dl>
            <div className="related-entities">
              <h3>les entités relatives : </h3>
              <div className="related-entities-container">
                <div className="related-entity">
                  <h5>Devoirs</h5>
                  {eleveEntity.devoirs && eleveEntity.devoirs.length > 0 ? (
                    <ul>{eleveEntity.devoirs?.map(val => <li key={val.id}>{val.id}</li>)}</ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div className="related-entity">
                  <h5>Service Etablisements</h5>
                  {eleveEntity.serviceEtablisements && eleveEntity.serviceEtablisements.length > 0 ? (
                    <ul>{eleveEntity.serviceEtablisements?.map(val => <li key={val.id}>{val.id}</li>)}</ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div className="related-entity">
                  <h5>Controles</h5>
                  {eleveEntity.controles && eleveEntity.controles.length > 0 ? (
                    <ul>{eleveEntity.controles?.map(val => <li key={val.id}>{val.id}</li>)}</ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div className="related-entity">
                  <h5>Tuteurs</h5>
                  {eleveEntity.tuteurs && eleveEntity.tuteurs.length > 0 ? (
                    <ul>
                      {eleveEntity.tuteurs?.map(val => (
                        <li key={val.id}>
                          {val.internalUser.firstName} {val.internalUser.lastName}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div className="related-entity">
                  <h5>Resources</h5>
                  {eleveEntity.resources && eleveEntity.resources.length > 0 ? (
                    <ul>{eleveEntity.resources?.map(val => <li key={val.id}>{val.id}</li>)}</ul>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
                <div className="related-entity">
                  <h5>Galeries</h5>
                  {eleveEntity.galeries && eleveEntity.galeries.length > 0 ? (
                    <Button color="primary" onClick={() => openImageModal()}>
                      Voir Image
                    </Button>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </div>
            </div>
            <Button tag={Link} to="/eleve" replace color="info" className="mt-3">
              <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/eleve/${eleveEntity.id}/edit`} replace color="primary" className="mt-3">
              <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
            </Button>
          </CardBody>
        </Card>
      </Col>
      <Modal isOpen={isModalOpen} toggle={closeModal}>
        <ModalBody>{<PhotoAlbum layout="rows" photos={photos} />}</ModalBody>
      </Modal>
    </Row>
  );
};

export default EleveDetail;
