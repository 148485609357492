import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AnneeScolaire from './annee-scolaire';
import AnneeScolaireDetail from './annee-scolaire-detail';
import AnneeScolaireUpdate from './annee-scolaire-update';
import AnneeScolaireDeleteDialog from './annee-scolaire-delete-dialog';

const AnneeScolaireRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<AnneeScolaire />} />
    <Route path="new" element={<AnneeScolaireUpdate />} />
    <Route path=":id">
      <Route index element={<AnneeScolaireDetail />} />
      <Route path="edit" element={<AnneeScolaireUpdate />} />
      <Route path="delete" element={<AnneeScolaireDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default AnneeScolaireRoutes;
