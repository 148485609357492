import React from 'react';
import { Badge, Col, Row } from 'reactstrap';
import { IEmploiTempsLigne } from 'app/shared/model/emploi-temps-ligne.model';
import { TextFormat } from 'react-jhipster';
import { APP_TIME_FORMAT } from 'app/config/constants';


export class TimeTableInfo extends React.Component<{ data: IEmploiTempsLigne }> {
  render() {
    return <>
      <Row>
        <Col>

          <Badge color="primary">{this.props.data.name}</Badge></Col>
      </Row>
      <Row>
        <Col>

          {this.props.data.heureDebut ? (
            <Badge color="info"><TextFormat type="date" value={this.props.data.heureDebut.toString()}
                                            format={APP_TIME_FORMAT} /></Badge>
          ) : null}
        </Col>
        <Col>

          {this.props.data.heureFin ? (
            <Badge color="info"><TextFormat type="date" value={this.props.data.heureFin.toString()}
                                            format={APP_TIME_FORMAT} /></Badge>

          ) : null}
        </Col>
      </Row>
    </>;
  }
}
