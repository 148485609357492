import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { IPayeeImpayee } from 'app/shared/model/paiementModel/payee-Impayee.model';
import { Mois } from 'app/shared/model/enumerations/mois.model';
import { StatusPaiement } from 'app/shared/model/enumerations/Status-paiement.model';
import { getEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { getPayeeImpayees, getPayeeImpayeesAnnuel } from 'app/entities/recu/recu.reducer';
import { getServiceEtablissementEntities } from 'app/entities/service-etablisement/service-etablisement.reducer';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';
import { Alert, Badge, Pagination, Select, Table } from 'antd';
import { Option } from 'antd/es/mentions';
import { GoLoading } from 'app/shared/loading';
import { ServiceEtablisementType } from 'app/shared/model/enumerations/service-etablisement-type.model';

export const RubriquesAnnuelles = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();

  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const niveaux = useAppSelector(state => state.niveau.entities);
  const serviceEtablisements = useAppSelector(state => state.serviceEtablisement.entities) as IServiceEtablisement[];
  const payeeImpayeeData = useAppSelector(state => state.recu.payeeImpayeeAnnuelData) as IPayeeImpayee[];
  const [filtredPayeeImpayeeData, setFiltredPayeeImpayeeData] = useState<IPayeeImpayee[]>([]);
  const loadPayeeImpayee = useAppSelector(state => state.recu.loadPayeeImpayeeAnnuel);
  const totalItemsPayeeImpayee = useAppSelector(state => state.recu.totalItemsPayeeImpayeeAnnuel);
  const [selectedService, setSelecteService] = useState('Tout');
  const [selectedStatusPaiement, setStatusPaiement] = useState('Tout');
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const statusPaiement = Object.values(StatusPaiement);

  const getAllEntities = () => {
    dispatch(getNiveaux({}));
    dispatch(getServiceEtablissementEntities({}));
    dispatch(
      getPayeeImpayeesAnnuel({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        statusFilter: selectedStatusPaiement ? selectedStatusPaiement : null,
        filterAnnuellService: true,
        serviceEtablisementId: selectedServiceId ? selectedServiceId : null,
      }),
    );
  };

  useEffect(() => {
    setFiltredPayeeImpayeeData(payeeImpayeeData);
  }, [payeeImpayeeData]);

  useEffect(() => {
    getAllEntities();
  }, []);
  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    setPaginationState(prevState => ({
      ...prevState,
      activePage: 1,
    }));
    if (paginationState.activePage == 1) {
      getAllEntities();
    }
  }, [selectedStatusPaiement, selectedServiceId]);

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);
  const columns = [
    {
      title: 'Eleve',
      dataIndex: 'eleve',
      render: (text, record) =>
        record.eleve && (
          <Link to={`/eleve/${record.eleve.id}`}>
            {record.eleve.prenom} {record.eleve.nom}
          </Link>
        ),
    },
    {
      title: 'Tuteur',
      dataIndex: ['tuteur', 'id'],
      render: (text, record) =>
        record.tuteur ? <Link to={`/tuteur/${record.tuteur.id}`}>{record.tuteur.internalUser.firstName}</Link> : '',
    },
    {
      title: 'Montant',
      dataIndex: 'montantAPayer',
    },
    {
      title: 'Montant Payé',
      dataIndex: 'montant',
    },
    {
      title: 'Reste',
      dataIndex: 'rest',
      render: (text, record) =>
        record.rest === 0 ? (
          <Badge.Ribbon text={StatusPaiement.PAYE} color="green">
            {record.rest}
          </Badge.Ribbon>
        ) : record.montant === 0 ? (
          <Badge.Ribbon text={StatusPaiement.IMPAYE} color="red">
            {record.rest}
          </Badge.Ribbon>
        ) : (
          <Badge.Ribbon text={StatusPaiement.AVANCE} color="orange">
            {record.rest}
          </Badge.Ribbon>
        ),
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  const handleSelectStatusPaiement = status => {
    setStatusPaiement(status);
  };
  const handleSelectServiceEtablissement = eventKey => {
    if (eventKey === 'Tout') {
      setSelectedServiceId(null);
      return;
    }
    const selectedService = JSON.parse(eventKey);
    setSelecteService(selectedService.libelle);
    setSelectedServiceId(Number(selectedService.id));
  };
  return (
    <div>
      <div className="flex-row-center space-x padding-top-left">
        <div className="flex-row-center">
          <Select
            style={{ width: 200, color: 'grey', marginLeft: '8px' }}
            placeholder={`${selectedService ? selectedService : 'Services annuels'}`}
            onChange={handleSelectServiceEtablissement}
          >
            <Option key="Tout" value="Tout">
              Tout
            </Option>
            {serviceEtablisements
              .filter(serviceEtablisement => serviceEtablisement.type === ServiceEtablisementType.ANNUELLE)
              .map(service => (
                <Option key={service.id.toString()} value={JSON.stringify({ libelle: service.libelle, id: service.id })}>
                  {service.libelle}
                </Option>
              ))}
          </Select>
        </div>
        <div className="flex-row-center">
          <Select
            style={{ width: 200, color: 'grey', marginLeft: '8px' }}
            placeholder={`Status Paiement`}
            onChange={handleSelectStatusPaiement}
          >
            <Option key="Tout" value="tout">
              Tout
            </Option>
            {statusPaiement.map(status => {
              return <Option key={status}>{status}</Option>;
            })}
          </Select>
        </div>
      </div>
      <div className="table-responsive mt-4">
        {loadPayeeImpayee ? (
          <GoLoading loading={loadPayeeImpayee} />
        ) : filtredPayeeImpayeeData && filtredPayeeImpayeeData.length > 0 ? (
          <Table columns={columns} dataSource={filtredPayeeImpayeeData} rowKey="id" loading={loadPayeeImpayee} pagination={false} />
        ) : (
          !loadPayeeImpayee && <Alert message="Aucun Paiement Trouvé" type="warning" showIcon />
        )}
      </div>
      {!loadPayeeImpayee && totalItemsPayeeImpayee && filtredPayeeImpayeeData && filtredPayeeImpayeeData.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePagination}
            total={totalItemsPayeeImpayee}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default RubriquesAnnuelles;
