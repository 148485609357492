import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Card, notification, List, Radio, Space } from 'antd';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import Box from '@mui/material/Box';
import './styling.scss';
import { IGarderie } from 'app/shared/model/garderie.model';
import { IGarderieNotif } from 'app/shared/model/IGarderieNotif';
import { GarderieStatus } from 'app/shared/model/enumerations/garderie-status.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteGarderie, getGarderies } from 'app/entities/garderie/garderie.reducer';
import dayjs from 'dayjs';
import { usePusher } from 'app/shared/util/pusherClient';

const Garderie = () => {
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [notificationPop, setNotificationPop] = useState<IGarderieNotif>(null);
  const garderies = useAppSelector(state => state.garderie.garderies) as IGarderie[];
  const [isGone, setIsGone] = useState<Record<number, boolean>>({});

  const handleEleveGone = (id: number) => {
    setIsGone(prevState => ({
      ...prevState,
      [id]: true,
    }));
    dispatch(deleteGarderie(id));
  };
  let pusherInstance = usePusher();

  useEffect(() => {
    if (pusherInstance) {
      const channel = pusherInstance.subscribe('garderie-channel');

      channel.bind('garderie-updated', data => {
        const garderieNotification = JSON.parse(data);
        setNotificationPop(garderieNotification);
        dispatch(getGarderies({}));
      });

      return () => {
        pusherInstance.unsubscribe('garderie-channel');
      };
    }
  }, [pusherInstance]);

  useEffect(() => {
    dispatch(getGarderies({}));
  }, []);

  const openNotificationWithIcon = (type: string, notificationPop: IGarderieNotif) => {
    const description = getDescriptionText(notificationPop.type, notificationPop.tuteurName, notificationPop.eleveName);
    api[type]({
      message: `Notification from ${notificationPop.tuteurName}`,
      description: description,
    });
  };
  const initialData = [
    {
      title: 'Ahmed Ben',
      phone: '06 51 35 87 37',
      image: 'https://img.freepik.com/free-photo/view-child-hair-salon_23-2150462483.jpg',
      description: 'En route',
      tuteur: 'Mouad Ben ',
      enfant: ' Ahmed Ben',
      isNew: true,
    },
    {
      title: 'Karim saad',
      phone: '06 51 35 87 37',
      image: 'https://www.shutterstock.com/image-photo/mixed-race-boy-smiling-portrait-260nw-504817279.jpg',
      description: 'Arrive',
      tuteur: 'Samir Saad ',
      enfant: ' Karim saad',
    },
    {
      title: 'Meriem boouza',
      phone: '06 51 35 87 37',
      image: 'https://www.shutterstock.com/image-photo/portrait-beautiful-european-boy-2-260nw-1768298075.jpg',
      description: 'Retard',
      tuteur: 'Halima fahid ',
      enfant: ' Meriem boouza',
    },
    {
      title: 'Ahmed Naaman',
      phone: '06 51 35 87 37',
      image:
        'https://media.istockphoto.com/id/1252971530/photo/emotional-boy-in-a-blue-t-shirt-close-up.jpg?s=612x612&w=is&k=20&c=2udtH_cCBuqRD42eMqMDO-rtmm7mujGqmBZTICgwYaY=',
      description: 'Tiers',
      tuteur: 'Abdellah Naaman ',
      enfant: ' Ahmed Naaman',
    },
  ];

  const data = Array.from({ length: 20 }, (_, i) => initialData[i % initialData.length]);
  // useEffect(() => {
  //   openNotificationWithIcon('info');
  // }, []);

  useEffect(() => {
    if (notificationPop && notificationPop.type) {
      const notificationType = getNotificationType(notificationPop.type);
      openNotificationWithIcon(notificationType, notificationPop);
    }
  }, [notificationPop]);
  const getColorForDescription = description => {
    switch (description) {
      case 'Retard':
        return 'red';
      case 'En_route':
        return 'blue';
      case 'Arrive':
        return 'green';
      default:
        return 'orange';
    }
  };
  const getNotificationType = (status): 'success' | 'error' | 'info' | 'warning' | 'open' | 'destroy' => {
    switch (status) {
      case 'En_route':
        return 'info';
      case 'Arrive':
        return 'success';
      case 'Retard':
        return 'warning';
      case 'Tiers':
        return 'error';
      default:
        return 'info';
    }
  };
  const getDescriptionText = (status: any, tuteurName: string | null, eleveName: string | null): string => {
    switch (status) {
      case GarderieStatus.Arrive:
        return `${tuteurName} arrivera dans quelques minutes pour ramener son enfant ${eleveName}.`;
      case GarderieStatus.Retard:
        return `${tuteurName} est en retard pour ramener son enfant ${eleveName}.`;
      case GarderieStatus.Tiers:
        return `${tuteurName} a désigné quelqu'un d'autre pour ramener son enfant ${eleveName}.`;
      case GarderieStatus.En_route:
        return `${tuteurName} est en route pour ramener son enfant ${eleveName}.`;
      default:
        return `${tuteurName} arrivera dans quelques minutes pour ramener son enfant ${eleveName}.`;
    }
  };
  return (
    <div>
      {contextHolder}
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '4px' }}>
        <div style={{ width: '95vw', height: '100vh' }}>
          <Badge.Ribbon text="Après-midi">
            <Card
              title="Mercredi 24 avril 2024"
              bordered={true}
              style={{ background: '#FFFFFF', filter: 'drop-shadow(0 0 0.75rem #eed5db)' }}
              className="custom-card"
            >
              <Box className="card-style--garderie">
                <List
                  grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 2,
                    xl: 2,
                    xxl: 3,
                  }}
                  dataSource={garderies}
                  renderItem={(item: IGarderie, index) => (
                    <List.Item
                      style={{
                        borderBottom: '1px solid #d9d9d9',
                        borderRight: index % 2 === 0 ? '1px solid #d9d9d9' : 'none',
                        marginBottom: '16px',
                        paddingRight: index % 2 === 0 ? '16px' : '0',
                        height: '130px',
                        paddingBottom: '20px',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            paddingBottom: '10px',
                            width: '100%',
                          }}
                        >
                          <div>
                            <AccessTimeIcon style={{ fontSize: '16px', opacity: '40%' }} />
                            <span style={{ fontSize: '12px', opacity: '40%' }}>{dayjs(item?.createdAt).format('HH:mm:ss')}</span>
                          </div>
                          {index === 1 && (
                            <div style={{ color: '#FF4C4C' }}>
                              <AddAlertIcon />
                              <span style={{ fontSize: '10px', opacity: '40%' }}>Nouveau</span>
                            </div>
                          )}
                        </div>
                        <List.Item.Meta
                          style={{ width: '100%' }}
                          avatar={<Avatar src={`data:image/jpeg;base64,${item.eleve?.photo}`} />}
                          title={
                            <div
                              style={{
                                display: 'flex',
                                fontSize: '16px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                              }}
                            >
                              <a href="https://ant.design" style={{ color: '#000', textDecoration: 'none' }}>
                                {item.tuteur?.firstName} {item.tuteur?.lastName}{' '}
                              </a>
                              <span style={{ marginLeft: '10px', opacity: '60%' }}>{item.tuteur?.tel}</span>
                            </div>
                          }
                          description={
                            <div
                              style={{
                                display: 'flex',
                                fontSize: '16px',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                paddingTop: '14px',
                              }}
                            >
                              <div style={{ fontSize: '16px' }}>
                                <span style={{ color: '#FF4C4C', fontWeight: 'bold', opacity: '80%' }}>
                                  {' '}
                                  {item.tuteur?.firstName} {item.tuteur?.lastName}{' '}
                                </span>
                                <span
                                  style={{
                                    fontWeight: 'bold',
                                    opacity: '80%',
                                    color: getColorForDescription(item.type),
                                  }}
                                >
                                  {item.type}
                                </span>
                                <span style={{ color: '#FF4C4C', fontWeight: 'bold', opacity: '80%' }}>
                                  {' '}
                                  {item.eleve?.prenom} {item.eleve?.nom}
                                </span>
                              </div>
                              <DirectionsRunIcon
                                style={{
                                  fontSize: '26px',
                                  color: isGone[item.id] ? 'green' : '',
                                  pointerEvents: isGone[item.id] ? 'none' : 'auto',
                                  cursor: isGone[item.id] ? 'default' : 'pointer',
                                }}
                                onClick={() => !isGone[item.id] && handleEleveGone(item.id)}
                              />
                            </div>
                          }
                        />
                      </div>
                    </List.Item>
                  )}
                />
              </Box>
            </Card>
          </Badge.Ribbon>
        </div>
      </div>
    </div>
  );
};

export default Garderie;
