import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IBoard, defaultValue } from 'app/shared/model/board.model';
import { ITDBComptableBilan, defaultTDBComptableBilan } from 'app/shared/model/ITDBComptableBilan.model';

const initialState: EntityState<IBoard> & {
  loading: boolean;
  errorMessage: string | null;
  entities: IBoard[];
  entity: IBoard;
  bilan: ITDBComptableBilan;
  bilanLoading: boolean;
  updating: boolean;
  totalItems: number;
  updateSuccess: boolean;
} = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  bilan: defaultTDBComptableBilan,
  bilanLoading: false,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/etablissements';

export const getBoard = createAsyncThunk(
  'etablissement/fetch_board',
  async () => {
    const requestUrl = `${apiUrl}/board`;
    return axios.get<IBoard>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getTDBComptableBilan = createAsyncThunk(
  'etablissement/fetch_bilan',
  async () => {
    const requestUrl = `${apiUrl}/bilan`;
    return axios.get<ITDBComptableBilan>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const BoardSlice = createEntitySlice({
  name: 'board',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getBoard.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getTDBComptableBilan.pending, (state: any, action) => {
        state.bilanLoading = true;
      })
      .addCase(getTDBComptableBilan.fulfilled, (state: any, action) => {
        state.bilanLoading = false;
        state.bilan = action.payload.data;
      });
  },
});

export const { reset } = BoardSlice.actions;

// Reducer
export default BoardSlice.reducer;
