import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { getEntity, updateEntity, createEntity, reset } from './page-mobile.reducer';
import { IPageMobile, defaultValue } from 'app/shared/model/page-mobile.model';
import { useAppSelector } from 'app/config/store';
import { Typography } from 'antd';

const { Title } = Typography;

const PageMobileUpdate = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [isNew, setIsNew] = useState(!id);
  const pagemobileEntity = useAppSelector(state => state.pageMobile.entity);
  const loading = useAppSelector(state => state.pageMobile.loading);
  const updating = useAppSelector(state => state.pageMobile.updating);
  const updateSuccess = useAppSelector(state => state.pageMobile.updateSuccess);

  const handleClose = () => {
    navigate('/page-mobile' + location.search);
  };

  const saveEntity = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const entity = { ...pageMobileEntity };

    if (isNew) {
      // @ts-ignore
      dispatch(createEntity(entity));
    } else {
      // @ts-ignore
      dispatch(updateEntity(entity));
    }
  };

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPageMobileEntity({ ...pageMobileEntity, [name]: value });
  };

  const [pageMobileEntity, setPageMobileEntity] = useState<IPageMobile>(defaultValue);

  return (
    <div>
      <h2>{isNew ? 'Create PageMobile' : 'Edit PageMobile'}</h2>
      <form onSubmit={saveEntity}>
        <div>
          <label>ID</label>
          <input type="text" name="id" value={pageMobileEntity.id} onChange={handleFieldChange} disabled={!isNew} />
        </div>
        <div>
          <label>Name</label>
          <input type="text" name="name" value={pagemobileEntity.name} onChange={handleFieldChange} />
        </div>
        <button type="submit" disabled={loading || updating}>
          Save
        </button>
        <button type="button" onClick={handleClose} disabled={loading || updating}>
          Cancel
        </button>
      </form>
    </div>
  );
};

export default PageMobileUpdate;
