import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Col, List, message, Popconfirm, Row, Space, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntities } from './matiere.reducer';
import { getPaginationState } from 'react-jhipster';
import { GoLoading } from 'app/shared/loading';
import { IMatiere } from 'app/shared/model/matiere.model';
import { Title } from 'chart.js';

export const Matiere = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const [currentId, setCurrentId] = useState(null);

  const matiereList = useAppSelector(state => state.matiere.entities);
  const loading = useAppSelector(state => state.matiere.loading);
  const totalItems = useAppSelector(state => state.matiere.totalItems);
  const { Text, Title } = Typography;

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(currentId));
    message.success('Supprimé');
  };

  const cancelDelete = () => {
    message.error('Annulé');
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: text => (
        <Button type="link" href={`/matiere/${text}`}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
    },
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: true,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/matiere/${record.id}`}>
            <Button type="link" color="info" icon={<FontAwesomeIcon icon={faEye} />}></Button>
          </Link>
          <Link to={`/matiere/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}>
            <Button type="link" color="primary" icon={<FontAwesomeIcon icon={faPencilAlt} />} style={{ color: 'green' }}></Button>
          </Link>
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer ?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" danger onClick={() => setCurrentId(record.id)} icon={<FontAwesomeIcon icon={faTrash} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="matiere-heading" data-cy="MatiereHeading">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="orange">
          Matières
        </Tag>
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/matiere/new">
            <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
              Créer une nouvelle Matière
            </Button>
          </Link>
        </div>
      </h2>
      {/*<Table
        columns={columns}
        dataSource={matiereList}
        rowKey="id"
        pagination={{
          current: paginationState.activePage,
          pageSize: paginationState.itemsPerPage,
          total: totalItems,
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={handleTableChange}
      />*/}
      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : matiereList && matiereList.length > 0 ? (
          <List
            grid={{ gutter: 16, column: 2 }}
            dataSource={matiereList}
            renderItem={(item: IMatiere) => {
              return (
                <List.Item
                  key={item.id}
                  onClick={() => navigate(`/matiere/${item.id}`)}
                  className="hoverable-list-item"
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Badge
                        count={item.code}
                        style={{
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                      <Title level={5}>{item.nom}</Title>
                    </Col>
                    <Col>
                      <Space size="small">
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/matiere/${item.id}`);
                          }}
                          type="link"
                          color="info"
                          icon={<FontAwesomeIcon icon={faEye} />}
                        ></Button>
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(
                              `/matiere/${item.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
                            );
                          }}
                          type="link"
                          color="primary"
                          icon={<FontAwesomeIcon icon={faPencilAlt} />}
                          style={{ color: 'green' }}
                        ></Button>
                        <Button
                          type="link"
                          danger
                          onClick={e => {
                            e.stopPropagation();
                            setCurrentId(item.id);
                            navigate(`/matiere/${item.id}/delete`);
                          }}
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun Matiere trouvé" type="warning" showIcon />
        )}
      </div>
    </div>
  );
};

export default Matiere;
