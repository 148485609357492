import React, { CSSProperties, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Form, Input, Row, Select, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, reset, updateEntity } from './emploi-temps.reducer';
import { EmploiTempsType } from 'app/shared/model/enumerations/emploi-temps-type.model';

const { Title } = Typography;
const { Option } = Select;

const EmploiTempsUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const emploiTempsEntity = useAppSelector(state => state.emploiTemps.entity);
  const loading = useAppSelector(state => state.emploiTemps.loading);
  const updating = useAppSelector(state => state.emploiTemps.updating);
  const updateSuccess = useAppSelector(state => state.emploiTemps.updateSuccess);
  const emploiTempsTypeValues = Object.keys(EmploiTempsType);

  const handleClose = () => {
    navigate('/emploi-temps' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, [isNew, dispatch, id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values: any) => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    console.log(values);

    const entity = {
      ...emploiTempsEntity,
      ...values,
      type: values.type,
    };
    console.log(entity);

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? { type: EmploiTempsType.GROUPE }
      : {
          ...emploiTempsEntity,
        };

  const styles: { [key: string]: CSSProperties } = {
    container: {
      color: 'white',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      marginTop: '20px',
    },
    heading: {
      color: 'rgb(2, 2, 44)',
      textAlign: 'center',
      marginBottom: '20px',
    },
    formControl: {
      marginBottom: '10px',
    },
    buttonGroup: {
      textAlign: 'center',
      marginTop: '20px',
    },
    buttonInfo: {
      backgroundColor: 'grey',
      color: 'white',
      border: 'none',
      marginRight: '10px',
    },
    buttonPrimary: {
      backgroundColor: 'red',
      color: 'white',
      border: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <Row className="justify-content-center">
        <Col span={16}>
          <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="geekblue">
            Créer un Emploi Temps
          </Tag>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col span={16}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form layout="vertical" initialValues={defaultValues()} onFinish={saveEntity}>
              {!isNew ? (
                <Form.Item name="id" label="Numéro" rules={[{ required: true, message: 'Numéro is required' }]}>
                  <Input readOnly />
                </Form.Item>
              ) : null}

              <Form.Item name="nom" label="Nom" rules={[{ required: true, message: 'Nom is required' }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Type" id="emploi-temps-type" name="type" data-cy="type">
                <Select>
                  {emploiTempsTypeValues.map(emploiTempsType => (
                    <Option value={emploiTempsType} key={emploiTempsType}>
                      {emploiTempsType}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <div style={styles.buttonGroup}>
                <Button type="default" onClick={() => navigate('/emploi-temps')} style={styles.buttonInfo}>
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp; Retour
                </Button>
                <Button type="primary" htmlType="submit" loading={updating} style={styles.buttonPrimary}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Sauvegarder
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EmploiTempsUpdate;
