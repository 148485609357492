import { INiveau } from 'app/shared/model/niveau.model';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';
import { IEleve } from 'app/shared/model/eleve.model';
import { IRecu } from 'app/shared/model/recu.model';

export interface ITarif {
  id?: number;
  prix?: number | null;
  niveau?: INiveau | null;
  serviceEtablisement?: IServiceEtablisement | null;
  eleve?: IEleve | null;
  recuses?: IRecu[] | null;
  checked?: boolean | null;
  beginPaiement?: boolean | null;
}

export const defaultValue: Readonly<ITarif> = {};
