import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ImportEleve from './import-eleve';
import ImportEleveDetail from './import-eleve-detail';
import ImportEleveUpdate from './import-eleve-update';
import ImportEleveDeleteDialog from './import-eleve-delete-dialog';

const ImportEleveRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ImportEleve />} />
    <Route path="new" element={<ImportEleveUpdate />} />
    <Route path=":id">
      <Route index element={<ImportEleveDetail />} />
      <Route path="edit" element={<ImportEleveUpdate />} />
      <Route path="delete" element={<ImportEleveDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ImportEleveRoutes;
