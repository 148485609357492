import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Tuteur from './tuteur';
import TuteurDetail from './tuteur-detail';
import TuteurUpdate from './tuteur-update';
import TuteurDeleteDialog from './tuteur-delete-dialog';

const TuteurRoutes = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <ErrorBoundaryRoutes>
      <Route index element={<Tuteur />} />
      <Route path="new" element={<TuteurUpdate toggleModal={toggleModal} />} />
      <Route path=":id">
        <Route index element={<TuteurDetail />} />
        <Route path="edit" element={<TuteurUpdate toggleModal={toggleModal} />} />
        <Route path="delete" element={<TuteurDeleteDialog />} />
      </Route>
    </ErrorBoundaryRoutes>
  );
};

export default TuteurRoutes;
