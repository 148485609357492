import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import MoisEleve from './mois-eleve';
import MoisEleveDetail from './mois-eleve-detail';
import MoisEleveUpdate from './mois-eleve-update';
import MoisEleveDeleteDialog from './mois-eleve-delete-dialog';

const MoisEleveRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<MoisEleve />} />
    <Route path="new" element={<MoisEleveUpdate />} />
    <Route path=":id">
      <Route index element={<MoisEleveDetail />} />
      <Route path="edit" element={<MoisEleveUpdate />} />
      <Route path="delete" element={<MoisEleveDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default MoisEleveRoutes;
