import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { createEntity, updateEntity as updateAnneeScolaire } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { useAppDispatch } from 'app/config/store';
import { Form, Input, Switch, Button, message } from 'antd';

const CreateAnneeScolaireModal = ({ show, handleClose, anneeScolaire }) => {
  const inpt = anneeScolaire == null ? '' : anneeScolaire.periode;

  const [inputValue, setInputValue] = useState(inpt);
  useEffect(() => {
    setInputValue(inpt);
  }, [inpt]);

  const dispatch = useAppDispatch();

  const isNew = anneeScolaire == null;

  const handleSave = values => {
    const entity = {
      ...values,
      periode: inputValue,
    } as IAnneeScolaire;

    if (isNew) {
      dispatch(createEntity(entity));
      message.success('Année scolaire créée avec succès !');
    } else {
      entity.id = anneeScolaire.id;
      dispatch(updateAnneeScolaire(entity));
      message.success('Année scolaire mise à jour avec succès !');
    }
    handleClose();
  };

  const defaultValues = () => {
    return isNew
      ? {}
      : {
          periode: inpt,
          isCurrent: anneeScolaire?.isCurrent,
        };
  };

  const validatePeriode = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Veuillez entrer l'année scolaire !"));
    }
    const regex = /^\d{4}-\d{4}$/;
    if (!regex.test(value)) {
      return Promise.reject(new Error("Le format doit être 'yyyy-yyyy' !"));
    }
    const years = value.split('-').map(Number);
    if (years[1] - years[0] !== 1 || years[0] >= years[1]) {
      return Promise.reject(new Error("L'année de fin doit être égale à l'année de début + 1 !"));
    }
    return Promise.resolve();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{isNew ? 'Créer un nouveau Année Scolaire' : "Modifier l'année scolaire"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form name="basic" initialValues={defaultValues()} onFinish={handleSave} autoComplete="off">
          <Form.Item name="periode" rules={[{ validator: validatePeriode }]}>
            <Input placeholder="Entrez l'année scolaire" value={inputValue} onChange={e => setInputValue(e.target.value)} />
          </Form.Item>
          <Form.Item label="Année courante" name="isCurrent" valuePropName="checked">
            <Switch disabled={anneeScolaire?.isCurrent} />
          </Form.Item>
          <Form.Item>
            <Modal.Footer>
              <Button onClick={handleClose}>Annuler</Button>
              <Button type="primary" htmlType="submit">
                Valider
              </Button>
            </Modal.Footer>
          </Form.Item>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CreateAnneeScolaireModal;
