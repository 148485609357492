import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Pagination, Space, Select, Alert } from 'antd';
import { EyeOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { getPaginationState } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './controle.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import dayjs from 'dayjs';
import { GoLoading } from 'app/shared/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;

export const Controle = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedEleve, setSelectedEleve] = useState('');

  const controleList = useAppSelector(state => state.controle.entities);
  const loading = useAppSelector(state => state.controle.loading);
  const totalItems = useAppSelector(state => state.controle.totalItems);
  const eleves = useAppSelector(state => state.eleve.entities);

  const getAllEntities = () => {
    const withRealAnneeScolaire = false; // set this to the desired value

    let filter = selectedEleve ? `eleveId.equals=${selectedEleve}` : ``;

    if (filter) {
      filter += `&withRealAnneeScolaire=${withRealAnneeScolaire}`;
    } else {
      filter = `withRealAnneeScolaire=${withRealAnneeScolaire}`;
    }
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: filter,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedEleve]);

  useEffect(() => {
    dispatch(getEleves({}));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleEleveChange = value => {
    setSelectedEleve(value);
  };

  const getSortIconByFieldName = fieldName => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return <SortAscendingOutlined />;
    } else {
      return order === ASC ? <SortAscendingOutlined /> : <SortDescendingOutlined />;
    }
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: text => (
        <Button type="link" onClick={() => navigate(`/controle/${text}`)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Libelle',
      dataIndex: 'libelle',
      sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: true,
      render: date => (date ? dayjs(date).format('YYYY-MM-DD') : null),
    },
    {
      title: 'Matiere',
      dataIndex: 'matiere',
      render: matiere => <span>{matiere ? matiere.nom : ''}</span>,
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/controle/${record.id}`}>
            <Button type="link" icon={<EyeOutlined />}>
              Voir
            </Button>
          </Link>
          <Link to={`/controle/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faEdit} />} style={{ color: 'green' }}>
              Editer
            </Button>
          </Link>
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            type="link"
            danger
            onClick={() =>
              (window.location.href = `/controle/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
          >
            Supprimer
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      <h2 id="controle-heading" data-cy="ControleHeading">
        Contrôles
      </h2>
      <div className="d-flex justify-content-end mb-3">
        <Button className="me-2" icon={<FontAwesomeIcon icon={faSync} spin={loading} />} onClick={handleSyncList} disabled={loading}>
          Actualiser la liste
        </Button>
        <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />} onClick={() => navigate('/controle/new')}>
          Créer un nouveau Contrôle
        </Button>
      </div>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Space size="large">
          Sélectionner élève:
          <Select
            showSearch
            value={selectedEleve}
            onChange={handleEleveChange}
            placeholder="Élève"
            style={{ width: 200 }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {eleves.map(eleve => (
              <Option key={eleve.id} value={eleve.id}>
                {eleve.nom} {eleve.prenom}
              </Option>
            ))}
          </Select>
        </Space>
      </div>
      <div className="table-responsive mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : controleList && controleList.length > 0 ? (
          <Table columns={columns} dataSource={controleList} rowKey="id" loading={loading} pagination={false} />
        ) : (
          !loading && <Alert message="Aucun Contrôles trouvé" type="warning" showIcon />
        )}
      </div>
      {!loading && totalItems && controleList && controleList.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePagination}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Controle;
