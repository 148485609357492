import { IUser } from 'app/shared/model/user.model';
import { IEleve } from 'app/shared/model/eleve.model';

export interface ITuteur {
  id?: number;
  tel?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  observation?: string | null;
  cin?: string | null;
  adresse?: string | null;
  relationAvecEleve?: string | null;
  internalUser?: IUser | null;
  enfants?: IEleve[] | null;
  principale?: boolean | null;
  activated?: boolean | null;
}

export const defaultValue: Readonly<ITuteur> = {};
