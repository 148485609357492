import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IMatiere } from 'app/shared/model/matiere.model';
import { Sexe } from 'app/shared/model/enumerations/sexe.model';
import { IEmploiTemps } from 'app/shared/model/emploi-temps.model';
import { IGroupe } from 'app/shared/model/groupe.model';

export interface IProf {
  id?: number;
  imageContentType?: string | null;
  image?: string | null;
  dateNaissance?: dayjs.Dayjs | null;
  email?: string | null;
  cin?: string | null;
  firtName?: string | null;
  lastName?: string | null;
  login?: string | null;
  prixHeure?: number | null;
  sexe?: keyof typeof Sexe | null;
  tel?: string | null;
  vacataire?: boolean | null;
  internalUser?: IUser | null;
  matieres?: IMatiere[] | null;
  emploisTemps?: IEmploiTemps | null;
  groupeIds?: number[] | null;
  groupes?: IGroupe[] | null;
}

export const defaultValue: Readonly<IProf> = {
  vacataire: false,
};
