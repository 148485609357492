export interface AggregatedPayment {
  rubrique: string;
  totalMontantAPayer: number;
  totalRest: number;
}

export interface ITDBComptableBilan {
  aggregatedPayments: Record<string, AggregatedPayment>;
  totalMontantAPayer: number;
  totalRest: number;
}

export const defaultTDBComptableBilan: Readonly<ITDBComptableBilan> = {
  aggregatedPayments: {},
  totalMontantAPayer: 0,
  totalRest: 0,
};
