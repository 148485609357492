import React, { useEffect, useState } from 'react';
import { Button, Input, InputNumber, notification, Select, Table } from 'antd';
import { Image } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getElevesOfGroupe } from 'app/entities/eleve/eleve.reducer';
import { IEleve } from 'app/shared/model/eleve.model';
import { INoteResponse } from 'app/shared/model/gestionNote/note.model';
import {
  generatePdfGroupeNotes,
  getNotesByEleveGroupe,
  updateNoteEleve,
  validateNotesOfEleveGroupe,
} from 'app/entities/gestion-notes/devoirSurv.reducer';
import { IDevoirSurvResponse } from 'app/shared/model/gestionNote/devoir-surv.model';
import { FileExcelFilled } from '@ant-design/icons';
import { CSVLink } from 'react-csv';

const DevoirNotePerGroupe = ({ groupeId }) => {
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const elevesOfTheGroupe = useAppSelector(state => state.eleve.entities) as IEleve[];
  const notesByElevesGroupe = useAppSelector(state => state.devoirSurv.notesByElevesGroupe) as INoteResponse[];
  const oneDevoirSurv = useAppSelector(state => state.devoirSurv.entity) as IDevoirSurvResponse;
  const loadNotesByElevesGroupe = useAppSelector(state => state.devoirSurv.loadNotesByElevesGroupe) as boolean;
  const validateNotesSuccess = useAppSelector(state => state.devoirSurv.validateNotesSuccess) as boolean;
  const loadValidateNotes = useAppSelector(state => state.devoirSurv.loadValidateNotes) as boolean;

  useEffect(() => {
    dispatch(
      getElevesOfGroupe({
        groupeId,
      }),
    );
  }, []);

  useEffect(() => {
    if (elevesOfTheGroupe.length > 0) {
      console.log(elevesOfTheGroupe.map(eleve => eleve.id));
      dispatch(
        getNotesByEleveGroupe({
          eleveIds: elevesOfTheGroupe.map(eleve => eleve.id),
          devoirSurvId: oneDevoirSurv?.id,
        }),
      );
    }
  }, [elevesOfTheGroupe]);

  const observationOptions = [
    { label: 'Passable', value: 'Passable' },
    { label: 'Assez Bien', value: 'Assez Bien' },
    { label: 'Bien', value: 'Bien' },
    { label: 'Très Bien', value: 'Très Bien' },
    { label: 'Excellent', value: 'Excellent' },
  ];

  const columns = [
    {
      title: 'Photo',
      dataIndex: 'eleve',
      key: 'eleve',
      render: (text, record) => {
        if (record?.eleve?.photoContentType && record?.eleve?.photo) {
          return (
            <Image
              src={`data:${record?.eleve?.photoContentType};base64,${record?.eleve?.photo}`}
              alt="photo"
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          );
        } else {
          return <span>No Image</span>;
        }
      },
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      key: 'nom',
      render: (text, record) => record?.eleve?.nom,
    },
    {
      title: 'Code Massar',
      dataIndex: 'codeMassar',
      key: 'codeMassar',
      render: (text, record) => record?.eleve?.codeMassar,
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      render: (text, record) => (
        <InputNumber
          min={0}
          max={20}
          value={record?.note}
          onChange={value => handleNoteChange(value as number, record?.eleve?.id)}
          formatter={value => value?.toString().replace('.', ',')}
          parser={value => value?.replace(',', '.')}
          style={{ width: '80px' }}
        />
      ),
    },
    {
      title: 'Observation',
      dataIndex: 'observation',
      key: 'observation',
      render: (text, record) => (
        <Select
          // value={observations[record.id]}
          // onChange={value => handleObservationChange(value, record.id)}
          options={observationOptions}
          style={{ width: '120px' }}
        />
      ),
    },
    // {
    //   title: 'Action',
    //   key: 'action',
    //   render: (text, record) => (
    //     <Button
    //       style={{
    //         backgroundColor: 'green',
    //       }}
    //       type="primary"
    //     >
    //       Validé La Note
    //     </Button>
    //   ),
    // },
  ];
  const handleNoteChange = (value: number | null, id: number) => {
    // Dispatch the action to update the note
    if (value !== null) {
      dispatch(updateNoteEleve({ id, note: value.toString() })); // Convert number to string if needed
    }
  };

  useEffect(() => {
    let groupe = oneDevoirSurv?.groupes.filter(groupe => groupe.id == groupeId)[0]?.nom;
    if (validateNotesSuccess) {
      api.success({
        message: `Information`,
        description: `Les notes pour le groupe ${groupe} sont validées`,
      });
    }
  }, [validateNotesSuccess]);

  const headers = [
    { label: 'Nom', key: 'eleve.nom' },
    { label: 'Code Massar', key: 'eleve.codeMassar' },
    { label: 'Note', key: 'note' },
    { label: 'Observation', key: 'observation' },
  ];

  const csvReport = {
    data: notesByElevesGroupe,
    headers: headers,
    filename: `${oneDevoirSurv?.groupes.filter(groupe => groupe.id == groupeId)[0]?.nom}_${oneDevoirSurv?.titre}_${oneDevoirSurv?.realAnneeScolaire}.csv`,
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
      {contextHolder}
      <Table
        columns={columns}
        dataSource={notesByElevesGroupe}
        rowKey={oneDevoirSurv?.id.toString()}
        loading={loadNotesByElevesGroupe}
        pagination={false}
        scroll={{ y: 400 }}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
        {/*<Button*/}
        {/*  style={{*/}
        {/*    backgroundColor: 'green',*/}
        {/*  }}*/}
        {/*  disabled={loadValidateNotes || notesByElevesGroupe[0]?.id === null}*/}
        {/*  onClick={() => {*/}
        {/*    let groupeNom = oneDevoirSurv?.groupes.filter(groupe => groupe.id == groupeId)[0]?.nom;*/}
        {/*    dispatch(generatePdfGroupeNotes({ notesByElevesGroupe, groupeNom }));*/}
        {/*  }}*/}
        {/*  type="primary"*/}
        {/*>*/}
        {/*  Telecharger Les Notes*/}
        {/*</Button>*/}
        <CSVLink {...csvReport} style={{ textDecoration: 'none' }}>
          <Button
            style={{
              backgroundColor: 'green',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textDecoration: 'none',
              color: 'inherit',
            }}
            disabled={loadValidateNotes || notesByElevesGroupe[0]?.id === null}
            type="primary"
          >
            <FileExcelFilled style={{ marginRight: 4 }} />
            Exporter Les Notes
          </Button>
        </CSVLink>
        <Button
          style={{
            backgroundColor: 'blue',
            marginLeft: '4px',
          }}
          disabled={loadValidateNotes}
          onClick={() => {
            dispatch(
              validateNotesOfEleveGroupe({
                devoirSurvId: oneDevoirSurv?.id,
                notesByElevesGroupe: notesByElevesGroupe,
              }),
            );
          }}
          type="primary"
        >
          Validé Les Notes
        </Button>
      </div>
    </div>
  );
};
export default DevoirNotePerGroupe;
