import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IRecu } from 'app/shared/model/recu.model';
import { IrecuRequest } from 'app/shared/model/paiementModel/recu-request.model';
import { IPayeeImpayee, defaultPayeeImpayee } from 'app/shared/model/paiementModel/payee-Impayee.model';
import { ITarif } from 'app/shared/model/tarif.model';
import { ITuteur } from 'app/shared/model/tuteur.model';

const initialState: EntityState<IRecu> & {
  loading: boolean;
  errorMessage: string | null;
  entities: [];
  payeeImpayeeData: IPayeeImpayee[];
  payeeImpayeeAnnuelData: IPayeeImpayee[];
  entity: IRecu;
  recuSelected: IRecu;
  updating: boolean;
  totalItems: number;
  totalItemsPayeeImpayee?: number;
  totalItemsPayeeImpayeeAnnuel?: number;
  updateSuccess: boolean;
  pdfData: Blob | null;
  generatingPdf: boolean;
  printPdf: boolean;
  loadPayeeImpayee: boolean;
  loadPayeeImpayeeAnnuel: boolean;
  pdfErrorMessage: string | null;
  payeeImpayeeErrorMessage: string | null;
  payeeImpayeeAnnuelErrorMessage: string | null;
} = {
  loading: false,
  errorMessage: null,
  entities: [],
  payeeImpayeeData: [],
  payeeImpayeeAnnuelData: [],
  entity: defaultValue,
  recuSelected: defaultValue,
  updating: false,
  totalItems: 0,
  totalItemsPayeeImpayee: 0,
  totalItemsPayeeImpayeeAnnuel: 0,
  updateSuccess: false,
  pdfData: null,
  generatingPdf: false,
  printPdf: false,
  loadPayeeImpayee: false,
  loadPayeeImpayeeAnnuel: false,
  pdfErrorMessage: null,
  payeeImpayeeErrorMessage: null,
  payeeImpayeeAnnuelErrorMessage: null,
};

const apiUrl = 'api/recus';

// Actions

export const getEntities = createAsyncThunk('recu/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IRecu[]>(requestUrl);
});

export const getPayeeImpayees = createAsyncThunk(
  'recu/fetch_payee_impayee_list',
  async ({ page, size, sort, mois, niveauId, statusFilter, filterAnnuellService }: IQueryParams) => {
    let requestUrl = `${apiUrl}/payee-impayee?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}&cacheBuster=${new Date().getTime()}`;
    if (mois) {
      requestUrl += `&mois=${mois}`;
    }
    if (niveauId) {
      requestUrl += `&niveauId=${niveauId}`;
    }
    if (statusFilter) {
      requestUrl += `&statusFilter=${statusFilter}`;
    }

    if (filterAnnuellService) {
      requestUrl += `&filterAnnuellService=${filterAnnuellService}`;
    }
    return axios.get<IPayeeImpayee[]>(requestUrl);
  },
);

export const getPayeeImpayeesAnnuel = createAsyncThunk(
  'recu/fetch_payee_impayee_annuel_list',
  async ({ page, size, sort, mois, niveauId, statusFilter, filterAnnuellService, serviceEtablisementId }: IQueryParams) => {
    let requestUrl = `${apiUrl}/payee-impayee?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}&cacheBuster=${new Date().getTime()}`;
    if (mois) {
      requestUrl += `&mois=${mois}`;
    }
    if (niveauId) {
      requestUrl += `&niveauId=${niveauId}`;
    }
    if (statusFilter) {
      requestUrl += `&statusFilter=${statusFilter}`;
    }

    if (filterAnnuellService) {
      requestUrl += `&filterAnnuellService=${filterAnnuellService}`;
    }

    if (serviceEtablisementId) {
      requestUrl += `&serviceEtablisementId=${serviceEtablisementId}`;
    }
    return axios.get<IPayeeImpayee[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'recu/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IRecu>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const generatePdfRecu = createAsyncThunk(
  'recu/generate_recu',
  async ({ recu, contentType = 'contentOne' }: { recu: IRecu; contentType?: string }) => {
    const requestUrl = `${apiUrl}/generate-pdf?contentType=${contentType}`;
    const response = await axios.post<Blob>(requestUrl, recu, {
      responseType: 'blob',
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    const fileName = `recu_${recu.id}.pdf`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);

    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const printPdfRecu = createAsyncThunk(
  'recu/print_recu',
  async ({ recu, contentType = 'contentOne' }: { recu: IRecu; contentType?: string }) => {
    const requestUrl = `${apiUrl}/generate-pdf?contentType=${contentType}`;
    const response = await axios.post<Blob>(requestUrl, recu, {
      responseType: 'blob',
    });

    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = window.URL.createObjectURL(pdfBlob);

    const iframe = document.createElement('iframe');
    iframe.style.position = 'absolute';
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.border = 'none';
    iframe.src = pdfUrl;

    document.body.appendChild(iframe);

    iframe.onload = () => {
      iframe.contentWindow?.print();
    };

    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const showPdfRecuInNewTab = createAsyncThunk(
  'recu/show_recu_in_new_tab',
  async ({ recu, contentType = 'contentOne' }: { recu: IRecu; contentType?: string }) => {
    const requestUrl = `${apiUrl}/generate-pdf?contentType=${contentType}`;
    const response = await axios.post<Blob>(requestUrl, recu, {
      responseType: 'blob',
    });

    // Create a blob for the PDF and generate a URL for it
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = window.URL.createObjectURL(pdfBlob);

    // Open the PDF in a new tab
    window.open(pdfUrl, '_blank');

    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'recu/create_entity',
  async (entity: IrecuRequest, thunkAPI) => {
    const result = await axios.post<IrecuRequest>(`${apiUrl}/addrecu`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'recu/update_entity',
  async (entity: IRecu, thunkAPI) => {
    const result = await axios.put<IRecu>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'recu/partial_update_entity',
  async (entity: IRecu, thunkAPI) => {
    const result = await axios.patch<IRecu>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'recu/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IRecu>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const RecuSlice = createEntitySlice({
  name: 'recu',
  initialState,
  reducers: {
    setRecu(state: any, action: PayloadAction<IRecu>) {
      state.recuSelected = action.payload;
    },
    resetHard: (state: any) => {
      state.payeeImpayeeData = [];
      state.loadPayeeImpayee = false;
      state.payeeImpayeeErrorMessage = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(generatePdfRecu.pending, (state: any) => {
        state.generatingPdf = true;
        state.pdfErrorMessage = null;
      })
      .addCase(generatePdfRecu.fulfilled, (state: any, action) => {
        state.generatingPdf = false;
        state.pdfData = action.payload;
      })
      .addCase(generatePdfRecu.rejected, (state: any, action) => {
        state.generatingPdf = false;
        state.pdfErrorMessage = action.error.message;
      })
      .addCase(printPdfRecu.pending, (state: any) => {
        state.printPdf = true;
        state.pdfErrorMessage = null;
      })
      .addCase(printPdfRecu.fulfilled, (state: any, action) => {
        state.printPdf = false;
        state.pdfData = action.payload;
      })
      .addCase(printPdfRecu.rejected, (state: any, action) => {
        state.printPdf = false;
        state.pdfErrorMessage = action.error.message;
      })
      .addCase(showPdfRecuInNewTab.pending, (state: any) => {
        state.printPdf = true;
        state.pdfErrorMessage = null;
      })
      .addCase(showPdfRecuInNewTab.fulfilled, (state: any, action) => {
        state.printPdf = false;
        state.pdfData = action.payload;
      })
      .addCase(showPdfRecuInNewTab.rejected, (state: any, action) => {
        state.printPdf = false;
        state.pdfErrorMessage = action.error.message;
      })
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(createEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(createEntity.pending, state => {
        state.loading = true;
        state.updateSuccess = false;
      })
      .addCase(getPayeeImpayees.pending, (state: any, action) => {
        state.loadPayeeImpayee = true;
      })
      .addCase(getPayeeImpayees.fulfilled, (state: any, action) => {
        const { data, headers } = action.payload;
        state.loadPayeeImpayee = false;
        state.payeeImpayeeData = data;
        state.totalItemsPayeeImpayee = parseInt(headers['x-total-count'], 10);
      })
      .addCase(getPayeeImpayees.rejected, (state: any, action) => {
        state.loadPayeeImpayee = false;
        state.payeeImpayeeErrorMessage = action.error.message;
      })
      .addCase(getPayeeImpayeesAnnuel.pending, (state: any, action) => {
        state.loadPayeeImpayeeAnnuel = true;
      })
      .addCase(getPayeeImpayeesAnnuel.fulfilled, (state: any, action) => {
        const { data, headers } = action.payload;
        state.loadPayeeImpayeeAnnuel = false;
        state.payeeImpayeeAnnuelData = data;
        state.totalItemsPayeeImpayeeAnnuel = parseInt(headers['x-total-count'], 10);
      })
      .addCase(getPayeeImpayeesAnnuel.rejected, (state: any, action) => {
        state.loadPayeeImpayeeAnnuel = false;
        state.payeeImpayeeAnnuelErrorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 12),
        };
      })
      .addMatcher(isFulfilled(updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.loading = true;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetHard, setRecu } = RecuSlice.actions;

// Reducer
export default RecuSlice.reducer;
