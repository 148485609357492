import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlus, faPencilAlt, faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import { message, Popconfirm, Space, Table } from 'antd';
import type { PopconfirmProps } from 'antd';
import CreateAnneeScolaireModal from 'app/modules/modals/CreateAnneeScolaireModal';
import { deleteEntity, getEntities } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { getPaginationState } from 'react-jhipster';
import { EyeOutlined } from '@ant-design/icons';

export const AnneeScolaire = () => {
  const dispatch = useAppDispatch();

  const [showModal, setShowModal] = useState(false);
  const [currentAnnee, setCurrentAnnee] = useState(null);
  const [currentId, setCurrentId] = useState(null);

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const confirmx: PopconfirmProps['onConfirm'] = () => {
    dispatch(deleteEntity(currentId));
    message.success('Supprimé');
  };

  const cancel: PopconfirmProps['onCancel'] = () => {
    message.error('Click on No');
  };

  const handleEditModal = anneeScolaire => {
    setCurrentAnnee(anneeScolaire);
    setShowModal(true);
  };

  const handleOpenModal = () => {
    setCurrentAnnee(null);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setCurrentAnnee(null);
    setShowModal(false);
  };

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const anneeScolaireList = useAppSelector(state => state.anneeScolaire.entities);
  const loading = useAppSelector(state => state.anneeScolaire.loading);
  const totalItems = useAppSelector(state => state.anneeScolaire.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  const thisAnne = anneeScolaireList.find(annee => annee.isCurrent);

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: (text, record) => (
        <Button type="link" href={`/annee-scolaire/${record.id}`}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Periode',
      dataIndex: 'periode',
      sorter: true,
    },
    {
      title: 'Et courant',
      dataIndex: 'isCurrent',
      sorter: true,
      render: text => (text ? 'oui' : 'non'),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            onClick={() => handleEditModal(record)}
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
            data-cy="entityEditButton"
          ></Button>
          <Popconfirm
            title="Delete the task"
            description="Are you sure to delete this task?"
            onConfirm={confirmx}
            onCancel={cancel}
            okText="Yes"
            cancelText="No"
          >
            <Button type="link" danger onClick={() => setCurrentId(record.id)} icon={<FontAwesomeIcon icon={faTrash} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
     <div>
      <h2 id="annee-scolaire-heading" data-cy="AnneeScolaireHeading" className="mt0">
        Année Scolaireee
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Button className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton" onClick={handleOpenModal}>
 
            <FontAwesomeIcon icon={faPlus} />
            &nbsp; Créer un nouveau Année Scolaire
          </Button>
          <CreateAnneeScolaireModal anneeScolaire={currentAnnee} show={showModal} handleClose={handleCloseModal} />
        </div>
        <div className="d-flex justify-content-start align-items-center">
          <p className="text-start mb-0 ">{thisAnne?.periode ? `Période actuelle: ${thisAnne.periode}` : 'Pas de période actuelle'}</p>
        </div>
      </h2>
      <div className="table-responsive">
        <Table
          columns={columns}
          dataSource={anneeScolaireList}
          rowKey="id"
          pagination={{
            current: paginationState.activePage,
            pageSize: paginationState.itemsPerPage,
            total: totalItems,
            showSizeChanger: true,
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default AnneeScolaire;
