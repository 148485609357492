import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table as AntTable, Space } from 'antd'; // Import Ant Design Table component
import { getPaginationState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faSort, faSortDown, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './salle.reducer';

export const Salle = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const salleList = useAppSelector(state => state.salle.entities);
  const loading = useAppSelector(state => state.salle.loading);
  const totalItems = useAppSelector(state => state.salle.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const columns = [
    {
      title: (
        <span onClick={sort('id')} style={{ cursor: 'pointer' }}>
          Code <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
        </span>
      ),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button type="link" size="small" href={`/salle/${record.id}`}>
          {text}
        </Button>
      ),
    },
    {
      title: (
        <span onClick={sort('numero')} style={{ cursor: 'pointer' }}>
          Numero <FontAwesomeIcon icon={getSortIconByFieldName('numero')} />
        </span>
      ),
      dataIndex: 'numero',
      key: 'numero',
    },
    {
      title: (
        <span onClick={sort('capacite')} style={{ cursor: 'pointer' }}>
          Capacite <FontAwesomeIcon icon={getSortIconByFieldName('capacite')} />
        </span>
      ),
      dataIndex: 'capacite',
      key: 'capacite',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button
            type="link"
            size="small"
            onClick={() => (window.location.href = `/salle/${record.id}`)}
            icon={<FontAwesomeIcon icon={faEye} />}
          ></Button>
          <Button
            type="link"
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
            onClick={() =>
              (window.location.href = `/salle/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
          ></Button>
          <Button
            type="link"
            danger
            icon={<FontAwesomeIcon icon={faTrash} />}
            onClick={() =>
              (window.location.href = `/salle/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
          ></Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="salle-heading" data-cy="SalleHeading">
        Salles
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} loading={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          <Button type="primary" onClick={() => navigate('/salle/new')}>
            <FontAwesomeIcon icon="plus" />
            &nbsp; Créer un nouveau Salle
          </Button>
        </div>
      </h2>
      <AntTable dataSource={salleList} columns={columns} rowKey="id" pagination={false} loading={loading} />
      {totalItems ? (
        <div className={salleList && salleList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Salle;
