import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IScreen } from 'app/shared/model/screen.model';
import { Button, Modal, Form, Input, Select, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScreenType } from 'app/shared/model/enumerations/screen-status.model';
import { addScreen } from 'app/entities/garderie/garderie.reducer';

const { Title } = Typography;
const { Option } = Select;

const CreateScreenModal = ({ show, handleClose, screen }) => {
  const dispatch = useAppDispatch();
  const isNew = screen == null;
  const screenEntity: IScreen = useAppSelector(state => state.garderie.entity);

  const [form] = Form.useForm();

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...values,
      type: ScreenType.GLOBAL,
    };
    console.log(entity);
    if (isNew) {
      dispatch(addScreen(entity));
    } else {
      // dispatch(updateServiceEtablissementEntity(entity));
    }
    handleClose();
  };

  const defaultValues = () => (isNew ? {} : screenEntity);

  return (
    <Modal title={isNew ? 'Créer un nouveau screen' : 'Modifier le screen'} visible={show} onCancel={handleClose} footer={null}>
      <Form form={form} initialValues={defaultValues()} onFinish={saveEntity} layout="vertical">
        <Form.Item name="title" label="Titre" rules={[{ required: true, message: 'Le titre est requis' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="numEleve" label="Nombre élève par screen" initialValue={1}>
          <Select>
            <Option value={1}>1</Option>
            <Option value={2}>2</Option>
            <Option value={4}>4</Option>
          </Select>
        </Form.Item>
        <Form.Item name="timeSlide" label="Time Slide" initialValue={2}>
          <Select>
            {[...Array(10).keys()].map(i => (
              <Option key={i + 1} value={i + 1}>
                {i + 1}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="default" onClick={handleClose} style={{ marginRight: 8 }}>
            Annuler
          </Button>
          <Button type="primary" htmlType="submit">
            <FontAwesomeIcon icon="save" />
            &nbsp; Sauvegarder
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateScreenModal;
