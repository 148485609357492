import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Recu from './recu';
import RecuDetail from './recu-detail';
import RecuUpdate from './recu-update';
import RecuDeleteDialog from './recu-delete-dialog';

const RecuRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Recu/>}/>
    <Route path="new" element={<RecuUpdate/>}/>
    <Route path=":id">
      <Route index element={<RecuDetail/>}/>
      <Route path="edit" element={<RecuUpdate/>}/>
      <Route path="delete" element={<RecuDeleteDialog/>}/>
    </Route>
  </ErrorBoundaryRoutes>
);

export default RecuRoutes;
