import 'react-toastify/dist/ReactToastify.css';
import './app.scss';
import 'app/config/dayjs';

import React, { useEffect, useState } from 'react';
import { Card, NavbarBrand } from 'reactstrap';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES } from 'app/config/constants';
import AppRoutes from 'app/routes';
import Header from 'app/shared/layout/header/header';
import { getEntities } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import Show from 'app/entities/garderie/ui/Show';
import { Button, Dropdown, Menu } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, UserOutlined } from '@ant-design/icons';
import { changeCurrentAnneeScolaire } from 'app/entities/utilisateur/utilisateur.reducer';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import NotificationsDropdown from 'app/modules/chat/NotificationsDropdown';
import useScreenWidth from 'app/shared/util/useScreenWidth';

const baseHref = document.querySelector('base').getAttribute('href').replace(/\/$/, '');

export const App = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
    dispatch(getProfile());
    dispatch(getEntities({}));
  }, []);

  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const ribbonEnv = useAppSelector(state => state.applicationProfile.ribbonEnv);
  const isInProduction = useAppSelector(state => state.applicationProfile.inProduction);
  const isOpenAPIEnabled = useAppSelector(state => state.applicationProfile.isOpenAPIEnabled);
  const isVisible = useAppSelector(state => state.garderie.showScreen);
  const paddingTop = '0px';

  const [collapsed, setCollapsed] = useState(false);
  const width = useScreenWidth();
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  useEffect(() => {
    if (width < 768) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [width]);
  const hascurrentanneescolaire: boolean = useAppSelector(state => state.utilisateur.hascurrentanneescolaire);
  const loadinghascurrentannescolaire: boolean = useAppSelector(state => state.utilisateur.loadinghascurrentannescolaire);
  const [periode, setPeriode] = useState<string>();
  const [currentAnneeScolaire, setCurrentAnneeScolaire] = useState<IAnneeScolaire>();
  const [currentYear, setCurrentYear] = useState<string | null>(null);
  const anneeScolaireList: IAnneeScolaire[] = useAppSelector(state => state.anneeScolaire.entities);
  const sortedAnneeScolaireList = [...anneeScolaireList].sort((a, b) => {
    if (a.periode < b.periode) return -1;
    if (a.periode > b.periode) return 1;
    return 0;
  });
  const handleMenuClick = e => {
    let selectedYear = anneeScolaireList.find(item => item.id == e.key);
    if (selectedYear) {
      setCurrentYear(selectedYear.periode);
      dispatch(changeCurrentAnneeScolaire(selectedYear));
    }
  };
  const account = useAppSelector(state => state.authentication.account);
  const yearMenu = (
    <Menu onClick={handleMenuClick}>
      {sortedAnneeScolaireList.map(item => (
        <Menu.Item key={item.id}>{item.periode}</Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    if (!loadinghascurrentannescolaire && account?.id && anneeScolaireList.length > 0) {
      if (hascurrentanneescolaire) {
        const currentPeriod = anneeScolaireList.find(item => item?.id === account?.anneeScolaire?.id);
        setPeriode(currentPeriod?.periode);
        setCurrentAnneeScolaire(currentPeriod);
      } else {
        const currentPeriod = anneeScolaireList.find(item => item.isCurrent);
        if (currentPeriod?.periode) {
          setPeriode(currentPeriod.periode);
          setCurrentAnneeScolaire(currentPeriod);
        }
      }
    }
  }, [loadinghascurrentannescolaire, hascurrentanneescolaire, anneeScolaireList]);

  return (
    <BrowserRouter basename={baseHref}>
      {isVisible && <Show />}
      <div className="app-container" style={{ paddingTop }}>
        <div style={{ display: 'flex' }} id="app-view-container">
          <ToastContainer position="top-left" className="toastify-container" toastClassName="toastify-toast" />
          <ErrorBoundary>
            <Header
              isAuthenticated={isAuthenticated}
              isAdmin={isAdmin}
              ribbonEnv={ribbonEnv}
              isInProduction={isInProduction}
              isOpenAPIEnabled={isOpenAPIEnabled}
              collapsed={collapsed}
            />
          </ErrorBoundary>

          <Card className="jh-card">
            <div
              style={{
                backgroundColor: '#001529',
                height: '3rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 15px',
              }}
            >
              {/* Left Section: Collapse Button */}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={toggleCollapsed} style={{ height: '100%' }}>
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>

                {/* User Image */}
                {account?.imageUrl ? (
                  <img
                    src={account.imageUrl}
                    alt="User"
                    style={{ width: '40px', height: '40px', borderRadius: '50%', marginLeft: '10px' }}
                  />
                ) : (
                  <UserOutlined style={{ fontSize: '40px', marginLeft: '15px', color: 'white' }} />
                )}
              </div>

              {/* Middle Section: Year Button */}
              {isAuthenticated && (
                <Dropdown overlay={yearMenu} trigger={['click']}>
                  <Button
                    style={{
                      backgroundColor: '#001529',
                      color: '#fff',
                      borderColor: '#fff',
                      fontWeight: 'bold',
                    }}
                  >
                    <img width={'22px'} src="content/images/calendar.png" alt="Logo" style={{ marginBottom: '6px', marginRight: '5px' }} />
                    <span style={{ color: '#fff' }}>{currentYear ? currentYear : periode || 'Loading ...'}</span>
                  </Button>
                </Dropdown>
              )}
              {/* Right Section: Logo */}
              <NavbarBrand className="brand-logo" style={{ display: 'flex', alignItems: 'center' }}>
                {/*<BrandIcon />*/}
                <NotificationsDropdown />
              </NavbarBrand>
            </div>
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Card>
        </div>
      </div>
      {/*  <Footer />*/}
    </BrowserRouter>
  );
};

export default App;
