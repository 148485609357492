import React from 'react';
import { Spin } from 'antd';

export class GoLoading extends React.Component<{ loading: any }> {
  render() {
    return <>
      {this.props.loading ? (
        <Spin size="large" />
      ) : (
        ''
      )}
    </>;
  }
}



