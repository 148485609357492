import './chart.scss';

import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Col, Row } from 'reactstrap';
import { Card, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBoard } from 'app/modules/chart/board.reducer';
import { IBoard } from 'app/shared/model/board.model';
import { NotificationOutlined } from '@ant-design/icons';
import ProgressBarChart from 'app/modules/chart/ProgressBarChart';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ArcElement, Legend);

export const ChartGestionScolaire = () => {
  const dispatch = useAppDispatch();

  const board = useAppSelector(state => state.board.entity) as IBoard;
  const isLoading = useAppSelector(state => state.board.loading) as boolean;
  const [totalStudents, setTotalStudents] = useState(0);
  const [eleveNiveauData, setEleveNiveauData] = useState([]);
  const [serviceCountData, setServiceCountData] = useState([]);
  const [genderPercentages, setGenderPercentages] = useState({ boys: 0, girls: 0 });
  const [isAnnuelleService, setIsAnnuelleService] = useState<boolean>(true);
  const [alignment, setAlignment] = useState('mensuel');

  useEffect(() => {
    dispatch(getBoard());
  }, []);

  useEffect(() => {
    if (board && board.sexeDto && board.sexeDto.values) {
      const total = board.sexeDto.values.reduce((acc, value) => acc + value, 0);
      setTotalStudents(total);

      const [girls, boys] = board.sexeDto.values;
      const girlsPercentage = ((girls / total) * 100).toFixed(2);
      const boysPercentage = ((boys / total) * 100).toFixed(2);
      setGenderPercentages({ girls: parseFloat(girlsPercentage), boys: parseFloat(boysPercentage) });
    }
  }, [board]);
  const dataGender = {
    labels: board.sexeDto?.labels.map((label, index) => {
      const percentage = index === 0 ? genderPercentages.girls : genderPercentages.boys;
      return `${label} (${percentage}%)`;
    }),
    datasets: [
      {
        data: board.sexeDto?.values,
        backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)'],
        borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    indexAxis: 'y' as const,

    responsive: true,
  };

  const data2 = {
    labels: board.eleveNiveauDto?.labels,
    datasets: [
      {
        label: "Nombre d'eleve par niveau",
        data: board.eleveNiveauDto?.values,
        backgroundColor: 'rgb(69,70,231)',
      },
    ],
  };

  const data3 = {
    labels: board.departDto?.labels,
    datasets: [
      {
        label: 'Nombre de départ',
        data: board?.departDto?.values,
        backgroundColor: 'rgb(235,54,75)',
      },
    ],
  };

  const data4 = {
    labels: ['Frais inscription', 'Frais Scolarite', 'Frias Transport', 'Frais Gard'],
    datasets: [
      {
        label: "Nombre d'eleve par service",
        data: board.eleveServiceDto?.values,
        backgroundColor: 'rgb(30,155,32)',
      },
    ],
  };
  const dummyData = [
    {
      id: 201,
      message: '60 élèves nouveaux inscrits (Double clic pour afficher).',
      date: '2024-06-06',
      priority: 'Medium',
      readStatus: false,
    },
    {
      id: 202,
      message: '1 élèves sont dispensés du paiement des Frais Inscription.',
      date: '2024-06-05',
      priority: 'High',
      readStatus: false,
    },
    {
      id: 203,
      message: '1 élèves sont dispensés du paiement des Frais Scolarité.',
      date: '2024-06-05',
      priority: 'Low',
      readStatus: true,
    },
    {
      id: 203,
      message: '2 élèves sont dispensés du paiement des Frais Transport.',
      date: '2024-06-05',
      priority: 'medium',
      readStatus: true,
    },
    {
      id: 203,
      message: '2 élèves sont dispensés du paiement des Frais Transport.',
      date: '2024-06-05',
      priority: 'medium',
      readStatus: true,
    },
    {
      id: 203,
      message: '2 élèves sont dispensés du paiement des Frais Transport.',
      date: '2024-06-05',
      priority: 'medium',
      readStatus: true,
    },
  ];

  useEffect(() => {
    if (board?.eleveNiveauDto) {
      const data = board?.eleveNiveauDto?.labels?.map((label, index) => ({
        label: label,
        value: board?.eleveNiveauDto?.values[index],
      }));
      setEleveNiveauData(data);
    }
    if (board?.eleveServiceDto) {
      const data = board?.eleveServiceDto?.labels?.map((label, index) => ({
        label: label,
        value: board?.eleveServiceDto?.values[index],
      }));
      setServiceCountData(data);
    }
  }, [board]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
    setAlignment(newAlignment);
    if (newAlignment == 'annuel') {
      setIsAnnuelleService(false);
      const data = board?.eleveServiceDtoAnnuel?.labels?.map((label, index) => ({
        label: label,
        value: board?.eleveServiceDtoAnnuel?.values[index],
      }));
      setServiceCountData(data);
    } else {
      setIsAnnuelleService(true);
      const data = board?.eleveServiceDto?.labels?.map((label, index) => ({
        label: label,
        value: board?.eleveServiceDto?.values[index],
      }));
      setServiceCountData(data);
    }
  };
  return (
    <>
      <Spin spinning={isLoading} tip="Chargement...">
        <Row>
          <Col md={2}>
            <Card title="Repatition fille garçon" bordered={false}>
              <Pie data={dataGender} />
              <div className="total-students">
                <strong>Total Elèves :</strong> {totalStudents}
              </div>
            </Card>
            <div style={{ width: '400px' }}></div>
          </Col>
          <Col md={5}>
            <Card title="Nombre de départ" bordered={false}>
              <Bar options={options} data={data3} />
            </Card>
          </Col>

          <Col md={5}>
            <Card title="Nombre d'eleve par niveau" bordered={true}>
              <div className="card-style">
                {eleveNiveauData?.map((eleveNiveau, index) => (
                  <ProgressBarChart
                    key={index}
                    niveauTitle={eleveNiveau.label}
                    elevesInfo={`${eleveNiveau.value} élèves (${((eleveNiveau.value / totalStudents) * 100).toFixed(2)}%)`}
                    progressValue={parseFloat(((eleveNiveau.value / totalStudents) * 100).toFixed(2))}
                  />
                ))}
              </div>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={5}>
            <Card title="Nombre d'eleve par service" bordered={true}>
              <div className="toggle-btn-groupe">
                <ToggleButtonGroup color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
                  <ToggleButton size="small" value="mensuel">
                    Mensuel
                  </ToggleButton>
                  <ToggleButton size="small" value="annuel">
                    Annuel
                  </ToggleButton>
                </ToggleButtonGroup>
              </div>
              <div className="card-style">
                {serviceCountData?.map((serviceData, index) => (
                  <ProgressBarChart
                    key={index}
                    niveauTitle={serviceData.label}
                    elevesInfo={`${serviceData.value} élèves (${((serviceData.value / totalStudents) * 100).toFixed(2)}%)`}
                    progressValue={parseFloat(((serviceData.value / totalStudents) * 100).toFixed(2))}
                  />
                ))}
              </div>
            </Card>
          </Col>
          {/*<Col md={5}>*/}
          {/*  <Card title="Notification" bordered={false}>*/}
          {/*    <div className="total-students" style={{ marginBottom: '10px' }}>*/}
          {/*      <strong>*/}
          {/*        {' '}*/}
          {/*        Total Notif non lus <NotificationOutlined /> :{' '}*/}
          {/*      </strong>*/}
          {/*      {dummyData.reduce((acc, stat) => {*/}
          {/*        if (stat.readStatus === false) acc++;*/}
          {/*        return acc;*/}
          {/*      }, 0)}*/}
          {/*    </div>*/}
          {/*    <div className="notification" style={{ height: '260px', overflowY: 'scroll' }}>*/}
          {/*      {dummyData.map(notif => {*/}
          {/*        const priorityClass = notif.priority.toLowerCase() + '-priority';*/}
          {/*        const readClass = notif.readStatus ? 'read' : '';*/}

          {/*        return (*/}
          {/*          <div className={`notif ${priorityClass} ${readClass}`} key={notif.id}>*/}
          {/*            <NotificationOutlined />*/}
          {/*            <p>{notif.message}</p>*/}
          {/*          </div>*/}
          {/*        );*/}
          {/*      })}*/}
          {/*    </div>*/}
          {/*  </Card>*/}
          {/*  <div style={{ width: '400px' }}></div>*/}
          {/*</Col>*/}
        </Row>
        <Row></Row>
      </Spin>
    </>
  );
};

export default ChartGestionScolaire;
