import { IEleve } from 'app/shared/model/eleve.model';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { Mois } from 'app/shared/model/enumerations/mois.model';

export interface IMoisEleve {
  id?: number;
  mois?: keyof typeof Mois | null;
  eleve?: IEleve | null;
  anneeScolaire?: IAnneeScolaire | null;
  beginPaiement?: boolean | null;
}

export const defaultValue: Readonly<IMoisEleve> = {};
