import dayjs from 'dayjs';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { ICaisse } from 'app/shared/model/caisse.model';
import { ITarif } from 'app/shared/model/tarif.model';
import { ModeReglement } from 'app/shared/model/enumerations/mode-reglement.model';

export interface IRecu {
  id?: number;
  dateCreation?: dayjs.Dayjs | null;
  montant?: number | null;
  reste?: number | null;
  observation?: string | null;
  htmlContent?: string | null;
  htmlContentTwo?: string | null;
  htmlContentThree?: string | null;
  modePaiement?: keyof typeof ModeReglement | null;
  dateModification?: dayjs.Dayjs | null;
  tuteur?: ITuteur | null;
  caisses?: ICaisse[] | null;
  servicesTarifs?: ITarif[] | null;
  cancelable?: boolean | null;
}

export const defaultValue: Readonly<IRecu> = {};
