import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { byteSize, openFile, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './prof.reducer';

export const ProfDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const profEntity = useAppSelector(state => state.prof.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="profDetailsHeading">Professeur</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{profEntity.id}</dd>
          <dt>
            <span id="image">Image</span>
          </dt>
          <dd>
            {profEntity.image ? (
              <div>
                {profEntity.imageContentType ? (
                  <a onClick={openFile(profEntity.imageContentType, profEntity.image)}>
                    <img src={`data:${profEntity.imageContentType};base64,${profEntity.image}`} style={{ maxHeight: '30px' }} />
                  </a>
                ) : null}
                <span>
                  {profEntity.imageContentType}, {byteSize(profEntity.image)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="dateNaissance">Date Naissance</span>
          </dt>
          <dd>
            {profEntity.dateNaissance ? <TextFormat value={profEntity.dateNaissance} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
          </dd>
          <dt>
            <span id="email">Email</span>
          </dt>
          <dd>{profEntity.email}</dd>
          <dt>
            <span id="cin">Cin</span>
          </dt>
          <dd>{profEntity.cin}</dd>
          <dt>
            <span id="prixHeure">Prix Heure</span>
          </dt>
          <dd>{profEntity.prixHeure}</dd>
          <dt>
            <span id="sexe">Sexe</span>
          </dt>
          <dd>{profEntity.sexe}</dd>
          <dt>
            <span id="tel">Tel</span>
          </dt>
          <dd>{profEntity.tel}</dd>
          <dt>
            <span id="vacataire">Vacataire</span>
          </dt>
          <dd>{profEntity.vacataire ? 'true' : 'false'}</dd>
          <dt>Internal User</dt>
          <dd>{profEntity.internalUser ? profEntity.internalUser.login : ''}</dd>
          <dt>Matieres</dt>
          <dd>
            {profEntity.matieres
              ? profEntity.matieres.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {profEntity.matieres && i === profEntity.matieres.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/prof" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/prof/${profEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ProfDetail;
