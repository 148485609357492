import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './controle.reducer';

export const ControleDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const controleEntity = useAppSelector(state => state.controle.entity);
  return (
    <Row>
      <Col md="8">
        <div className="entity-details">
          <h2 className="entity-heading" data-cy="controleDetailsHeading">
            Contrôles
          </h2>
          <dl className="entity-details-list">
            <div className="entity-details-item">
              <dt>ID</dt>
              <dd className="entity-details-value">{controleEntity.id}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Libelle</dt>
              <dd className="entity-details-value">{controleEntity.libelle}</dd>
            </div>
            <div className="entity-details-item">
              <dt>
                <span id="date">Date</span>
              </dt>
              <dd className="entity-details-value">
                {controleEntity.date ? <TextFormat value={controleEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
              </dd>
            </div>
            <div className="entity-details-item">
              <dt>Matiere</dt>
              <dd className="entity-details-value">{controleEntity.matiere ? controleEntity.matiere.nom : ''}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Groupe</dt>
              <dd className="entity-details-value">
                {controleEntity.eleves
                  ? Array.from(new Set(controleEntity.eleves.map(eleve => eleve.groupe?.id).filter(id => id !== undefined)))
                      .map(id => {
                        const groupe = controleEntity.eleves.find(eleve => eleve.groupe?.id === id)?.groupe;
                        return `${groupe?.nom}`;
                      })
                      .join(', ')
                  : null}
              </dd>
            </div>
          </dl>
          <div className="entity-actions">
            <Button tag={Link} to="/controle" replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/controle/${controleEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ControleDetail;
