import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Quiz from './Quiz';
import QuizUpdate from 'app/entities/quiz/quiz_update';

const QuizRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Quiz />} />
    <Route path="new" element={<QuizUpdate />} />
    <Route path=":id">
      <Route path="edit" element={<QuizUpdate />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default QuizRoutes;
