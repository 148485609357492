import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IServiceEtablisement, defaultValue } from 'app/shared/model/service-etablisement.model';

const initialState: EntityState<IServiceEtablisement> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  created: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/service-etablisements';

// Actions

export const getServiceEtablissementEntities = createAsyncThunk(
  'serviceEtablisement/fetch_entity_list',
  async ({ page, size, sort, query }: IQueryParams) => {
    const requestUrl = `${apiUrl}?global.equals=${true}&${query}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    return axios.get<IServiceEtablisement[]>(requestUrl);
  },
);

export const getEntity = createAsyncThunk(
  'serviceEtablisement/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IServiceEtablisement>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createServiceEtablissementEntity = createAsyncThunk(
  'serviceEtablisement/create_entity',
  async (entity: IServiceEtablisement, thunkAPI) => {
    const result = await axios.post<IServiceEtablisement>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getServiceEtablissementEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateServiceEtablissementEntity = createAsyncThunk(
  'serviceEtablisement/update_entity',
  async (entity: IServiceEtablisement, thunkAPI) => {
    const result = await axios.put<IServiceEtablisement>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getServiceEtablissementEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'serviceEtablisement/partial_update_entity',
  async (entity: IServiceEtablisement, thunkAPI) => {
    const result = await axios.patch<IServiceEtablisement>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getServiceEtablissementEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteServiceEtablissementEntity = createAsyncThunk(
  'serviceEtablisement/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IServiceEtablisement>(requestUrl);
    thunkAPI.dispatch(getServiceEtablissementEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const ServiceEtablisementSlice = createEntitySlice({
  name: 'serviceEtablisement',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteServiceEtablissementEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getServiceEtablissementEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createServiceEtablissementEntity, updateServiceEtablissementEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.created = true;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getServiceEtablissementEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(
        isPending(
          createServiceEtablissementEntity,
          updateServiceEtablissementEntity,
          partialUpdateEntity,
          deleteServiceEtablissementEntity,
        ),
        state => {
          state.errorMessage = null;
          state.created = false;
          state.updateSuccess = false;
          state.updating = true;
        },
      );
  },
});

export const { reset } = ServiceEtablisementSlice.actions;

// Reducer
export default ServiceEtablisementSlice.reducer;
