import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getTransmissions as getTransmissionsThatNeedValidation,
  validateTransmission,
} from 'app/entities/cahier-transmission/transmission.reducer';
import { Avatar, Badge, Button, Card, List, Spin } from 'antd';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import { IAbsenceEleve } from 'app/shared/model/absence-eleve.model';
import { ITransmissionResponse } from 'app/shared/model/cahier_transmission_model/transmission.model';
import BackButton from 'app/shared/layout/buttons/BackButton';

dayjs.locale('fr');
const TransmissionValidation = () => {
  const dispatch = useAppDispatch();
  const formattedDate = dayjs().format('DD MMMM YYYY');
  const transmissionsThatNeedValidation = useAppSelector(state => state.transmission.entities);
  const loadTransmissions = useAppSelector(state => state.transmission.loadTransmissions);
  const validatingload = useAppSelector(state => state.transmission.validatingload);

  useEffect(() => {
    dispatch(
      getTransmissionsThatNeedValidation({
        query: `withRealAnneeScolaire=${true}&validated=${false}&paged=false`,
      }),
    );
  }, []);

  const handleTransmissionValidation = (id: number) => {
    dispatch(validateTransmission({ id }));
  };
  return (
    <div>
      <BackButton path="/cahier_transmission" />
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '4px' }}>
        <div style={{ width: '95vw', height: '100vh' }}>
          <Badge.Ribbon text={formattedDate}>
            <Card
              title="Validation Suivi pédagogique"
              bordered={true}
              style={{
                background: '#FFFFFF',
                filter: 'drop-shadow(0 0 0.75rem #eed5db)',
                opacity: validatingload ? '60%' : '100%',
                pointerEvents: validatingload ? 'none' : 'auto',
              }}
              className="custom-card"
            >
              {validatingload && <LinearProgress />}
              {loadTransmissions ? (
                <Spin size="large" />
              ) : (
                <Box className="card-style--garderie">
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 2,
                      lg: 2,
                      xl: 2,
                      xxl: 3,
                    }}
                    dataSource={transmissionsThatNeedValidation}
                    renderItem={(item: ITransmissionResponse, index) => (
                      <List.Item
                        style={{
                          borderBottom: '1px solid #d9d9d9',
                          borderRight: index % 2 === 0 ? '1px solid #d9d9d9' : 'none',
                          marginBottom: '16px',
                          paddingRight: index % 2 === 0 ? '16px' : '0',
                          height: '130px',
                          paddingBottom: '20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: '10px',
                              width: '100%',
                            }}
                          >
                            <div>
                              <span style={{ fontSize: '12px', opacity: '40%' }}>
                                créer par {item?.prof?.internalUser?.firstName} {item?.prof?.internalUser?.lastName}
                              </span>
                            </div>
                          </div>
                          <List.Item.Meta
                            style={{ width: '100%' }}
                            avatar={<Avatar src={`data:image/jpeg;base64,${item.eleve?.photo}`} />}
                            title={
                              <div
                                style={{
                                  display: 'flex',
                                  fontSize: '16px',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div
                                  style={{
                                    color: '#000',
                                    textDecoration: 'none',
                                  }}
                                >
                                  {dayjs(item?.createdAt).format('DD MMMM YYYY')}
                                </div>
                                <span
                                  style={{
                                    color: 'green',
                                    fontWeight: 'bold',
                                    opacity: '60%',
                                  }}
                                >
                                  {item.eleve?.prenom} {item.eleve?.nom}
                                </span>
                              </div>
                            }
                            description={
                              <div
                                style={{
                                  display: 'flex',
                                  fontSize: '16px',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  paddingTop: '14px',
                                }}
                              >
                                <div style={{ fontSize: '14px' }}>
                                  <span
                                    style={{
                                      opacity: '70%',
                                    }}
                                  >
                                    {item?.description}
                                  </span>
                                </div>
                                <Button
                                  style={{
                                    backgroundColor: 'green',
                                  }}
                                  onClick={() => handleTransmissionValidation(item.id)}
                                  type="primary"
                                >
                                  Valider
                                </Button>
                              </div>
                            }
                          />
                        </div>
                      </List.Item>
                    )}
                  />
                </Box>
              )}
            </Card>
          </Badge.Ribbon>
        </div>
      </div>
    </div>
  );
};
export default TransmissionValidation;
