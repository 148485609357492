import React, { useEffect, useState } from 'react';
import { Row, Col, Alert } from 'reactstrap';
import { Tabs, TabsProps } from 'antd';
import FicheDePaiment from 'app/modules/tresorerie/FicheDePaiment';

export const Tresorerie = () => {
  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Fiche de Paiment',
      children: <FicheDePaiment />,
    },
  ];

  return (
    <Row>
      <Tabs tabPosition={'top'} defaultActiveKey="1" items={items} />
    </Row>
  );
};

export default Tresorerie;
