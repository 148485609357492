import dayjs from 'dayjs';
import { IProf } from 'app/shared/model/prof.model';
import { IEleve } from 'app/shared/model/eleve.model';
import { ITuteur } from 'app/shared/model/tuteur.model';

export interface ITransmissionRequest {
  id?: number;
  description?: string | null;
  validated?: boolean | null;
  profId?: number | null;
  eleveId?: number | null;
}

export interface ITransmissionResponse {
  id?: number;
  description?: string | null;
  validated?: boolean | null;
  prof?: IProf | null;
  eleve?: IEleve | null;
  realAnneeScolaire?: string | null;
  anneeScolaireId?: string | null;
  createdAt?: dayjs.Dayjs | null;
  commentaires?: ICommentaire[] | null;
}

export interface ICommentaire {
  id?: number;
  description?: string | null;
  tuteur?: ITuteur | null;
  createdAt?: dayjs.Dayjs | null;
}

export const defaultValueTransmissionRequest: Readonly<ITransmissionRequest> = {};
export const defaultValueTransmissionResponse: Readonly<ITransmissionResponse> = {};
