import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ServiceEtablisement from './service-etablisement';
import ServiceEtablisementDetail from './service-etablisement-detail';
import ServiceEtablisementUpdate from './service-etablisement-update';
import ServiceEtablisementDeleteDialog from './service-etablisement-delete-dialog';

const ServiceEtablisementRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ServiceEtablisement />} />
    <Route path="new" element={<ServiceEtablisementUpdate />} />
    <Route path=":id">
      <Route index element={<ServiceEtablisementDetail />} />
      <Route path="edit" element={<ServiceEtablisementUpdate />} />
      <Route path="delete" element={<ServiceEtablisementDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ServiceEtablisementRoutes;
