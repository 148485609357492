import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { byteSize, getPaginationState, JhiItemCount, JhiPagination, openFile, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities } from './utilisateur.reducer';
import { Avatar, Card, Input, Space } from 'antd';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { GoLoading } from 'app/shared/loading';

const gridStyle: React.CSSProperties = {
  width: '25%',
  backgroundColor: 'grey',
};

export const Utilisateur = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const utilisateurList = useAppSelector(state => state.utilisateur.entities || []); // Ensure it's an array
  const loading = useAppSelector(state => state.utilisateur.loading);
  const totalItems = useAppSelector(state => state.utilisateur.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  return (
    <div>
      <h2 id="utilisateur-heading" data-cy="UtilisateurHeading">
        Utilisateurs
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/utilisateur/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp; Créer un nouveau Utilisateur
          </Link>
        </div>
      </h2>

      <div className="table-responsive">
        <Card
          title={
            <Space.Compact style={{ width: '100%' }}>
              <Input defaultValue="Combine input and button" />
              <Button>Submit</Button>
            </Space.Compact>
          }
        >
          {utilisateurList && utilisateurList.length > 0 ? (
            <>
              {utilisateurList.map((user, i) => (
                <Card.Grid key={i} style={gridStyle}>
                  <Row>
                    <Col sm={4}>
                      {' '}
                      <Avatar
                        style={{ width: '100px', height: '100px' }}
                        src={`data:${user.photoContentType};base64,${user.photo}`}
                      ></Avatar>
                    </Col>
                    <Col sm={8}>
                      <table>
                        <tr>
                          <td>Nom</td>
                          <th>{user.nom + ' ' + user.prenom}</th>
                        </tr>
                        <tr>
                          <td>Niveau</td>
                          <th>CM1</th>
                        </tr>
                        <tr>
                          <td>CodeMassar</td>
                          <th>{user.codeMassar}</th>
                        </tr>
                      </table>
                    </Col>
                  </Row>

                  <Card
                    key={i}
                    actions={[
                      <span>
                        {' '}
                        <EyeOutlined title={'voir'} key="eye" /> Voir
                      </span>,
                      <span>
                        {' '}
                        <EditOutlined title={'voir'} key="eye" /> Editer
                      </span>,
                      <span>
                        {' '}
                        <DeleteOutlined title={'voir'} key="eye" /> Supprimer
                      </span>,
                    ]}
                  ></Card>
                </Card.Grid>
              ))}
            </>
          ) : (
            <></>
          )}
          <Card.Grid> </Card.Grid>
        </Card>
      </div>

      <div className="table-responsive">
        {loading ? (
          <GoLoading loading={loading} />
        ) : utilisateurList && utilisateurList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  Code <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th>
                <th className="hand" onClick={sort('nom')}>
                  Nom <FontAwesomeIcon icon={getSortIconByFieldName('nom')} />
                </th>
                <th className="hand" onClick={sort('prenom')}>
                  Prenom <FontAwesomeIcon icon={getSortIconByFieldName('prenom')} />
                </th>
                <th className="hand" onClick={sort('dateNaissance')}>
                  Date Naissance <FontAwesomeIcon icon={getSortIconByFieldName('dateNaissance')} />
                </th>
                <th className="hand" onClick={sort('lieuNaissance')}>
                  Lieu Naissance <FontAwesomeIcon icon={getSortIconByFieldName('lieuNaissance')} />
                </th>
                <th className="hand" onClick={sort('sexe')}>
                  Sexe <FontAwesomeIcon icon={getSortIconByFieldName('sexe')} />
                </th>
                <th className="hand" onClick={sort('photo')}>
                  Photo <FontAwesomeIcon icon={getSortIconByFieldName('photo')} />
                </th>
                <th className="hand" onClick={sort('cin')}>
                  Cin <FontAwesomeIcon icon={getSortIconByFieldName('cin')} />
                </th>
                <th className="hand" onClick={sort('adresse')}>
                  Adresse <FontAwesomeIcon icon={getSortIconByFieldName('adresse')} />
                </th>
                <th className="hand" onClick={sort('rib')}>
                  Rib <FontAwesomeIcon icon={getSortIconByFieldName('rib')} />
                </th>
                <th className="hand" onClick={sort('role')}>
                  Role <FontAwesomeIcon icon={getSortIconByFieldName('role')} />
                </th>
                <th>
                  User <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {utilisateurList.map((utilisateur, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/utilisateur/${utilisateur.id}`} size="sm">
                      {utilisateur.id}
                    </Button>
                  </td>
                  <td>{utilisateur.nom}</td>
                  <td>{utilisateur.prenom}</td>
                  <td>
                    {utilisateur.dateNaissance ? (
                      <TextFormat type="date" value={utilisateur.dateNaissance} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{utilisateur.lieuNaissance}</td>
                  <td>{utilisateur.sexe}</td>
                  <td>
                    {utilisateur.photo ? (
                      <div>
                        {utilisateur.photoContentType ? (
                          <a onClick={openFile(utilisateur.photoContentType, utilisateur.photo)}>
                            <img src={`data:${utilisateur.photoContentType};base64,${utilisateur.photo}`} style={{ maxHeight: '30px' }} />
                            &nbsp;
                          </a>
                        ) : null}
                        <span>
                          {utilisateur.photoContentType}, {byteSize(utilisateur.photo)}
                        </span>
                      </div>
                    ) : null}
                  </td>
                  <td>{utilisateur.cin}</td>
                  <td>{utilisateur.adresse}</td>
                  <td>{utilisateur.rib}</td>
                  <td>{utilisateur.role}</td>
                  <td>{utilisateur.user ? utilisateur.user.id : ''}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/utilisateur/${utilisateur.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">Voir</span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/utilisateur/${utilisateur.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
                      </Button>
                      <Button
                        onClick={() =>
                          (window.location.href = `/utilisateur/${utilisateur.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
                        }
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Supprimer</span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && <div className="alert alert-warning">Aucun Utilisateur trouvé</div>
        )}
      </div>
      {totalItems ? (
        <div className={utilisateurList && utilisateurList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Utilisateur;
