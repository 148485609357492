import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { openFile, byteSize, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './utilisateur.reducer';

export const UtilisateurDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const utilisateurEntity = useAppSelector(state => state.utilisateur.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="utilisateurDetailsHeading">Utilisateur</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{utilisateurEntity.id}</dd>
          <dt>
            <span id="nom">Nom</span>
          </dt>
          <dd>{utilisateurEntity.nom}</dd>
          <dt>
            <span id="prenom">Prenom</span>
          </dt>
          <dd>{utilisateurEntity.prenom}</dd>
          <dt>
            <span id="dateNaissance">Date Naissance</span>
          </dt>
          <dd>
            {utilisateurEntity.dateNaissance ? (
              <TextFormat value={utilisateurEntity.dateNaissance} type="date" format={APP_LOCAL_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>
            <span id="lieuNaissance">Lieu Naissance</span>
          </dt>
          <dd>{utilisateurEntity.lieuNaissance}</dd>
          <dt>
            <span id="sexe">Sexe</span>
          </dt>
          <dd>{utilisateurEntity.sexe}</dd>
          <dt>
            <span id="photo">Photo</span>
          </dt>
          <dd>
            {utilisateurEntity.photo ? (
              <div>
                {utilisateurEntity.photoContentType ? (
                  <a onClick={openFile(utilisateurEntity.photoContentType, utilisateurEntity.photo)}>
                    <img
                      src={`data:${utilisateurEntity.photoContentType};base64,${utilisateurEntity.photo}`}
                      style={{ maxHeight: '30px' }}
                    />
                  </a>
                ) : null}
                <span>
                  {utilisateurEntity.photoContentType}, {byteSize(utilisateurEntity.photo)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="cin">Cin</span>
          </dt>
          <dd>{utilisateurEntity.cin}</dd>
          <dt>
            <span id="adresse">Adresse</span>
          </dt>
          <dd>{utilisateurEntity.adresse}</dd>
          <dt>
            <span id="rib">Rib</span>
          </dt>
          <dd>{utilisateurEntity.rib}</dd>
          <dt>
            <span id="role">Role</span>
          </dt>
          <dd>{utilisateurEntity.role}</dd>
          <dt>User</dt>
          <dd>{utilisateurEntity.user ? utilisateurEntity.user.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/utilisateur" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/utilisateur/${utilisateurEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default UtilisateurDetail;
