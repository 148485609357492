import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getNiveaus } from 'app/entities/niveau/niveau.reducer';
import { getServiceEtablissementEntities as getServiceEtablisements } from 'app/entities/service-etablisement/service-etablisement.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { createTarifEntity, getEntity, reset, updateEntity } from './tarif.reducer';
import { getEntities as getRecus } from 'app/entities/recu/recu.reducer';

export const TarifUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const niveaus = useAppSelector(state => state.niveau.entities);
  const serviceEtablisements = useAppSelector(state => state.serviceEtablisement.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const recus = useAppSelector(state => state.recu.entities);
  const tarifEntity = useAppSelector(state => state.tarif.entity);
  const loading = useAppSelector(state => state.tarif.loading);
  const updating = useAppSelector(state => state.tarif.updating);
  const updateSuccess = useAppSelector(state => state.tarif.updateSuccess);

  const handleClose = () => {
    navigate('/tarif' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getNiveaus({}));
    dispatch(getServiceEtablisements({}));
    dispatch(getEleves({}));
    dispatch(getRecus({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.prix !== undefined && typeof values.prix !== 'number') {
      values.prix = Number(values.prix);
    }

    const entity = {
      ...tarifEntity,
      ...values,
      niveau: niveaus.find(it => it.id.toString() === values.niveau?.toString()),
      serviceEtablisement: serviceEtablisements.find(it => it.id.toString() === values.serviceEtablisement?.toString()),
      eleve: eleves.find(it => it.id.toString() === values.eleve?.toString()),
      recuses: mapIdList(values.recuses),
    };

    if (isNew) {
      dispatch(createTarifEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...tarifEntity,
          niveau: tarifEntity?.niveau?.id,
          serviceEtablisement: tarifEntity?.serviceEtablisement?.id,
          eleve: tarifEntity?.eleve?.id,
          recuses: tarifEntity?.recuses?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.tarif.home.createOrEditLabel" data-cy="TarifCreateUpdateHeading">
            Créer ou éditer un Tarif
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="tarif-id" label="Numéro" validate={{ required: true }} /> : null}
              <ValidatedField label="Prix" id="tarif-prix" name="prix" data-cy="prix" type="text" />
              <ValidatedField id="tarif-niveau" name="niveau" data-cy="niveau" label="Niveau" type="select">
                <option value="" key="0" />
                {niveaus
                  ? niveaus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.libelle}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="tarif-serviceEtablisement"
                name="serviceEtablisement"
                data-cy="serviceEtablisement"
                label="Service Etablisement"
                type="select"
              >
                <option value="" key="0" />
                {serviceEtablisements
                  ? serviceEtablisements.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.libelle}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField id="tarif-eleve" name="eleve" data-cy="eleve" label="Eleve" type="select">
                <option value="" key="0" />
                {eleves
                  ? eleves.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.nom}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField label="Recus" id="tarif-recus" data-cy="recus" type="select" multiple name="recuses">
                <option value="" key="0" />
                {recus
                  ? recus.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.observation}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/tarif" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TarifUpdate;
