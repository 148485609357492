import dayjs from 'dayjs';

export interface IAuditLog {
  id?: number;
  entityName?: string | null;
  operationType?: string | null;
  entityId?: string | null;
  changedBy?: string | null;
  timestamp?: dayjs.Dayjs | null;
  oldValue?: string | null;
  newValue?: string | null;
}

export const defaultValue: Readonly<IAuditLog> = {};
