export interface IBoard {
  sexeDto?: DataBoardDto;
  departDto?: DataBoardDto;
  eleveNiveauDto?: DataBoardDto;
  eleveServiceDto?: DataBoardDto;
  eleveServiceDtoAnnuel?: DataBoardDto;
  elevePaieParMoisDto?: DataBoardDto;
}

export interface DataBoardDto {
  labels: string[];
  values: number[];
}

export const defaultValue: Readonly<IBoard> = {};
