export interface IEtablissement {
  id?: number;
  nom?: string | null;
  step?: string | null;
  tel?: string | null;
  adresse?: string | null;
  cnss?: string | null;
}

export const defaultValue: Readonly<IEtablissement> = {};
