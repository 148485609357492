import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import EmploiTempsLigneDetail from './emploi-temps-ligne-detail';
import EmploiTempsLigneDeleteDialog from './emploi-temps-ligne-delete-dialog';

const EmploiTempsLigneRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route path=":id">
      <Route index element={<EmploiTempsLigneDetail />} />
      <Route path="delete" element={<EmploiTempsLigneDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmploiTempsLigneRoutes;
