import dayjs from 'dayjs';
import {IMatiere} from 'app/shared/model/matiere.model';
import {IEleve} from 'app/shared/model/eleve.model';

export interface IControle {
  id?: number;
  libelle?: string | null;
  date?: dayjs.Dayjs | null;
  duree?: dayjs.Dayjs | null;
  matiere?: IMatiere | null;
  eleves?: IEleve[] | null;
}

export const defaultValue: Readonly<IControle> = {};
