import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getMatieres } from 'app/entities/matiere/matiere.reducer';
import { Sexe } from 'app/shared/model/enumerations/sexe.model';
import { createEntity, getEntity, reset, updateEntity } from './prof.reducer';
import { Form, Input, Select, Upload, message, Switch, UploadProps, GetProp } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { GoLoading } from 'app/shared/loading';
import { getProfEntities as getEmploiTemps } from 'app/entities/emploi-temps/emploi-temps.reducer';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IUser } from 'app/shared/model/user.model';
import { getAllUsers } from 'app/modules/administration/user-management/user-management.reducer';

export const ProfUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users) as IUser[];
  const matieres = useAppSelector(state => state.matiere.entities);
  const profEntity = useAppSelector(state => state.prof.entity);
  const loading = useAppSelector(state => state.prof.loading);
  const updating = useAppSelector(state => state.prof.updating);
  const updateSuccess = useAppSelector(state => state.prof.updateSuccess);
  const emploiTemps = useAppSelector(state => state.emploiTemps.entities);
  const groupes = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const sexeValues = Object.keys(Sexe);
  const [imageUrlType, setImageUrlType] = useState<string>();
  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
  const [imageUrl, setImageUrl] = useState<string>();
  const [password, setPassword] = useState('');
  const [initialLogin, setInitialLogin] = useState<string>('');

  const handleClose = () => {
    navigate('/prof' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getMatieres({}));
    dispatch(getGroupes({}));
    dispatch(getEmploiTemps({}));
    dispatch(getAllUsers());
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (profEntity && !isNew) {
      setImageUrlType(profEntity.imageContentType);
      if (profEntity.image) {
        if (profEntity.image.includes('data:image/jpeg;base64')) {
          setImageUrl(profEntity.image.split(',')[1]);
        } else {
          setImageUrl(profEntity.image);
        }
      }
      setInitialLogin(profEntity?.internalUser?.login || '');
    }
  }, [profEntity, isNew]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.prixHeure !== undefined && typeof values.prixHeure !== 'number') {
      values.prixHeure = Number(values.prixHeure);
    }

    const entity = {
      ...profEntity,
      ...values,
      image: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
      imageContentType: imageUrlType,
      internalUser: {
        ...profEntity.internalUser,
        firstName: values.firstName,
        lastName: values.lastName,
        login: values.login,
        password: values.password,
        activated: values.activated !== undefined ? values.activated : false,
      },
      matieres: values.matieres ? mapIdList(values.matieres) : null,
      emploisTemps: emploiTemps.find(it => it.id.toString() === values.emploisTemps?.toString()),
      activated: values.activated !== undefined ? values.activated : false,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          sexe: 'MALE',
          ...profEntity,
          internalUser: profEntity?.internalUser?.id,
          matieres: profEntity?.matieres?.map(e => e.id.toString()),
          groupeIds: profEntity?.groupes?.map(groupe => groupe.id),
        };

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Charger une image</div>
    </button>
  );

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as FileType, url => {
        setImageUrl(url.split(',')[1]);
        setImageUrlType(info.file.type);
      });
    }
  };

  const updatePassword = event => setPassword(event.target.value);

  const userLogins = users.map(user => user.login);

  const checkUniqueField = async (rule, value) => {
    if (!value) {
      return Promise.resolve();
    }

    const existingItem = userLogins.find(item => item?.toString() === value.toString());

    if (isNew) {
      if (existingItem) {
        return Promise.reject(`Login already exists`);
      }
    } else {
      if (value !== initialLogin && existingItem) {
        return Promise.reject(`Login already exists`);
      }
    }

    return Promise.resolve();
  };

  return (
    <div style={{ width: '90vw' }}>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.prof.home.createOrEditLabel" data-cy="ProfCreateUpdateHeading">
            Créer ou éditer un Professeur
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <GoLoading loading={loading}></GoLoading>
          ) : (
            <Form
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={defaultValues()}
              onFinish={saveEntity}
              autoComplete="off"
            >
              {!isNew && (
                <Form.Item label="Numéro" name="id" rules={[{ required: true, message: "L'identifiant est obligatoire!" }]}>
                  <Input readOnly />
                </Form.Item>
              )}
              <Form.Item hasFeedback name="image" label="Image">
                <Upload
                  customRequest={({ onSuccess }) => onSuccess('ok')}
                  name="image"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  onChange={handleChange}
                >
                  {imageUrl ? <img src={`data:image/jpeg;base64,${imageUrl}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                {imageUrlType}
              </Form.Item>

              <Form.Item label="Prénom" name="firstName" rules={[{ required: true, message: 'Veuillez entrer votre prénom!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Nom" name="lastName" rules={[{ required: true, message: 'Veuillez entrer votre nom de famille!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Sexe" name="sexe" rules={[{ required: true, message: 'Veuillez sélectionner votre sexe!' }]}>
                <Select>
                  {sexeValues.map(sexe => (
                    <Select.Option value={sexe} key={sexe}>
                      {sexe}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Tel"
                name="tel"
                rules={[
                  { required: true, message: 'Please input your telephone number!' },
                  { pattern: /^\d{10}$/, message: 'Veuillez entrer un numéro de téléphone valide!' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Login" name="login" rules={[{ validator: (rule, value, callback) => checkUniqueField(rule, value) }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Votre mot de passe est requis.' },
                  { min: 4, message: 'Votre mot de passe doit comporter au moins 4 caractères.' },
                  { max: 50, message: 'Votre mot de passe ne doit pas comporter plus de 50 caractères.' },
                ]}
              >
                <Input.Password onChange={updatePassword} />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <PasswordStrengthBar password={password} />
              </Form.Item>
              <Form.Item
                label="Date Naissance"
                name="dateNaissance"
                rules={[{ required: true, message: 'Veuillez entrer votre date de naissance!' }]}
              >
                <Input type="date" />
              </Form.Item>
              <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Veuillez entrer un email valide!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Cin" name="cin" rules={[{ required: true, message: 'Veuillez entrer votre CIN!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Prix Heure" name="prixHeure" rules={[{ required: true, message: 'Veuillez entrer votre taux horaire!' }]}>
                <Input />
              </Form.Item>

              <Form.Item label="emplois du Temps" name="emploisTemps">
                <Select>
                  {emploiTemps.map(emploi => (
                    <Select.Option value={emploi.id} key={emploi.id}>
                      {emploi.nom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Groupes" name="groupeIds" rules={[{ required: true, message: 'Please select a groupe !' }]}>
                <Select
                  mode="multiple"
                  placeholder="selectionner un groupe"
                  className="custom-select"
                  showSearch
                  filterOption={(input, option) => option.children.toString().includes(input)}
                >
                  {groupes.map(groupe => (
                    <Select.Option key={groupe.id} value={groupe.id}>
                      {groupe.nom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Activé" name="activated" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item label="Vacataire" name="vacataire" valuePropName="checked">
                <Switch />
              </Form.Item>
              <Form.Item
                name="matieres"
                label="Matieres"
                rules={[
                  {
                    required: true,
                    message: 'Sélectionnez au moins une matière!',
                    type: 'array',
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="Sélectionner des matières"
                  optionFilterProp="label"
                  filterOption={(input, option) => {
                    return option?.label?.toString().toLowerCase().includes(input.toLowerCase());
                  }}
                  options={matieres.map(matiere => ({
                    value: matiere.id,
                    label: matiere.nom,
                  }))}
                />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Link to="/prof">
                  <Button type="link" style={{ marginRight: '8px' }}>
                    <FontAwesomeIcon icon="arrow-left" />
                    &nbsp;
                    <span className="d-none d-md-inline">Retour</span>
                  </Button>
                </Link>
                <Button type="primary" htmlType="submit" loading={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProfUpdate;
