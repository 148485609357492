import React from 'react';
import { Modal } from 'antd';
import EmploiTempsLigne from 'app/entities/emploi-temps-ligne/emploi-temps-ligne';


const ShowEmploiTempsLigneModal = ({ show, handleClose, emploiId }) => {


  function handleSave() {

  }

  return (
    <Modal width={'80%'} title="Emploi Temps Lignes" open={show} onOk={handleClose} onCancel={handleClose}>

      <EmploiTempsLigne emploiId={emploiId}></EmploiTempsLigne>
    </Modal>
  );
};

export default ShowEmploiTempsLigneModal;
