import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { createEntity, getEntity, reset, updateEntity } from './galerie.reducer';
import { getEntities as getGroupesEntities } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getNiveauEntities } from 'app/entities/niveau/niveau.reducer';
import { Button, Col, Form, Input, Row, Select, Spin, Tag, Upload } from 'antd';
import { ArrowLeftOutlined, LoadingOutlined, PlusOutlined, SaveOutlined } from '@ant-design/icons';
import { mapIdList } from 'app/shared/util/entity-utils';

const { Option } = Select; // Correctly import Option from Select

export const GalerieUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const eleves = useAppSelector(state => state.eleve.entities);
  const galerieEntity = useAppSelector(state => state.galerie.entity);
  const loading = useAppSelector(state => state.galerie.loading);
  const updating = useAppSelector(state => state.galerie.updating);
  const updateSuccess = useAppSelector(state => state.galerie.updateSuccess);
  const niveauList = useAppSelector(state => state.niveau.entities);
  const groupeList = useAppSelector(state => state.groupe.entities);

  const [selectedNiveau, setSelectedNiveau] = useState('');
  const [groupeId, setGroupeId] = useState('');
  const [fileList, setFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');

  const filteredGroupeList = groupeList.filter(groupe => groupe.niveau.id === selectedNiveau);
  const filteredEleveList = eleves.filter(eleve => eleve.groupe?.id === groupeId);

  const handleClose = () => {
    navigate('/galerie' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, [isNew, id]);

  useEffect(() => {
    if (groupeId) {
      dispatch(
        getEleves({
          query: `groupeId.equals=${groupeId}&`,
        }),
      );
    }
    getAllEntities();
  }, [selectedNiveau, groupeId]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (galerieEntity && !isNew) {
      if (galerieEntity.images) {
        setFileList(
          galerieEntity.images.map((image, index) => {
            const binaryString = atob(image.photo);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            return {
              status: 'done',
              url: `data:${image.photoContentType};base64,${image.photo}`,
              originFileObj: new Blob([bytes], { type: image.photoContentType }),
            };
          }),
        );
      }
    }
  }, [galerieEntity, isNew]);

  const handleNiveauChange = value => {
    setSelectedNiveau(value);
  };

  const handleGroupeChange = value => {
    setGroupeId(value);
  };

  const getAllEntities = () => {
    if (selectedNiveau) {
      dispatch(
        getGroupesEntities({
          query: `niveauId.equals=${selectedNiveau}&`,
        }),
      );
    }
    dispatch(getNiveauEntities({}));
  };

  const handlePreview = file => {
    setPreviewImage(file.url || file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const getBase64 = (file, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(file);
  };

  const saveEntity = values => {
    const images = fileList.map(file => ({
      photo: file.thumbUrl.split(',')[1], // Base64 encoded image data
      photoContentType: file.originFileObj.type, // MIME type of the file
    }));

    const entity = {
      ...galerieEntity,
      ...values,
      images: images,
      niveauId: values.niveau,
      groupeId: values.groupe,
      eleves: values.eleve ? mapIdList(values.eleve) : null,
      image: null,
      imageContentType: null,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...galerieEntity,
          eleves: galerieEntity?.eleves?.map(e => e.id.toString()),
        };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Charger une image</div>
    </div>
  );

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="red">
            Créer une Galerie
          </Tag>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="12">
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form name="basic" initialValues={defaultValues()} onFinish={saveEntity} layout="horizontal" autoComplete="off">
              {!isNew && (
                <Form.Item label="Numéro" name="id" rules={[{ required: true, message: 'ID est obligatoire!' }]}>
                  <Input readOnly />
                </Form.Item>
              )}
              <Form.Item label="Niveau" name="niveau">
                <Select
                  showSearch
                  placeholder="Sélectionner un niveau"
                  onChange={handleNiveauChange}
                  allowClear
                  filterOption={(input, option) => {
                    const text = option?.label || option?.children;
                    return text?.toString().toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  <Option value="0" key="0">
                    tous les niveaux
                  </Option>
                  {niveauList.map(otherEntity => (
                    <Option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.libelle}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Groupe" name="groupe">
                <Select
                  allowClear
                  onChange={handleGroupeChange}
                  showSearch
                  filterOption={(input, option) => {
                    const text = option?.label || option?.children;
                    return text?.toString().toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {groupeList.map(otherEntity => (
                    <Option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.nom}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label="Eleve" name="eleve">
                <Select
                  allowClear
                  mode="multiple"
                  showSearch
                  filterOption={(input, option) => {
                    const text = option?.label || option?.children;
                    return text?.toString().toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {eleves.map(otherEntity => (
                    <Option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.nom} {otherEntity.prenom}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <>
                <Form.Item name="images" label="Images">
                  <Upload
                    listType="picture-card"
                    multiple
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    accept="image/*"
                    customRequest={({ file, onSuccess }) => {
                      getBase64(file, url => {
                        const newFileList = [
                          ...fileList,
                          {
                            status: 'done',
                            url,
                            originFileObj: file,
                          },
                        ];
                        setFileList(newFileList);
                        onSuccess('ok');
                      });
                    }}
                  >
                    {fileList.length >= 5 ? null : uploadButton}
                  </Upload>
                </Form.Item>
                <Form.Item name="nom" label="Nom de la Galerie">
                  <Input />
                </Form.Item>
              </>
              <Button onClick={handleClose} icon={<ArrowLeftOutlined />} style={{ marginRight: 8 }}>
                Retour
              </Button>
              <Button type="primary" htmlType="submit" loading={updating} icon={<SaveOutlined />}>
                Sauvegarder
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GalerieUpdate;
