import React, { useEffect, useState } from 'react';
import { Form, FormProps, GetProp, Input, Modal, Upload, UploadProps, InputNumber, Form as FormANT, DatePicker } from 'antd';
import { createEntity } from 'app/entities/tuteur/tuteur.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Col } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { addCheque, GetFichePaimentsGlobal, handleAmountCheque } from 'app/modules/tresorerie/fiche-paiement.reducer';
import moment from 'moment/moment';

const CreateCheckModal = ({ show, handleClose }) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();
  const [imageUrl, setImageUrl] = useState<string>();
  const [imageUrlType, setImageUrlType] = useState<string>();
  const amountCheque = useAppSelector(state => state.fichePaiement.amountCheque);
  const updateSuccess = useAppSelector(state => state.recu.updateSuccess);
  type FileType = Parameters<GetProp<UploadProps, 'beforeUpload'>>[0];
  const onFinish: FormProps['onFinish'] = values => {
    const entity = {
      ...values,
      file: imageUrl ? imageUrl.replace('data:image/jpeg;base64,', '') : imageUrl,
      fileContentType: imageUrlType,
      dateDeRemise: values.dateDeRemise,
    };

    dispatch(addCheque(entity));
    handleClose();
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {};

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Charger chèque</div>
    </button>
  );

  const getBase64 = (img: FileType, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };

  const handleChange: UploadProps['onChange'] = info => {
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj as FileType, url => {
        setImageUrl(url.split(',')[1]);
        setImageUrlType(info.file.type);
      });
    }
  };

  const handleChangeForm = (changedValues, allValues) => {
    if (changedValues?.montantCheque) {
      let montantCheque = changedValues.montantCheque;
      dispatch(handleAmountCheque(montantCheque));
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      montantCheque: amountCheque,
    });
  }, [amountCheque]);

  useEffect(() => {
    if (updateSuccess) {
      form.resetFields();
    }
  }, [updateSuccess]);
  return (
    <Modal width={'40%'} title="Créer un chèque" open={show} onOk={() => form.submit()} onCancel={handleClose}>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        onValuesChange={handleChangeForm}
        autoComplete="off"
      >
        <Form.Item hasFeedback>
          <Upload
            customRequest={({ onSuccess }) => onSuccess('ok')}
            name="file"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            onChange={handleChange}
          >
            {imageUrl ? <img src={`data:image/jpeg;base64,${imageUrl}`} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          {imageUrlType}
        </Form.Item>
        <Col>
          <Form.Item name="numCheque">
            <Input placeholder="Numero de Chèque" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="montantCheque">
            <InputNumber placeholder="Montant de Chèque" style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="dateDeRemise">
            <DatePicker placeholder="Date De Remise" style={{ width: '100%' }} type="date" />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item name="observation">
            <Input.TextArea placeholder="Observation" />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};

export default CreateCheckModal;
