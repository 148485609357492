import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, createEntity as createQuiz, updateEntity, reset } from './quiz.reducer';
import { createEntity as createQuestion } from './question.reducer';
import { createEntity as createChoice } from './choice.reducer';
import { Form, Input, Select, Button as AntButton, Card, Checkbox, Spin, AutoComplete } from 'antd';
import { Row, Col } from 'reactstrap'; // Keep Reactstrap's Row and Col
import { IQuiz } from 'app/shared/model/quiz.model';
import { IQuestion } from 'app/shared/model/question.model';
import { IChoice } from 'app/shared/model/choice.model';

const { Option } = Select;

export const QuizUpdate: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const quizEntity = useAppSelector(state => state.quiz.entity);
  const loading = useAppSelector(state => state.quiz.loading);
  const updating = useAppSelector(state => state.quiz.updating);
  const updateSuccess = useAppSelector(state => state.quiz.updateSuccess);

  const [form] = Form.useForm();

  const handleClose = () => {
    navigate('/quiz' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, [id, isNew, dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (!isNew && quizEntity) {
      form.setFieldsValue({
        ...quizEntity,
        questions:
          quizEntity.questions?.map(question => ({
            ...question,
            choices: question.choices || [],
          })) || [],
      });
    }
  }, [quizEntity, isNew, form]);

  const saveEntity = async values => {
    const entity: IQuiz = {
      ...quizEntity,
      ...values,
      questions: [],
    };

    const processQuestionsAndChoices = async (quizId, questions) => {
      const questionPromises = questions.map(async question => {
        const questionEntity: IQuestion = {
          ...question,
          quizId: quizId,
          choices: [],
        };

        const questionResult = await dispatch(createQuestion(questionEntity));
        const createdQuestion = questionResult.payload as { data: IQuestion };

        const questionId = createdQuestion.data.id;

        const choices = question.choices || [];
        const choicePromises = choices.map(async choice => {
          const choiceEntity: IChoice = {
            ...choice,
            questionId: questionId,
            isCorrect: choice.correct,
          };
          await dispatch(createChoice(choiceEntity));
        });

        await Promise.all(choicePromises);

        return createdQuestion;
      });

      const createdQuestions = await Promise.all(questionPromises);

      if (createdQuestions.includes(null)) {
        throw new Error('One or more questions failed to create');
      }
    };

    if (isNew) {
      try {
        const result = await dispatch(createQuiz(entity));
        const createdQuiz = result.payload as { data: IQuiz };

        const quizId = createdQuiz.data.id;
        const questions = values.questions || [];

        await processQuestionsAndChoices(quizId, questions);
        handleClose();
      } catch (error) {
        console.error('An error occurred while saving the entity:', error);
      }
    } else {
      try {
        // Update existing quiz
        const updatedResult = await dispatch(updateEntity(entity));
        const updatedQuiz = updatedResult.payload as { data: IQuiz };

        const quizId = updatedQuiz.data.id;
        const questions = values.questions || [];

        await processQuestionsAndChoices(quizId, questions);
        handleClose();
      } catch (error) {
        console.error('An error occurred while updating the entity:', error);
      }
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...quizEntity,
          questions:
            quizEntity.questions?.map(question => ({
              ...question,
              choices: question.choices || [],
            })) || [],
        };

  const handleAddQuestion = () => {
    const questions = form.getFieldValue('questions') || [];
    form.setFieldsValue({ questions: [...questions, { text: '', choices: [{ text: '', correct: false }] }] });
  };

  const handleAddChoice = index => {
    const questions = form.getFieldValue('questions');
    questions[index].choices.push({ text: '', correct: false });
    form.setFieldsValue({ questions });
  };

  const handleCorrectChange = (qIndex, cIndex) => {
    const questions = form.getFieldValue('questions');
    questions[qIndex].choices = questions[qIndex].choices.map((choice, index) => ({
      ...choice,
      correct: index === cIndex,
    }));
    form.setFieldsValue({ questions });
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.quiz.home.createOrEditLabel" data-cy="QuizCreateUpdateHeading">
            {isNew ? 'Créer un Quiz' : 'Editer le Quiz'}
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <Spin size="large" />
          ) : (
            <Form form={form} initialValues={defaultValues()} onFinish={saveEntity} layout="vertical">
              {!isNew && (
                <Form.Item name="id" label="ID">
                  <Input readOnly />
                </Form.Item>
              )}
              <Form.Item name="name" label="Quiz Name" rules={[{ required: true, message: 'Please enter the quiz name' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="category" label="Category" rules={[{ required: true, message: 'Please select a category' }]}>
                <AutoComplete
                  options={[{ value: 'Mathématiques' }, { value: 'Géographie' }, { value: 'Science' }]}
                  style={{ width: '100%' }}
                  placeholder="Select or create a category"
                />
              </Form.Item>

              <Form.Item name="niveau" label="Niveau" rules={[{ required: true, message: 'Please select a level' }]}>
                <Select>
                  <Option value="Facile">Facile</Option>
                  <Option value="Moyen">Moyen</Option>
                  <Option value="Difficile">Difficile</Option>
                </Select>
              </Form.Item>

              <Form.List name="questions">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Card key={field.key} style={{ marginBottom: '20px' }}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'text']}
                          label={`Question ${index + 1}`}
                          rules={[{ required: true, message: 'Veuillez entrer la question' }]} // Ajout de la règle de validation
                        >
                          <Input />
                        </Form.Item>
                        <Form.List name={[field.name, 'choices']}>
                          {(choiceFields, { add: addChoice, remove: removeChoice }) => (
                            <>
                              {choiceFields.map((choiceField, choiceIndex) => (
                                <Form.Item
                                  key={choiceField.key}
                                  {...choiceField}
                                  name={[choiceField.name, 'text']}
                                  label={`Choix ${choiceIndex + 1}`}
                                >
                                  <Input
                                    addonAfter={
                                      <Checkbox
                                        checked={form.getFieldValue(['questions', index, 'choices', choiceIndex, 'correct'])}
                                        onChange={() => handleCorrectChange(index, choiceIndex)}
                                      >
                                        Correct
                                      </Checkbox>
                                    }
                                  />
                                </Form.Item>
                              ))}
                              <AntButton type="dashed" onClick={() => addChoice()} style={{ width: '100%' }}>
                                Ajouter un choix
                              </AntButton>
                            </>
                          )}
                        </Form.List>
                      </Card>
                    ))}
                    <AntButton type="dashed" onClick={handleAddQuestion} style={{ width: '100%' }}>
                      Ajouter une question
                    </AntButton>
                  </>
                )}
              </Form.List>

              <Form.Item>
                <AntButton type="default" href="/quiz" style={{ marginRight: '8px' }}>
                  Back
                </AntButton>
                <AntButton type="primary" htmlType="submit" loading={updating}>
                  Save
                </AntButton>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default QuizUpdate;
