import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, reset, updateEntity } from './ressource.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { Button as AntButton, Checkbox, Col, DatePicker, Form, Row, Select, Spin, Tabs, Tag, Upload } from 'antd';
import moment from 'moment';
import { mapIdList } from 'app/shared/util/entity-utils';
import { ArrowLeftOutlined, LoadingOutlined, PlusOutlined, SaveOutlined, UploadOutlined } from '@ant-design/icons';

const { Option } = Select;
const { TabPane } = Tabs;

export const RessourceUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const groupes = useAppSelector(state => state.groupe.entities);
  const niveaux = useAppSelector(state => state.niveau.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const ressourceEntity = useAppSelector(state => state.ressource.entity);
  const loading = useAppSelector(state => state.ressource.loading);
  const updating = useAppSelector(state => state.ressource.updating);
  const updateSuccess = useAppSelector(state => state.ressource.updateSuccess);

  const [selectedNiveau, setSelectedNiveau] = useState<string | undefined>(undefined);
  const [selectedGroupe, setSelectedGroupe] = useState<number[]>([]);
  const [selectedEleve, setSelectedEleve] = useState<number[]>([]);
  const [fileData, setFileData] = useState<string | undefined>();
  const [fileContentType, setFileContentType] = useState<string | undefined>();
  const [isEleveMode, setIsEleveMode] = useState(false);

  const handleClose = () => {
    navigate('/ressource' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getNiveaux({}));
    dispatch(getGroupes({}));
    dispatch(getEleves({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (ressourceEntity && !isNew) {
      setFileContentType(ressourceEntity.fichierContentType);
      setFileData(ressourceEntity.fichier);
    }
  }, [ressourceEntity, isNew]);

  const saveEntity = values => {
    const entity = {
      ...ressourceEntity,
      ...values,
      fichier: fileData,
      fichierContentType: fileContentType,
      groupes: mapIdList(selectedGroupe),
      eleves: mapIdList(selectedEleve),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...ressourceEntity,
          fichier: fileData,
          fichierContentType: fileContentType,
          date: moment(ressourceEntity.date),
        };

  const handleFileChange = ({ file }) => {
    if (file.status === 'done') {
      const reader = new FileReader();
      reader.onload = () => {
        const result = reader.result as string;
        setFileData(result.split(',')[1]); // Extract base64 data
        setFileContentType(file.type);
      };
      reader.readAsDataURL(file.originFileObj as Blob);
    }
  };

  const disabledDate = current => current && current < moment().startOf('day');

  const handleSelectAll = checked => {
    const entities = isEleveMode ? eleves : groupes;
    const setSelected = isEleveMode ? setSelectedEleve : setSelectedGroupe;
    setSelected(checked ? entities.map(entity => entity.id) : []);
  };

  const handleFileRemove = () => {
    setFileData(null);
    setFileContentType(null);
  };

  return (
    <div>
      <Row justify="center">
        <Col md={16}>
          <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="green">
            Créer une Ressource
          </Tag>
        </Col>
      </Row>
      <Row justify="center">
        <Col md={16}>
          {loading ? (
            <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />
          ) : (
            <Form
              name="basic"
              style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}
              initialValues={defaultValues()}
              onFinish={saveEntity}
              autoComplete="off"
            >
              <div style={{ padding: '20px', borderRadius: '5px' }}>
                <Form.Item name="date" rules={[{ required: true, message: 'Veuillez entrer la date !' }]}>
                  <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" disabledDate={disabledDate} />
                </Form.Item>
                <Form.Item>
                  <Tabs defaultActiveKey="groupe" onChange={key => setIsEleveMode(key === 'eleve')}>
                    <TabPane tab="Groupe" key="groupe">
                      <Checkbox onChange={e => handleSelectAll(e.target.checked)} checked={selectedGroupe.length === groupes.length}>
                        Tout sélectionner
                      </Checkbox>
                      <div
                        style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '8px' }}
                      >
                        <Checkbox.Group onChange={setSelectedGroupe} value={selectedGroupe}>
                          {groupes
                            .slice()
                            .sort((a, b) => a.nom.localeCompare(b.nom))
                            .map(entity => (
                              <Checkbox value={entity.id} key={entity.id}>
                                {entity.nom}
                              </Checkbox>
                            ))}
                        </Checkbox.Group>
                      </div>
                    </TabPane>
                    <TabPane tab="Élève" key="eleve">
                      <Checkbox onChange={e => handleSelectAll(e.target.checked)} checked={selectedEleve.length === eleves.length}>
                        Tout sélectionner
                      </Checkbox>
                      <div
                        style={{ maxHeight: '200px', overflowY: 'auto', border: '1px solid #d9d9d9', borderRadius: '4px', padding: '8px' }}
                      >
                        <Checkbox.Group onChange={setSelectedEleve} value={selectedEleve}>
                          {eleves
                            .slice()
                            .sort((a, b) => a.nom.localeCompare(b.nom))
                            .map(entity => (
                              <Checkbox value={entity.id} key={entity.id}>
                                {`${entity.nom} ${entity.prenom}`}
                              </Checkbox>
                            ))}
                        </Checkbox.Group>
                      </div>
                    </TabPane>
                  </Tabs>
                </Form.Item>
              </div>
              <div style={{ padding: '20px', borderRadius: '5px' }}>
                <Form.Item name="file" hasFeedback>
                  <Upload
                    customRequest={({ onSuccess }) => onSuccess('ok')}
                    name="file"
                    showUploadList={false}
                    onChange={handleFileChange}
                    style={{ textAlign: 'center' }}
                  >
                    {fileData ? (
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <span style={{ display: 'block', margin: '10px 0' }}>
                          Fichier sélectionné: {fileContentType}
                          <AntButton
                            style={{
                              position: 'relative',
                              top: 0,
                              right: 0,
                              background: 'red',
                              color: 'white',
                              border: 'none',
                              cursor: 'pointer',
                              fontSize: '16px',
                              padding: '2px',
                              lineHeight: '1',
                              marginLeft: '10px',
                            }}
                            icon={<PlusOutlined />}
                            onClick={e => {
                              e.stopPropagation(); // Prevent triggering file input click
                              handleFileRemove();
                            }}
                          >
                            &times;
                          </AntButton>
                        </span>
                      </div>
                    ) : (
                      <AntButton icon={<UploadOutlined />}>Télécharger un fichier</AntButton>
                    )}
                  </Upload>
                </Form.Item>
              </div>
              <div>
                <AntButton
                  type="primary"
                  icon={<ArrowLeftOutlined />}
                  onClick={handleClose}
                  style={{ marginRight: '8px', width: '100%', backgroundColor: '#b7eb8f', border: 'none' }}
                >
                  Retour
                </AntButton>
              </div>
              <div>
                <AntButton
                  type="primary"
                  htmlType="submit"
                  loading={updating}
                  style={{ width: '100%', backgroundColor: '#389e0d', border: 'none' }}
                  icon={<SaveOutlined />}
                >
                  Enregistrer
                </AntButton>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RessourceUpdate;
