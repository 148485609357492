import React, { useEffect } from 'react';
import { Form, FormProps, Input, Modal, Radio } from 'antd';
import { ITarif } from 'app/shared/model/tarif.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createServiceEtablissementEntity, ServiceEtablisementSlice } from 'app/entities/service-etablisement/service-etablisement.reducer';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';
import { ServiceEtablisementType } from 'app/shared/model/enumerations/service-etablisement-type.model';

const CreateServiceModal = ({ show, handleClose, updateService }) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const newService = useAppSelector(state => state.serviceEtablisement.entity) as IServiceEtablisement;
  const isCreated = useAppSelector(state => state.serviceEtablisement.created);

  useEffect(() => {
    if (isCreated) {
      const generateRandomId = () => Math.floor(Math.random() * 1000000);
      updateService({ id: generateRandomId(), prix: 0, serviceEtablisement: newService } as ITarif);
      handleClose();
    }
  }, [isCreated]);
  const onFinish: FormProps['onFinish'] = async values => {
    const entity = {
      ...values,
      activated: true,
    };
    dispatch(createServiceEtablissementEntity({ type: ServiceEtablisementType.ANNUELLE, libelle: values.service, global: false }));
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {};

  return (
    <Modal width={'80%'} title="Créer un Service" open={show} onOk={() => form.submit()} onCancel={handleClose}>
      <Form
        name="basic"
        form={form}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Form.Item label="Service" name="service" rules={[{ required: true, message: 'Please input your username!' }]}>
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateServiceModal;
