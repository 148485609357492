import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState, JhiItemCount, JhiPagination, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import dayjs from 'dayjs';
import { generatePdfRecu, getEntities, printPdfRecu, setRecu, showPdfRecuInNewTab } from './recu.reducer';
import { IRecu } from 'app/shared/model/recu.model';
import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import { Alert, Badge, Button, Col, DatePicker, InputNumber, List, Modal, Pagination, Row, Select, Space, Table, Typography } from 'antd';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { GoLoading } from 'app/shared/loading';
import { Option } from 'antd/es/mentions';
import { getEntities as getTuteurs } from 'app/entities/tuteur/tuteur.reducer';
import { getEntities as getAnneeScolaires } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import useCurrentAnneeScolaire from 'app/shared/util/useCurrentAnneeScolaire';
import { getSession } from 'app/shared/reducers/authentication';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import { ModeReglement } from 'app/shared/model/enumerations/mode-reglement.model';
import ChooseRecuTypeModal from 'app/entities/recu/ChooseRecuTypeModal';

const { Text, Title } = Typography;
export const Recu = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedTuteur, setSelectedTuteur] = useState('All');
  const [selectedTuteurId, setSelectedTuteurId] = useState(null);
  const anneeScolaireList = useAppSelector(state => state.anneeScolaire.entities) as IAnneeScolaire[];
  const [selectedAnneeScolaire, setSelectedAnneeScolaire] = useState('All');
  const recuList: IRecu[] = useAppSelector(state => state.recu.entities);
  const loading = useAppSelector(state => state.recu.loading);
  const totalItems = useAppSelector(state => state.recu.totalItems);
  const tuteurs = useAppSelector(state => state.tuteur.entities) as ITuteur[];
  const [modal, setModal] = useState(false);
  const [recuSelected, setRecuSelected] = useState<IRecu>(null);
  const toggleModal = () => setModal(!modal);

  // filtrage

  const [selectedTuteurFilter, setSelectedTuteurFilter] = useState('');
  const [selectedDateDebut, setSelectedDateDebut] = useState(null);
  const [selectedDateFin, setSelectedDateFin] = useState(null);
  const [selectedMontantDebut, setSelectedMontantDebut] = useState(null);
  const [selectedMontantFin, setSelectedMontantFin] = useState(null);
  const [selectedModePaiement, setSelectedModePaiement] = useState(null);

  ////////
  const { currentAnneeScolaire, loadinghascurrentannescolaire } = useCurrentAnneeScolaire();
  useEffect(() => {
    const queryParams = [];

    if (selectedTuteurFilter) {
      queryParams.push(`tuteurId.equals=${selectedTuteurFilter}`);
    }

    if (selectedDateDebut) {
      const formattedDateDebut = selectedDateDebut.format('YYYY-MM-DD');
      queryParams.push(`dateDebut.greaterThanOrEqual=${formattedDateDebut}`);
    }

    if (selectedMontantDebut) {
      queryParams.push(`montantDebut.greaterThanOrEqual=${selectedMontantDebut}`);
    }
    if (selectedModePaiement) {
      queryParams.push(`modePaiement.equals=${selectedModePaiement.toString()}`);
    }

    if (selectedMontantFin) {
      queryParams.push(`montantFin.lessThanOrEqual=${selectedMontantFin}`);
    }

    if (selectedDateFin) {
      const formattedDateFin = selectedDateFin.format('YYYY-MM-DD');
      queryParams.push(`dateFin.lessThanOrEqual=${formattedDateFin}`);
    }
    if (currentAnneeScolaire?.id) {
      queryParams.push(`anneeScolaireId.equals=${Number(currentAnneeScolaire?.id)}`);
    }

    const query = queryParams.join('&');

    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      }),
    );
  }, [
    selectedTuteurFilter,
    currentAnneeScolaire,
    selectedMontantDebut,
    selectedMontantFin,
    selectedModePaiement,
    selectedDateDebut,
    selectedDateFin,
    paginationState,
  ]);

  const getAllEntities = () => {
    if (currentAnneeScolaire?.id) {
      const queryFilters = [`anneeScolaireId.equals=${Number(currentAnneeScolaire?.id)}`];

      if (selectedTuteurId) {
        queryFilters.push(`tuteurId.equals=${Number(selectedTuteurId)}`);
      }

      const query = queryFilters.length > 0 ? queryFilters.join('&') : null;

      dispatch(getTuteurs({ query: `&paged=false` }));
      dispatch(getAnneeScolaires({}));
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query: query,
        }),
      );
    }
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    dispatch(getSession());
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const roles = useAppSelector(state => state.authentication.account.authorities);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const isLatestRecuForTuteur = recu => {
    const tuteurRecus = recuList.filter(r => r.tuteur && r.tuteur.id === recu.tuteur.id);
    const latestRecu = tuteurRecus.reduce((latest, current) => {
      return dayjs(current.dateCreation).isAfter(dayjs(latest.dateCreation)) ? current : latest;
    }, tuteurRecus[0]);
    return recu.id === latestRecu.id;
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handlePaginationChange = (currentPage: number) => {
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

    navigate(`${pageLocation.pathname}?page=${currentPage}&sort=${paginationState.sort},${paginationState.order}`);
  };

  const handleSelectTuteur = eventKey => {
    if (eventKey === 'all') {
      setSelectedTuteurId(null);
      return;
    }
    const selectedTuteur = JSON.parse(eventKey);
    setSelectedTuteur(selectedTuteur.firstName);
    setSelectedTuteurId(selectedTuteur.id);
  };

  useEffect(() => {
    setPaginationState(prevState => ({
      ...prevState,
      activePage: 1,
    }));
    if (paginationState.activePage == 1) {
      getAllEntities();
    }
  }, [selectedTuteurId, loadinghascurrentannescolaire]);

  return (
    <div>
      <h2 id="recu-heading" data-cy="RecuHeading">
        Recus
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          {/*<Link to="/recu/new">*/}
          {/*  <Button className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">*/}
          {/*    <FontAwesomeIcon icon={faPlus} />*/}
          {/*    &nbsp; Créer un nouveau Recu*/}
          {/*  </Button>*/}
          {/*</Link>*/}
        </div>
      </h2>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={8}>
          <Select
            allowClear={true}
            placeholder="Tuteur"
            onChange={setSelectedTuteurFilter}
            showSearch
            filterOption={(input, option) => option.children.toString().includes(input)}
            style={{ width: '100%', fontWeight: 'lighter' }}
          >
            {tuteurs.map((tuteur: ITuteur) => (
              <Option key={tuteur?.id?.toString()} value={tuteur?.id.toString()}>
                {tuteur?.internalUser?.firstName} {tuteur?.internalUser?.lastName}
              </Option>
            ))}
          </Select>
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Select Date de Début" onChange={setSelectedDateDebut} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <DatePicker placeholder="Select Date de Fin" onChange={setSelectedDateFin} style={{ width: '100%', fontWeight: 'lighter' }} />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <InputNumber
            placeholder="Montant Début"
            onChange={setSelectedMontantDebut}
            style={{ width: '100%', fontWeight: 'lighter' }}
            min={0}
          />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <InputNumber
            placeholder="Montant Fin"
            onChange={setSelectedMontantFin}
            style={{ width: '100%', fontWeight: 'lighter' }}
            min={0}
          />
        </Col>

        <Col xs={24} sm={12} md={8}>
          <Select
            placeholder="Mode de Paiement"
            onChange={setSelectedModePaiement}
            style={{ width: '100%', fontWeight: 'lighter', opacity: '0' }}
            allowClear
          >
            {Object.keys(ModeReglement).map(mode => (
              <Option key={mode} value={mode}>
                {ModeReglement[mode as keyof typeof ModeReglement]}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>

      {recuList && recuList.length > 0 ? (
        <div>
          <div className="mt-4">
            {loading && recuList.length === 0 ? (
              <GoLoading loading={loading} />
            ) : recuList && recuList.length > 0 ? (
              <List
                grid={{
                  gutter: 16,
                  xs: 1, // 1 column on extra small screens (<576px)
                  sm: 1, // 1 column on small screens (≥576px)
                  md: 2, // 2 columns on medium screens (≥768px)
                  lg: 2, // 2 columns on large screens (≥992px)
                  xl: 2, // 2 columns on extra large screens (≥1200px)
                }}
                dataSource={recuList}
                renderItem={(item: IRecu) => {
                  const reglementTypes: ModeReglement[] = item.caisses ? item?.caisses?.map(caisse => caisse.type as ModeReglement) : [];
                  const canDelete = hasAnyAuthority(roles, [AUTHORITIES.ADMIN, 'ROLE_GESTION_DES_RECU_SUP']) && item?.cancelable;
                  return (
                    <List.Item
                      key={item.id}
                      className="hoverable-list-item"
                      onClick={() => {
                        if (!item?.htmlContent) return;
                        dispatch(showPdfRecuInNewTab({ recu: item }));
                      }}
                      style={{
                        padding: '20px',
                        borderRadius: '8px',
                        border: '1px solid #e8e8e8',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#fff',
                        marginBottom: '16px',
                        cursor: 'pointer',
                      }}
                    >
                      <Row justify="space-between" align="middle">
                        <Col>
                          <div style={{ display: 'flex', gap: '8px', marginTop: '8px' }}>
                            {reglementTypes.map((mode, index) => (
                              <Badge
                                key={index}
                                count={mode}
                                style={{
                                  backgroundColor: mode === 'CHEQUE' ? '#f5222d' : mode === 'VIREMENT' ? '#1890ff' : '#52c41a',
                                  color: '#fff',
                                }}
                              />
                            ))}
                          </div>
                          <Title level={5}>{dayjs(item?.dateCreation).format('DD MMM, YYYY')}</Title>
                          <Text
                            style={{ color: '#1DA57A' }}
                            strong
                            onClick={e => {
                              e.stopPropagation();
                            }}
                          >
                            <a style={{ color: '#1DA57A', marginRight: 6, textDecoration: 'none' }}>
                              {item?.tuteur?.internalUser?.firstName}
                            </a>
                          </Text>
                          <Text type="secondary">{dayjs(item?.dateCreation).fromNow()}</Text>
                          <p style={{ marginTop: '8px', maxWidth: '250px', wordWrap: 'break-word' }}>
                            Un montant de {item?.montant} Dh est payé et il reste une somme de {item?.reste} Dh .
                          </p>
                        </Col>
                        <Col>
                          <Space size="small">
                            <Button
                              icon={item?.htmlContent != null ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              style={{ color: item?.htmlContent ? '#FFA500' : '' }}
                              onClick={e => {
                                e.stopPropagation();
                                toggleModal();
                                dispatch(setRecu(item));
                              }}
                              type="link"
                              disabled={item?.htmlContent ? false : true}
                            />

                            <Button
                              onClick={e => {
                                e.stopPropagation();
                                window.location.href = `/recu/${item.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
                              }}
                              type="link"
                              danger
                              disabled={!canDelete}
                              icon={<FontAwesomeIcon icon={faTrash} />}
                            />
                          </Space>
                        </Col>
                      </Row>
                    </List.Item>
                  );
                }}
              />
            ) : (
              !loading && <Alert message="Aucun Annonce trouvé" type="warning" />
            )}
          </div>
          <div className={recuList && recuList.length > 0 ? '' : 'd-none'}>
            {totalItems ? (
              <div className="d-flex justify-content-center mt-4">
                <Pagination
                  current={paginationState.activePage}
                  onChange={handlePaginationChange}
                  total={totalItems}
                  pageSize={paginationState.itemsPerPage}
                  showSizeChanger={false}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <Modal title={'Choisir Format'} visible={modal} onCancel={toggleModal} footer={null}>
            <ChooseRecuTypeModal toggleModal={modal} />
          </Modal>
        </div>
      ) : (
        !loading && (
          <div className="alert alert-warning">
            <GoLoading loading={loading} />
            Pas de Recus trouvé
          </div>
        )
      )}
    </div>
  );
};
export default Recu;
