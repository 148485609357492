import './chart.scss';

import React from 'react';
import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Tabs } from 'antd';
import ChartGestionScolaire from 'app/modules/chart/chartGestionScolaire';
import Tresotory from 'app/modules/chart/tresotory';
import Computable from 'app/modules/chart/computable';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, ArcElement, Legend);

export const ChartX = () => {
  let items = [
    {
      label: `Gestion de soclaire `,
      key: 1,
      children: (
        <div>
          <ChartGestionScolaire />
        </div>
      ),
    },
    {
      label: `TDB Tresorerie  `,
      key: 2,
      children: (
        <div>
          <Tresotory />
        </div>
      ),
    },
    {
      label: `TDB Comptable  `,
      key: 3,
      children: (
        <div>
          <Computable />
        </div>
      ),
    },
  ] as any;
  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size={'large'} items={items} />
    </>
  );
};

export default ChartX;
