import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';

import {
  IDevoirSurvRequest,
  IDevoirSurvResponse,
  defaultValueDevoirSurvRequest,
  defaultValueDevoirSurvResponse,
} from 'app/shared/model/gestionNote/devoir-surv.model';

import { INoteResponse } from 'app/shared/model/gestionNote/note.model';

const apiUrl = 'api/devoir-surv';
const apiNoteUrl = 'api/notes';
interface UpdateNotePayload {
  id: number;
  note: string;
}
const initialState: EntityState<IDevoirSurvResponse> & {
  errorMessage: string | null;
  entities: IDevoirSurvResponse[];
  notesByElevesGroupe: INoteResponse[];
  entity: IDevoirSurvResponse;
  totalDevoirSurvs: number;
  showDevoirSurv: boolean;
  updateForm: boolean;
  updateDevoirSurvSuccess: boolean;
  validateNotesSuccess: boolean;
  loadDevoirSurvs: boolean;
  loadOneDevoirSurv: boolean;
  loadnotesByElevesGroupe: boolean;
  loadAddDevoirSurv: boolean;
  loadUpdateDevoirSurv: boolean;
  loadValidateNotes: boolean;
  getDevoirSurvsErrorMessage: string | null;
  getOneDevoirSurvErrorMessage: string | null;
} = {
  loading: false,
  stateTwo: undefined,
  updateSuccess: false,
  validateNotesSuccess: false,
  updating: false,
  errorMessage: null,
  entities: [],
  notesByElevesGroupe: [],
  entity: defaultValueDevoirSurvResponse,
  totalDevoirSurvs: 0,
  showDevoirSurv: false,
  updateForm: false,
  loadOneDevoirSurv: false,
  loadUpdateDevoirSurv: false,
  updateDevoirSurvSuccess: false,
  loadDevoirSurvs: false,
  loadAddDevoirSurv: false,
  loadnotesByElevesGroupe: false,
  loadValidateNotes: false,
  getDevoirSurvsErrorMessage: null,
  getOneDevoirSurvErrorMessage: null,
};

export const getDevoirSurvs = createAsyncThunk('devoir-surv/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}
  cacheBuster=${new Date().getTime()}`;
  return axios.get<IDevoirSurvResponse[]>(requestUrl);
});

export const getOneDevoirSurv = createAsyncThunk(
  'devoir-surv/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IDevoirSurvResponse>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const addDevoirSurv = createAsyncThunk('devoir-surv/add_entity_list', async (devoirSurv: IDevoirSurvRequest, thunkAPI) => {
  const result = await axios.post<IDevoirSurvResponse>(apiUrl, devoirSurv);
  const withRealAnneeScolaire = false;
  thunkAPI.dispatch(
    getDevoirSurvs({
      query: `withRealAnneeScolaire=${withRealAnneeScolaire}`,
    }),
  );
  return result;
});

export const deleteDevoirSurv = createAsyncThunk(
  'devoir-surv/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete(requestUrl);
    const withRealAnneeScolaire = false;
    thunkAPI.dispatch(
      getDevoirSurvs({
        query: `withRealAnneeScolaire=${withRealAnneeScolaire}`,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateDevoirSurv = createAsyncThunk(
  'devoir-surv/update_entity',
  async (devoirSurv: IDevoirSurvRequest, thunkAPI) => {
    const requestUrl = `${apiUrl}/${devoirSurv.id}`;
    const result = await axios.put<IDevoirSurvResponse>(requestUrl, devoirSurv);
    const withRealAnneeScolaire = false;
    thunkAPI.dispatch(
      getDevoirSurvs({
        query: `withRealAnneeScolaire=${withRealAnneeScolaire}`,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getNotesByEleveGroupe = createAsyncThunk(
  'devoirSurv/fetch_notes_by_eleve_groupe',
  async ({ eleveIds, devoirSurvId }: { eleveIds: number[]; devoirSurvId: number }) => {
    const requestUrl = `${apiNoteUrl}/byEleveGroupe?devoirSurvId=${devoirSurvId}&${eleveIds.map(id => `eleveIds=${id}`).join('&')}`;
    return axios.get<INoteResponse[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const validateNotesOfEleveGroupe = createAsyncThunk(
  'devoirSurv/bulk_update_notes',
  async ({ notesByElevesGroupe, devoirSurvId }: { notesByElevesGroupe: INoteResponse[]; devoirSurvId: number }, thunkAPI) => {
    const requestUrl = 'api/notes/validate_note';
    await axios.post<void>(requestUrl, notesByElevesGroupe, { params: { devoirSurvId } });
    return notesByElevesGroupe;
  },
  { serializeError: serializeAxiosError },
);

export const generatePdfGroupeNotes = createAsyncThunk(
  'notes/generate_GroupeNotes',
  async ({ notesByElevesGroupe, groupeNom }: { notesByElevesGroupe: INoteResponse[]; groupeNom: string }) => {
    const requestUrl = `api/notes/generate-pdf?groupeNom=${encodeURIComponent(groupeNom)}`;
    const response = await axios.post<Blob>(requestUrl, notesByElevesGroupe, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'generated.pdf');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const DevoirSurvSlice = createEntitySlice({
  name: 'devoir-surv',
  initialState,
  reducers: {
    updateStatusForm: (state: any, action: PayloadAction<boolean>) => {
      state.updateForm = action.payload;
    },
    updateNoteEleve: (state: any, action: PayloadAction<UpdateNotePayload>) => {
      const { id, note } = action.payload;

      state.notesByElevesGroupe = state.notesByElevesGroupe.map(noteItem =>
        noteItem?.eleve?.id === id ? { ...noteItem, note } : noteItem,
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDevoirSurvs.pending, (state: any) => {
        state.loadDevoirSurvs = true;
      })
      .addCase(getDevoirSurvs.fulfilled, (state: any, action) => {
        state.loadDevoirSurvs = false;
        state.entities = action.payload.data;
      })
      .addCase(getDevoirSurvs.rejected, (state: any, action) => {
        state.loadDevoirSurvs = false;
        state.getDevoirSurvsErrorMessage = action.error.message;
      })
      .addCase(getOneDevoirSurv.pending, (state: any) => {
        state.loadOneDevoirSurv = true;
      })
      .addCase(getOneDevoirSurv.fulfilled, (state: any, action) => {
        state.loadOneDevoirSurv = false;
        state.entity = action.payload.data;
      })
      .addCase(getOneDevoirSurv.rejected, (state: any, action) => {
        state.loadOneDevoirSurv = false;
        state.getOneDevoirSurvErrorMessage = action.error.message;
      })
      .addCase(addDevoirSurv.pending, (state: any) => {
        state.loadAddDevoirSurv = true;
      })
      .addCase(addDevoirSurv.fulfilled, (state: any, action) => {
        state.loadAddDevoirSurv = false;
        state.entity = action.payload.data;
      })
      .addCase(addDevoirSurv.rejected, (state: any, action) => {
        state.loadAddDevoirSurv = false;
        state.getOneDevoirSurvErrorMessage = action.error.message;
      })
      .addCase(updateDevoirSurv.pending, (state: any) => {
        state.loadUpdateDevoirSurv = true;
        state.updateDevoirSurvSuccess = false;
      })
      .addCase(updateDevoirSurv.fulfilled, (state: any, action) => {
        state.loadUpdateDevoirSurv = false;
        state.updateDevoirSurvSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateDevoirSurv.rejected, (state: any, action) => {
        state.loadUpdateDevoirSurv = false;
        state.updateDevoirSurvSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(getNotesByEleveGroupe.pending, (state: any) => {
        state.loadNotesByElevesGroupe = true;
      })
      .addCase(getNotesByEleveGroupe.fulfilled, (state: any, action) => {
        state.loadNotesByElevesGroupe = false;
        state.notesByElevesGroupe = action.payload.data;
      })
      .addCase(getNotesByEleveGroupe.rejected, (state: any, action) => {
        state.loadNotesByElevesGroupe = false;
        state.errorMessage = action.error.message;
      })
      .addCase(validateNotesOfEleveGroupe.pending, (state: any) => {
        state.loadValidateNotes = true;
        state.validateNotesSuccess = false;
      })
      .addCase(validateNotesOfEleveGroupe.fulfilled, (state: any, action) => {
        state.loadValidateNotes = false;
        state.validateNotesSuccess = true;
      })
      .addCase(validateNotesOfEleveGroupe.rejected, (state: any, action) => {
        state.loadValidateNotes = false;
        state.validateNotesSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
});

export const { updateStatusForm, updateNoteEleve, reset } = DevoirSurvSlice.actions;

export default DevoirSurvSlice.reducer;
