import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './emploi-temps.reducer';
import { Tag } from 'antd';

export const EmploiTempsDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const emploiTempsEntity = useAppSelector(state => state.emploiTemps.entity);
  return (
    <Row>
      <Col md="8">
        <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="orange">
          Emploi Temps
        </Tag>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{emploiTempsEntity.id}</dd>
          <dt>
            <span id="nom">Nom</span>
          </dt>
          <dd>{emploiTempsEntity.nom}</dd>
        </dl>
        <Button tag={Link} to="/emploi-temps" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/emploi-temps/${emploiTempsEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default EmploiTempsDetail;
