import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Caisse from './caisse';
import CaisseDetail from './caisse-detail';
import CaisseUpdate from './caisse-update';
import CaisseDeleteDialog from './caisse-delete-dialog';

const CaisseRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Caisse />} />
    <Route path="new" element={<CaisseUpdate />} />
    <Route path=":id">
      <Route index element={<CaisseDetail />} />
      <Route path="edit" element={<CaisseUpdate />} />
      <Route path="delete" element={<CaisseDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CaisseRoutes;
