import { useEffect, useState } from 'react';
import { hasCurrentAnneeScolaire } from 'app/entities/utilisateur/utilisateur.reducer';
import { getEntities as GetAnneeScolaire } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';

const useCurrentAnneeScolaire = () => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const hascurrentanneescolaire: boolean = useAppSelector(state => state.utilisateur.hascurrentanneescolaire);
  const loadinghascurrentannescolaire: boolean = useAppSelector(state => state.utilisateur.loadinghascurrentannescolaire);
  const anneeScolaireList: IAnneeScolaire[] = useAppSelector(state => state.anneeScolaire.entities);

  const [periode, setPeriode] = useState<string>();
  const [currentAnneeScolaire, setCurrentAnneeScolaire] = useState<IAnneeScolaire>();

  useEffect(() => {
    dispatch(GetAnneeScolaire({}));
    dispatch(hasCurrentAnneeScolaire());
  }, [dispatch]);

  useEffect(() => {
    if (!loadinghascurrentannescolaire) {
      if (hascurrentanneescolaire) {
        const currentPeriod = anneeScolaireList.find(item => item?.id === account?.anneeScolaire?.id);
        setPeriode(currentPeriod?.periode);
        setCurrentAnneeScolaire(currentPeriod);
      } else {
        const currentPeriod = anneeScolaireList.find(item => item.isCurrent);
        if (currentPeriod?.periode) {
          setPeriode(currentPeriod.periode);
          setCurrentAnneeScolaire(currentPeriod);
        }
      }
    }
  }, [loadinghascurrentannescolaire, hascurrentanneescolaire, account]);

  return { periode, loadinghascurrentannescolaire, hascurrentanneescolaire, anneeScolaireList, currentAnneeScolaire };
};

export default useCurrentAnneeScolaire;
