import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue } from 'app/shared/model/screen.model';
import { IScreen } from 'app/shared/model/screen.model';
import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { IGarderie, defaultValue as defaultGarderie } from 'app/shared/model/garderie.model';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { getServiceEtablissementEntities } from 'app/entities/service-etablisement/service-etablisement.reducer';
import { IRecu } from 'app/shared/model/recu.model';
import { getEntities } from 'app/entities/recu/recu.reducer';

const initialState: EntityState<IScreen> & {
  errorMessage: string | null;
  entities: IScreen[];
  entity: IScreen;
  garderies: IGarderie[];
  garderie: IGarderie;
  totalScreens: number;
  showScreen: boolean;
  updateScreenSuccess: boolean;
  loadScreens: boolean;
  loadOneScreen: boolean;
  loadAddScreen: boolean;
  getScreensErrorMessage: string | null;
  getOneScreenErrorMessage: string | null;
  loadGarderies: boolean;
  loadOneGarderie: boolean;
  getGarderiesErrorMessage: string | null;
  getOneGarderieErrorMessage: string | null;
} = {
  loading: false,
  stateTwo: undefined,
  updateSuccess: false,
  updating: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  garderies: [],
  garderie: defaultGarderie,
  totalScreens: 0,
  showScreen: false,
  loadOneScreen: false,
  updateScreenSuccess: false,
  loadScreens: false,
  loadAddScreen: false,
  getScreensErrorMessage: null,
  getOneScreenErrorMessage: null,
  loadGarderies: false,
  loadOneGarderie: false,
  getGarderiesErrorMessage: null,
  getOneGarderieErrorMessage: null,
};

const apiUrl = 'api/screens';
const apiUrlGarderie = 'api/garderie_notification';

export const getScreens = createAsyncThunk('screens/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}
  cacheBuster=${new Date().getTime()}`;
  return axios.get<IScreen[]>(requestUrl);
});

export const getOneScreen = createAsyncThunk(
  'screens/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IScreen>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const addScreen = createAsyncThunk('screens/add_entity_list', async (screen: IScreen, thunkAPI) => {
  const result = await axios.post<IScreen>(apiUrl, cleanEntity(screen));
  thunkAPI.dispatch(getScreens({}));
  return result;
});

export const getGarderies = createAsyncThunk('garderies/fetch_garderie_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrlGarderie}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}
  cacheBuster=${new Date().getTime()}`;
  return axios.get<IGarderie[]>(requestUrl);
});

export const deleteGarderie = createAsyncThunk(
  'garderie/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrlGarderie}/${id}`;
    const result = await axios.delete(requestUrl);
    // thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const GarderieSlice = createEntitySlice({
  name: 'garderie',
  initialState,
  reducers: {
    showScreen: (state: any) => {
      state.showScreen = !state.showScreen;
    },
    updateGarderies(state: any, action: PayloadAction<IGarderie[]>) {
      state.garderies = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getScreens.pending, (state: any) => {
        state.loadScreens = true;
      })
      .addCase(getScreens.fulfilled, (state: any, action) => {
        state.loadScreens = false;
        state.entities = action.payload.data;
      })
      .addCase(getScreens.rejected, (state: any, action) => {
        state.loadScreens = false;
        state.getScreensErrorMessage = action.error.message;
      })
      .addCase(getOneScreen.pending, (state: any) => {
        state.loadOneScreen = true;
      })
      .addCase(getOneScreen.fulfilled, (state: any, action) => {
        state.loadOneScreen = false;
        state.entity = action.payload.data;
      })
      .addCase(getOneScreen.rejected, (state: any, action) => {
        state.loadOneScreen = false;
        state.getOneScreenErrorMessage = action.error.message;
      })
      .addCase(addScreen.pending, (state: any) => {
        state.loadAddScreen = true;
      })
      .addCase(addScreen.fulfilled, (state: any, action) => {
        state.loadAddScreen = false;
        state.entity = action.payload.data;
      })
      .addCase(addScreen.rejected, (state: any, action) => {
        state.loadAddScreen = false;
        state.getOneScreenErrorMessage = action.error.message;
      })
      .addCase(getGarderies.pending, (state: any) => {
        state.loadGarderies = true;
      })
      .addCase(getGarderies.fulfilled, (state: any, action) => {
        state.loadGarderies = false;
        state.garderies = action.payload.data;
      })
      .addCase(getGarderies.rejected, (state: any, action) => {
        state.loadGarderies = false;
        state.getGarderiesErrorMessage = action.error.message;
      });
  },
});

export const { reset, updateGarderies, showScreen } = GarderieSlice.actions;

export default GarderieSlice.reducer;
