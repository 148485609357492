import React from 'react';
import { LinearProgress, linearProgressClasses, styled } from '@mui/material';

// Custom styled LinearProgress component
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

interface ProgressBarInfoProps {
  niveauTitle: string;
  elevesInfo: string;
  progressValue: number;
}

const ProgressBarInfo: React.FC<ProgressBarInfoProps> = ({ niveauTitle, elevesInfo, progressValue }) => {
  return (
    <div style={{ marginBottom: '14px' }}>
      <div className="progress-bar-info">
        <h1 className="niveau-title">{niveauTitle}</h1>
        <h2 className="niveau-info">{elevesInfo}</h2>
      </div>
      <BorderLinearProgress variant="determinate" value={progressValue} />
    </div>
  );
};

export default ProgressBarInfo;
