import dayjs from 'dayjs';
import { IEleve } from 'app/shared/model/eleve.model';

interface IImage {
  photoContentType?: string | null;
  photo?: string | null;
}

export interface IGalerie {
  id?: number;
  nom?: string;
  date?: dayjs.Dayjs | null;
  images?: IImage[] | null;
  imageContentType?: string | null;
  image?: string | null;
  eleves?: IEleve[] | null;
  groupeId?: number | null;
  niveauId?: number | null;
}

export const defaultValue: Readonly<IGalerie> = {};
