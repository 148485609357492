import axios from 'axios';
import { PayloadAction, createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IMessage, defaultValue } from 'app/shared/model/message.model';
import { MessageSender } from 'app/shared/model/enumerations/MessageSender';
import { getScreens } from 'app/entities/garderie/garderie.reducer';

const initialState: EntityState<IMessage> = {
  loading: false,
  errorMessage: null,
  entities: [],
  selectedUserIdForChat: null,
  selectedTutorNameForChat: '',
  unreadMessages: [],
  loadingUnreadMessages: false,
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/messages';
const apiUnreadUrl = `${apiUrl}/unread`;

// Actions

export const getUnreadMessages = createAsyncThunk(
  'message/fetch_unread_messages',
  async () => {
    const requestUrl = `${apiUnreadUrl}?cacheBuster=${new Date().getTime()}`;
    return axios.get<IMessage[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntities = createAsyncThunk('message/fetch_entity_list', async ({ query, sort, page, size }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${query}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IMessage[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'message/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IMessage>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);
export const createEntity = createAsyncThunk(
  'message/create_entity',
  async (entity: IMessage, thunkAPI) => {
    // Set the message_sender to MessageSender.USER
    entity.messageSender = MessageSender.USER;

    const result = await axios.post<IMessage>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const createChatMessage = createAsyncThunk(
  'message/create_chat_message',
  async ({ entity, queryParams }: { entity: IMessage; queryParams: IQueryParams }, thunkAPI) => {
    entity.messageSender = MessageSender.ADMINISTRATION;
    entity.isRead = false;

    const result = await axios.post<IMessage>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities(queryParams));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'message/update_entity',
  async (entity: IMessage, thunkAPI) => {
    const result = await axios.put<IMessage>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'message/partial_update_entity',
  async (entity: IMessage, thunkAPI) => {
    const result = await axios.patch<IMessage>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'message/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IMessage>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);
export const changeReadStatusAsync = createAsyncThunk(
  'message/mark_read',
  async ({ userId, type }: { userId: number; type: 'ADMINISTRATION' | 'USER' }) => {
    try {
      const response = await axios.put(`${apiUrl}/mark_read`, null, {
        params: {
          userId,
          messageSender: type,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Failed to change read status:', error);
      throw error;
    }
  },
);

// slice

export const MessageSlice = createEntitySlice({
  name: 'message',
  initialState,
  reducers: {
    setSelectedUserIdForChat(state, action: PayloadAction<number | null>) {
      state.selectedUserIdForChat = action.payload;
    },
    setSelectedTutorNameForChat(state, action: PayloadAction<string | null>) {
      state.selectedTutorNameForChat = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(changeReadStatusAsync.fulfilled, (state, action) => {
        state.loadingUnreadMessages = false;
        // state.updateSuccess = true; // Set update success flag if needed
      })
      .addCase(getUnreadMessages.pending, (state: any) => {
        state.loadingUnreadMessages = true;
      })
      .addCase(getUnreadMessages.fulfilled, (state: any, action) => {
        state.loadingUnreadMessages = false;
        state.unreadMessages = action.payload.data;
      })
      .addCase(getUnreadMessages.rejected, (state: any, action) => {
        state.loadScreens = false;
      })
      .addMatcher(isPending(changeReadStatusAsync), state => {
        state.loading = true; // Handle loading state if needed
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, setSelectedTutorNameForChat, setSelectedUserIdForChat } = MessageSlice.actions;

// Reducer
export default MessageSlice.reducer;
