// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-list-container {
  width: 60vw;
}

.card-style--garderie {
  height: 65vh;
  overflow-y: auto;
}

.card-style--garderie::-webkit-scrollbar {
  width: 0px;
}

.custom-card .ant-card-head {
  background: #ffffff; /* White background for the header */
}

.hoverable-list-item {
  transition: all 0.3s ease; /* Smooth transition for hover effect */
}

.hoverable-list-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
  background-color: #f9f9f9; /* Slightly different background on hover */
  transform: translateY(-2px); /* Lift the item a bit on hover */
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/garderie/styling.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;AACF;;AAEA;EACE,YAAA;EACA,gBAAA;AACF;;AACA;EACE,UAAA;AAEF;;AAAA;EACE,mBAAA,EAAA,oCAAA;AAGF;;AAAA;EACE,yBAAA,EAAA,uCAAA;AAGF;;AAAA;EACE,yCAAA,EAAA,2BAAA;EACA,yBAAA,EAAA,2CAAA;EACA,2BAAA,EAAA,iCAAA;AAGF","sourcesContent":[".w-list-container {\n  width: 60vw;\n  //filter:drop-shadow(0 0 0.75rem #eed5db);\n}\n.card-style--garderie {\n  height: 65vh;\n  overflow-y: auto;\n}\n.card-style--garderie::-webkit-scrollbar {\n  width: 0px;\n}\n.custom-card .ant-card-head {\n  background: #ffffff; /* White background for the header */\n}\n\n.hoverable-list-item {\n  transition: all 0.3s ease; /* Smooth transition for hover effect */\n}\n\n.hoverable-list-item:hover {\n  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */\n  background-color: #f9f9f9; /* Slightly different background on hover */\n  transform: translateY(-2px); /* Lift the item a bit on hover */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
