import dayjs from 'dayjs';
import { IEleve } from 'app/shared/model/eleve.model';
import { ServiceEtablisementType } from 'app/shared/model/enumerations/service-etablisement-type.model';

export interface IServiceEtablisement {
  id?: number;
  libelle?: string | null;
  type?: keyof typeof ServiceEtablisementType | null;
  global?: boolean | null;
  duree?: dayjs.Dayjs | null;
  eleves?: IEleve[] | null;
}

export const defaultValue: Readonly<IServiceEtablisement> = {};
