import './chart.scss';

import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBoard, getTDBComptableBilan } from 'app/modules/chart/board.reducer';
import { IBoard } from 'app/shared/model/board.model';
import { NotificationOutlined } from '@ant-design/icons';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Button from 'react-bootstrap/Button';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
import './chart.scss';
import { ITDBComptableBilan } from 'app/shared/model/ITDBComptableBilan.model';
import { GoLoading } from 'app/shared/loading';

export const Computable = () => {
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(state => state.board.bilanLoading) as boolean;
  const bilan = useAppSelector(state => state.board.bilan) as ITDBComptableBilan;
  const aggregatedPayments = Object.entries(bilan.aggregatedPayments).map(([key, value]) => ({
    rubrique: value.rubrique,
    montantGlobal: value.totalMontantAPayer,
    montantEffectue: value.totalMontantAPayer - value.totalRest,
    restDh: value.totalRest,
    restPercent: ((value.totalRest / value.totalMontantAPayer) * 100).toFixed(2),
  }));

  useEffect(() => {
    dispatch(getTDBComptableBilan());
  }, []);

  return (
    <div className="container">
      <h2>TDB Comptable Bilan</h2>

      <ul className="responsive-table">
        <li className="table-header">
          <div className="col col-1">Rubrique</div>
          <div className="col col-2">Montent Global</div>
          <div className="col col-3">Montent Effectue</div>
          <div className="col col-4">Rest (DH)</div>
          <div className="col col-5">Rest (%)</div>
        </li>
        {isLoading ? (
          <GoLoading loading={isLoading} />
        ) : (
          <>
            {aggregatedPayments.map(item => (
              <li className="table-row" key={item.rubrique}>
                <div className="col col-1" data-label="Rubrique">
                  {item.rubrique}
                </div>
                <div className="col col-2" data-label="Montent Global">
                  {item.montantGlobal} DH
                </div>
                <div className="col col-3" data-label="Montent Effectue">
                  {item.montantEffectue} DH
                </div>
                <div className="col col-4" data-label="Rest (DH)">
                  {item.restDh} DH
                </div>
                <div className="col col-5" data-label="Rest (%)">
                  {item.restPercent} %
                </div>
              </li>
            ))}

            <li className="table-row" style={{ backgroundColor: 'lightgray', color: 'white', fontWeight: 'bold' }}>
              <div className="col col-1" data-label="Total">
                Total
              </div>
              <div className="col col-2" data-label="Montent Global">
                {bilan.totalMontantAPayer} DH
              </div>
              <div className="col col-3" data-label="Montent Effectue">
                {bilan.totalMontantAPayer - bilan.totalRest} DH
              </div>
              <div className="col col-4" data-label="Rest (DH)">
                {bilan.totalRest} DH
              </div>
              <div className="col col-5" data-label="Rest (%)">
                {((bilan.totalRest / bilan.totalMontantAPayer) * 100).toFixed(2)} %
              </div>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Computable;
