import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './matiere.reducer';

export const MatiereDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const matiereEntity = useAppSelector(state => state.matiere.entity);
  return (
    <Row>
      <Col md="8">
        <div className="entity-details">
          <h2 data-cy="matiereDetailsHeading">Matières</h2>
          <dl className="jh-entity-details">
            <div className="entity-details-item">
              <dt>
                <span id="id">ID</span>
              </dt>
              <dd className="entity-details-value">{matiereEntity.id}</dd>
            </div>
            <div className="entity-details-item">
              <dt>
                <span id="nom">Nom</span>
              </dt>
              <dd className="entity-details-value">{matiereEntity.nom}</dd>
            </div>
            <div className="entity-details-item">
              <dt>
                <span id="code">Code</span>
              </dt>
              <dd className="entity-details-value">{matiereEntity.code}</dd>
            </div>
          </dl>
          <div className="entity-actions">
            <Button tag={Link} to="/matiere" replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/matiere/${matiereEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default MatiereDetail;
