import React from 'react';
import { Image } from 'antd';
import { IGarderie } from 'app/shared/model/garderie.model';

const Cards: React.FC<{ data: IGarderie[]; numEleve?: number }> = ({ data, numEleve }) => {
  const contentStyle: React.CSSProperties = {
    height: '100vh',
    color: '#fff',
    backgroundImage: 'url(content/images/composed-puzzles-white.jpg)',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const imageContainerStyle: React.CSSProperties = {
    position: 'relative',
    width: '100%',
    borderRadius: '25px',
    overflow: 'hidden',
  };

  const imageStyle: React.CSSProperties = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const overlayTextStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
  };

  const getColorForDescription = (description: string | undefined) => {
    switch (description) {
      case 'Retard':
        return 'red';
      case 'En route':
        return 'blue';
      case 'Arrive':
        return 'green';
      default:
        return 'orange';
    }
  };

  const gridStyle: React.CSSProperties = {
    display: 'grid',
    gridTemplateColumns: `repeat(2, 1fr)`, // je vais changé cette valeur apres
    gap: '20px',
    padding: '20px',
    width: '100%',
  };

  return (
    <div style={contentStyle}>
      <div style={numEleve && numEleve > 1 ? gridStyle : {}}>
        {data.map((item, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              height: numEleve && numEleve > 2 ? '50vh' : 'auto',
              flexDirection: 'column',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <div style={{ fontSize: '36px' }}>
              <span style={{ fontWeight: 'bold', opacity: '80%' }}>
                {' '}
                {item.tuteur?.firstName} {item.tuteur?.lastName}{' '}
              </span>
              <span
                style={{
                  fontWeight: 'bold',
                  opacity: '80%',
                  color: getColorForDescription(item.type?.toString()),
                }}
              >
                {item.type}
              </span>
              <span style={{ fontWeight: 'bold', opacity: '80%' }}>
                {' '}
                {item.eleve?.prenom} {item.eleve?.nom}
              </span>
            </div>
            <div style={imageContainerStyle}>
              <Image src={`data:image/jpeg;base64,${item.eleve?.photo}`} style={imageStyle} preview={false} />
              <div style={overlayTextStyle}>
                <h1>{item.eleve?.groupe.niveau.libelle}</h1>
                <h1 style={{ paddingLeft: '10px', paddingRight: '10px' }}> - </h1>
                <h1>{item.eleve?.groupe.nom}</h1>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Cards;
