import dayjs from 'dayjs';
import { IEleve } from 'app/shared/model/eleve.model';
import { IGroupe } from 'app/shared/model/groupe.model';

export interface IRessource {
  id?: number;
  date?: dayjs.Dayjs | null;
  fichierContentType?: string | null;
  fichier?: string | null;
  groupes?: IGroupe[] | null;
  eleves?: IEleve[] | null;
}

export const defaultValue: Readonly<IRessource> = {};
