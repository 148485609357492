import React, { useState } from 'react';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { Route } from 'react-router-dom';
import CahierTransmission from 'app/entities/cahier-transmission/CahierTransmission';
import TransmissionDeleteDialog from 'app/entities/cahier-transmission/transmission-delete-dialog';
import TransmissionValidation from 'app/entities/cahier-transmission/transmission-validation';
import TransmissionDetail from 'app/entities/cahier-transmission/transmission-detail';

const CahierTransmissionRoutes = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <ErrorBoundaryRoutes>
      <Route index element={<CahierTransmission />} />
      <Route path="validate" element={<TransmissionValidation />} />
      <Route path=":id">
        <Route index element={<TransmissionDetail />} />
        <Route path="delete" element={<TransmissionDeleteDialog />} />
      </Route>
    </ErrorBoundaryRoutes>
  );
};

export default CahierTransmissionRoutes;
