import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Space, Popconfirm, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlus, faPencilAlt, faTrash, faSortUp, faSortDown, faSort } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, deleteEntity } from './caisse.reducer';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { getPaginationState, openFile, TextFormat } from 'react-jhipster';
import type { PopconfirmProps } from 'antd';

export const Caisse = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [currentCaisse, setCurrentCaisse] = useState(null);
  const [currentId, setCurrentId] = useState(null);

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const caisseList = useAppSelector(state => state.caisse.entities);
  const loading = useAppSelector(state => state.caisse.loading);
  const totalItems = useAppSelector(state => state.caisse.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const confirmDelete: PopconfirmProps['onConfirm'] = () => {
    dispatch(deleteEntity(currentId));
    message.success('Supprimé');
  };

  const cancelDelete: PopconfirmProps['onCancel'] = () => {
    message.error('Annulé');
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    return sortFieldName === fieldName ? (order === ASC ? faSortUp : faSortDown) : faSort;
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: text => (
        <Button type="link" href={`/caisse/${text}`}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Statut',
      dataIndex: 'statut',
      sorter: true,
      render: text => (text ? 'true' : 'false'),
    },
    {
      title: 'Montant',
      dataIndex: 'montant',
      sorter: true,
    },
    {
      title: 'Montant Sortie',
      dataIndex: 'montantSortie',
      sorter: true,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: true,
      render: date => (date ? <TextFormat type="date" value={date} format={APP_LOCAL_DATE_FORMAT} /> : null),
    },
    {
      title: 'Num Cheque',
      dataIndex: 'numCheque',
      sorter: true,
    },
    {
      title: 'Photo Cheque',
      dataIndex: 'photoCheque',
      render: (text, record) => (
        <div>
          {record.photoChequeContentType ? (
            <a onClick={() => openFile(record.photoChequeContentType, record.photoCheque)}>
              <img src={`data:${record.photoChequeContentType};base64,${record.photoCheque}`} style={{ maxHeight: '30px' }} />
            </a>
          ) : null}
        </div>
      ),
    },
    {
      title: 'Etat Service Mois',
      dataIndex: 'etatServiceMois',
      render: (text, record) =>
        record.etatServiceMois ? <Link to={`/etat-service-mois/${record.etatServiceMois.id}`}>{record.etatServiceMois.id}</Link> : '',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" color="info" href={`/caisse/${record.id}`} icon={<FontAwesomeIcon icon="eye" />}></Button>
          <Button
            type="link"
            color="primary"
            href={`/caisse/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
          ></Button>
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer ?"
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" danger onClick={() => setCurrentId(record.id)} icon={<FontAwesomeIcon icon={faTrash} />} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="caisse-heading" data-cy="CaisseHeading">
        Caisses
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/caisse/new">
            <Button className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon={faPlus} />
              &nbsp; Créer un nouveau Caisse
            </Button>
          </Link>
        </div>
      </h2>
      <Table
        columns={columns}
        dataSource={caisseList}
        rowKey="id"
        pagination={{
          current: paginationState.activePage,
          pageSize: paginationState.itemsPerPage,
          total: totalItems,
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default Caisse;
