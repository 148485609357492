export interface IAnneeScolaire {
  id?: number;
  periode?: string | null;
  isCurrent?: boolean | null;
  current?: boolean | null;
}

export const defaultValue: Readonly<IAnneeScolaire> = {
  isCurrent: false,
};
