import React from 'react';
import { Button, Modal, Form, Input, Select, message } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ServiceEtablisementType } from 'app/shared/model/enumerations/service-etablisement-type.model';
import {
  createServiceEtablissementEntity,
  updateServiceEtablissementEntity,
} from 'app/entities/service-etablisement/service-etablisement.reducer';

const { Option } = Select;

const CreateEtablissementServiceModal = ({ show, handleClose, etablissementService }) => {
  const dispatch = useAppDispatch();
  const isNew = etablissementService == null;
  const serviceEtablisementTypeValues = Object.keys(ServiceEtablisementType);

  const [form] = Form.useForm();

  // Initialize form with default values if not new
  React.useEffect(() => {
    if (!isNew) {
      form.setFieldsValue(etablissementService);
    } else {
      form.resetFields();
    }
  }, [etablissementService, isNew, form]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...values,
      global: true,
    };

    if (isNew) {
      dispatch(createServiceEtablissementEntity(entity))
        .then(() => {
          message.success('Service Établissement créé avec succès');
          handleClose();
        })
        .catch(() => message.error('Échec de la création du Service Établissement'));
    } else {
      dispatch(updateServiceEtablissementEntity(entity))
        .then(() => {
          message.success('Service Établissement mis à jour avec succès');
          handleClose();
        })
        .catch(() => message.error('Échec de la mise à jour du Service Établissement'));
    }
  };

  return (
    <Modal
      title={isNew ? 'Créer un nouveau Service Établissement' : 'Modifier le Service Établissement'}
      visible={show}
      onCancel={handleClose}
      footer={null}
    >
      <Form form={form} layout="vertical" onFinish={saveEntity} initialValues={isNew ? { type: 'MENSUELLE' } : etablissementService}>
        {!isNew ? (
          <Form.Item name="id" label="Numéro" rules={[{ required: true }]}>
            <Input readOnly />
          </Form.Item>
        ) : null}
        <Form.Item name="libelle" label="Libelle" rules={[{ required: true, message: 'Veuillez entrer le libellé!' }]}>
          <Input placeholder="Entrez le libellé" />
        </Form.Item>
        <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Veuillez sélectionner le type!' }]}>
          <Select placeholder="Sélectionnez le type">
            {serviceEtablisementTypeValues.map(type => (
              <Option key={type} value={type}>
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="default" onClick={handleClose} style={{ marginRight: '8px' }}>
            Annuler
          </Button>
          <Button type="primary" htmlType="submit">
            <FontAwesomeIcon icon="save" />
            &nbsp; {isNew ? 'Créer' : 'Sauvegarder'}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateEtablissementServiceModal;
