import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Row, Form as AntForm, Select, Input, Spin, Typography, Space } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { createEntity, reset } from './absence-eleve.reducer';
import { IGroupe } from 'app/shared/model/groupe.model';
import { INiveau } from 'app/shared/model/niveau.model';
import { PERIODE_ABSENCE } from 'app/shared/model/enumerations/periode-absence.model';
import { PlusOutlined, ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';

const { Option } = Select;
const { Title } = Typography;

const AbsenceEleveCreate = ({ toggleModal }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const eleves = useAppSelector(state => state.eleve.entities);
  const groupes = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const niveaux = useAppSelector(state => state.niveau.entities) as INiveau[];
  const loading = useAppSelector(state => state.absenceEleve.loading);
  const updating = useAppSelector(state => state.absenceEleve.updating);
  const updateSuccess = useAppSelector(state => state.absenceEleve.updateSuccess);
  const periodeValues = Object.keys(PERIODE_ABSENCE);
  const [selectedEleve, setSelectedEleve] = useState<number | null>(null);
  const [selectedGroupe, setSelectedGroupe] = useState<number | null>(null);
  const [selectedNiveau, setSelectedNiveau] = useState<number | null>(null);

  const handleClose = () => {
    navigate('/absence-eleve');
    toggleModal();
  };

  useEffect(() => {
    let query = '';
    let query2 = '';

    if (selectedGroupe) {
      query += `groupeId.equals=${selectedGroupe}&`;
    }
    dispatch(getEleves({ query }));

    if (selectedNiveau) {
      query2 += `niveauId.equals=${selectedNiveau}&`;
    }
    dispatch(getGroupes({ query: query2 }));
    dispatch(getNiveaux({}));
  }, [selectedGroupe, selectedNiveau]);

  // useEffect(() => {
  //   if (updateSuccess) {
  //     handleClose();
  //   }
  // }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...values,
      eleve: eleves.find(it => it.id.toString() === selectedEleve?.toString()),
    };
    dispatch(createEntity(entity));
    handleClose();
  };

  const defaultValues = () => ({});

  const handleEleveChange = value => {
    setSelectedEleve(value);
  };

  const handleGroupeChange = value => {
    setSelectedGroupe(value);
  };

  const handleNiveauChange = value => {
    setSelectedNiveau(value);
  };

  const eleveOptions = eleves.map(eleve => (
    <Option key={eleve.id} value={eleve.id}>
      {eleve.nom} {eleve.prenom}
    </Option>
  ));

  const groupeOptions = groupes.map(groupe => (
    <Option key={groupe.id} value={groupe.id}>
      {groupe.nom}
    </Option>
  ));
  const niveauOptions = niveaux.map(niveau => (
    <Option key={niveau.id} value={niveau.id}>
      {niveau.libelle}
    </Option>
  ));
  const periodeOptions = periodeValues.map(periode => (
    <Option key={periode} value={periode}>
      {PERIODE_ABSENCE[periode]}
    </Option>
  ));

  return (
    <div>
      <Row justify="center">
        <Col span={16}>
          <Title level={3}>Créer une Absence</Title>
          <AntForm layout="vertical" onFinish={saveEntity} initialValues={defaultValues()}>
            <AntForm.Item label="Niveau">
              <Select
                value={selectedNiveau}
                onChange={handleNiveauChange}
                allowClear
                placeholder="Sélectionner un niveau"
                showSearch
                filterOption={(input, option) => {
                  const text = option?.label || option?.children;
                  return text?.toString().toLowerCase().includes(input.toLowerCase());
                }}
              >
                {niveauOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item label="Groupe">
              <Select
                value={selectedGroupe}
                onChange={handleGroupeChange}
                allowClear
                placeholder="Sélectionner un groupe"
                showSearch
                filterOption={(input, option) => {
                  const text = option?.label || option?.children;
                  return text?.toString().toLowerCase().includes(input.toLowerCase());
                }}
              >
                {groupeOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item label="Eleve" name="eleve" rules={[{ required: true, message: 'Élève est requis' }]}>
              <Select
                value={selectedEleve}
                onChange={handleEleveChange}
                allowClear
                showSearch
                placeholder="Sélectionner un élève"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  const text = option?.label || option?.children;
                  return text?.toString().toLowerCase().includes(input.toLowerCase());
                }}
              >
                {eleveOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item label="Date d'absence" name="date" rules={[{ required: true, message: 'Date est requise' }]}>
              <Input type="date" />
            </AntForm.Item>
            <AntForm.Item label="Période d'absence" name="periode">
              <Select allowClear placeholder="Sélectionner une période" optionFilterProp="children">
                {periodeOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item label="Cause" name="cause">
              <Input type="text" />
            </AntForm.Item>
            <AntForm.Item>
              <Space>
                <Button onClick={handleClose} type="default" icon={<ArrowLeftOutlined />}>
                  Retour
                </Button>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  Sauvegarder
                </Button>
              </Space>
            </AntForm.Item>
          </AntForm>
        </Col>
      </Row>
      {loading && (
        <Row justify="center">
          <Col span={16} style={{ textAlign: 'center', marginTop: '20px' }}>
            <Spin tip="Chargement..." />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default AbsenceEleveCreate;
