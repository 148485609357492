import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './mois-eleve.reducer';

export const MoisEleveDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const moisEleveEntity = useAppSelector(state => state.moisEleve.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="moisEleveDetailsHeading">Mois Eleve</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{moisEleveEntity.id}</dd>
          <dt>
            <span id="mois">Mois</span>
          </dt>
          <dd>{moisEleveEntity.mois}</dd>
          <dt>Eleve</dt>
          <dd>{moisEleveEntity.eleve ? moisEleveEntity.eleve.id : ''}</dd>
          <dt>Annee Scolaire</dt>
          <dd>{moisEleveEntity.anneeScolaire ? moisEleveEntity.anneeScolaire.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/mois-eleve" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/mois-eleve/${moisEleveEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default MoisEleveDetail;
