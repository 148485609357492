import './chart.scss';
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Card, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getBoard } from 'app/modules/chart/board.reducer';
import { IBoard } from 'app/shared/model/board.model';
import { NotificationOutlined } from '@ant-design/icons';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import ProgressBarChart from 'app/modules/chart/ProgressBarChart';
import { getEntities as GetAnneeScolaire } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { hasCurrentAnneeScolaire } from 'app/entities/utilisateur/utilisateur.reducer';
import useCurrentAnneeScolaire from 'app/shared/util/useCurrentAnneeScolaire';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const Tresotory = () => {
  const isLoading = useAppSelector(state => state.board.loading) as boolean;
  const dispatch = useAppDispatch();

  const [totalStudents, setTotalStudents] = useState(0);
  const [paiePerMonthCountData, setPaiePerMonthCountData] = useState([]);
  const board = useAppSelector(state => state.board.entity) as IBoard;

  const { periode } = useCurrentAnneeScolaire();

  useEffect(() => {
    if (board && board.sexeDto && board.sexeDto.values) {
      const total = board.sexeDto.values.reduce((acc, value) => acc + value, 0);
      setTotalStudents(total);
    }
    if (board?.elevePaieParMoisDto) {
      const data = board?.elevePaieParMoisDto?.labels?.map((label, index) => ({
        label: label,
        value: board?.elevePaieParMoisDto?.values[index],
      }));
      setPaiePerMonthCountData(data);
    }
  }, [board]);

  return (
    <>
      <Spin spinning={isLoading} tip="Chargement...">
        <Card title={`Nombre des élèves qui paient les frais de scolarité pour ${periode}`} bordered={true}>
          <div className="card-style--2">
            {paiePerMonthCountData.map((paiePerMonth, index) => (
              <ProgressBarChart
                key={index}
                niveauTitle={paiePerMonth.label}
                elevesInfo={`${paiePerMonth.value} élèves (${((paiePerMonth.value / totalStudents) * 100).toFixed(2)}%)`}
                progressValue={parseFloat(((paiePerMonth.value / totalStudents) * 100).toFixed(2))}
              />
            ))}
          </div>
        </Card>
      </Spin>
    </>
  );
};

export default Tresotory;
