import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Controle from './controle';
import ControleDetail from './controle-detail';
import ControleUpdate from './controle-update';
import ControleDeleteDialog from './controle-delete-dialog';

const ControleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Controle />} />
    <Route path="new" element={<ControleUpdate />} />
    <Route path=":id">
      <Route index element={<ControleDetail />} />
      <Route path="edit" element={<ControleUpdate />} />
      <Route path="delete" element={<ControleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ControleRoutes;
