import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getAnneeScolaires } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { createEntity, getEntity, reset, updateEntity } from './niveau.reducer';

export const NiveauUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const anneeScolaires = useAppSelector(state => state.anneeScolaire.entities);
  const niveauEntity = useAppSelector(state => state.niveau.entity);
  const loading = useAppSelector(state => state.niveau.loading);
  const updating = useAppSelector(state => state.niveau.updating);
  const updateSuccess = useAppSelector(state => state.niveau.updateSuccess);

  const handleClose = () => {
    navigate('/niveau' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getAnneeScolaires({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...niveauEntity,
      ...values,
      anneeScolaire: anneeScolaires.find(it => it.id.toString() === values.anneeScolaire?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...niveauEntity,
          anneeScolaire: niveauEntity?.anneeScolaire?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.niveau.home.createOrEditLabel" data-cy="NiveauCreateUpdateHeading">
            Créer ou éditer un Niveau
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="niveau-id" label="Numéro" validate={{ required: true }} /> : null}
              <ValidatedField label="Libelle" id="niveau-libelle" name="libelle" data-cy="libelle" type="text" />
              <ValidatedField label="Option" id="niveau-option" name="option" data-cy="option" type="text" />
              <ValidatedField label="Id Massar" id="niveau-idMassar" name="idMassar" data-cy="idMassar" type="text" />
              <ValidatedField id="niveau-anneeScolaire" name="anneeScolaire" data-cy="anneeScolaire" label="Annee Scolaire" type="select">
                <option value="" key="0" />
                {anneeScolaires
                  ? anneeScolaires.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/niveau" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default NiveauUpdate;
