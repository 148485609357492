import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Button, Card, Select, Typography } from 'antd';
import { IRecu } from 'app/shared/model/recu.model';
import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { generatePdfRecu, printPdfRecu, showPdfRecuInNewTab } from 'app/entities/recu/recu.reducer';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const ChooseRecuTypeModal = ({ toggleModal }) => {
  const dispatch = useAppDispatch();
  const recuSelected: IRecu = useAppSelector(state => state.recu.recuSelected);
  return (
    <div style={{ padding: '20px' }}>
      {/* Group 1: Template 1 */}
      <Card title="Reçu Normal" style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            icon={recuSelected?.htmlContent ? <PrintIcon /> : <PrintDisabledIcon />}
            style={{ color: recuSelected?.htmlContent ? '#FFA500' : '' }}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContent) return;
              dispatch(printPdfRecu({ recu: recuSelected }));
            }}
            disabled={!recuSelected?.htmlContent}
          >
            Print
          </Button>
          <Button
            icon={recuSelected?.htmlContent ? <VisibilityIcon /> : <VisibilityOffIcon />}
            style={{ color: recuSelected?.htmlContent ? '#1890ff' : '' }}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContent) return;
              dispatch(showPdfRecuInNewTab({ recu: recuSelected }));
            }}
            disabled={!recuSelected?.htmlContent}
          >
            Open
          </Button>
          <Button
            icon={recuSelected?.htmlContent ? <FileDownloadIcon /> : <FileDownloadOffIcon />}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContent) return;
              dispatch(generatePdfRecu({ recu: recuSelected }));
            }}
            disabled={!recuSelected?.htmlContent}
          >
            Download
          </Button>
        </div>
      </Card>

      {/* Group 2: Template 2 */}
      <Card title="Reçu Alternative" style={{ marginBottom: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            icon={recuSelected?.htmlContentTwo ? <PrintIcon /> : <PrintDisabledIcon />}
            style={{ color: recuSelected?.htmlContentTwo ? '#FFA500' : '' }}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContentTwo) return;
              dispatch(printPdfRecu({ recu: recuSelected, contentType: 'contentTwo' }));
            }}
            disabled={!recuSelected?.htmlContentTwo}
          >
            Print
          </Button>
          <Button
            icon={recuSelected?.htmlContentTwo ? <VisibilityIcon /> : <VisibilityOffIcon />}
            style={{ color: recuSelected?.htmlContentTwo ? '#1890ff' : '' }}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContentTwo) return;
              dispatch(showPdfRecuInNewTab({ recu: recuSelected, contentType: 'contentTwo' }));
            }}
            disabled={!recuSelected?.htmlContentTwo}
          >
            Open
          </Button>
          <Button
            icon={recuSelected?.htmlContentTwo ? <FileDownloadIcon /> : <FileDownloadOffIcon />}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContentTwo) return;
              dispatch(generatePdfRecu({ recu: recuSelected, contentType: 'contentTwo' }));
            }}
            disabled={!recuSelected?.htmlContentTwo}
          >
            Download
          </Button>
        </div>
      </Card>

      {/* Group 3: Template 3 */}
      <Card title="Reçu Élégant">
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            icon={recuSelected?.htmlContentThree ? <PrintIcon /> : <PrintDisabledIcon />}
            style={{ color: recuSelected?.htmlContentThree ? '#FFA500' : '' }}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContentThree) return;
              dispatch(printPdfRecu({ recu: recuSelected, contentType: 'contentThree' }));
            }}
            disabled={!recuSelected?.htmlContentThree}
          >
            Print
          </Button>
          <Button
            icon={recuSelected?.htmlContentThree ? <VisibilityIcon /> : <VisibilityOffIcon />}
            style={{ color: recuSelected?.htmlContentThree ? '#1890ff' : '' }}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContentThree) return;
              dispatch(showPdfRecuInNewTab({ recu: recuSelected, contentType: 'contentThree' }));
            }}
            disabled={!recuSelected?.htmlContentThree}
          >
            Open
          </Button>
          <Button
            icon={recuSelected?.htmlContentThree ? <FileDownloadIcon /> : <FileDownloadOffIcon />}
            onClick={e => {
              e.stopPropagation();
              if (!recuSelected?.htmlContentThree) return;
              dispatch(generatePdfRecu({ recu: recuSelected, contentType: 'contentThree' }));
            }}
            disabled={!recuSelected?.htmlContentThree}
          >
            Download
          </Button>
        </div>
      </Card>
    </div>
  );
};
export default ChooseRecuTypeModal;
