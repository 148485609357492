import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Input, Tag } from 'antd';
import { getPaginationState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { getEntities } from './tuteur.reducer';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { isNotEmpty } from 'app/shared/util/utilitaire';

const { Search } = Input;

export const Tuteur = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const tuteurList = useAppSelector(state => state.tuteur.entities) as ITuteur[];
  const loading = useAppSelector(state => state.tuteur.loading);
  const totalItems = useAppSelector(state => state.tuteur.totalItems);

  const getAllEntities = (search: string) => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: isNotEmpty(search) ? 'search.equals=' + search : null,
      }),
    );
  };

  const onSearch = (value, _e, info) => {
    if (isNotEmpty(value)) {
      getAllEntities(value);
    } else {
      sortEntities();
    }
  };

  const sortEntities = () => {
    getAllEntities('');
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="tuteur-heading" data-cy="TuteurHeading">
        <Tag bordered={false} className={'capitalize'} style={{ padding: 15 }} color="processing">
          Tuteurs
        </Tag>
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button id="actualiser-button" className="me-2" type="primary" onClick={handleSyncList} loading={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          <Link to={'/tuteur/new'}>
            <Button type="primary">
              <FontAwesomeIcon icon="plus" />
              &nbsp; Créer un nouveau Tuteur
            </Button>
          </Link>
        </div>
      </h2>
      <div className="container mt-5">
        <Search placeholder="Nom Prénom Tél" allowClear enterButton="chercher" onSearch={onSearch} style={{ width: 304 }} />
      </div>
      <div className="table-responsive">
        {tuteurList && tuteurList.length > 0 ? (
          <Row style={{ margin: 'auto', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            {tuteurList.map((tuteur, i) => (
              <Col className="mb-3 mt-3" xs={12} sm={8} md={6} lg={3} key={i}>
                <Card
                  key={i}
                  actions={[
                    <span
                      onClick={() => {
                        navigate(`/tuteur/${tuteur.id}`);
                      }}
                    >
                      <EyeOutlined style={{ color: '#FF4D4F' }} key="eye" />
                    </span>,
                    <span
                      onClick={() => {
                        navigate(
                          `/tuteur/${tuteur.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
                        );
                      }}
                    >
                      <EditOutlined style={{ color: '#1890FF' }} key="eye" />
                    </span>,
                    <span
                      onClick={() => {
                        navigate(
                          `/tuteur/${tuteur.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
                        );
                      }}
                    >
                      <DeleteOutlined style={{ color: '#1DA57A' }} key="eye" />
                    </span>,
                  ]}
                >
                  <Row>
                    <Col>
                      <h6 style={{ fontWeight: 'bold' }}> {tuteur.firstName} </h6>

                      <Tag bordered={false} className={'small-title'} color="orange">
                        {tuteur.tel}
                      </Tag>

                      <Tag bordered={true} className={'small-title'} color="orange">
                        {tuteur.email}
                      </Tag>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          !loading && <div className="alert alert-warning mt-4">Aucun tuteur Dans l'annee scolaire courante</div>
        )}
      </div>

      {totalItems ? (
        <div className={tuteurList && tuteurList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Tuteur;
