import dayjs from 'dayjs';
import { IEleve } from 'app/shared/model/eleve.model';
import { PERIODE_ABSENCE } from 'app/shared/model/enumerations/periode-absence.model';
import { IProf } from 'app/shared/model/prof.model';

export interface IAbsenceEleve {
  id?: number;
  date?: dayjs.Dayjs | null;
  periode?: keyof typeof PERIODE_ABSENCE | null;
  cause?: string | null;
  eleve?: IEleve | null;
  prof?: IProf | null;
}

export const defaultValue: Readonly<IAbsenceEleve> = {};
