import eleve from 'app/entities/eleve/eleve.reducer';
import utilisateur from 'app/entities/utilisateur/utilisateur.reducer';
import etatServiceMois from 'app/entities/etat-service-mois/etat-service-mois.reducer';
import moisEleve from 'app/entities/mois-eleve/mois-eleve.reducer';
import absenceEleve from 'app/entities/absence-eleve/absence-eleve.reducer';
import devoir from 'app/entities/devoir/devoir.reducer';
import salle from 'app/entities/salle/salle.reducer';
import etablissement from 'app/entities/etablissement/etablissement.reducer';
import importEleve from 'app/entities/import-eleve/import-eleve.reducer';
import tarif from 'app/entities/tarif/tarif.reducer';
import emploiTemps from 'app/entities/emploi-temps/emploi-temps.reducer';
import emploiTempsLigne from 'app/entities/emploi-temps-ligne/emploi-temps-ligne.reducer';
import controle from 'app/entities/controle/controle.reducer';
import serviceEtablisement from 'app/entities/service-etablisement/service-etablisement.reducer';
import anneeScolaire from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import groupe from 'app/entities/groupe/groupe.reducer';
import matiere from 'app/entities/matiere/matiere.reducer';
import niveau from 'app/entities/niveau/niveau.reducer';
import caisse from 'app/entities/caisse/caisse.reducer';
import message from 'app/entities/message/message.reducer';
import annonce from 'app/entities/annonce/annonce.reducer';
import board from 'app/modules/chart/board.reducer';
import tuteur from 'app/entities/tuteur/tuteur.reducer';
import prof from 'app/entities/prof/prof.reducer';
import recu from 'app/entities/recu/recu.reducer';
import galerie from 'app/entities/galerie/galerie.reducer';
import ressource from 'app/entities/ressource/ressource.reducer';
import fichePaiement from 'app/modules/tresorerie/fiche-paiement.reducer';
import reinscription from 'app/shared/reducers/reinscription.reducer';
import quiz from 'app/entities/quiz/quiz.reducer';
import question from 'app/entities/quiz/question.reducer';
import garderie from 'app/entities/garderie/garderie.reducer';
import devoirSurv from 'app/entities/gestion-notes/devoirSurv.reducer';
import auditLog from 'app/entities/audit-log/audit-log.reducer';
import transmission from 'app/entities/cahier-transmission/transmission.reducer';
import pageMobile from 'app/entities/page-mobile/page-mobile.reducer';
// import userManagement from 'app/entities/us';

const entitiesReducers = {
  eleve,
  fichePaiement,
  utilisateur,
  etatServiceMois,
  moisEleve,
  absenceEleve,
  devoir,
  salle,
  etablissement,
  importEleve,
  tarif,
  emploiTemps,
  emploiTempsLigne,
  controle,
  serviceEtablisement,
  anneeScolaire,
  groupe,
  matiere,
  niveau,
  caisse,
  message,
  annonce,
  board,
  tuteur,
  prof,
  recu,
  quiz,
  question,
  galerie,
  ressource,
  reinscription,
  garderie,
  devoirSurv,
  auditLog,
  transmission,
  pageMobile,
};

export default entitiesReducers;
