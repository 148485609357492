import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Space, Table } from 'antd';
import { getPaginationState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faSort, faSortDown, faSortUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './mois-eleve.reducer';

export const MoisEleve = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const moisEleveList = useAppSelector(state => state.moisEleve.entities);
  const loading = useAppSelector(state => state.moisEleve.loading);
  const totalItems = useAppSelector(state => state.moisEleve.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = fieldName => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      key: 'id',
      sorter: true,
      render: id => <Link to={`/mois-eleve/${id}`}>{id}</Link>,
    },
    {
      title: 'Mois',
      dataIndex: 'mois',
      key: 'mois',
      sorter: true,
    },
    {
      title: 'Eleve',
      dataIndex: ['eleve', 'id'],
      key: 'eleve',
      render: eleveId => (eleveId ? <Link to={`/eleve/${eleveId}`}>{eleveId}</Link> : ''),
    },
    {
      title: 'Annee Scolaire',
      dataIndex: ['anneeScolaire', 'id'],
      key: 'anneeScolaire',
      render: anneeScolaireId => (anneeScolaireId ? <Link to={`/annee-scolaire/${anneeScolaireId}`}>{anneeScolaireId}</Link> : ''),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" icon={<FontAwesomeIcon icon={faEye} />} onClick={() => (window.location.href = `/mois-eleve/${record.id}`)} />
          <Button
            type="link"
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
            onClick={() =>
              (window.location.href = `/mois-eleve/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
          />
          <Button
            type="link"
            danger
            onClick={() =>
              (window.location.href = `/mois-eleve/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="mois-eleve-heading" data-cy="MoisEleveHeading">
        Mois Eleves
        <div className="d-flex justify-content-end " style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} loading={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} /> Actualiser la liste
          </Button>
          <Button type="primary" onClick={() => navigate('/mois-eleve/new')}>
            <FontAwesomeIcon icon="plus" />
            &nbsp; Créer un nouveau Mois Eleve
          </Button>
        </div>
      </h2>
      <Table
        columns={columns}
        dataSource={moisEleveList}
        loading={loading}
        pagination={{
          current: paginationState.activePage,
          total: totalItems,
          pageSize: paginationState.itemsPerPage,
          onChange: handlePagination,
        }}
        rowKey="id"
      />
      {totalItems ? (
        <div className="justify-content-center d-flex">
          <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default MoisEleve;
