import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';

export interface IAnnonce {
  id?: number;
  contenu?: string | null;
  lien?: string | null;
  imageContentType?: string | null;
  image?: string | null;
  dateEnvoi?: dayjs.Dayjs | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IAnnonce> = {};
