import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  path: string;
};
const BackButton = ({ path }: Props) => {
  return (
    <Link to={path} style={{ textDecoration: 'none' }}>
      <Button
        type="primary"
        icon={<FontAwesomeIcon icon="arrow-left" />}
        style={{
          backgroundColor: '#1890ff',
          color: '#fff',
          padding: '0 20px',
          borderRadius: '5px',
          marginTop: '10px',

          marginBottom: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
        onMouseOver={e => {
          e.currentTarget.style.backgroundColor = '#40a9ff';
        }}
        onMouseOut={e => {
          e.currentTarget.style.backgroundColor = '#1890ff';
        }}
      >
        <span style={{ textDecoration: 'none' }}>Retour</span>
      </Button>
    </Link>
  );
};
export default BackButton;
