import './parametrage.scss';

import React, { useEffect, useState } from 'react';

import { Row } from 'reactstrap';

import { useAppSelector } from 'app/config/store';
import { Tabs, TabsProps } from 'antd';
import EtablissementUpdate from 'app/entities/etablissement/etablissement-update';
import AnneeScolaire from 'app/entities/annee-scolaire/annee-scolaire';
import ServiceEtablisement from 'app/entities/service-etablisement/service-etablisement';
import Niveau from 'app/entities/niveau/niveau';
import ImportEleveUpdate from 'app/entities/import-eleve/import-eleve-update';
import PageMobile from 'app/entities/page-mobile/pagemobile';

export const Parametrage = () => {
  const account = useAppSelector(state => state.authentication.account);
  const eleveList = useAppSelector(state => state.eleve.entities);
  const loading = useAppSelector(state => state.eleve.loading);

  const [eleve, setEleve] = useState([]);
  useEffect(() => {}, [loading]);

  const onChange = (key: string) => {};

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: "Fiche de l'établisement",
      children: (
        <div>
          <EtablissementUpdate />
        </div>
      ),
    },
    {
      key: '2',
      label: 'Période scolaires',
      children: (
        <div>
          <AnneeScolaire />
        </div>
      ),
    },
    {
      key: '3',
      label: "Services d'etablisement",
      children: (
        <div>
          <ServiceEtablisement />
        </div>
      ),
    },
    {
      key: '6',
      label: 'Niveaux soclaire',
      children: (
        <div>
          <Niveau />
        </div>
      ),
    },
    {
      key: '7',
      label: 'Importation des données',
      children: (
        <div>
          <ImportEleveUpdate />
        </div>
      ),
    },
    {
      key: '8',
      label: 'Pages mobiles',
      children: (
        <div>
          <PageMobile />
        </div>
      ),
    },
  ];

  return (
    <Row>
      <Tabs tabPosition={'top'} defaultActiveKey="1" items={items} onChange={onChange} />
    </Row>
  );
};

export default Parametrage;
