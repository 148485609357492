import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import AbsenceEleve from './absence-eleve';
import AbsenceEleveDetail from './absence-eleve-detail';
import AbsenceEleveUpdate from './absence-eleve-update';
import AbsenceEleveDeleteDialog from './absence-eleve-delete-dialog';
import AbsenceEleveCreate from 'app/entities/absence-eleve/absence-eleve-create';
import AbsenceEleveValidation from 'app/entities/absence-eleve/absence-eleve-validation';

const AbsenceEleveRoutes = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <ErrorBoundaryRoutes>
      <Route index element={<AbsenceEleve />} />
      <Route path="new" element={<AbsenceEleveCreate toggleModal={toggleModal} />} />
      <Route path="validate" element={<AbsenceEleveValidation />} />
      <Route path=":id">
        <Route index element={<AbsenceEleveDetail />} />
        <Route path="edit" element={<AbsenceEleveUpdate />} />
        <Route path="delete" element={<AbsenceEleveDeleteDialog />} />
      </Route>
    </ErrorBoundaryRoutes>
  );
};

export default AbsenceEleveRoutes;
