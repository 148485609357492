import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './tuteur.reducer';
import './tuteur-detail.css';

export const TuteurDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const tuteurEntity = useAppSelector(state => state.tuteur.entity);
  return (
    <Row className="justify-content-center">
      <Col md="8">
        <div className="entity-details">
          <h2 className="entity-heading" data-cy="tuteurDetailsHeading">
            Tuteur
          </h2>
          <dl className="entity-details-list">
            <div className="entity-details-item">
              <dt>ID :</dt>
              <dd className="entity-details-value">{tuteurEntity.id}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Tel :</dt>
              <dd className="entity-details-value">{tuteurEntity.tel}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Email :</dt>
              <dd className="entity-details-value">{tuteurEntity.email}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Observation :</dt>
              <dd className="entity-details-value">{tuteurEntity.observation}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Cin :</dt>
              <dd className="entity-details-value">{tuteurEntity.cin}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Adresse :</dt>
              <dd className="entity-details-value">{tuteurEntity.adresse}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Internal User :</dt>
              <dd className="entity-details-value">{tuteurEntity.internalUser ? tuteurEntity.internalUser.login : ''}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Enfant :</dt>
              <dd className="entity-details-value">
                <div className="enfants-list">
                  {tuteurEntity.enfants
                    ? tuteurEntity.enfants.map((val, i) => (
                        <span key={val.id} className="enfant-item">
                          {val.nom} {val.prenom}
                        </span>
                      ))
                    : null}
                </div>
              </dd>
            </div>
          </dl>
          <div className="entity-actions">
            <Button tag={Link} to="/tuteur" replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/tuteur/${tuteurEntity.id}/edit`} replace color="primary">
              <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default TuteurDetail;
