import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Form, Input, DatePicker, Select, Typography, Checkbox } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getTuteurs } from 'app/entities/tuteur/tuteur.reducer';
import { getEntities as getCaisses } from 'app/entities/caisse/caisse.reducer';
import { getEntities as getTarifs } from 'app/entities/tarif/tarif.reducer';
import { ModeReglement } from 'app/shared/model/enumerations/mode-reglement.model';
import { createEntity, getEntity, reset, updateEntity } from './recu.reducer';

const { Title } = Typography;
const { Option } = Select;

export const RecuUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const tuteurs = useAppSelector(state => state.tuteur.entities);
  const caisses = useAppSelector(state => state.caisse.entities);
  const tarifs = useAppSelector(state => state.tarif.entities);
  const recuEntity = useAppSelector(state => state.recu.entity);
  const loading = useAppSelector(state => state.recu.loading);
  const updating = useAppSelector(state => state.recu.updating);
  const updateSuccess = useAppSelector(state => state.recu.updateSuccess);
  const modeReglementValues = Object.keys(ModeReglement);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getTuteurs({}));
    dispatch(getCaisses({}));
    dispatch(getTarifs({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      navigate('/recu' + location.search);
    }
  }, [updateSuccess]);

  const handleSubmit = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.montant !== undefined && typeof values.montant !== 'number') {
      values.montant = Number(values.montant);
    }
    if (values.reste !== undefined && typeof values.reste !== 'number') {
      values.reste = Number(values.reste);
    }

    const entity = {
      ...recuEntity,
      ...values,
      tuteur: tuteurs.find(it => it.id.toString() === values.tuteur?.toString()),
      caisse: caisses.find(it => it.id.toString() === values.caisse?.toString()),
      servicesTarifs: values.servicesTarifs ? values.servicesTarifs.map(id => ({ id })) : [],
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => ({
    modePaiement: 'CHEQUE',
    ...recuEntity,
    tuteur: recuEntity?.tuteur?.id,
    caisse: recuEntity?.caisse?.id,
    servicesTarifs: recuEntity?.servicesTarifs?.map(e => e.id.toString()) || [],
  });

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Row className="justify-content-center">
        <Col span={16}>
          <Title level={2} style={{ textAlign: 'center' }}>
            {isNew ? 'Créer' : 'Éditer'} un Recu
          </Title>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col span={16}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form layout="vertical" initialValues={defaultValues()} onFinish={handleSubmit}>
              {!isNew ? (
                <Form.Item name="id" label="Numéro" rules={[{ required: true, message: 'Numéro est requis' }]}>
                  <Input readOnly />
                </Form.Item>
              ) : null}
              <Form.Item name="dateCreation" label="Date Creation" rules={[{ required: true, message: 'Date Creation est requis' }]}>
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="montant" label="Montant" rules={[{ required: true, message: 'Montant est requis' }]}>
                <Input type="number" />
              </Form.Item>
              <Form.Item name="reste" label="Reste" rules={[{ required: true, message: 'Reste est requis' }]}>
                <Input type="number" />
              </Form.Item>
              <Form.Item name="observation" label="Observation">
                <Input.TextArea />
              </Form.Item>
              <Form.Item name="modePaiement" label="Mode Paiement" rules={[{ required: true, message: 'Mode Paiement est requis' }]}>
                <Select placeholder="Sélectionnez le mode de paiement">
                  {modeReglementValues.map(modeReglement => (
                    <Option value={modeReglement} key={modeReglement}>
                      {modeReglement}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="dateModification" label="Date Modification">
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
              <Form.Item name="tuteur" label="Tuteur" rules={[{ required: true, message: 'Tuteur est requis' }]}>
                <Select placeholder="Sélectionnez un tuteur">
                  {tuteurs.map(tuteur => (
                    <Option key={tuteur.id} value={tuteur.id}>
                      {tuteur.firstName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="caisse" label="Caisse" rules={[{ required: true, message: 'Caisse est requis' }]}>
                <Select placeholder="Sélectionnez une caisse">
                  {caisses.map(caisse => (
                    <Option key={caisse.id} value={caisse.id}>
                      {caisse.id}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="servicesTarifs" label="Services Tarifs">
                <Select
                  mode="multiple"
                  placeholder="Sélectionnez les tarifs"
                  options={tarifs.map(tarif => ({ value: tarif.id, label: tarif.prix }))}
                />
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button type="default" onClick={() => navigate('/recu')} style={{ marginRight: '8px' }}>
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp; Retour
                </Button>
                <Button type="primary" htmlType="submit" loading={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RecuUpdate;
