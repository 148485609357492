import React, { useEffect, useState } from 'react';
import { Option } from 'antd/es/mentions';
import { Alert, Badge, Button, Pagination, Select, Space, Table } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { generatePdfRecu, getPayeeImpayees } from 'app/entities/recu/recu.reducer';
import { Mois } from 'app/shared/model/enumerations/mois.model';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getPaginationState } from 'react-jhipster';
import { ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileDownloadOffIcon from '@mui/icons-material/FileDownloadOff';
import { GoLoading } from 'app/shared/loading';
import { IPayeeImpayee } from 'app/shared/model/paiementModel/payee-Impayee.model';
import { StatusPaiement } from 'app/shared/model/enumerations/Status-paiement.model';

export const RubriquesMensuelles = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const niveaux = useAppSelector(state => state.niveau.entities);
  const payeeImpayeeData = useAppSelector(state => state.recu.payeeImpayeeData) as IPayeeImpayee[];
  const [filtredPayeeImpayeeData, setFiltredPayeeImpayeeData] = useState<IPayeeImpayee[]>([]);
  const loadPayeeImpayee = useAppSelector(state => state.recu.loadPayeeImpayee);
  const totalItemsPayeeImpayee = useAppSelector(state => state.recu.totalItemsPayeeImpayee);
  const [selectedNiveau, setSelectedNiveau] = useState('Tout');
  const [selectedMonth, setSelectedMonth] = useState(Mois.SEP);
  const [selectedStatusPaiement, setStatusPaiement] = useState('Tout');
  const [selectedNiveauId, setSelectedNiveauId] = useState(null);
  const mois = Object.values(Mois);
  const statusPaiement = Object.values(StatusPaiement);
  useEffect(() => {
    getAllEntities();
  }, [selectedMonth]);

  const getAllEntities = () => {
    dispatch(getNiveaux({}));
    dispatch(
      getPayeeImpayees({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        mois: selectedMonth,
        niveauId: selectedNiveauId ? selectedNiveauId : null,
        statusFilter: selectedStatusPaiement ? selectedStatusPaiement : null,
      }),
    );
  };

  useEffect(() => {
    setFiltredPayeeImpayeeData(payeeImpayeeData);
  }, [payeeImpayeeData]);

  useEffect(() => {
    setPaginationState(prevState => ({
      ...prevState,
      activePage: 1,
    }));
    if (paginationState.activePage == 1) {
      getAllEntities();
    }
  }, [selectedNiveauId, selectedStatusPaiement]);

  const handleSelectNiveau = eventKey => {
    if (eventKey === 'Tout') {
      setSelectedNiveauId(null);
      return;
    }
    const selectedNiveau = JSON.parse(eventKey);
    setSelectedNiveau(selectedNiveau.libelle);
    setSelectedNiveauId(selectedNiveau.id);
  };

  const handleSelectMonth = month => {
    setSelectedMonth(month);
  };

  const handleSelectStatusPaiement = status => {
    setStatusPaiement(status);
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const columns = [
    {
      title: 'Eleve',
      dataIndex: 'eleve',
      render: (text, record) =>
        record.eleve && (
          <Link to={`/eleve/${record.eleve.id}`}>
            {record.eleve.prenom} {record.eleve.nom}
          </Link>
        ),
    },
    {
      title: 'Tuteur',
      dataIndex: ['tuteur', 'id'],
      render: (text, record) =>
        record.tuteur ? <Link to={`/tuteur/${record.tuteur.id}`}>{record.tuteur.internalUser.firstName}</Link> : '',
    },
    {
      title: 'Montant',
      dataIndex: 'montantAPayer',
    },
    {
      title: 'Montant Payé',
      dataIndex: 'montant',
    },
    {
      title: 'Reste',
      dataIndex: 'rest',
      render: (text, record) =>
        record.rest === 0 ? (
          <Badge.Ribbon text={StatusPaiement.PAYE} color="green">
            {record.rest}
          </Badge.Ribbon>
        ) : record.montant === 0 ? (
          <Badge.Ribbon text={StatusPaiement.IMPAYE} color="red">
            {record.rest}
          </Badge.Ribbon>
        ) : (
          <Badge.Ribbon text={StatusPaiement.AVANCE} color="orange">
            {record.rest}
          </Badge.Ribbon>
        ),
    },
  ];

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);
  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  return (
    <div>
      <div className="flex-row-center space-x padding-top-left">
        <div className="flex-row-center">
          <span>Mois :</span>
          <Select defaultValue={selectedMonth} onChange={handleSelectMonth} style={{ width: 200, color: 'grey', marginLeft: '8px' }}>
            {mois.map(month => {
              return (
                <Option key={month} value={month}>
                  {month}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="flex-row-center">
          <span>Niveaux :</span>
          <Select
            style={{ width: 200, color: 'grey', marginLeft: '8px' }}
            placeholder={`${selectedNiveau ? selectedNiveau : 'Niveaux'}`}
            onChange={handleSelectNiveau}
          >
            <Option key="Tout" value="Tout">
              Tout
            </Option>
            {niveaux.map(niveau => {
              return (
                <Option key={niveau.id} value={JSON.stringify({ libelle: niveau.libelle, id: niveau.id })}>
                  {niveau.libelle}
                </Option>
              );
            })}
          </Select>
        </div>
        <div className="flex-row-center">
          <Select
            style={{ width: 200, color: 'grey', marginLeft: '8px' }}
            placeholder={`Status Paiement`}
            onChange={handleSelectStatusPaiement}
          >
            <Option key="Tout" value="tout">
              Tout
            </Option>
            {statusPaiement.map(status => {
              return <Option key={status}>{status}</Option>;
            })}
          </Select>
        </div>
      </div>
      <div className="table-responsive mt-4">
        {loadPayeeImpayee ? (
          <GoLoading loading={loadPayeeImpayee} />
        ) : filtredPayeeImpayeeData && filtredPayeeImpayeeData.length > 0 ? (
          <Table columns={columns} dataSource={filtredPayeeImpayeeData} rowKey="id" loading={loadPayeeImpayee} pagination={false} />
        ) : (
          !loadPayeeImpayee && <Alert message="Aucun Paiement Trouvé" type="warning" showIcon />
        )}
      </div>
      {!loadPayeeImpayee && totalItemsPayeeImpayee && filtredPayeeImpayeeData && filtredPayeeImpayeeData.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePagination}
            total={totalItemsPayeeImpayee}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default RubriquesMensuelles;
