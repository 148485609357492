import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedBlobField, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { Sexe } from 'app/shared/model/enumerations/sexe.model';
import { Role } from 'app/shared/model/enumerations/role.model';
import { createEntity, getEntity, reset, updateEntity } from './utilisateur.reducer';

export const UtilisateurUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const utilisateurEntity = useAppSelector(state => state.utilisateur.entity);
  const loading = useAppSelector(state => state.utilisateur.loading);
  const updating = useAppSelector(state => state.utilisateur.updating);
  const updateSuccess = useAppSelector(state => state.utilisateur.updateSuccess);
  const sexeValues = Object.keys(Sexe);
  const roleValues = Object.keys(Role);

  const handleClose = () => {
    navigate('/utilisateur' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...utilisateurEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          sexe: 'MALE',
          role: 'ELEVE',
          ...utilisateurEntity,
          user: utilisateurEntity?.user?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.utilisateur.home.createOrEditLabel" data-cy="UtilisateurCreateUpdateHeading">
            Créer ou éditer un Utilisateur
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="utilisateur-id" label="Numéro" validate={{ required: true }} />
              ) : null}
              <ValidatedField label="Nom" id="utilisateur-nom" name="nom" data-cy="nom" type="text" />
              <ValidatedField label="Prenom" id="utilisateur-prenom" name="prenom" data-cy="prenom" type="text" />
              <ValidatedField
                label="Date Naissance"
                id="utilisateur-dateNaissance"
                name="dateNaissance"
                data-cy="dateNaissance"
                type="date"
              />
              <ValidatedField
                label="Lieu Naissance"
                id="utilisateur-lieuNaissance"
                name="lieuNaissance"
                data-cy="lieuNaissance"
                type="text"
              />
              <ValidatedField label="Sexe" id="utilisateur-sexe" name="sexe" data-cy="sexe" type="select">
                {sexeValues.map(sexe => (
                  <option value={sexe} key={sexe}>
                    {sexe}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedBlobField label="Photo" id="utilisateur-photo" name="photo" data-cy="photo" isImage accept="image/*" />
              <ValidatedField label="Cin" id="utilisateur-cin" name="cin" data-cy="cin" type="text" />
              <ValidatedField label="Adresse" id="utilisateur-adresse" name="adresse" data-cy="adresse" type="text" />
              <ValidatedField label="Rib" id="utilisateur-rib" name="rib" data-cy="rib" type="text" />
              <ValidatedField label="Role" id="utilisateur-role" name="role" data-cy="role" type="select">
                {roleValues.map(role => (
                  <option value={role} key={role}>
                    {role}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField id="utilisateur-user" name="user" data-cy="user" label="User" type="select">
                <option value="" key="0" />
                {users
                  ? users.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/utilisateur" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UtilisateurUpdate;
