import React from 'react';
import { Form, Input, Select, Button, Card, Checkbox } from 'antd';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import { IQuiz } from 'app/shared/model/quiz.model';
import { createEntity } from 'app/entities/quiz/quiz.reducer';

const { Option } = Select;

const CreateQuizModal = ({ q }) => {
  const [form] = Form.useForm();
  const quizEntity = useAppSelector(state => state.quiz.entity) as IQuiz;
  const dispatch = useAppDispatch();

  const handleSubmit = values => {
    // Here you can add the logic to update the quiz
    const entity = {
      ...quizEntity,
      ...values,
      category: values.category,
      name: values.name,
      niveau: values.niveau,
      questions: values.questions,
    };
    dispatch(createEntity(entity));
  };

  const handleAddQuestion = () => {
    const questions = form.getFieldValue('questions') || [];
    form.setFieldsValue({ questions: [...questions, { text: '', choices: [{ text: '', correct: false }] }] });
  };

  const handleAddChoice = index => {
    const questions = form.getFieldValue('questions');
    questions[index].choices.push({ text: '', correct: false });
    form.setFieldsValue({ questions });
  };

  const handleCorrectChange = (qIndex, cIndex) => {
    const questions = form.getFieldValue('questions');
    questions[qIndex].choices = questions[qIndex].choices.map((choice, index) => ({
      ...choice,
      correct: index === cIndex,
    }));
    form.setFieldsValue({ questions });
  };

  return (
    <div style={{ maxHeight: '75vh', overflowY: 'auto' }}>
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          name: q?.name,
          category: q?.category,
          niveau: q?.niveau,
          questions: q?.questions || [],
        }}
        onFinish={handleSubmit}
      >
        <Form.Item name="name" label="Nom du Quiz" rules={[{ required: true, message: 'Veuillez entrer le nom du quiz' }]}>
          <Input />
        </Form.Item>
        <Form.Item name="category" label="Catégorie" rules={[{ required: true, message: 'Veuillez sélectionner une catégorie' }]}>
          <Select>
            <Option value="Mathématiques">Mathématiques</Option>
            <Option value="Géographie">Géographie</Option>
            <Option value="Science">Science</Option>
          </Select>
        </Form.Item>
        <Form.Item name="niveau" label="Niveau" rules={[{ required: true, message: 'Veuillez sélectionner un niveau' }]}>
          <Select>
            <Option value="Facile">Facile</Option>
            <Option value="Moyen">Moyen</Option>
            <Option value="Difficile">Difficile</Option>
          </Select>
        </Form.Item>

        <Form.List name="questions">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <Card key={field.key} style={{ marginBottom: '20px' }}>
                  <Form.Item {...field} name={[field.name, 'text']} label={`Question ${index + 1}`}>
                    <Input />
                  </Form.Item>
                  <Form.List name={[field.name, 'choices']}>
                    {(choiceFields, { add: addChoice, remove: removeChoice }) => (
                      <>
                        {choiceFields.map((choiceField, choiceIndex) => (
                          <Form.Item
                            key={choiceField.key}
                            {...choiceField}
                            name={[choiceField.name, 'text']}
                            label={`Réponse ${choiceIndex + 1}`}
                          >
                            <Input
                              addonAfter={
                                <Checkbox
                                  checked={form.getFieldValue(['questions', index, 'choices', choiceIndex, 'correct'])}
                                  onChange={() => handleCorrectChange(index, choiceIndex)}
                                >
                                  Correct
                                </Checkbox>
                              }
                            />
                          </Form.Item>
                        ))}
                        <Button type="dashed" onClick={() => addChoice()} style={{ width: '100%' }}>
                          Ajouter une réponse
                        </Button>
                      </>
                    )}
                  </Form.List>
                </Card>
              ))}
              <Button type="dashed" onClick={() => handleAddQuestion()} style={{ width: '100%' }}>
                Ajouter une question
              </Button>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Sauvegarder
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateQuizModal;
