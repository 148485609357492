import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntity, updateEntity } from './absence-eleve.reducer';

const AbsenceEleveUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const eleves = useAppSelector(state => state.eleve.entities);
  const absenceEleveEntity = useAppSelector(state => state.absenceEleve.entity);
  const loading = useAppSelector(state => state.absenceEleve.loading);
  const updating = useAppSelector(state => state.absenceEleve.updating);
  const updateSuccess = useAppSelector(state => state.absenceEleve.updateSuccess);

  const handleClose = () => {
    navigate('/absence-eleve');
  };

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getEleves({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...absenceEleveEntity,
      ...values,
      eleve: eleves.find(it => it.id.toString() === values.eleve?.toString()),
    };
    dispatch(updateEntity(entity));
  };

  const defaultValues = () => ({
    ...absenceEleveEntity,
    eleve: absenceEleveEntity?.eleve?.id,
  });

  return (
    <Row className="justify-content-center">
      <Col md="8">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
            <ValidatedField name="id" required readOnly id="absence-eleve-id" label="ID" validate={{ required: true }} />
            <ValidatedField label="Date" id="absence-eleve-date" name="date" data-cy="date" type="date" />
            <ValidatedField label="Periode" id="absence-eleve-periode" name="periode" data-cy="periode" type="text" />
            <ValidatedField label="Cause" id="absence-eleve-cause" name="cause" data-cy="cause" type="text" />
            <ValidatedField id="absence-eleve-eleve" name="eleve" data-cy="eleve" label="Eleve" type="select">
              <option value="" key="0" />
              {eleves
                ? eleves.map(otherEntity => (
                    <option value={otherEntity.id} key={otherEntity.id}>
                      {otherEntity.id}
                    </option>
                  ))
                : null}
            </ValidatedField>
            <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/absence-eleve" replace color="info">
              <FontAwesomeIcon icon="arrow-left" />
              &nbsp;
              <span className="d-none d-md-inline">Retour</span>
            </Button>
            &nbsp;
            <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
              <FontAwesomeIcon icon="save" />
              &nbsp; Sauvegarder
            </Button>
          </ValidatedForm>
        )}
      </Col>
    </Row>
  );
};

export default AbsenceEleveUpdate;
