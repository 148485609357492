import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './absence-eleve.reducer';
import { Tag } from 'antd';

export const AbsenceEleveDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, [dispatch, id]);

  const absenceEleveEntity = useAppSelector(state => state.absenceEleve.entity);
  return (
    <Row>
      <Col md="8">
        <div className="entity-details" style={{ width: '68rem' }}>
          <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="red">
            Absence Élèves
          </Tag>
          <dl className="entity-details-list">
            <div className="entity-details-item">
              <dt>ID</dt>
              <dd className="entity-details-value">{absenceEleveEntity.id}</dd>
            </div>
            <div className="entity-details-item">
              <dt>Nom Élève</dt>
              <dd className="entity-details-value">
                {absenceEleveEntity.eleve ? `${absenceEleveEntity.eleve.nom} ${absenceEleveEntity.eleve.prenom}` : ''}
              </dd>
            </div>
            <div className="entity-details-item">
              <dt>
                <span id="date">Date</span>
              </dt>
              <dd className="entity-details-value">
                {absenceEleveEntity.date ? <TextFormat value={absenceEleveEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
              </dd>
            </div>
            <div className="entity-details-item">
              <dt>
                <span id="periode">Période</span>
              </dt>
              <dd className="entity-details-value">{absenceEleveEntity.periode}</dd>
            </div>
            <div className="entity-details-item">
              <dt>
                <span id="cause">Cause</span>
              </dt>
              <dd className="entity-details-value">{absenceEleveEntity.cause}</dd>
            </div>
          </dl>
          <div className="entity-actions">
            <Button tag={Link} to="/absence-eleve" replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
            </Button>
            &nbsp;
            <Button tag={Link} color="danger" onClick={() => (window.location.href = `/absence-eleve/${id}/delete?`)}>
              Supprimer
            </Button>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AbsenceEleveDetail;
