import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Salle from './salle';
import SalleDetail from './salle-detail';
import SalleUpdate from './salle-update';
import SalleDeleteDialog from './salle-delete-dialog';

const SalleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Salle />} />
    <Route path="new" element={<SalleUpdate />} />
    <Route path=":id">
      <Route index element={<SalleDetail />} />
      <Route path="edit" element={<SalleUpdate />} />
      <Route path="delete" element={<SalleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SalleRoutes;
