import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Galerie from './galerie';
import GalerieDetail from './galerie-detail';
import GalerieUpdate from './galerie-update';
import GalerieDeleteDialog from './galerie-delete-dialog';

const GalerieRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Galerie/>}/>
    <Route path="new" element={<GalerieUpdate/>}/>
    <Route path=":id">
      <Route index element={<GalerieDetail/>}/>
      <Route path="edit" element={<GalerieUpdate/>}/>
      <Route path="delete" element={<GalerieDeleteDialog/>}/>
    </Route>
  </ErrorBoundaryRoutes>
);

export default GalerieRoutes;
