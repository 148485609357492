import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Space, Table, message, Popconfirm } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlus, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities, deleteEntity } from './etablissement.reducer';
import { getPaginationState } from 'react-jhipster';

export const Etablissement = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const etablissementList = useAppSelector(state => state.etablissement.entities);
  const loading = useAppSelector(state => state.etablissement.loading);
  const totalItems = useAppSelector(state => state.etablissement.totalItems);
  const [currentId, setCurrentId] = useState(null);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  useEffect(() => {
    getAllEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const handleTableChange = (pagination, filters, sorter) => {
    setPaginationState({
      ...paginationState,
      activePage: pagination.current,
      itemsPerPage: pagination.pageSize,
      sort: sorter.field,
      order: sorter.order === 'ascend' ? ASC : DESC,
    });
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  const confirmDelete = () => {
    dispatch(deleteEntity(currentId));
    message.success('Établissement supprimé');
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: (text, record) => (
        <Button type="link" href={`/etablissement/${record.id}`}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
    },
    {
      title: 'Step',
      dataIndex: 'step',
      sorter: true,
    },
    {
      title: 'Tel',
      dataIndex: 'tel',
      sorter: true,
    },
    {
      title: 'Adresse',
      dataIndex: 'adresse',
      sorter: true,
    },
    {
      title: 'Cnss',
      dataIndex: 'cnss',
      sorter: true,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (text, record) => (
        <Space size="middle">
          <Button
            type="link"
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            onClick={() =>
              navigate(
                `/etablissement/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
              )
            }
          />
          <Popconfirm
            title="Êtes-vous sûr de vouloir supprimer cet établissement?"
            onConfirm={confirmDelete}
            onCancel={() => message.error('Annulé')}
            okText="Oui"
            cancelText="Non"
          >
            <Button type="link" danger icon={<FontAwesomeIcon icon={faTrash} />} onClick={() => setCurrentId(record.id)} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="etablissement-heading" data-cy="EtablissementHeading">
        Établissements
        <div className="d-flex justify-content-end" style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button type="primary" icon={<FontAwesomeIcon icon={faSync} spin={loading} />} onClick={handleSyncList} disabled={loading}>
            Actualiser la liste
          </Button>
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => navigate('/etablissement/new')}
            style={{ marginLeft: '10px' }}
          >
            Créer un nouveau Établissement
          </Button>
        </div>
      </h2>
      <Table
        columns={columns}
        dataSource={etablissementList}
        pagination={{
          current: paginationState.activePage,
          pageSize: paginationState.itemsPerPage,
          total: totalItems,
          showSizeChanger: true,
        }}
        loading={loading}
        onChange={handleTableChange}
        rowKey="id"
      />
    </div>
  );
};

export default Etablissement;
