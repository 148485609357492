import React from 'react';
import { Tabs } from 'antd';
import RubriquesMensuelles from 'app/modules/tresorerie/RubriquesMensuelles';
import RubriquesAnnuelles from 'app/modules/tresorerie/RubriquesAnnuelles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'app/config/store';
import { reset as resetRecu, resetHard } from 'app/entities/recu/recu.reducer';
export const PayeeImpayee = () => {
  const dispatch = useAppDispatch();
  const paginationState = { sort: 'id', order: 'asc' };
  const navigate = useNavigate();
  const location = useLocation();
  const onChange = (key: string) => {
    const endURL = `?page=1&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
    // dispatch(resetHard());
    // dispatch(resetRecu());
  };
  let items = [
    {
      label: `Rubriques Mensuelles `,
      key: 1,
      children: <RubriquesMensuelles />,
    },
    {
      label: `Rubriques Annuelles  `,
      key: 2,
      children: <RubriquesAnnuelles />,
    },
  ] as any;
  return (
    <>
      <Tabs defaultActiveKey="1" type="card" size={'large'} items={items} onChange={onChange} />
    </>
  );
};

export default PayeeImpayee;
