import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { useNavigate, useParams } from 'react-router-dom';
import { getOneTransmission } from 'app/entities/cahier-transmission/transmission.reducer';
import { ITransmissionResponse, ICommentaire } from 'app/shared/model/cahier_transmission_model/transmission.model';
import dayjs from 'dayjs';
import { Card, Typography, Descriptions, List, Avatar, Row, Col } from 'antd';
import { Box, Divider } from '@mui/material';

const { Title, Paragraph, Text } = Typography;

const TransmissionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const transmissionEntity: ITransmissionResponse = useAppSelector(state => state.transmission.entity);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getOneTransmission(id));
  }, [dispatch, id]);

  return (
    <Box sx={{ maxWidth: '800px', margin: 'auto', padding: 2 }}>
      <Card bordered={false} style={{ marginBottom: '20px', padding: '20px' }}>
        <Row gutter={16} align="middle">
          {/* Student Avatar */}
          <Col xs={24} sm={6} md={4}>
            <Avatar src={`data:image/jpeg;base64,${transmissionEntity?.eleve?.photo}`} size={80} style={{ marginBottom: '20px' }} />
          </Col>

          {/* Transmission Details */}
          <Col xs={24} sm={18} md={20}>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item label="Description">{transmissionEntity?.description || 'N/A'}</Descriptions.Item>
              <Descriptions.Item label="Validation">{transmissionEntity?.validated ? 'Oui' : 'Non'}</Descriptions.Item>
              <Descriptions.Item label="Professeur">
                {transmissionEntity?.prof
                  ? `${transmissionEntity.prof.internalUser?.firstName} ${transmissionEntity.prof.internalUser?.lastName}`
                  : 'aucun professeur'}
              </Descriptions.Item>
              <Descriptions.Item label="Elève">
                {`${transmissionEntity?.eleve?.prenom} ${transmissionEntity?.eleve?.nom}` || 'N/A'}
              </Descriptions.Item>
              <Descriptions.Item label="Créer le">
                {transmissionEntity?.createdAt ? dayjs(transmissionEntity.createdAt).format('DD/MM/YYYY HH:MM') : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </Card>

      <Card bordered={false}>
        <Title level={3}>Comments</Title>
        {transmissionEntity?.commentaires?.length ? (
          <List
            itemLayout="horizontal"
            dataSource={transmissionEntity.commentaires}
            renderItem={(comment: ICommentaire) => (
              <>
                <List.Item
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                  }}
                >
                  <List.Item.Meta
                    avatar={<Avatar>{`${comment?.tuteur?.internalUser?.firstName}`.charAt(0).toUpperCase()}</Avatar>}
                    title={
                      <span style={{ cursor: 'pointer' }} onClick={() => navigate(`/tuteur/${comment?.tuteur?.id}`)}>
                        {comment?.tuteur?.internalUser?.firstName}
                      </span>
                    }
                    description={comment.description || 'No description available'}
                  />
                  <Text type="secondary">{comment.createdAt ? dayjs(comment.createdAt).format('DD/MM/YYYY HH:mm') : 'N/A'}</Text>
                </List.Item>
                <Divider />
              </>
            )}
          />
        ) : (
          <Paragraph>Aucun commentaire disponible.</Paragraph>
        )}
      </Card>
    </Box>
  );
};

export default TransmissionDetail;
