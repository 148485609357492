import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAppDispatch, useAppSelector} from 'app/config/store';
import {deleteEntity, getEntity} from './tuteur.reducer';

export const TuteurDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();
  const {id} = useParams<'id'>();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);

  const tuteurEntity = useAppSelector(state => state.tuteur.entity);
  const updateSuccess = useAppSelector(state => state.tuteur.updateSuccess);

  const handleClose = () => {
    navigate('/tuteur' + pageLocation.search);
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(tuteurEntity.id));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="tuteurDeleteDialogHeading">
        Confirmation de suppression
      </ModalHeader>
      <ModalBody id="goSchoolApp.tuteur.delete.question">Êtes-vous certain de vouloir supprimer le
        Tuteur {tuteurEntity.id} ?</ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban"/>
          &nbsp; Annuler
        </Button>
        <Button id="jhi-confirm-delete-tuteur" data-cy="entityConfirmDeleteButton" color="danger"
                onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash"/>
          &nbsp; Supprimer
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TuteurDeleteDialog;
