import { IMoisEleve } from 'app/shared/model/mois-eleve.model';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';

export interface IEtatServiceMois {
  id?: number;
  montant?: number | null;
  avance?: number | null;
  moisEleve?: IMoisEleve | null;
  serviceEtablisement?: IServiceEtablisement | null;
}

export const defaultValue: Readonly<IEtatServiceMois> = {};
