import { saveAs } from 'file-saver';

export function isNotEmpty(str: string | null | undefined): boolean {
  return !!str && str.length > 0;
}

export function downloadFile(blobString, fileType, fileName) {
  // Convert the string back to a Uint8Array
  const byteCharacters = atob(blobString); // Decode base64 string
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);

  // Create a blob from the Uint8Array
  const blob = new Blob([byteArray], { type: fileType });

  // Use FileSaver to save the file
  saveAs(blob, fileName);
}
