import { IQuestion } from 'app/shared/model/question.model';

export interface IQuiz {
  id?: number;
  name?: string;
  category?: string;
  niveau?: string;
  image?: string;
  questions?: IQuestion[];
}

export const defaultValue: Readonly<IQuiz> = {};
