import { createSlice, createAsyncThunk, isPending, isFulfilled } from '@reduxjs/toolkit';
import axios from 'axios';
import { IEleve } from 'app/shared/model/eleve.model';
import { serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { INiveau } from 'app/shared/model/niveau.model';

const initialState = {
  entities: [],
  loading: false,
  errorMessage: null,
  entity: {} as IEleve,
  updateSuccess: false,
};

const apiUrl = 'api/eleves';

// Async thunk for fetching entities
export const getEntities = createAsyncThunk(
  'reinscriptionEleve/fetch_entity_list',
  async ({ page, size, sort, query, niveauId, groupeId }: any) => {
    let requestUrl = `${apiUrl}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    if (niveauId) {
      requestUrl += `&niveauId.equals=${niveauId}`;
    }
    if (groupeId) {
      requestUrl += `&groupeId.equals=${groupeId}`;
    }
    return axios.get<IEleve[]>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

const reinscriptionEleveSlice = createSlice({
  name: 'reinscriptionEleve',
  initialState,
  reducers: {
    reset(state) {
      return initialState;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getEntities.fulfilled, (state, action) => {
        const { data, headers } = action.payload;
        state.entities = data;
        state.loading = false;
      })
      .addMatcher(isPending(getEntities), state => {
        state.loading = true;
        state.errorMessage = null;
        state.updateSuccess = false;
      });
  },
});

export const { reset } = reinscriptionEleveSlice.actions;

export default reinscriptionEleveSlice.reducer;
