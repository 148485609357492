import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './recu.reducer';

export const RecuDetail = () => {
  const dispatch = useAppDispatch();

  const {id} = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const recuEntity = useAppSelector(state => state.recu.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="recuDetailsHeading">Recu</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{recuEntity.id}</dd>
          <dt>
            <span id="dateCreation">Date Creation</span>
          </dt>
          <dd>
            {recuEntity.dateCreation ?
              <TextFormat value={recuEntity.dateCreation} type="date" format={APP_LOCAL_DATE_FORMAT}/> : null}
          </dd>
          <dt>
            <span id="montant">Montant</span>
          </dt>
          <dd>{recuEntity.montant}</dd>
          <dt>
            <span id="reste">Reste</span>
          </dt>
          <dd>{recuEntity.reste}</dd>
          <dt>
            <span id="observation">Observation</span>
          </dt>
          <dd>{recuEntity.observation}</dd>
          <dt>
            <span id="modePaiement">Mode Paiement</span>
          </dt>
          <dd>{recuEntity.modePaiement}</dd>
          <dt>
            <span id="dateModification">Date Modification</span>
          </dt>
          <dd>
            {recuEntity.dateModification ? (
              <TextFormat value={recuEntity.dateModification} type="date" format={APP_LOCAL_DATE_FORMAT}/>
            ) : null}
          </dd>
          <dt>Tuteur</dt>
          <dd>{recuEntity.tuteur ? recuEntity.tuteur.id : ''}</dd>
          <dt>Caisse</dt>
          <dd>{recuEntity.caisse ? recuEntity.caisse.id : ''}</dd>
          <dt>Services Tarifs</dt>
          <dd>
            {recuEntity.servicesTarifs
              ? recuEntity.servicesTarifs.map((val, i) => (
                <span key={val.id}>
                    <a>{val.id}</a>
                  {recuEntity.servicesTarifs && i === recuEntity.servicesTarifs.length - 1 ? '' : ', '}
                  </span>
              ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/recu" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left"/> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/recu/${recuEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt"/> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default RecuDetail;
