import React, { useEffect, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { Layout, Menu, MenuProps } from 'antd';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import {
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  BarsOutlined,
  BookOutlined,
  CalendarOutlined,
  ClusterOutlined,
  DollarOutlined,
  EuroCircleOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileTextOutlined,
  FormOutlined,
  FundProjectionScreenOutlined,
  HomeOutlined,
  LockOutlined,
  LoginOutlined,
  LogoutOutlined,
  MessageOutlined,
  MobileOutlined,
  NotificationOutlined,
  PictureOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  ScheduleOutlined,
  SettingOutlined,
  TeamOutlined,
  TransactionOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupDeleteOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import HistoryIcon from '@mui/icons-material/History';
import TvIcon from '@mui/icons-material/Tv';
import { changeCurrentAnneeScolaire, hasCurrentAnneeScolaire } from 'app/entities/utilisateur/utilisateur.reducer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';

import { getUnreadMessages } from 'app/entities/message/message.reducer';
import usePusher from 'app/shared/util/pusherClient';

const { Sider } = Layout;

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  collapsed: boolean;
}

export interface IHeaderMenuProps {
  to: string;
  icon: IconProp;
  title: string;
}

const Header = (props: IHeaderProps) => {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState('mail');
  const [currentYear, setCurrentYear] = useState<string | null>(null);
  const account = useAppSelector(state => state.authentication.account);
  const hascurrentanneescolaire: boolean = useAppSelector(state => state.utilisateur.hascurrentanneescolaire);
  const loadinghascurrentannescolaire: boolean = useAppSelector(state => state.utilisateur.loadinghascurrentannescolaire);
  const anneeScolaireList: IAnneeScolaire[] = useAppSelector(state => state.anneeScolaire.entities);
  const [periode, setPeriode] = useState<string>();
  const [currentAnneeScolaire, setCurrentAnneeScolaire] = useState<IAnneeScolaire>();

  const pusherInstance = usePusher();
  useEffect(() => {
    dispatch(getEntities({}));
    dispatch(hasCurrentAnneeScolaire());
  }, [dispatch, props.isAuthenticated]);

  useEffect(() => {
    if (pusherInstance) {
      const channel = pusherInstance.subscribe('messagerie-channel');
      channel.bind('message-new', data => {
        console.log('Received new message event:', data);
        dispatch(getUnreadMessages());
      });

      return () => {
        pusherInstance.unsubscribe('messagerie-channel');
      };
    }
  }, [pusherInstance]);

  useEffect(() => {
    if (!loadinghascurrentannescolaire && account?.id && anneeScolaireList.length > 0) {
      if (hascurrentanneescolaire) {
        const currentPeriod = anneeScolaireList.find(item => item?.id === account?.anneeScolaire?.id);
        setPeriode(currentPeriod?.periode);
        setCurrentAnneeScolaire(currentPeriod);
      } else {
        const currentPeriod = anneeScolaireList.find(item => item.isCurrent);
        if (currentPeriod?.periode) {
          setPeriode(currentPeriod.periode);
          setCurrentAnneeScolaire(currentPeriod);
        }
      }
    }
  }, [loadinghascurrentannescolaire, hascurrentanneescolaire, account, props.isAuthenticated, anneeScolaireList]);

  useEffect(() => {}, [periode]);

  const handleMenuClick = e => {
    let selectedYear = anneeScolaireList.find(item => item.id == e.key);
    if (selectedYear) {
      setCurrentYear(selectedYear.periode);
      dispatch(changeCurrentAnneeScolaire(selectedYear));
      // anneeScolaireList.forEach(item => {
      //   if (item.periode === selectedYear.periode) {
      //     dispatch(updateEntity({ ...item, isCurrent: true }));
      //   }
      // });
    }
  };

  const sortedAnneeScolaireList = [...anneeScolaireList].sort((a, b) => {
    if (a.periode < b.periode) return -1;
    if (a.periode > b.periode) return 1;
    return 0;
  });

  const yearMenu = (
    <Menu onClick={handleMenuClick}>
      {sortedAnneeScolaireList.map(item => (
        <Menu.Item key={item.id}>{item.periode}</Menu.Item>
      ))}
    </Menu>
  );

  const menus = [
    { label: 'État Service Mois', to: 'etat-service-mois', icon: <CalendarOutlined style={{ color: '#1DA57A' }} /> },
    { label: 'Mois Élève', to: 'mois-eleve', icon: <CalendarOutlined style={{ color: '#1890FF' }} /> },
    { label: 'Absence Élève', to: 'absence-eleve', icon: <UserOutlined style={{ color: '#1DA57A' }} /> },
    { label: 'Salle', to: 'salle', icon: <HomeOutlined style={{ color: '#FFA500' }} /> },
    { label: 'Établissement', to: 'etablissement', icon: <BankOutlined style={{ color: '#8A2BE2' }} /> },
    { label: 'Tarif', to: 'tarif', icon: <DollarOutlined style={{ color: '#FFD700' }} /> },
    { label: 'Emploi Temps', to: 'emploi-temps', icon: <ScheduleOutlined style={{ color: '#FF69B4' }} /> },
    { label: 'Emploi Temps Ligne', to: 'emploi-temps-ligne', icon: <BarsOutlined style={{ color: '#A9A9A9' }} /> },
    { label: 'Service Établissement', to: 'service-etablisement', icon: <ClusterOutlined style={{ color: '#1DA57A' }} /> },
    { label: 'Année Scolaire', to: 'annee-scolaire', icon: <FundProjectionScreenOutlined style={{ color: '#FFA500' }} /> },
    { label: 'Groupe x', to: 'groupe', icon: <TeamOutlined style={{ color: '#FFD700' }} /> },
    { label: 'Niveau x', to: 'niveau', icon: <AppstoreOutlined style={{ color: '#8A2BE2' }} /> },
    { label: 'Caisse', to: 'caisse', icon: <DollarOutlined style={{ color: '#FF4D4F' }} /> },
    { label: 'Message', to: 'message', icon: <MessageOutlined style={{ color: '#1890FF' }} /> },
    { label: 'Reçu x', to: 'recu', icon: <FileTextOutlined style={{ color: '#FFA500' }} /> },
    { label: 'Audit Log', to: 'audit-log', icon: <SettingOutlined style={{ color: '#FF69B4' }} /> },
    {
      label: 'Messages',
      to: 'message',
      icon: <MessageOutlined style={{ color: '#2196F3' }} />, // Bleu
    },
  ];

  const HeaderMenusItem = ({ to, icon, title }) => (
    <ul>
      <NavItem>
        <NavLink tag={Link} to={to} className="d-flex align-items-center">
          <span style={{ marginRight: '7px' }}>{icon}</span>
          <span>{title}</span>
        </NavLink>
      </NavItem>
    </ul>
  );

  function getItemMenu(menus: any) {
    return menus.map(item => ({
      label: <HeaderMenusItem to={'/' + item.to} icon={item.icon} title={item.label} />,
      key: item.label,
    }));
  }

  const menuGestionRessource = [
    {
      label: 'Ressources',
      to: 'ressource',
      icon: <FileOutlined style={{ color: '#4CAF50' }} />, // Vert
    },
    {
      label: 'Galeries',
      to: 'galerie',
      icon: <PictureOutlined style={{ color: '#3F51B5' }} />, // Bleu Indigo
    },
    {
      label: 'Annonces',
      to: 'annonce',
      icon: <NotificationOutlined style={{ color: '#FFC107' }} />, // Jaune
    },
    /*{
      label: 'Gestion des devoirs',
      to: 'devoir',
      icon: <BookOutlined style={{ color: '#FF5722' }} />, // Orange
    },
    {
      label: 'Gestion des annonces',
      to: 'annonce',
      icon: <NotificationOutlined style={{ color: '#FFC107' }} />, // Jaune
    },
    {
      label: 'Gestion des ressources',
      to: 'ressource',
      icon: <FileOutlined style={{ color: '#4CAF50' }} />, // Vert
    },
    {
      label: 'Gestion des galeries',
      to: 'galerie',
      icon: <PictureOutlined style={{ color: '#3F51B5' }} />, // Bleu Indigo
    },
    {
      label: 'Gestion des contrôles',
      to: 'controle',
      icon: <FormOutlined style={{ color: '#9C27B0' }} />, // Violet
    },
    {
      label: 'Gestion des emplois du temps',
      to: 'emploi-temps',
      icon: <ScheduleOutlined style={{ color: '#009688' }} />, // Teal
    },
    {
      label: 'Gestion des messages',
      to: 'message',
      icon: <MessageOutlined style={{ color: '#2196F3' }} />, // Bleu
    },*/
  ];

  const menuGestionEleve = [
    {
      label: 'Niveau',
      to: 'niveau',
      icon: <AppstoreOutlined style={{ color: '#1E90FF' }} />, // Bleu Dodger
    },
    {
      label: 'Matiere',
      to: 'matiere',
      icon: <BookOutlined style={{ color: '#32CD32' }} />, // Vert Lime
    },
    {
      label: 'emplois du temps',
      to: 'emploi-temps',
      icon: <ScheduleOutlined style={{ color: '#009688' }} />, // Teal
    },
    {
      label: 'Notes',
      to: 'notes',
      icon: <AssignmentIcon style={{ fontSize: 16, marginBottom: 4 }} />,
    },
    {
      label: 'Contrôles',
      to: 'controle',
      icon: <FormOutlined style={{ color: '#9C27B0' }} />, // Violet
    },
    {
      label: 'Devoirs',
      to: 'devoir',
      icon: <BookOutlined style={{ color: '#FF5722' }} />, // Orange
    },
    {
      label: 'Devoir Surveillé',
      to: 'devoirsurv',
      icon: <FolderZipIcon style={{ fontSize: 16, marginBottom: 4 }} />,
    },
    {
      label: 'Quiz',
      to: 'quiz',
      icon: <QuestionCircleOutlined style={{ color: '#E91E63' }} />, // Rose
    },
  ];

  const menuTresorerie = [
    { label: 'Tarif', to: 'tarif', icon: <DollarOutlined style={{ color: '#FF4599' }} /> },
    { label: 'Caisse', to: 'caisse', icon: <DollarOutlined style={{ color: '#FF4D4F' }} /> },
    {
      label: 'Trésorerie',
      to: 'tresorerie',
      icon: <EuroCircleOutlined style={{ color: '#FFD700' }} />, // Or
    },
    {
      label: 'Reçu',
      to: 'recu',
      icon: <FilePdfOutlined style={{ color: '#DC143C' }} />, // Rouge Cramoisi
    },
    {
      label: 'Payés / Impayés',
      to: 'payeeimpayee',
      icon: <TransactionOutlined style={{ color: '#00BFFF' }} />, // Bleu Délicat
    },
  ];

  const menuGestionNotes = [
    {
      label: 'Devoir Surveillé',
      to: 'devoirsurv',
      icon: <FolderZipIcon style={{ fontSize: 16, marginBottom: 4 }} />,
    },
    {
      label: 'Notes',
      to: 'notes',
      icon: <AssignmentIcon style={{ fontSize: 16, marginBottom: 4 }} />,
    },
    {
      label: 'Quiz',
      to: 'quiz',
      icon: <QuestionCircleOutlined style={{ color: '#E91E63' }} />, // Rose
    },
  ];

  const menuGarderie = [
    { label: 'Service Établissement', to: 'service-etablisement', icon: <ClusterOutlined style={{ color: '#1DA57A' }} /> },
    {
      label: 'Garderie',
      to: 'garderie',
      icon: <WarehouseIcon style={{ fontSize: 16, marginBottom: 4, color: '#FF5722' }} />, // Orange
    },
    {
      label: 'Screen',
      to: 'screen',
      icon: <TvIcon style={{ fontSize: 16, marginBottom: 4, color: '#3F51B5' }} />, // Bleu
    },
    {
      label: 'Historique',
      to: 'historique',
      icon: <HistoryIcon style={{ fontSize: 16, marginBottom: 4, color: '#4CAF50' }} />, // Vert
    },

    /*{
      label: 'Garderie',
      to: 'garderie',
      icon: <WarehouseIcon style={{ fontSize: 16, marginBottom: 4, color: '#FF5722' }} />, // Orange
    },
    {
      label: 'Historique',
      to: 'historique',
      icon: <HistoryIcon style={{ fontSize: 16, marginBottom: 4, color: '#4CAF50' }} />, // Vert
    },

    /*{
      label: 'Garderie',
      to: 'garderie',
      icon: <WarehouseIcon style={{ fontSize: 16, marginBottom: 4, color: '#FF5722' }} />, // Orange
    },
    {
      label: 'Screen',
      to: 'screen',
      icon: <TvIcon style={{ fontSize: 16, marginBottom: 4, color: '#3F51B5' }} />, // Bleu
    },
    {
      label: 'Historique',
      to: 'historique',
      icon: <HistoryIcon style={{ fontSize: 16, marginBottom: 4, color: '#4CAF50' }} />, // Vert
    },*/
  ];

  const menuAccount = [
    { label: 'parametrage', to: 'parametrage', icon: <SettingOutlined style={{ color: '#FF7877' }} /> },
    { label: 'Audit Log', to: 'audit-log', icon: <SettingOutlined style={{ color: '#FF69B4' }} /> },
    {
      label: 'Profil',
      to: 'account/settings',
      icon: <EuroCircleOutlined style={{ color: '#4CAF50' }} />, // Vert
    },
    {
      label: 'Mot de passe',
      to: 'account/password',
      icon: <LockOutlined style={{ color: '#FF5722' }} />, // Orange
    },
    {
      label: 'Déconnexion',
      to: 'logout',
      icon: <LogoutOutlined style={{ color: '#2196F3' }} />, // Bleu
    },
    { label: 'Pages mobile', to: 'page-mobile', icon: <MobileOutlined style={{ color: '#FF4500' }} /> },
  ];

  const menuAccountOut = [
    {
      label: "S'authentifier",
      to: 'login',
      icon: <LoginOutlined style={{ color: '#9C27B0' }} />, // Violet
    },
    {
      label: 'Créer un compte',
      to: 'account/register',
      icon: <UserAddOutlined style={{ color: '#FF9800' }} />, // Orange Clair
    },
  ];

  const menueleve = [
    {
      label: 'Elèves',
      to: 'eleve',
      icon: <UserOutlined style={{ color: '#4CAF50' }} />, // Vert
    },
    {
      label: 'Tuteurs',
      to: 'tuteur',
      icon: <UserSwitchOutlined style={{ color: '#3F51B5' }} />, // Bleu Indigo
    },
    {
      label: 'Professeurs',
      to: 'prof',
      icon: <TeamOutlined style={{ color: '#9C27B0' }} />, // Violet
    },
    {
      label: 'Absences',
      to: 'absence-eleve',
      icon: <UserDeleteOutlined style={{ color: '#2196F3' }} />, // Bleu
    },
    {
      label: 'Départs',
      to: 'depart',
      icon: <UsergroupDeleteOutlined style={{ color: '#FF9800' }} />, // Orange Clair
    },
    {
      label: 'Suivi pédagogique',
      to: 'cahier_transmission',
      icon: <BookmarkAddIcon style={{ color: '#FF9800', fontSize: '15px' }} />,
    },
  ];

  const menuEcole = [
    { label: 'Salle', to: 'salle', icon: <HomeOutlined style={{ color: '#FFA500' }} /> },
    {
      label: 'Groupe',
      to: 'groupe',
      icon: <TeamOutlined style={{ color: '#FF6347' }} />, // Tomate
    },
    {
      label: 'Chat',
      to: 'chat',
      icon: <MessageOutlined style={{ color: '#FF69B4' }} />, // Rose Chaud
    },
    {
      label: 'Utilisateur',
      to: 'admin/user-management',
      icon: <UserOutlined style={{ color: '#FF9800' }} />, // Orange Clair
    },

    /*{
      label: 'Niveau',
      to: 'niveau',
      icon: <AppstoreOutlined style={{ color: '#1E90FF' }} />, // Bleu Dodger
    },
    {
      label: 'Groupe',
      to: 'groupe',
      icon: <TeamOutlined style={{ color: '#FF6347' }} />, // Tomate
    },
    {
      label: 'Matiere',
      to: 'matiere',
      icon: <BookOutlined style={{ color: '#32CD32' }} />, // Vert Lime
    },
    {
      label: 'Chat',
      to: 'chat',
      icon: <MessageOutlined style={{ color: '#FF69B4' }} />, // Rose Chaud
    },
    {
      label: 'Utilisateur',
      to: 'admin/user-management',
      icon: <UserOutlined style={{ color: '#FF9800' }} />, // Orange Clair
    },*/
  ];

  const onClick: MenuProps['onClick'] = e => {
    setCurrent(e.key);
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  const Support = [{ label: 'Audit Log', to: 'audit-log', icon: <SettingOutlined style={{ color: '#FF69B4' }} /> }];

  const items = [
    ...(!props.collapsed
      ? [
          {
            label: (
              <div>
                <img height={'100px'} src="content/images/ok.png" alt="Logo" />
              </div>
            ),
            key: 'user',
            style: { height: '100px' },
          },
        ]
      : []),

    ...(props.isAuthenticated
      ? [
          {
            label: (
              <HeaderMenusItem
                to="chart"
                title={!props.collapsed ? 'Board' : ''}
                icon={<PieChartOutlined style={{ color: '#1DA57A' }} />}
              ></HeaderMenusItem>
            ),
            key: 'Board',
          },
        ]
      : []),

    /*...(props.isAuthenticated
      ? [
          {
            label: (
              <HeaderMenusItem
                to="parametrage"
                title={!props.collapsed ? 'Parametrage' : ''}
                icon={<SettingOutlined style={{ color: '#FF69B4' }} />}
              ></HeaderMenusItem>
            ),
            key: 'Parametrage',
          },
        ]
      : []),*/

    ...(props.isAuthenticated && !props.isInProduction
      ? [
          {
            label: 'Entities',
            key: 'SubMenu',
            icon: <ReadOutlined style={{ color: '#FFD700' }} />,
            children: getItemMenu(menus),
          },
        ]
      : []),
    ...(props.isAuthenticated
      ? [
          {
            label: 'Gestion des Élèves',
            key: 'Gestion des Élèves',
            icon: <AssignmentIcon />,
            children: getItemMenu(menueleve),
          },
        ]
      : []),

    ...(props.isAuthenticated
      ? [
          {
            label: 'Gestion Scolaire',
            key: 'Gestion des utilisateurs',
            icon: <BankOutlined style={{ color: '#8A2BE2' }} />,
            children: getItemMenu(menuGestionEleve),
          },
        ]
      : []),

    ...(props.isAuthenticated
      ? [
          {
            label: 'Gestion Ressources',
            key: 'Gestion des Ressources',
            icon: <AuditOutlined style={{ color: '#1890FF' }} />,
            children: getItemMenu(menuGestionRessource),
          },
        ]
      : []),
    /*...(props.isAuthenticated
      ? [
          {
            label: 'Gestion Des Notes',
            key: 'Gestion_Des_Notes',
            icon: <AssignmentIcon fontSize={'small'} />,
            children: getItemMenu(menuGestionNotes),
          },
        ]
      : []),*/
    ...(props.isAuthenticated
      ? [
          {
            label: 'Gestion des Services',
            key: 'garderie',
            icon: <WarehouseIcon fontSize={'small'} style={{ color: '#FFA500' }} />,
            children: getItemMenu(menuGarderie),
          },
        ]
      : []),
    ...(props.isAuthenticated
      ? [
          {
            label: 'Gestion Ecole',
            key: 'Gestion Ecole',
            icon: <BookOutlined style={{ color: '#A9A9A9' }} />,
            children: getItemMenu(menuEcole),
          },
        ]
      : []),
    ...(props.isAuthenticated
      ? [
          {
            label: 'Trésorerie',
            key: 'trésoreriex',
            icon: <EuroCircleOutlined style={{ color: '#FF4D4F' }} />,
            children: getItemMenu(menuTresorerie),
          },
        ]
      : []),

    ...(props.isAuthenticated
      ? [
          {
            label: (
              <HeaderMenusItem
                to="parametrage"
                title={!props.collapsed ? 'Parametrage' : ''}
                icon={<SettingOutlined style={{ color: '#FF7877' }} />}
              ></HeaderMenusItem>
            ),
            key: 'parametrage',
          },
        ]
      : []),

    {
      key: 'Compte',
      label: 'Compte',
      icon: <UserOutlined style={{ color: '#FF69B4' }} />,
      children: props.isAuthenticated ? getItemMenu(menuAccount) : getItemMenu(menuAccountOut),
    },
  ];

  return (
    <div>
      {
        <Menu
          style={{ height: '70rem', paddingTop: '20px' }}
          theme={'dark'}
          onClick={onClick}
          selectedKeys={[current]}
          mode="vertical"
          inlineCollapsed={props.collapsed}
          items={items}
        />
      }
    </div>
  );
};

export default Header;
