import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Button, Card, List, Spin } from 'antd';
import Box from '@mui/material/Box';
import '../garderie/styling.scss';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { getEntities as getAbseneceThatNeedValidation, validateAbsence } from 'app/entities/absence-eleve/absence-eleve.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IAbsenceEleve } from 'app/shared/model/absence-eleve.model';
import { LinearProgress } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import BackButton from 'app/shared/layout/buttons/BackButton';
dayjs.locale('fr');

const AbsenceEleveValidation = () => {
  const dispatch = useAppDispatch();
  const formattedDate = dayjs().format('DD MMMM YYYY');
  const abseneceThatNeedValidation = useAppSelector(state => state.absenceEleve.entities);
  const loading = useAppSelector(state => state.absenceEleve.loading);
  const validatingload = useAppSelector(state => state.absenceEleve.validatingload);
  const [isGone, setIsGone] = useState<Record<number, boolean>>({});

  useEffect(() => {
    dispatch(
      getAbseneceThatNeedValidation({
        query: `withRealAnneeScolaire=${true}&validated=${false}&paged=false`,
      }),
    );
  }, []);

  const handleEleveGone = (id: number) => {
    // setIsGone(prevState => ({
    //   ...prevState,
    //   [id]: true,
    // }));
    dispatch(validateAbsence({ id }));
  };
  return (
    <div>
      <BackButton path="/absence-eleve" />
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '4px' }}>
        <div style={{ width: '95vw', height: '100vh' }}>
          <Badge.Ribbon text={formattedDate}>
            <Card
              title="Validation des absences"
              bordered={true}
              style={{
                background: '#FFFFFF',
                filter: 'drop-shadow(0 0 0.75rem #eed5db)',
                opacity: validatingload ? '60%' : '100%',
                pointerEvents: validatingload ? 'none' : 'auto',
              }}
              className="custom-card"
            >
              {validatingload && <LinearProgress />}
              {loading ? (
                <Spin size="large" />
              ) : (
                <Box className="card-style--garderie">
                  <List
                    grid={{
                      gutter: 16,
                      xs: 1,
                      sm: 1,
                      md: 2,
                      lg: 2,
                      xl: 2,
                      xxl: 3,
                    }}
                    dataSource={abseneceThatNeedValidation}
                    renderItem={(item: IAbsenceEleve, index) => (
                      <List.Item
                        style={{
                          borderBottom: '1px solid #d9d9d9',
                          borderRight: index % 2 === 0 ? '1px solid #d9d9d9' : 'none',
                          marginBottom: '16px',
                          paddingRight: index % 2 === 0 ? '16px' : '0',
                          height: '130px',
                          paddingBottom: '20px',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            width: '100%',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                              paddingBottom: '10px',
                              width: '100%',
                            }}
                          >
                            <div>
                              <span style={{ fontSize: '12px', opacity: '40%' }}>
                                créer par {item?.prof?.internalUser?.firstName} {item?.prof?.internalUser?.lastName}
                              </span>
                            </div>
                          </div>
                          <List.Item.Meta
                            style={{ width: '100%' }}
                            avatar={<Avatar src={`data:image/jpeg;base64,${item.eleve?.photo}`} />}
                            title={
                              <div
                                style={{
                                  display: 'flex',
                                  fontSize: '16px',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <div style={{ color: '#000', textDecoration: 'none' }}>{dayjs(item?.date).format('DD MMMM YYYY')}</div>
                                <span style={{ marginLeft: '10px', opacity: '60%' }}>{item?.periode}</span>
                              </div>
                            }
                            description={
                              <div
                                style={{
                                  display: 'flex',
                                  fontSize: '16px',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  paddingTop: '14px',
                                }}
                              >
                                <div style={{ fontSize: '16px' }}>
                                  <span
                                    style={{
                                      color: 'green',
                                      fontWeight: 'bold',
                                      opacity: '60%',
                                    }}
                                  >
                                    {item.eleve?.prenom} {item.eleve?.nom}
                                  </span>
                                </div>
                                <Button
                                  style={{
                                    backgroundColor: 'green',
                                  }}
                                  onClick={() => handleEleveGone(item.id)}
                                  type="primary"
                                >
                                  Valider
                                </Button>
                                {/*<DirectionsRunIcon*/}
                                {/*  style={{*/}
                                {/*    fontSize: '26px',*/}
                                {/*    color: isGone[item.id] ? 'green' : '',*/}
                                {/*    pointerEvents: isGone[item.id] ? 'none' : 'auto',*/}
                                {/*    cursor: isGone[item.id] ? 'default' : 'pointer',*/}
                                {/*  }}*/}
                                {/*  onClick={() => !isGone[item.id] && handleEleveGone(item.id)}*/}
                                {/*/>*/}
                              </div>
                            }
                          />
                        </div>
                      </List.Item>
                    )}
                  />
                </Box>
              )}
            </Card>
          </Badge.Ribbon>
        </div>
      </div>
    </div>
  );
};
export default AbsenceEleveValidation;
