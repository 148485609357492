import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Badge, Col, List, Row, Space, Switch, Tag, Typography } from 'antd';
import { useAppSelector } from 'app/config/store';
import { getEntities, partialUpdateEntity } from './page-mobile.reducer';
import { useNavigate } from 'react-router-dom';
import { IPageMobile } from 'app/shared/model/page-mobile.model';

const { Title } = Typography;

const PageMobile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const pageMobileList = useAppSelector(state => state.pageMobile.entities as IPageMobile[]);
  const loading = useAppSelector(state => state.pageMobile.loading);

  useEffect(() => {
    // @ts-ignore
    dispatch(getEntities({}));
  }, [dispatch]);

  const onSwitchChange = (checked: boolean, id: number) => {
    console.log(`Switch for page ID ${id} changed to ${checked}`);
    // @ts-ignore
    dispatch(partialUpdateEntity({ id, isActive: checked }));
  };
  console.log(pageMobileList);
  return (
    <div>

      <Tag bordered={false} className={'capitalize'} style={{ padding: 15 }} color="processing">
        Pages mobiles
      </Tag>

      <div className="mt-4">
        {loading ? (
          <div>Chargement...</div>
        ) : pageMobileList && pageMobileList.length > 0 ? (
          <List<IPageMobile>

            grid={{ gutter: 16, column: 4 }}

            dataSource={pageMobileList}
            renderItem={item => (
              <List.Item
                key={item.id}
                className="hoverable-list-item"
                style={{
                  padding: '20px',
                  borderRadius: '8px',
                  border: '1px solid #e8e8e8',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  backgroundColor: '#fff',
                  marginBottom: '16px',
                  cursor: 'pointer',
                }}
                // onClick={() => navigate(`/page-mobile/${item.id}`)}
              >
                <Row justify="space-between" align="middle">
                  <Col>
                    <Badge
                      count={item.code}
                      style={{
                        color: '#fff',
                        backgroundColor: '#52c41a',
                        marginBottom: '8px',
                      }}
                    />
                    <Title level={5}>{item.pageName}</Title>
                  </Col>
                  <Col>
                    <Space size="middle">
                      <Switch
                        checked={item.isActive}
                        onChange={(checked, event) => {
                          event.stopPropagation(); // Empêche la propagation du clic pour ne pas déclencher la navigation
                          onSwitchChange(checked, item.id);
                        }}
                      />
                    </Space>
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        ) : (
          <Alert message="Aucune Page Mobile trouvée" type="warning" showIcon />
        )}
      </div>
    </div>
  );
};

export default PageMobile;
