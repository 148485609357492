import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './service-etablisement.reducer';

export const ServiceEtablisementDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const serviceEtablisementEntity = useAppSelector(state => state.serviceEtablisement.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="serviceEtablisementDetailsHeading">Service Établissement</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{serviceEtablisementEntity.id}</dd>
          <dt>
            <span id="libelle">Libelle</span>
          </dt>
          <dd>{serviceEtablisementEntity.libelle}</dd>
          <dt>
            <span id="type">Type</span>
          </dt>
          <dd>{serviceEtablisementEntity.type}</dd>
          <dt>
            <span id="duree">Duree</span>
          </dt>
          <dd>
            {serviceEtablisementEntity.duree ? (
              <TextFormat value={serviceEtablisementEntity.duree} type="date" format={APP_DATE_FORMAT} />
            ) : null}
          </dd>
          <dt>Eleve</dt>
          <dd>
            {serviceEtablisementEntity.eleves
              ? serviceEtablisementEntity.eleves.map((val, i) => (
                  <span key={val.id}>
                    <a>{val.id}</a>
                    {serviceEtablisementEntity.eleves && i === serviceEtablisementEntity.eleves.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/service-etablisement" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/service-etablisement/${serviceEtablisementEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ServiceEtablisementDetail;
