import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { byteSize, openFile, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './ressource.reducer';

export const RessourceDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const ressourceEntity = useAppSelector(state => state.ressource.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="ressourceDetailsHeading">Ressource</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{ressourceEntity.id}</dd>
          <dt>
            <span id="date">Date</span>
          </dt>
          <dd>{ressourceEntity.date ? <TextFormat value={ressourceEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="fichier">Fichier</span>
          </dt>
          <dd>
            {ressourceEntity.fichier ? (
              <div>
                {ressourceEntity.fichierContentType ? (
                  <a onClick={openFile(ressourceEntity.fichierContentType, ressourceEntity.fichier)}>Ouvrir&nbsp;</a>
                ) : null}
                <span>
                  {ressourceEntity.fichierContentType}, {byteSize(ressourceEntity.fichier)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>
            <span id="groupe">Groupe</span>
          </dt>
          <dd>{ressourceEntity.groupe ? ressourceEntity.groupe.nom : ''}</dd>
          <dt>Eleve</dt>
          <dd>
            {ressourceEntity.eleves
              ? ressourceEntity.eleves.map((val, i) => (
                  <span key={val.id}>
                    <a>
                      {val.nom} {val.prenom}
                    </a>
                    {ressourceEntity.eleves && i === ressourceEntity.eleves.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/ressource" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/ressource/${ressourceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default RessourceDetail;
