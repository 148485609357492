import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Pagination, Space, Tooltip } from 'antd';
import {
  SyncOutlined,
  PlusOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities } from './tarif.reducer';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getPaginationState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

export const Tarif = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const tarifList = useAppSelector(state => state.tarif.entities);
  const loading = useAppSelector(state => state.tarif.loading);
  const totalItems = useAppSelector(state => state.tarif.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = (p: string) => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = (currentPage: number) =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return <SortAscendingOutlined />;
    } else {
      return order === ASC ? <SortAscendingOutlined /> : <SortDescendingOutlined />;
    }
  };

  const columns = [
    {
      title: (
        <span onClick={sort('id')} style={{ cursor: 'pointer' }}>
          Code {getSortIconByFieldName('id')}
        </span>
      ),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button type="link">
          <Link to={`/tarif/${record.id}`}>{text}</Link>
        </Button>
      ),
    },
    {
      title: (
        <span onClick={sort('prix')} style={{ cursor: 'pointer' }}>
          Prix {getSortIconByFieldName('prix')}
        </span>
      ),
      dataIndex: 'prix',
      key: 'prix',
    },
    {
      title: 'Niveau',
      dataIndex: ['niveau', 'libelle'],
      key: 'niveau',
      render: (text, record) => (record.niveau ? <Link to={`/niveau/${record.niveau.id}`}>{text}</Link> : ''),
    },
    {
      title: 'Service Etablisement',
      dataIndex: ['serviceEtablisement', 'libelle'],
      key: 'serviceEtablisement',
      render: (text, record) =>
        record.serviceEtablisement ? <Link to={`/service-etablisement/${record.serviceEtablisement.id}`}>{text}</Link> : '',
    },
    {
      title: 'Elève',
      dataIndex: ['eleve', 'id'],
      key: 'eleve',
      render: (text, record) => (record.eleve ? <Link to={`/eleve/${record.eleve.id}`}>{text}</Link> : ''),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => navigate(`/tarif/${record.id}`)} icon={<FontAwesomeIcon icon={faEye} />} />
          <Button
            type="link"
            onClick={() =>
              navigate(`/tarif/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
          />
          <Button
            type="link"
            danger
            onClick={() =>
              navigate(
                `/tarif/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
              )
            }
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="tarif-heading" data-cy="TarifHeading">
        Tarifs
        <div className="d-flex justify-content-end " style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button onClick={handleSyncList} loading={loading} icon={<SyncOutlined />}>
            Actualiser la liste
          </Button>
          <Button type="primary" icon={<PlusOutlined />} style={{ marginLeft: 8 }}>
            <Link to="/tarif/new">Créer un nouveau Tarif</Link>
          </Button>
        </div>
      </h2>
      <div className="table-responsive">
        {tarifList && tarifList.length > 0 ? (
          <Table dataSource={tarifList} columns={columns} rowKey="id" pagination={false} loading={loading} />
        ) : (
          !loading && <div className="alert alert-warning">Aucun Tarif trouvé</div>
        )}
      </div>
      {totalItems ? (
        <div className={tarifList && tarifList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <Pagination
              current={paginationState.activePage}
              pageSize={paginationState.itemsPerPage}
              total={totalItems}
              onChange={handlePagination}
              showSizeChanger={false}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Tarif;
