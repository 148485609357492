import { IChoice } from 'app/shared/model/choice.model';
import { IQuiz } from 'app/shared/model/quiz.model';

export interface IQuestion {
  id?: number;
  text?: string;
  choices?: IChoice[];
  quiz?: IQuiz;
  quizId?: number;
}

export const defaultValue: Readonly<IQuestion> = {};
