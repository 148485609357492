import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Eleve from './eleve';
import EleveDetail from './eleve-detail';
import EleveUpdate from './eleve-update';
import EleveDeleteDialog from './eleve-delete-dialog';

const EleveRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Eleve />} />
    <Route path="new" element={<EleveUpdate />} />
    <Route path=":id">
      <Route index element={<EleveDetail />} />
      <Route path="edit" element={<EleveUpdate />} />
      <Route path="delete" element={<EleveDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EleveRoutes;
