export enum Mois {
  JAN = 'JAN',

  FEV = 'FEV',

  MAR = 'MAR',

  AVR = 'AVR',

  MAI = 'MAI',

  JUI = 'JUI',

  AOU = 'AOU',

  SEP = 'SEP',

  OCT = 'OCT',

  NOV = 'NOV',

  DEC = 'DEC',
}
