import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { createAsyncThunk, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
  defaultValueTransmissionResponse,
  ITransmissionRequest,
  ITransmissionResponse,
} from 'app/shared/model/cahier_transmission_model/transmission.model';

const apiUrl = 'api/transmission';

const initialState: EntityState<ITransmissionResponse> & {
  errorMessage: string | null;
  entities: ITransmissionResponse[];
  totalItems: number;
  entity: ITransmissionResponse;
  statusFormAdd: boolean;
  totalTransmissions: number;
  showTransmission: boolean;
  updateForm: boolean;
  updateTransmissionSuccess: boolean;
  loadTransmissions: boolean;
  loading: boolean;
  loadOneTransmission: boolean;
  loadAddTransmission: boolean;
  validatingload: boolean;
  addTransmissionSuccess: boolean;
  deleteTransmissionSuccess: boolean;
  loadUpdateTransmission: boolean;
  getTransmissionsErrorMessage: string | null;
  getOneTransmissionErrorMessage: string | null;
} = {
  stateTwo: undefined,
  updateSuccess: false,
  updating: false,
  loading: false,
  errorMessage: null,
  entities: [],
  totalItems: 0,
  entity: defaultValueTransmissionResponse,
  statusFormAdd: true,
  totalTransmissions: 0,
  showTransmission: false,
  updateForm: false,
  updateTransmissionSuccess: false,
  loadTransmissions: false,
  loadOneTransmission: false,
  loadAddTransmission: false,
  validatingload: false,
  addTransmissionSuccess: false,
  deleteTransmissionSuccess: false,
  loadUpdateTransmission: false,
  getTransmissionsErrorMessage: null,
  getOneTransmissionErrorMessage: null,
};

export const getTransmissions = createAsyncThunk('transmission/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ITransmissionResponse[]>(requestUrl);
});

export const getOneTransmission = createAsyncThunk(
  'transmission/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITransmissionResponse>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const addTransmission = createAsyncThunk(
  'transmission/add_entity',
  async (transmission: ITransmissionRequest, thunkAPI) => {
    const result = await axios.post<ITransmissionResponse>(apiUrl, transmission);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateTransmission = createAsyncThunk(
  'transmission/update_entity',
  async (transmission: ITransmissionRequest, thunkAPI) => {
    const requestUrl = `${apiUrl}/${transmission.id}`;
    const result = await axios.put<ITransmissionResponse>(requestUrl, transmission);
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteTransmission = createAsyncThunk(
  'transmission/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete(requestUrl);
    thunkAPI.dispatch(getTransmissions({ query: `withRealAnneeScolaire=${false}` })); // Refresh list after deleting
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const validateTransmission = createAsyncThunk(
  'transmission/validate_transmission',
  async ({ id }: { id: number }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}/validate`;
    const result = await axios.patch(requestUrl);
    thunkAPI.dispatch(
      getTransmissions({
        query: `withRealAnneeScolaire=${true}&validated=${false}&paged=false`,
      }),
    );
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const TransmissionSlice = createEntitySlice({
  name: 'transmission',
  initialState,
  reducers: {
    updateStatusForm(state: any, action: PayloadAction<Boolean>) {
      state.statusFormAdd = action.payload;
    },
    clearTransmission(state: any) {
      state.entity = initialState.entity;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getTransmissions.pending, (state: any) => {
        state.loadTransmissions = true;
      })
      .addCase(getTransmissions.fulfilled, (state: any, action) => {
        const { data, headers } = action.payload;
        state.loadTransmissions = false;
        state.entities = data;
        state.totalItems = parseInt(headers['x-total-count'], 10);
      })
      .addCase(getTransmissions.rejected, (state: any, action) => {
        state.loadTransmissions = false;
        state.getTransmissionsErrorMessage = action.error.message;
      })
      .addCase(getOneTransmission.pending, (state: any) => {
        state.loadOneTransmission = true;
      })
      .addCase(getOneTransmission.fulfilled, (state: any, action) => {
        state.loadOneTransmission = false;
        state.entity = action.payload.data;
      })
      .addCase(getOneTransmission.rejected, (state: any, action) => {
        state.loadOneTransmission = false;
        state.getOneTransmissionErrorMessage = action.error.message;
      })
      .addCase(addTransmission.pending, (state: any) => {
        state.addTransmissionSuccess = false;
        state.loadAddTransmission = true;
      })
      .addCase(addTransmission.fulfilled, (state: any, action) => {
        state.loadAddTransmission = false;
        state.addTransmissionSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(addTransmission.rejected, (state: any, action) => {
        state.loadAddTransmission = false;
        state.addTransmissionSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(updateTransmission.pending, (state: any) => {
        state.loadUpdateTransmission = true;
        state.updateTransmissionSuccess = false;
      })
      .addCase(updateTransmission.fulfilled, (state: any, action) => {
        state.loadUpdateTransmission = false;
        state.updateTransmissionSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateTransmission.rejected, (state: any, action) => {
        state.loadUpdateTransmission = false;
        state.updateTransmissionSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(deleteTransmission.pending, (state: any) => {
        state.loadUpdateTransmission = true;
        state.deleteTransmissionSuccess = false;
      })
      .addCase(deleteTransmission.fulfilled, (state: any, action) => {
        state.loadUpdateTransmission = false;
        state.deleteTransmissionSuccess = true;
      })
      .addCase(deleteTransmission.rejected, (state: any, action) => {
        state.loadUpdateTransmission = false;
        state.deleteTransmissionSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addMatcher(isFulfilled(validateTransmission), (state, action) => {
        state.validatingload = false;
      })
      .addMatcher(isPending(validateTransmission), state => {
        state.validatingload = true;
      });
  },
});

export const { updateStatusForm, clearTransmission, reset } = TransmissionSlice.actions;

export default TransmissionSlice.reducer;
