export interface IPageMobile {
  id?: number;
  pageName?: string;
  code?: string;
  isActive?: boolean;
  createdAt?: string;
}

export const defaultValue: Readonly<IPageMobile> = {
  isActive: true,
};
