import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Pagination, Space, Select, Alert } from 'antd';
import { SyncOutlined, PlusOutlined, SortAscendingOutlined, SortDescendingOutlined } from '@ant-design/icons';
import { getPaginationState, TextFormat } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities } from './message.reducer';
import dayjs from 'dayjs';
import { GoLoading } from 'app/shared/loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';

const { Option } = Select;

export const Message = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedUser, setSelectedUser] = useState('');

  const messageList = useAppSelector(state => state.message.entities);
  const loading = useAppSelector(state => state.message.loading);
  const totalItems = useAppSelector(state => state.message.totalItems);
  const users = useAppSelector(state => state.userManagement.users);

  const getAllEntities = () => {
    const filter = selectedUser ? `userId.equals=${selectedUser}` : '';
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: filter,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedUser]);

  useEffect(() => {
    dispatch(getUsers({}));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleUserChange = value => {
    setSelectedUser(value);
  };

  const getSortIconByFieldName = fieldName => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return <SortAscendingOutlined />;
    } else {
      return order === ASC ? <SortAscendingOutlined /> : <SortDescendingOutlined />;
    }
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: text => (
        <Button type="link" onClick={() => navigate(`/message/${text}`)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Contenu',
      dataIndex: 'contenu',
      sorter: true,
    },
    {
      title: 'Date Envoi',
      dataIndex: 'dateEnvoi',
      sorter: true,
      render: date => (date ? dayjs(date).format('YYYY-MM-DD') : null),
    },
    {
      title: 'Lu',
      dataIndex: 'isRead',
      render: isRead => (isRead ? 'Lu' : 'Non lu'),
    },
    {
      title: 'Sender',
      dataIndex: 'messageSender',
      render: messageSender => (messageSender === 'USER' ? 'Utilisateur' : 'Administration'),
    },
    {
      title: 'Actions',
      render: (text, record) => (
        <Space size="middle">
          <Link to={`/message/${record.id}`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faEye} />}></Button>
          </Link>
          <Link to={`/message/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faPencilAlt} />} style={{ color: 'green' }}></Button>
          </Link>
          <Button
            icon={<FontAwesomeIcon icon={faTrash} />}
            type="link"
            danger
            onClick={() =>
              (window.location.href = `/message/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`)
            }
          ></Button>
        </Space>
      ),
    },
  ];

  return (
    <div className="container">
      <h2 id="message-heading" data-cy="MessageHeading">
        Messages
      </h2>
      <div className="d-flex justify-content-end mb-3">
        <Button className="me-2" icon={<SyncOutlined spin={loading} />} onClick={handleSyncList} disabled={loading}>
          Actualiser la liste
        </Button>
        <Button type="primary" icon={<PlusOutlined />} onClick={() => navigate('/message/new')}>
          Créer un nouveau Message
        </Button>
      </div>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Space size="large">
          Sélectionner utilisateur:
          <Select
            showSearch
            value={selectedUser}
            onChange={handleUserChange}
            placeholder="Utilisateur"
            style={{ width: 200 }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {users.map(user => (
              <Option key={user.id} value={user.id}>
                {user.login}
              </Option>
            ))}
          </Select>
        </Space>
      </div>
      <div className="table-responsive mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : messageList && messageList.length > 0 ? (
          <Table columns={columns} dataSource={messageList} rowKey="id" loading={loading} pagination={false} />
        ) : (
          !loading && <div className="alert alert-warning mt-4">Aucun Message trouvé</div>
        )}
      </div>
      {!loading && totalItems && messageList && messageList.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePagination}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Message;
