import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { byteSize, openFile } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './import-eleve.reducer';

export const ImportEleveDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const importEleveEntity = useAppSelector(state => state.importEleve.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="importEleveDetailsHeading">Import Eleve</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{importEleveEntity.id}</dd>
          <dt>
            <span id="fichier">Fichier</span>
          </dt>
          <dd>
            {importEleveEntity.fichier ? (
              <div>
                {importEleveEntity.fichierContentType ? (
                  <a onClick={openFile(importEleveEntity.fichierContentType, importEleveEntity.fichier)}>Ouvrir&nbsp;</a>
                ) : null}
                <span>
                  {importEleveEntity.fichierContentType}, {byteSize(importEleveEntity.fichier)}
                </span>
              </div>
            ) : null}
          </dd>
        </dl>
        <Button tag={Link} to="/import-eleve" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/import-eleve/${importEleveEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default ImportEleveDetail;
