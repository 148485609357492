import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Ressource from './ressource';
import RessourceDetail from './ressource-detail';
import RessourceUpdate from './ressource-update';
import RessourceDeleteDialog from './ressource-delete-dialog';

const RessourceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Ressource/>}/>
    <Route path="new" element={<RessourceUpdate/>}/>
    <Route path=":id">
      <Route index element={<RessourceDetail/>}/>
      <Route path="edit" element={<RessourceUpdate/>}/>
      <Route path="delete" element={<RessourceDeleteDialog/>}/>
    </Route>
  </ErrorBoundaryRoutes>
);

export default RessourceRoutes;
