import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getPaginationState } from 'react-jhipster';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { useLocation } from 'react-router-dom';
import { Table } from 'reactstrap';
import { InputNumber } from 'antd';
import { createListTarifEntity, getEntities as getTarifs } from 'app/entities/tarif/tarif.reducer';
import { ITarif } from 'app/shared/model/tarif.model';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';
import _ from 'lodash';

const UpdateTarifSerivice = ({ show, handleClose, niveau }) => {
  const tarifList = useAppSelector(state => state.tarif.entities) as ITarif[];
  const tarifLoading = useAppSelector(state => state.tarif.loading);
  const [tarifs, setTarifs] = useState([] as ITarif[]);
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const serviceEtablisementList = useAppSelector(state => state.serviceEtablisement.entities) as IServiceEtablisement[];
  const loading = useAppSelector(state => state.serviceEtablisement.loading);

  const handleChange = (index, value, serviceEtablisement, id) => {
    const newInputs = [...tarifs]; // Copie du tableau d'inputs
    newInputs[index] = { id: id, prix: value, serviceEtablisement: serviceEtablisement, niveau: niveau }; // Mise à jour de la valeur de l'input à l'index spécifié
    setTarifs(newInputs);
  };

  useEffect(() => {
    function serviceExiste(arr: ITarif[], libelle) {
      return arr.some(function (el) {
        return el?.serviceEtablisement?.libelle === libelle;
      });
    }

    if (tarifList) {
      var tarifCopie = _.clone(tarifList);

      serviceEtablisementList?.forEach(value => {
        if (!serviceExiste(tarifCopie, value.libelle)) {
          tarifCopie?.push({ id: null, prix: 0, serviceEtablisement: value, niveau: niveau });
        }
      });
      tarifCopie.sort((a, b) => {
        if (a.serviceEtablisement?.libelle < b.serviceEtablisement?.libelle) return -1;
        if (a.serviceEtablisement?.libelle > b.serviceEtablisement?.libelle) return 1;
        return 0;
      });

      setTarifs(tarifCopie);
    }
  }, [tarifList]);

  useEffect(() => {
    if (niveau?.id) {
      dispatch(
        getTarifs({
          query: 'niveauId.equals=' + niveau?.id,
        }),
      );
      // dispatch(getServiceEtablissementEntities({  query: 'niveauId.equals=' + niveau?.id, }));
    }

    // dispatch(getTarifsByNiveauAndServiceEtablisementWithoutEleve({ niveauId: niveau?.id }));
  }, [niveau?.id ?? '']);

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = event => {
    event.preventDefault();
    dispatch(createListTarifEntity(tarifs));
    handleClose();
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un nouveau Niveau</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/*<CreateServiceAffectationToLevelModal niveau={niveau} close={handleClose} />*/}
        <form onSubmit={handleSubmit}>
          <div className="table-responsive">
            {serviceEtablisementList && serviceEtablisementList.length > 0 ? (
              <Table responsive>
                <thead>
                  <tr>
                    <th className="hand">Libelle</th>
                    <th className="hand">Type</th>
                    <th className="hand">Tarif</th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {tarifs.map((tarif, i) => (
                    <tr key={`entity-${i}`} data-cy="entityTable">
                      <td>{tarif?.serviceEtablisement?.libelle}</td>
                      <td>{tarif?.serviceEtablisement?.type}</td>
                      <td>
                        <InputNumber value={tarif.prix} onChange={event => handleChange(i, event, tarif.serviceEtablisement, tarif.id)} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              !loading && <div className="alert alert-warning">Aucun Service Etablisement trouvé</div>
            )}
          </div>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Annuler
            </Button>
            <Button type="submit">Valider</Button>
          </Modal.Footer>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateTarifSerivice;
