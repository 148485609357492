import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedBlobField, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEtatServiceMois } from 'app/entities/etat-service-mois/etat-service-mois.reducer';
import { ModeReglement } from 'app/shared/model/enumerations/mode-reglement.model';
import { createEntity, getEntity, reset, updateEntity } from './caisse.reducer';
import { Spin } from 'antd';

export const CaisseUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const etatServiceMois = useAppSelector(state => state.etatServiceMois.entities);
  const caisseEntity = useAppSelector(state => state.caisse.entity);
  const loading = useAppSelector(state => state.caisse.loading);
  const updating = useAppSelector(state => state.caisse.updating);
  const updateSuccess = useAppSelector(state => state.caisse.updateSuccess);
  const modeReglementValues = Object.keys(ModeReglement);

  const handleClose = () => {
    navigate('/caisse' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getEtatServiceMois({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.montant !== undefined && typeof values.montant !== 'number') {
      values.montant = Number(values.montant);
    }
    if (values.montantSortie !== undefined && typeof values.montantSortie !== 'number') {
      values.montantSortie = Number(values.montantSortie);
    }

    const entity = {
      ...caisseEntity,
      ...values,
      etatServiceMois: etatServiceMois.find(it => it.id.toString() === values.etatServiceMois?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          type: 'CHEQUE',
          ...caisseEntity,
          etatServiceMois: caisseEntity?.etatServiceMois?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.caisse.home.createOrEditLabel" data-cy="CaisseCreateUpdateHeading">
            Créer ou éditer un Caisse
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <Spin size="large" />
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? <ValidatedField name="id" required readOnly id="caisse-id" label="Numéro" validate={{ required: true }} /> : null}
              <ValidatedField label="Statut" id="caisse-statut" name="statut" data-cy="statut" check type="checkbox" />
              <ValidatedField label="Montant" id="caisse-montant" name="montant" data-cy="montant" type="text" />
              <ValidatedField label="Montant Sortie" id="caisse-montantSortie" name="montantSortie" data-cy="montantSortie" type="text" />
              <ValidatedField label="Type" id="caisse-type" name="type" data-cy="type" type="select">
                {modeReglementValues.map(modeReglement => (
                  <option value={modeReglement} key={modeReglement}>
                    {modeReglement}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField label="Date" id="caisse-date" name="date" data-cy="date" type="date" />
              <ValidatedField label="Num Cheque" id="caisse-numCheque" name="numCheque" data-cy="numCheque" type="text" />
              <ValidatedBlobField
                label="Photo Cheque"
                id="caisse-photoCheque"
                name="photoCheque"
                data-cy="photoCheque"
                isImage
                accept="image/*"
              />
              <ValidatedField
                id="caisse-etatServiceMois"
                name="etatServiceMois"
                data-cy="etatServiceMois"
                label="Etat Service Mois"
                type="select"
              >
                <option value="" key="0" />
                {etatServiceMois
                  ? etatServiceMois.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.montant}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/caisse" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CaisseUpdate;
