import dayjs from 'dayjs';
import { IProf } from 'app/shared/model/prof.model';
import { IMatiere } from 'app/shared/model/matiere.model';
import { IGroupe } from 'app/shared/model/groupe.model';

export interface IDevoirSurvRequest {
  id?: number;
  titre?: string | null;
  global?: boolean;
  dateLimite?: dayjs.Dayjs | null;
  profId?: number | null;
  matiereId?: number | null;
  groupeIds?: number[] | null;
  realAnneeScolaire?: string | null;
  anneeScolaireId?: string | null;
}

export interface IDevoirSurvResponse {
  id?: number;
  titre?: string | null;
  global?: boolean;
  dateLimite?: dayjs.Dayjs | null;
  prof?: IProf | null;
  matiere?: IMatiere | null;
  groupes?: IGroupe[] | null;
  realAnneeScolaire?: string | null;
  anneeScolaireId?: string | null;
}

export const defaultValueDevoirSurvRequest: Readonly<IDevoirSurvRequest> = {};
export const defaultValueDevoirSurvResponse: Readonly<IDevoirSurvResponse> = {};
