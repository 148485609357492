import dayjs, { Dayjs } from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { IGroupe } from 'app/shared/model/groupe.model';
import { IDevoir } from 'app/shared/model/devoir.model';
import { IServiceEtablisement } from 'app/shared/model/service-etablisement.model';
import { IControle } from 'app/shared/model/controle.model';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { IRessource } from 'app/shared/model/ressource.model';
import { IGalerie } from 'app/shared/model/galerie.model';
import { Sexe } from 'app/shared/model/enumerations/sexe.model';
import { ITarif } from 'app/shared/model/tarif.model';
import { Mois } from 'app/shared/model/enumerations/mois.model';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';

export interface IEleve {
  id?: number;
  nom?: string | null;
  prenom?: string | null;
  dateNaissance?: Dayjs | null;
  lieuNaissance?: string | null;
  codeMassar?: string | null;
  sexe?: keyof typeof Sexe | null;
  photoContentType?: string | null;
  photo?: string | null;
  cin?: string | null;
  dateDepart?: dayjs.Dayjs | null;
  raisonDepart?: string | null;
  observation?: string | null;
  mois?: Mois[] | null;
  anneeScolaireId?: number | null;
  niveauId?: number | null;
  internalUser?: IUser | null;
  groupe?: IGroupe | null;
  devoirs?: IDevoir[] | null;
  serviceEtablisements?: IServiceEtablisement[] | null;
  controles?: IControle[] | null;
  anneeScolaires?: IAnneeScolaire[] | null;
  tuteurs?: ITuteur[] | null;
  resources?: IRessource[] | null;
  galeries?: IGalerie[] | null;
  tarifs?: ITarif[] | null;
  beginPaiement?: boolean | null;
}

export const defaultValue: Readonly<IEleve> = {};
