import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, Form, Select, Upload, Table as AntTable, Button, Input } from 'antd'; // Importing Ant Design components
import { ValidatedBlobField, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Papa from 'papaparse';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, reset, updateEntity } from './import-eleve.reducer';
import { getEntities as getGroupesEntities } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getNiveauEntities } from 'app/entities/niveau/niveau.reducer';

export const ImportEleveUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = true;

  const loading = useAppSelector(state => state.importEleve.loading);
  const updating = useAppSelector(state => state.importEleve.updating);
  const updateSuccess = useAppSelector(state => state.importEleve.updateSuccess);

  const groupeList = useAppSelector(state => state.groupe.entities);
  const niveauList = useAppSelector(state => state.niveau.entities);

  const [selectedNiveau, setSelectedNiveau] = useState('');
  const [parsedData, setParsedData] = useState([]);
  const [tableRows, setTableRows] = useState([]);
  const [values, setValues] = useState([]);

  const handleClose = () => {
    setParsedData(null);
    setTableRows(null);
    setValues(null);
  };

  const getAllEntities = () => {
    dispatch(getGroupesEntities({}));
    dispatch(getNiveauEntities({}));
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    getAllEntities();
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      setParsedData([]);
      setTableRows([]);
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleNiveauChange = value => {
    setSelectedNiveau(value);
  };

  const changeHandler = info => {
    Papa.parse(info.file.originFileObj, {
      header: true,
      encoding: 'ISO-8859-1',
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        results.data.map(d => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        setParsedData(results.data);
        setTableRows(rowsArray[0]);
        setValues(valuesArray);
      },
    });
  };

  const filteredGroupeList = groupeList.filter(groupe => groupe.niveau && groupe.niveau.id.toString() === selectedNiveau);

  return (
    <div>
      <Row justify="center">
        <Col md="12">
          <h2 id="goSchoolApp.importEleve.home.createOrEditLabel" data-cy="ImportEleveCreateUpdateHeading">
            Import des élèves
          </h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col md="4">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form onFinish={saveEntity}>
              {!isNew ? (
                <Form.Item name="id" label="Numéro" rules={[{ required: true }]}>
                  <Input readOnly />
                </Form.Item>
              ) : null}

              <Form.Item
                name="niveau"
                label="Niveau"
                rules={[{ required: true, message: 'niveau est obligatoir' }]} // Required validation
              >
                <Select onChange={handleNiveauChange}>
                  <Select.Option value="" key="0" children={undefined} />
                  {niveauList.map(niveau => (
                    <Select.Option key={niveau.id} value={niveau.id}>
                      {niveau.libelle}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="groupe"
                label="Groupe"
                rules={[{ required: true, message: 'groupe est obligatoir' }]} // Required validation
              >
                <Select>
                  <Select.Option value="" key="0" children={undefined} />
                  {filteredGroupeList.map(groupe => (
                    <Select.Option key={groupe.id} value={groupe.id}>
                      {groupe.nom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name="fichier"
                label="Fichier"
                valuePropName="fileList"
                getValueFromEvent={e => (Array.isArray(e) ? e : e && e.fileList)}
                rules={[{ required: true, message: 'champ obligatoir' }]} // Required validation
              >
                <Upload.Dragger name="files" customRequest={changeHandler}>
                  <p className="ant-upload-drag-icon">
                    <FontAwesomeIcon icon="upload" />
                  </p>
                  <p className="ant-upload-text">Cliquez ou faites glisser le fichier pour le télécharger</p>
                </Upload.Dragger>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
        <Col md="8">
          <div className="table-responsive">
            {tableRows && tableRows.length > 0 ? (
              <AntTable dataSource={values} columns={tableRows.map((row, index) => ({ title: row, dataIndex: index }))} />
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImportEleveUpdate;
