import React, { useEffect } from 'react';
import { Tabs, Typography } from 'antd';
import { Badge } from 'reactstrap';
import DevoirNotePerGroupe from 'app/entities/gestion-notes/DevoirNotePerGroupe';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IDevoirSurvResponse } from 'app/shared/model/gestionNote/devoir-surv.model';
import { getOneDevoirSurv, reset } from 'app/entities/gestion-notes/devoirSurv.reducer';
import { getEntities as getElevesOfGroupe } from 'app/entities/eleve/eleve.reducer';

const DevoirNote = () => {
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const dispatch = useAppDispatch();
  const oneDevoirSurv = useAppSelector(state => state.devoirSurv.entity) as IDevoirSurvResponse;

  useEffect(() => {
    dispatch(reset());
    dispatch(getOneDevoirSurv(id));
  }, []);

  const items =
    oneDevoirSurv?.groupes?.map((groupe, index) => ({
      label: `${groupe.nom}`,
      key: `${groupe?.id}`,
      children: <DevoirNotePerGroupe groupeId={groupe?.id} />,
    })) || [];

  const handleTabChange = groupeId => {
    dispatch(getElevesOfGroupe({ groupeId }));
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Badge color="info">
          <Typography.Text style={{ fontSize: '28px' }}>{oneDevoirSurv?.titre}</Typography.Text>
        </Badge>
      </div>
      <Tabs
        defaultActiveKey={oneDevoirSurv?.groupes?.[0]?.id?.toString()}
        onChange={handleTabChange}
        type="card"
        size={'large'}
        items={items}
      />
    </div>
  );
};
export default DevoirNote;
