import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {byteSize, openFile, TextFormat} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {APP_LOCAL_DATE_FORMAT} from 'app/config/constants';
import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './caisse.reducer';

export const CaisseDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const caisseEntity = useAppSelector(state => state.caisse.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="caisseDetailsHeading">Caisse</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{caisseEntity.id}</dd>
          <dt>
            <span id="statut">Statut</span>
          </dt>
          <dd>{caisseEntity.statut ? 'true' : 'false'}</dd>
          <dt>
            <span id="montant">Montant</span>
          </dt>
          <dd>{caisseEntity.montant}</dd>
          <dt>
            <span id="montantSortie">Montant Sortie</span>
          </dt>
          <dd>{caisseEntity.montantSortie}</dd>
          <dt>
            <span id="type">Type</span>
          </dt>
          <dd>{caisseEntity.type}</dd>
          <dt>
            <span id="date">Date</span>
          </dt>
          <dd>{caisseEntity.date ? <TextFormat value={caisseEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="numCheque">Num Cheque</span>
          </dt>
          <dd>{caisseEntity.numCheque}</dd>
          <dt>
            <span id="photoCheque">Photo Cheque</span>
          </dt>
          <dd>
            {caisseEntity.photoCheque ? (
              <div>
                {caisseEntity.photoChequeContentType ? (
                  <a onClick={openFile(caisseEntity.photoChequeContentType, caisseEntity.photoCheque)}>
                    <img
                      src={`data:${caisseEntity.photoChequeContentType};base64,${caisseEntity.photoCheque}`}
                      style={{maxHeight: '30px'}}
                    />
                  </a>
                ) : null}
                <span>
                  {caisseEntity.photoChequeContentType}, {byteSize(caisseEntity.photoCheque)}
                </span>
              </div>
            ) : null}
          </dd>
          <dt>Etat Service Mois</dt>
          <dd>{caisseEntity.etatServiceMois ? caisseEntity.etatServiceMois.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/caisse" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/caisse/${caisseEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default CaisseDetail;
