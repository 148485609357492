import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Badge, Col, Row } from 'reactstrap';
import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { createEntity, getEntity, reset, updateEntity } from './tuteur.reducer';
import { Checkbox, Form, Input, Select, Descriptions, Typography } from 'antd';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';

const { Option } = Select;

export const TuteurUpdate = ({ toggleModal }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const [form] = Form.useForm();
  const isNew = id === undefined;

  const eleves = useAppSelector(state => state.eleve.entities);
  const tuteurEntity = useAppSelector(state => state.tuteur.entity);
  const loading = useAppSelector(state => state.tuteur.loading);
  const updating = useAppSelector(state => state.tuteur.updating);
  const updateSuccess = useAppSelector(state => state.tuteur.updateSuccess);
  const [password, setPassword] = useState('');

  const handleClose = () => {
    navigate('/tuteur' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
      let query = '';
      if (tuteurEntity) {
        query += `tuteurId.equals=${id}`;
      }
      dispatch(getEleves({ query }));
    }
    dispatch(getUsers({}));
  }, [dispatch, id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...tuteurEntity,
      ...values,
      internalUser: {
        ...tuteurEntity.internalUser,
        firstName: values.firstName,
        lastName: values.lastName,
        login: values.login,
        password: values.password,
      },
      relationAvecEleve: values.relationAvecEleve,
      enfants: tuteurEntity.enfants,
      principale: tuteurEntity.principale,
      activated: values.activated !== undefined ? values.activated : false,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...tuteurEntity,
          internalUser: tuteurEntity?.internalUser?.id,
          enfants: tuteurEntity?.enfants?.map(e => e.id.toString()),
          activated: tuteurEntity?.activated,
        };

  useEffect(() => {
    form.setFieldsValue(defaultValues());
  }, [tuteurEntity]);

  useEffect(() => {
    const currentLogin = form.getFieldValue('login');
    const currentPassword = form.getFieldValue('password');

    if (!currentLogin && form.getFieldValue('tel')) {
      form.setFieldsValue({
        login: form.getFieldValue('tel'),
      });
    }

    if (!currentPassword && form.getFieldValue('tel')) {
      form.setFieldsValue({
        password: form.getFieldValue('tel'),
      });
    }
  }, [form.getFieldValue('tel')]);

  const handleTelChange = e => {
    const tel = e.target.value;
    form.setFieldsValue({
      login: tel,
      password: tel,
    });
  };

  const updatePassword = event => setPassword(event.target.value);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.tuteur.home.createOrEditLabel" data-cy="TuteurCreateUpdateHeading">
            Créer ou éditer un Tuteur
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              initialValues={defaultValues()}
              onFinish={saveEntity}
              autoComplete="off"
            >
              {!isNew && (
                <Form.Item label="Numéro" name="id" rules={[{ required: true, message: 'ID est obligatoire!' }]}>
                  <Input readOnly />
                </Form.Item>
              )}
              <Form.Item label="Nom complet" name="firstName" rules={[{ required: true, message: 'Veuillez entrer le nom complet!' }]}>
                <Input />
              </Form.Item>
              <Form.Item
                label="Tel"
                name="tel"
                rules={[
                  { required: true, message: 'Veuillez entrer le numéro de téléphone!' },
                  { pattern: /^\d{10}$/, message: 'Veuillez entrer un numéro de téléphone valide!' },
                ]}
              >
                <Input onChange={handleTelChange} />
              </Form.Item>
              <Form.Item label="Email" name="email" rules={[{ type: 'email', message: 'Veuillez entrer un email valide!' }]}>
                <Input />
              </Form.Item>
              <Form.Item label="Login" name="login" rules={[{ required: true, message: 'Veuillez entrer le login!' }]}>
                <Input />
              </Form.Item>
              {isNew || tuteurEntity.principale ? (
                <>
                  <Form.Item
                    label="Password"
                    name="password"
                    rules={[
                      { required: false, message: 'Votre mot de passe est requis.' },
                      { min: 4, message: 'Votre mot de passe doit comporter au moins 4 caractères.' },
                      { max: 50, message: 'Votre mot de passe ne doit pas comporter plus de 50 caractères.' },
                    ]}
                  >
                    <Input.Password onChange={updatePassword} />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <PasswordStrengthBar password={password} />
                  </Form.Item>
                </>
              ) : null}
              <Form.Item label="Observation" name="observation">
                <Input />
              </Form.Item>
              <Form.Item label="CIN" name="cin">
                <Input />
              </Form.Item>
              <Form.Item label="Adresse" name="adresse">
                <Input />
              </Form.Item>
              {isNew || tuteurEntity.principale ? (
                <Form.Item label="Activé" name="activated" valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              ) : null}
              {!isNew ? (
                <>
                  <Form.Item label="Relation avec l'élève" name="relationAvecEleve">
                    <Select>
                      <Option value="pere">Père</Option>
                      <Option value="mere">Mère</Option>
                      <Option value="autre">Autre</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Enfant" name="enfants">
                    <Descriptions size="small">
                      <Descriptions.Item>
                        {tuteurEntity.enfants?.map((enfant, i) => (
                          <>
                            <span key={`user-auth-${i}`} style={{ margin: '3px' }}>
                              <Badge color="info">
                                <Typography.Text style={{ fontSize: '12px' }}>
                                  {enfant.nom} {enfant.prenom}
                                </Typography.Text>
                              </Badge>
                            </span>
                          </>
                        ))}
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>
                </>
              ) : null}
              <Link to="/tuteur">
                <Button type="link" id="cancel-save" data-cy="entityCreateCancelButton" color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Retour</span>
                </Button>
              </Link>
              &nbsp;
              <Button type="primary" id="save-entity" data-cy="entityCreateSaveButton" htmlType="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TuteurUpdate;
