import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Carousel, Col, List, Pagination, Row, Select, Space, Tag, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faEdit,
  faEye,
  faPencilAlt,
  faPlus,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { getPaginationState, openFile } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { GoLoading } from 'app/shared/loading';
import { getEntities } from './galerie.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { IGalerie } from 'app/shared/model/galerie.model';
import JSZip from 'jszip';

const { Option } = Select;
const { Text, Title } = Typography;

export const Galerie = () => {
  const dispatch = useAppDispatch();
  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );
  const [selectedEleve, setSelectedEleve] = useState('');

  const galerieList = useAppSelector(state => state.galerie.entities);
  const loading = useAppSelector(state => state.galerie.loading);
  const totalItems = useAppSelector(state => state.galerie.totalItems);
  const eleves = useAppSelector(state => state.eleve.entities);

  const getAllEntities = () => {
    const withRealAnneeScolaire = false;

    let filters = selectedEleve ? `eleveId.equals=${selectedEleve}` : ``;

    if (filters) {
      filters += `&withRealAnneeScolaire=${withRealAnneeScolaire}`;
    } else {
      filters = `withRealAnneeScolaire=${withRealAnneeScolaire}`;
    }
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query: filters,
      }),
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, selectedEleve]);

  useEffect(() => {
    dispatch(getEleves({}));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  const handleEleveChange = (value: string) => {
    setSelectedEleve(value);
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = paginationState.sort;
    const order = paginationState.order;
    if (sortFieldName !== fieldName) {
      return <FontAwesomeIcon icon={faSort} />;
    } else {
      return order === ASC ? <FontAwesomeIcon icon={faSortUp} /> : <FontAwesomeIcon icon={faSortDown} />;
    }
  };

  const columns = [
    {
      title: 'Code',
      dataIndex: 'id',
      sorter: true,
      render: (text: string) => <Button type="link">{text}</Button>,
    },
    {
      title: 'Nom',
      dataIndex: 'nom',
      sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      sorter: true,
      render: (date: string) => (date ? dayjs(date).format('YYYY-MM-DD') : null),
    },
    {
      title: 'Image',
      dataIndex: 'image',
      render: (image: string, record: any) => (
        <div>
          {record.images &&
            record.images.length > 0 &&
            record.images.map((image, index) => (
              <div key={index}>
                <a onClick={() => openFile(image.photoContentType, image.photo)}>
                  <img src={`data:${image.photoContentType};base64,${image.photo}`} style={{ maxHeight: '30px' }} alt="Galerie Image" />
                </a>
                <span>{image.photoContentType}</span>
              </div>
            ))}
        </div>
      ),
    },
    {
      title: '',
      render: (text: any, record: any) => (
        <Space>
          <Link to={`/galerie/${record.id}`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faEye} />}>
              Voir
            </Button>
          </Link>
          <Link to={`/galerie/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}>
            <Button type="link" icon={<FontAwesomeIcon icon={faPencilAlt} />} style={{ color: 'green' }}>
              Editer
            </Button>
          </Link>
          <Link
            to={`/galerie/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
          >
            <Button type="link" icon={<FontAwesomeIcon icon={faTrash} />} danger>
              Supprimer
            </Button>
          </Link>
        </Space>
      ),
    },
  ];
  console.log(galerieList);
  return (
    <div className="container">
      <Tag bordered={false} style={{ padding: 15 }} className={'capitalize'} color="red">
        Galeries
      </Tag>
      <div className="d-flex justify-content-end mb-3">
        <Button className="me-2" icon={<FontAwesomeIcon icon={faSync} />} onClick={handleSyncList} disabled={loading}>
          Actualiser la liste
        </Button>
        <Link to="/galerie/new">
          <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
            Créer un nouveau Galerie
          </Button>
        </Link>
      </div>
      <div className="d-flex justify-content-center mt-5 mb-5">
        <Space size="large">
          Sélectionner élève:
          <Select
            showSearch
            value={selectedEleve}
            onChange={handleEleveChange}
            placeholder="Élève"
            style={{ width: 200 }}
            allowClear
            filterOption={(input, option) => {
              const text = option?.label || option?.children;
              return text?.toString().toLowerCase().includes(input.toLowerCase());
            }}
          >
            {eleves.map(eleve => (
              <Option key={eleve.id} value={eleve.id}>
                {eleve.nom} {eleve.prenom}
              </Option>
            ))}
          </Select>
        </Space>
      </div>
      <div className="mt-4">
        {loading ? (
          <GoLoading loading={loading} />
        ) : galerieList && galerieList.length > 0 ? (
          <List
            grid={{ gutter: 16, column: 3 }}
            dataSource={galerieList}
            renderItem={(item: IGalerie) => {
              const isDeadlinePassed = dayjs().isAfter(item?.date);
              return (
                <List.Item
                  key={item.id}
                  className="hoverable-list-item"
                  onClick={() => navigate(`/galerie/${item.id}`)}
                  style={{
                    padding: '20px',
                    borderRadius: '8px',
                    border: '1px solid #e8e8e8',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    backgroundColor: '#fff',
                    marginBottom: '16px',
                    cursor: 'pointer',
                  }}
                >
                  <Row justify="space-between" align="middle">
                    <Col span={12}>
                      <Badge
                        count={isDeadlinePassed ? 'Passé' : 'En cours'}
                        style={{
                          backgroundColor: isDeadlinePassed ? '#f5222d' : '#52c41a',
                          color: '#fff',
                          marginBottom: '8px',
                        }}
                      />
                      <Title level={5}>{dayjs(item?.date).format('DD MMM, YYYY')}</Title>
                      <Text strong>
                        <Text style={{ color: '#1DA57A', marginRight: 6 }}>{item?.nom}</Text>
                      </Text>
                      <Text type="secondary">{dayjs(item?.date).fromNow()}</Text>
                    </Col>
                    <Col span={8}>
                      <Carousel autoplay>
                        {item.images?.map((image, index) => (
                          <div key={index}>
                            <img
                              src={`data:${image.photoContentType};base64,${image.photo}`}
                              alt={`${item.nom} ${index + 1}`}
                              style={{
                                width: '100%',
                                height: 'auto',
                                maxHeight: '200px',
                                objectFit: 'cover',
                                borderRadius: '8px',
                              }}
                            />
                          </div>
                        ))}
                      </Carousel>
                    </Col>
                    <Col span={4}>
                      <Space size="small" style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          onClick={async e => {
                            e.stopPropagation();
                            if (item?.images) {
                              const zip = new JSZip();
                              const folder = zip.folder(item?.nom || 'images');

                              for (let i = 0; i < item.images.length; i++) {
                                const image = item.images[i];
                                const blobString = image.photo; // base64 encoded string
                                const fileType = image.photoContentType; // MIME type
                                const fileName = `${item?.nom || 'image'}_${i + 1}.${fileType?.split('/')[1] || 'jpg'}`; // Filename with extension

                                // Convert the base64 string to a byte array and add to the zip
                                const byteCharacters = atob(blobString || ''); // Decode base64 string
                                const byteNumbers = new Array(byteCharacters.length);
                                for (let j = 0; j < byteCharacters.length; j++) {
                                  byteNumbers[j] = byteCharacters.charCodeAt(j);
                                }
                                const byteArray = new Uint8Array(byteNumbers);

                                // Add file to the zip folder
                                folder?.file(fileName, byteArray, { binary: true });
                              }

                              // Generate the zip file and trigger download
                              const content = await zip.generateAsync({ type: 'blob' });
                              saveAs(content, `${item?.nom || 'images'}.zip`);
                            }
                          }}
                          type="link"
                          disabled={!item?.images || item.images.length === 0}
                          icon={<FontAwesomeIcon icon={faDownload} />}
                        />

                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/galerie/${item.id}/edit`);
                          }}
                          type="link"
                          icon={<FontAwesomeIcon icon={faEdit} />}
                          style={{ color: 'green' }}
                        />
                        <Button
                          onClick={e => {
                            e.stopPropagation();
                            navigate(`/galerie/${item.id}/delete`);
                          }}
                          type="link"
                          danger
                          icon={<FontAwesomeIcon icon={faTrash} />}
                        />
                      </Space>
                    </Col>
                  </Row>
                </List.Item>
              );
            }}
          />
        ) : (
          <Alert message="Aucun Galerie trouvé" type="warning" showIcon />
        )}
      </div>
      {!loading && totalItems && galerieList && galerieList.length > 0 && (
        <div className="d-flex justify-content-center mt-4">
          <Pagination
            current={paginationState.activePage}
            onChange={handlePagination}
            total={totalItems}
            pageSize={paginationState.itemsPerPage}
            showSizeChanger={false}
          />
        </div>
      )}
    </div>
  );
};

export default Galerie;
