import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getMatieres } from 'app/entities/matiere/matiere.reducer';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { createEntity, getEntity, reset, updateEntity } from './controle.reducer';
import { DatePicker, Form, Input, Select } from 'antd';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';

export const ControleUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const matieres = useAppSelector(state => state.matiere.entities);
  const eleves = useAppSelector(state => state.eleve.entities);
  const groupes = useAppSelector(state => state.groupe.entities);
  const controleEntity = useAppSelector(state => state.controle.entity);
  const loading = useAppSelector(state => state.controle.loading);
  const updating = useAppSelector(state => state.controle.updating);
  const updateSuccess = useAppSelector(state => state.controle.updateSuccess);

  const handleClose = () => {
    navigate('/controle' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getMatieres({}));
    dispatch(getEleves({}));
    dispatch(getGroupes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    values.duree = convertDateTimeToServer(values.duree);

    const entity = {
      ...controleEntity,
      ...values,
      matiere: matieres.find(it => it.id.toString() === values.matiere?.toString()),
      eleves: mapIdList(values.eleves),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {
          duree: displayDefaultDateTime(),
        }
      : {
          ...controleEntity,
          date: moment(controleEntity.date),
          matiere: controleEntity?.matiere?.nom,
          eleves: controleEntity?.eleves?.map(e => e.id.toString()),
        };

  return (
    <div>
      <Row justify="center">
        <Col md="8">
          <h2 id="goSchoolApp.controle.home.createOrEditLabel" data-cy="ControleCreateUpdateHeading">
            Créer ou éditer un Contrôle
          </h2>
        </Col>
      </Row>
      <Row justify="center">
        <Col md="8">
          {loading ? (
            <p>Chargement en cours...</p>
          ) : (
            <Form
              initialValues={defaultValues()}
              onFinish={saveEntity}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              style={{ maxWidth: 600 }}
              layout="horizontal"
            >
              {!isNew && (
                <Form.Item name="id" label="Numéro" rules={[{ required: true, message: 'ID requis' }]}>
                  <Input readOnly />
                </Form.Item>
              )}
              <Form.Item name="libelle" label="Libellé">
                <Input />
              </Form.Item>
              <Form.Item name="date" label="Date" rules={[{ required: true, message: 'Veuillez sélectionner une date !' }]}>
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
              </Form.Item>
              <Form.Item name="matiere" label="Matière" rules={[{ required: true, message: 'Veuillez sélectionner une matière !' }]}>
                <Select>
                  {matieres.map(otherEntity => (
                    <Select.Option value={otherEntity.id.toString()} key={otherEntity.nom}>
                      {otherEntity.nom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="eleves" label="Élèves" rules={[{ required: true, message: 'Veuillez sélectionner un groupe !' }]}>
                <Select>
                  {eleves.map(otherEntity => (
                    <Select.Option key={otherEntity.id.toString()} value={otherEntity.id.toString()}>
                      {otherEntity.nom}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Button onClick={handleClose} icon={<ArrowLeftOutlined />} style={{ marginRight: '8px' }}>
                Retour
              </Button>
              <Button type="primary" htmlType="submit" loading={updating} icon={<SaveOutlined />}>
                Sauvegarder
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ControleUpdate;
