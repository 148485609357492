import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Prof from './prof';
import ProfDetail from './prof-detail';
import ProfUpdate from './prof-update';
import ProfDeleteDialog from './prof-delete-dialog';

const ProfRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Prof/>}/>
    <Route path="new" element={<ProfUpdate/>}/>
    <Route path=":id">
      <Route index element={<ProfDetail/>}/>
      <Route path="edit" element={<ProfUpdate/>}/>
      <Route path="delete" element={<ProfDeleteDialog/>}/>
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfRoutes;
