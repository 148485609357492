import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, ListGroup, Row } from 'react-bootstrap';
import './styling.scss';
import PersonIcon from '@mui/icons-material/Person';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCardIcon from '@mui/icons-material/AddCard';
import GroupsIcon from '@mui/icons-material/Groups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPrint } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-bootstrap/Modal';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { addItemsSelected, GetFichePaimentsGlobal, handleRest, handleVirement, reset } from 'app/modules/tresorerie/fiche-paiement.reducer';
import { IFichePaiementGlobal } from 'app/shared/model/paiementModel/fiche-paiement-global.model';
import { Collapse, DatePicker, Empty, Form as FormANT, Input, InputNumber, notification, Select, Switch, Typography } from 'antd';
import { GoLoading } from 'app/shared/loading';
import { IFichePaiement } from 'app/shared/model/paiementModel/fiche-paiement.model';
import dayjs from 'dayjs';
import { Option } from 'antd/es/mentions';
import { createEntity, generatePdfRecu, reset as resetRecu } from 'app/entities/recu/recu.reducer';
import { getAllEleves } from 'app/entities/eleve/eleve.reducer';
import { ModeReglement } from 'app/shared/model/enumerations/mode-reglement.model';
import { IEleve } from 'app/shared/model/eleve.model';
import Checkbox from '@mui/material/Checkbox';
import CreateCheckModal from 'app/modules/modals/CreateCheckModal';
import { Itypes } from 'app/shared/model/paiementModel/types.model';
import { ICheque } from 'app/shared/model/paiementModel/cheque.model';
import { LinearProgress } from '@mui/material';
import { IRecu } from 'app/shared/model/recu.model';

const { Search } = Input;

interface eleve {
  name: string;
  yearOfStudy: string;
  class: string;
  monthlyData: MonthlyData[];
}
interface MonthlyData {
  month: string;
  content: number;
  rest: number;
}
const dummyData = [
  {
    name: 'Alice Smith',
    yearOfStudy: 'Junior',
    class: 'Class A',
    monthlyData: [
      { month: 'September', content: 10, rest: 5, color: 'red', paymentMethod: 'espece', numeroDeRecu: '001', dateDeRecu: '2024-09-01' },
      { month: 'October', content: 12, rest: 3, color: 'green', paymentMethod: 'cheque', numeroDeRecu: '002', dateDeRecu: '2024-10-01' },
      { month: 'November', content: 15, rest: 2, color: 'green', paymentMethod: 'virement', numeroDeRecu: '003', dateDeRecu: '2024-11-01' },
      { month: 'December', content: 10, rest: 5, color: 'red', paymentMethod: 'espece', numeroDeRecu: '004', dateDeRecu: '2024-12-01' },
      { month: 'January', content: 10, rest: 4, color: 'green', paymentMethod: 'cheque', numeroDeRecu: '005', dateDeRecu: '2025-01-01' },
      { month: 'February', content: 11, rest: 3, color: 'green', paymentMethod: 'virement', numeroDeRecu: '006', dateDeRecu: '2025-02-01' },
      { month: 'March', content: 12, rest: 2, color: 'red', paymentMethod: 'espece', numeroDeRecu: '007', dateDeRecu: '2025-03-01' },
      { month: 'April', content: 10, rest: 4, color: 'green', paymentMethod: 'cheque', numeroDeRecu: '008', dateDeRecu: '2025-04-01' },
      { month: 'May', content: 9, rest: 5, color: 'green', paymentMethod: 'virement', numeroDeRecu: '009', dateDeRecu: '2025-05-01' },
    ],
  },
  {
    name: 'youssef Dahar',
    yearOfStudy: 'Junior',
    class: 'Class A',
    monthlyData: [
      { month: 'September', content: 10, rest: 5, color: 'red', paymentMethod: 'espece', numeroDeRecu: '001', dateDeRecu: '2024-09-01' },
      { month: 'October', content: 12, rest: 3, color: 'green', paymentMethod: 'cheque', numeroDeRecu: '002', dateDeRecu: '2024-10-01' },
      { month: 'November', content: 15, rest: 2, color: 'green', paymentMethod: 'virement', numeroDeRecu: '003', dateDeRecu: '2024-11-01' },
      { month: 'December', content: 10, rest: 5, color: 'red', paymentMethod: 'espece', numeroDeRecu: '004', dateDeRecu: '2024-12-01' },
      { month: 'January', content: 10, rest: 4, color: 'green', paymentMethod: 'cheque', numeroDeRecu: '005', dateDeRecu: '2025-01-01' },
      { month: 'February', content: 11, rest: 3, color: 'green', paymentMethod: 'virement', numeroDeRecu: '006', dateDeRecu: '2025-02-01' },
      { month: 'March', content: 12, rest: 2, color: 'red', paymentMethod: 'espece', numeroDeRecu: '007', dateDeRecu: '2025-03-01' },
      { month: 'April', content: 10, rest: 4, color: 'green', paymentMethod: 'cheque', numeroDeRecu: '008', dateDeRecu: '2025-04-01' },
      { month: 'May', content: 9, rest: 5, color: 'green', paymentMethod: 'virement', numeroDeRecu: '009', dateDeRecu: '2025-05-01' },
    ],
  },
  {
    name: 'soghri',
    yearOfStudy: 'Senior',
    class: 'Class B',
    monthlyData: [
      {
        month: 'September',
        content: 11,
        rest: 6,
        color: '#32de84',
        paymentMethod: 'espece',
        numeroDeRecu: '010',
        dateDeRecu: '2024-09-01',
      },
      { month: 'October', content: 13, rest: 4, color: '#32de84', paymentMethod: 'cheque', numeroDeRecu: '011', dateDeRecu: '2024-10-01' },
      {
        month: 'November',
        content: 16,
        rest: 3,
        color: '#FFD700',
        paymentMethod: 'virement',
        numeroDeRecu: '012',
        dateDeRecu: '2024-11-01',
      },
      { month: 'December', content: 12, rest: 6, color: '#FF033E', paymentMethod: 'espece', numeroDeRecu: '013', dateDeRecu: '2024-12-01' },
      { month: 'January', content: 11, rest: 5, color: '#FFD700', paymentMethod: 'cheque', numeroDeRecu: '014', dateDeRecu: '2025-01-01' },
      {
        month: 'February',
        content: 13,
        rest: 3,
        color: '#32de84',
        paymentMethod: 'virement',
        numeroDeRecu: '015',
        dateDeRecu: '2025-02-01',
      },
      { month: 'March', content: 14, rest: 4, color: '#FF033E', paymentMethod: 'espece', numeroDeRecu: '016', dateDeRecu: '2025-03-01' },
      { month: 'April', content: 12, rest: 5, color: '#32de84', paymentMethod: 'cheque', numeroDeRecu: '017', dateDeRecu: '2025-04-01' },
      { month: 'May', content: 10, rest: 6, color: '#32de84', paymentMethod: 'virement', numeroDeRecu: '018', dateDeRecu: '2025-05-01' },
    ],
  },
  // Add more students as needed
];
const tableData = [
  { name: 'John Doe', yearOfStudy: '2023', service: "Frais d'inscription", designation: ['Jan', 'Feb', 'Mar'], frais: '100 DH' },
  { name: 'Jane Smith', yearOfStudy: '2023', service: 'Frais de scolarité', designation: ['Apr', 'May'], frais: '200 DH' },
  { name: 'Mike Johnson', yearOfStudy: '2022', service: 'Frais de transport', designation: ['Jun', 'Jul', 'Aug', 'Sep'], frais: '150 DH' },
  { name: 'Emily Davis', yearOfStudy: '2021', service: "Frais d'examen", designation: ['Oct', 'Nov', 'Dec'], frais: '250 DH' },
  // Add more rows as needed
];
export const FicheDePaiment = () => {
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const [search, setSearch] = useState('');
  const [searchBack, setSearchBack] = useState('');
  const [data, setData] = useState(dummyData);
  const [selectedTypePaiement, setSelectedTypePaiement] = useState<ModeReglement[]>([ModeReglement.ESPECE]);
  const [filtredData, setFilteredData] = useState([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const currentDate = dayjs().format('YYYY-MM-DD');
  const eleveList = useAppSelector(state => state.eleve.entities) as IEleve[];
  const fichePaiementsGlobal: IFichePaiementGlobal[] = useAppSelector(state => state.fichePaiement.entities);
  const fichePaiementsGlobalLoading = useAppSelector(state => state.fichePaiement.loading);
  const totalMontantAPayer = useAppSelector(state => state.fichePaiement.totalMontantAPayer);
  const avance = useAppSelector(state => state.fichePaiement.avance);
  const cheque: ICheque = useAppSelector(state => state.fichePaiement.cheque);
  const rest = useAppSelector(state => state.fichePaiement.rest);
  const amountVirement = useAppSelector(state => state.fichePaiement.amountVirement);
  const amountEspece = useAppSelector(state => state.fichePaiement.amountEspece);
  const itemsSelected = useAppSelector(state => state.fichePaiement.itemsSelected);
  const loading = useAppSelector(state => state.recu.loading);
  const updateSuccess = useAppSelector(state => state.recu.updateSuccess);
  const recu: IRecu = useAppSelector(state => state.recu.entity);
  const entities: IFichePaiementGlobal[] = useAppSelector(state => state.fichePaiement.entities);
  const [showCheckModal, setShowCheckModal] = useState<boolean>(false);
  const [isGroupeFamiliale, setIsGroupeFamiliale] = useState<boolean>(false);
  const [filteredEleveList, setFilteredEleveList] = useState([]);
  const [selectedFiche, setSelectedFiche] = useState({
    dateDeRecu: dayjs(currentDate),
    montant: totalMontantAPayer,
    avance: avance,
    reste: rest,
    paymentMethod: selectedTypePaiement,
    amountVirement: amountVirement,
  });

  const [form] = FormANT.useForm();
  const handleSearch = search => {
    setSearch(search);
    if (search === '') return;
    if (isGroupeFamiliale) {
      dispatch(GetFichePaimentsGlobal({ keyword: search, fetchOne: false }));
    } else {
      dispatch(GetFichePaimentsGlobal({ keyword: search, fetchOne: true }));
    }
  };
  const handleSubmit = eventKey => {
    dispatch(resetRecu());
    if (!eventKey) return;
    let search = null;
    const selectedEleve = JSON.parse(eventKey);
    if (isGroupeFamiliale) {
      search = selectedEleve.nom;
    } else {
      search = selectedEleve.id.toString();
    }
    handleSearch(search);
  };

  useEffect(() => {
    form.setFieldsValue({
      dateDeRecu: selectedFiche.dateDeRecu,
      montant: totalMontantAPayer,
      avance: avance,
      reste: rest,
      paymentMethod: selectedFiche.paymentMethod,
      amountVirement: amountVirement,
    });
  }, [totalMontantAPayer, rest, avance, amountVirement]);

  const handleChange = (changedValues, allValues) => {
    if (changedValues.avance) {
      let avance = changedValues.avance;
      dispatch(handleRest(avance));
    }

    if (changedValues.amountVirement) {
      let amountVirement = changedValues.amountVirement;
      dispatch(handleVirement(amountVirement));
    }
  };

  useEffect(() => {
    if (isGroupeFamiliale) {
      dispatch(getAllEleves({}));
    } else {
      dispatch(getAllEleves({}));
    }
  }, [isGroupeFamiliale]);

  const handling = (cin: string, item: IFichePaiement, checked: boolean) => {
    setShowModal(true);
    dispatch(addItemsSelected({ cin, item, checked }));
  };

  const handleSelectTypePaiement = value => {
    setSelectedTypePaiement(value);
  };

  const changing = valeur => {
    setSearch(valeur);
    const eleves = dummyData.filter(item => {
      if (item.name.includes(valeur)) {
        return item;
      }
    });
    setFilteredData(eleves);
  };
  const searchingByOption = valeur => {
    setSearch(valeur);
    handleSearch(valeur);
    setSearch('');
  };

  const isAnnualService = (rubrique: string | null) => {
    const months = ['JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUI', 'AOU', 'SEP', 'OCT', 'NOV', 'DEC'];
    return !months.includes(rubrique || '');
  };

  const items = fichePaiementsGlobal.map((fiche, index) => {
    const sortedFichePaymentList = [...fiche.fichePaymentList].sort((a, b) => {
      const aIsAnnual = isAnnualService(a.rubrique);
      const bIsAnnual = isAnnualService(b.rubrique);

      if (aIsAnnual && !bIsAnnual) return -1;
      if (!aIsAnnual && bIsAnnual) return 1;

      if (!aIsAnnual && !bIsAnnual) {
        const monthOrder = ['SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEV', 'MAR', 'AVR', 'MAI', 'JUI', 'AOU', 'JUN'];
        return monthOrder.indexOf(a.rubrique || '') - monthOrder.indexOf(b.rubrique || '');
      }

      return 0;
    });

    return {
      key: (index + 1).toString(),
      label: (
        <div className="headerfiche">
          <div className="headerficheChild">
            <PersonIcon />
            <h6 className="namecards">
              {fiche.eleve.nom} {fiche.eleve.prenom}
            </h6>
          </div>
          <div className="headerficheChild">
            <CalendarMonthIcon />
            <h6 className="namecards">{fiche.anneeScolaire.periode}</h6>
          </div>
          <div className="headerficheChild">
            <GroupsIcon />
            <h6 className="namecards">{fiche.eleve.groupe?.nom}</h6>
          </div>
        </div>
      ),
      children: (
        <>
          {sortedFichePaymentList.map((item, index) => (
            <div
              key={index}
              style={{
                backgroundColor: item.rest === 0 ? '#95D2B3' : item.rest < item.montantAPayer ? 'orange' : '#FFF5E1',
                display: 'flex',
                alignItems: 'center',
                pointerEvents: item.rest === 0 ? 'none' : 'auto',
                marginTop: '2px',
                marginBottom: '10px',
                paddingTop: '10px',
                paddingBottom: '10px',
                borderRadius: '0.5rem',
              }}
            >
              <Checkbox
                onChange={e => {
                  let checked = e.target.checked;
                  handling(fiche.eleve.cin, item, checked);
                  if (updateSuccess) {
                    dispatch(resetRecu());
                  }
                }}
              />
              <li
                style={{
                  padding: '8px',
                  marginBottom: '8px',
                  listStyle: 'none',
                  width: '100%',
                }}
              >
                <div className="col col-1" style={{ width: '30%' }} data-label="Rubrique">
                  {item.rubrique}
                </div>
                <div className="col col-2" data-label="Montant à payer">
                  {item.montantAPayer} DH
                </div>
                <div className="col col-3" data-label="Rest">
                  {item.rest} DH
                </div>
              </li>
            </div>
          ))}
        </>
      ),
    };
  });

  const onFinish = (values: any) => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const filteredFichePaiementsGlobal = fichePaiementsGlobal
      .map(fichePaiementGlobal => ({
        ...fichePaiementGlobal,
        fichePaymentList: fichePaiementGlobal.fichePaymentList.filter(fichePaiement =>
          itemsSelected.some(
            selectedItem =>
              selectedItem.id === fichePaiement.id &&
              selectedItem.rubrique === fichePaiement.rubrique &&
              selectedItem.montantAPayer === fichePaiement.montantAPayer &&
              selectedItem.rest === fichePaiement.rest,
          ),
        ),
      }))
      .filter(fichePaiementGlobal => fichePaiementGlobal.fichePaymentList.length > 0);

    let types: Itypes[] = selectedTypePaiement.map(typePaiement => ({
      modeReglement: typePaiement,
      amount:
        typePaiement === 'ESPECE'
          ? amountEspece !== null
            ? amountEspece
            : avance
          : typePaiement === 'VIREMENT'
            ? amountVirement
            : cheque.montantCheque,
    }));

    const tuteurs = entities[0].eleve.tuteurs;
    const getDesiredTuteur = tuteurs => {
      const priorities = ['pere', 'mere', 'autre', null];

      for (const relation of priorities) {
        const tuteur = tuteurs.find(t => t.relationAvecEleve === relation);
        if (tuteur) {
          return tuteur;
        }
      }

      return tuteurs[0] || null;
    };

    const desiredTuteur = getDesiredTuteur(tuteurs);

    if (!desiredTuteur) {
      api.error({
        message: `Tuteur non trouvé pour ${entities[0]?.eleve.nom}`,
        description: `Veuillez choisir un tuteur pour ${entities[0]?.eleve.nom} avant de continuer.`,
      });
    } else {
      const entity = {
        cheque: cheque,
        dateDeRecu: values.dateDeRecu,
        montant: totalMontantAPayer,
        avance: values.avance,
        reste: values.reste,
        types: types,
        fichePayments: filteredFichePaiementsGlobal,
        tuteur: desiredTuteur,
      };

      dispatch(createEntity(entity));
    }
  };

  const handleOk = () => {
    setShowCheckModal(false);
  };

  const handleSwitchChange = checked => {
    setIsGroupeFamiliale(checked);
  };

  useEffect(() => {
    dispatch(reset());
    dispatch(resetRecu());
  }, []);

  useEffect(() => {}, [loading]);
  useEffect(() => {
    if (updateSuccess) {
      if (search == '') return;
      if (isGroupeFamiliale) {
        dispatch(GetFichePaimentsGlobal({ keyword: search, fetchOne: false }));
      } else {
        dispatch(GetFichePaimentsGlobal({ keyword: search, fetchOne: true }));
      }
      form.resetFields();
      dispatch(reset());
    }
  }, [updateSuccess]);
  return (
    <div>
      {contextHolder}
      <CreateCheckModal show={showCheckModal} handleClose={handleOk}></CreateCheckModal>

      <Container className="d-flex justify-content-center align-items-center mt-3 ">
        <Row className="align-items-center mt-5 mb-6">
          <Col lg={8} md={10} sm={12} className="position-relative ">
            <div style={{ display: 'flex' }}>
              <Select
                showSearch
                allowClear
                placeholder="élève"
                optionFilterProp="children"
                style={{ width: 400, marginRight: '30px' }}
                onChange={handleSubmit}
              >
                {eleveList.map(eleve => (
                  <Option key={eleve.id.toString()} value={JSON.stringify({ nom: eleve.nom, prenom: eleve.prenom, id: eleve.id })}>
                    {eleve.nom} {eleve.prenom}
                  </Option>
                ))}
              </Select>
              <FormANT.Item label="Groupe familiale" name="isGroupeFamiliale" valuePropName="checked">
                <Switch checked={isGroupeFamiliale} onChange={handleSwitchChange} />
              </FormANT.Item>
            </div>
            {search && (
              <ListGroup className="position-absolute w-100 mt-1" style={{ zIndex: 1000 }}>
                {filtredData.map(item => (
                  <ListGroup.Item key={item.id} className="listgroup" onClick={() => searchingByOption(item.name)}>
                    {item.name}
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Col>
          <Col style={{ opacity: 0 }} lg={4} md={10} sm={12} className="d-flex justify-content-center">
            <Button variant="outline-primary" className="mx-2  mb-md-0" style={{ width: '250px' }}>
              <FontAwesomeIcon icon={faEdit} /> Modify
            </Button>
            <Button variant="outline-success" style={{ width: '200px' }}>
              <FontAwesomeIcon icon={faPrint} /> Print
            </Button>
          </Col>
        </Row>
      </Container>
      <div className="container" style={{ marginTop: '15px' }}>
        <div className="row full-height">
          {/* Left Half */}
          <div className="col-md-7 overflow-auto">
            <ul className="responsive-table" style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px', margin: 'auto' }}>
              <li className="table-header">
                <div className="col col-1" style={{ width: '30%' }}>
                  Rubrique
                </div>
                <div className="col col-2" style={{ width: '30%' }}>
                  Montant à payer
                </div>
                <div className="col col-3">Rest</div>
              </li>
              {fichePaiementsGlobalLoading ? (
                <GoLoading loading={fichePaiementsGlobalLoading} />
              ) : (
                <>
                  {fichePaiementsGlobal.length > 0 ? (
                    <Collapse items={items} />
                  ) : search !== '' ? (
                    <Empty description={<Typography.Text>Aucun paiement pour {search}</Typography.Text>} />
                  ) : (
                    <></>
                  )}
                </>
              )}
            </ul>
          </div>

          {/* Right Half */}
          <div className="col-md-5 d-flex flex-column">
            {loading && <LinearProgress />}
            <div
              className="half-height overflow-auto"
              style={{
                boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                opacity: loading ? '60%' : '100%',
                pointerEvents: loading ? 'none' : 'auto',
              }}
            >
              {/* Top Right Table */}
              <div className="payement">
                {/* Add rows for the first table here */}
                <FormANT form={form} onFinish={onFinish} initialValues={selectedFiche} onValuesChange={handleChange}>
                  <h3 style={{ textAlign: 'center' }}>Payement </h3>
                  <Row>
                    <Col>
                      <Form.Label>Numero de Recu</Form.Label>
                      <FormANT.Item name="idRecu">
                        <InputNumber disabled={true} style={{ width: '100%' }} />
                      </FormANT.Item>
                    </Col>
                    <Col>
                      <Form.Label>Date de Recu</Form.Label>
                      <FormANT.Item name="dateDeRecu">
                        <DatePicker disabled={true} style={{ width: '100%' }} type="date" />
                      </FormANT.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col xs={24} md={24}>
                      <Form.Label>Total</Form.Label>
                      <FormANT.Item name="montant">
                        <InputNumber disabled={true} style={{ width: '100%' }} />
                      </FormANT.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Label>Avance</Form.Label>
                      <FormANT.Item name="avance">
                        <InputNumber disabled={fichePaiementsGlobal.length <= 0} min={0} style={{ width: '100%' }} />
                      </FormANT.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Label>Rest</Form.Label>
                      <FormANT.Item name="reste">
                        <InputNumber disabled={true} style={{ width: '100%' }} />
                      </FormANT.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Label>Type de paiement</Form.Label>
                      <FormANT.Item rules={[{ required: true, message: 'sélectionner votre level!' }]}>
                        <Select
                          mode="multiple"
                          placeholder={'choisir le type'}
                          allowClear
                          onChange={handleSelectTypePaiement}
                          defaultValue={ModeReglement.ESPECE}
                          disabled={fichePaiementsGlobal.length <= 0}
                        >
                          <Option value={ModeReglement.ESPECE}>Espece</Option>
                          <Option value={ModeReglement.CHEQUE}>Cheque</Option>
                          <Option value={ModeReglement.VIREMENT}>Virement</Option>
                        </Select>
                      </FormANT.Item>
                    </Col>
                  </Row>
                  <div style={{ display: 'flex' }}>
                    {selectedTypePaiement.includes(ModeReglement.CHEQUE) && (
                      <AddCardIcon
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setShowCheckModal(true);
                        }}
                      />
                    )}
                    {selectedTypePaiement.includes(ModeReglement.VIREMENT) && (
                      <FormANT.Item name="amountVirement" style={{ width: '100%', marginLeft: '10px' }}>
                        <InputNumber min={0} placeholder="Amount Virement" style={{ width: '100%' }} />
                      </FormANT.Item>
                    )}
                  </div>
                  <Modal.Footer>
                    {updateSuccess ? (
                      <Button
                        onClick={() => {
                          dispatch(generatePdfRecu({ recu }));
                          dispatch(reset());
                          dispatch(resetRecu());
                        }}
                        disabled={fichePaiementsGlobal.length <= 0}
                        variant="success"
                        style={{ marginTop: '10px' }}
                      >
                        Telecharger le reçu
                      </Button>
                    ) : (
                      <Button disabled={fichePaiementsGlobal.length <= 0} variant="primary" type="submit" style={{ marginTop: '10px' }}>
                        Valider le reçu
                      </Button>
                    )}
                  </Modal.Footer>
                </FormANT>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FicheDePaiment;
