import { GarderieStatus } from 'app/shared/model/enumerations/garderie-status.model';
import { ITuteur } from 'app/shared/model/tuteur.model';
import { IEleve } from 'app/shared/model/eleve.model';

export interface IGarderie {
  id?: number;
  type?: GarderieStatus | null;
  createdAt?: Date | null;
  tuteur?: ITuteur | null;
  eleve?: IEleve | null;
}

export const defaultValue: Readonly<IGarderie> = {};
