import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getMoisEleves } from 'app/entities/mois-eleve/mois-eleve.reducer';
import { getServiceEtablissementEntities as getServiceEtablisements } from 'app/entities/service-etablisement/service-etablisement.reducer';
import { createEntity, getEntity, reset, updateEntity } from './etat-service-mois.reducer';

export const EtatServiceMoisUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const moisEleves = useAppSelector(state => state.moisEleve.entities);
  const serviceEtablisements = useAppSelector(state => state.serviceEtablisement.entities);
  const etatServiceMoisEntity = useAppSelector(state => state.etatServiceMois.entity);
  const loading = useAppSelector(state => state.etatServiceMois.loading);
  const updating = useAppSelector(state => state.etatServiceMois.updating);
  const updateSuccess = useAppSelector(state => state.etatServiceMois.updateSuccess);

  const handleClose = () => {
    navigate('/etat-service-mois' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getMoisEleves({}));
    dispatch(getServiceEtablisements({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    if (values.montant !== undefined && typeof values.montant !== 'number') {
      values.montant = Number(values.montant);
    }
    if (values.avance !== undefined && typeof values.avance !== 'number') {
      values.avance = Number(values.avance);
    }

    const entity = {
      ...etatServiceMoisEntity,
      ...values,
      moisEleve: moisEleves.find(it => it.id.toString() === values.moisEleve?.toString()),
      serviceEtablisement: serviceEtablisements.find(it => it.id.toString() === values.serviceEtablisement?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...etatServiceMoisEntity,
          moisEleve: etatServiceMoisEntity?.moisEleve?.id,
          serviceEtablisement: etatServiceMoisEntity?.serviceEtablisement?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.etatServiceMois.home.createOrEditLabel" data-cy="EtatServiceMoisCreateUpdateHeading">
            Créer ou éditer un État Service Mois
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="etat-service-mois-id" label="Numéro" validate={{ required: true }} />
              ) : null}
              <ValidatedField label="Montant" id="etat-service-mois-montant" name="montant" data-cy="montant" type="text" />
              <ValidatedField label="Avance" id="etat-service-mois-avance" name="avance" data-cy="avance" type="text" />
              <ValidatedField id="etat-service-mois-moisEleve" name="moisEleve" data-cy="moisEleve" label="Mois Eleve" type="select">
                <option value="" key="0" />
                {moisEleves
                  ? moisEleves.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.mois}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="etat-service-mois-serviceEtablisement"
                name="serviceEtablisement"
                data-cy="serviceEtablisement"
                label="Service Etablisement"
                type="select"
              >
                <option value="" key="0" />
                {serviceEtablisements
                  ? serviceEtablisements.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.libelle}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/etat-service-mois" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EtatServiceMoisUpdate;
