import { IGroupe } from 'app/shared/model/groupe.model';

export interface ISalle {
  id?: number;
  numero?: number | null;
  capacite?: number | null;
  groupes?: IGroupe[] | null;
}

export const defaultValue: Readonly<ISalle> = {};
