// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Espacement entre les boutons */
}

.button-container .btn {
  margin-bottom: 10px; /* Espacement en bas pour les boutons en mode colonne */
}

@media (max-width: 768px) {
  /* Largeur d'écran maximale pour le mode colonne */
  .button-container {
    flex-direction: column;
    align-items: flex-end;
  }
}
.form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px; /* Espacement entre les éléments du formulaire */
  justify-content: flex-start;
}

.search-input,
.select-input {
  margin-bottom: 10px; /* Espacement en bas pour les éléments du formulaire en mode colonne */
}

/* Media query pour les écrans plus petits */
@media (max-width: 768px) {
  .form-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .search-input,
  .select-input {
    width: 100%; /* Occupe toute la largeur disponible sur les petits écrans */
    margin-left: 0; /* Réinitialise la marge à gauche sur les petits écrans */
  }
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/eleve/styleEleve.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,aAAA;EACA,yBAAA;EACA,SAAA,EAAA,iCAAA;AAEF;;AACA;EACE,mBAAA,EAAA,uDAAA;AAEF;;AACA;EACE,kDAAA;EACA;IACE,sBAAA;IACA,qBAAA;EAEF;AACF;AACA;EACE,aAAA;EACA,eAAA;EACA,QAAA,EAAA,gDAAA;EACA,2BAAA;AACF;;AAEA;;EAEE,mBAAA,EAAA,sEAAA;AACF;;AAEA,4CAAA;AACA;EACE;IACE,sBAAA;IACA,uBAAA;EACF;EAEA;;IAEE,WAAA,EAAA,6DAAA;IACA,cAAA,EAAA,yDAAA;EAAF;AACF","sourcesContent":[".button-container {\n  display: flex;\n  justify-content: flex-end;\n  gap: 10px; /* Espacement entre les boutons */\n}\n\n.button-container .btn {\n  margin-bottom: 10px; /* Espacement en bas pour les boutons en mode colonne */\n}\n\n@media (max-width: 768px) {\n  /* Largeur d'écran maximale pour le mode colonne */\n  .button-container {\n    flex-direction: column;\n    align-items: flex-end;\n  }\n}\n\n.form-container {\n  display: flex;\n  flex-wrap: wrap;\n  gap: 8px; /* Espacement entre les éléments du formulaire */\n  justify-content: flex-start;\n}\n\n.search-input,\n.select-input {\n  margin-bottom: 10px; /* Espacement en bas pour les éléments du formulaire en mode colonne */\n}\n\n/* Media query pour les écrans plus petits */\n@media (max-width: 768px) {\n  .form-container {\n    flex-direction: column;\n    align-items: flex-start;\n  }\n\n  .search-input,\n  .select-input {\n    width: 100%; /* Occupe toute la largeur disponible sur les petits écrans */\n    margin-left: 0; /* Réinitialise la marge à gauche sur les petits écrans */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
