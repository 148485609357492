import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IGroupe } from 'app/shared/model/groupe.model';
import { INiveau } from 'app/shared/model/niveau.model';
import { Button, Col, Form as AntForm, Input, Row, Select, Space, Spin, Typography } from 'antd';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getGroupes } from 'app/entities/groupe/groupe.reducer';
import { getEntities as getNiveaux } from 'app/entities/niveau/niveau.reducer';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { addTransmission, updateTransmission } from 'app/entities/cahier-transmission/transmission.reducer';
import { ITransmissionResponse } from 'app/shared/model/cahier_transmission_model/transmission.model';
import { useLocation, useNavigate } from 'react-router-dom';
import { getPaginationState } from 'react-jhipster';

const CreateTransmissionModal = ({ toggleModal }) => {
  const pageLocation = useLocation();
  const navigate = useNavigate();
  const [paginationState, setPaginationState] = useState(getPaginationState(pageLocation, 6, 'id'));
  const [form] = AntForm.useForm();
  const { Option } = Select;
  const { Title } = Typography;
  const dispatch = useAppDispatch();
  const eleves = useAppSelector(state => state.eleve.entities);
  const groupes = useAppSelector(state => state.groupe.entities) as IGroupe[];
  const niveaux = useAppSelector(state => state.niveau.entities) as INiveau[];

  const transmissionEntity: ITransmissionResponse = useAppSelector(state => state.transmission.entity);
  const loadAddTransmission = useAppSelector(state => state.transmission.loadAddTransmission);
  const addTransmissionSuccess = useAppSelector(state => state.transmission.addTransmissionSuccess);
  const statusFormAdd = useAppSelector(state => state.transmission.statusFormAdd);

  const [selectedEleve, setSelectedEleve] = useState<number | null>(null);
  const [selectedGroupe, setSelectedGroupe] = useState<number | null>(null);
  const [selectedNiveau, setSelectedNiveau] = useState<number | null>(null);

  useEffect(() => {
    // dispatch(reset());
    let query = 'paged=false&';
    let query2 = '';

    if (selectedGroupe) {
      query += `groupeId.equals=${selectedGroupe}&`;
    }
    dispatch(getEleves({ query }));

    if (selectedNiveau) {
      query2 += `niveauId.equals=${selectedNiveau}&`;
    }
    dispatch(getGroupes({ query: query2 }));
    dispatch(getNiveaux({}));
  }, [dispatch, selectedGroupe, selectedNiveau]);

  const eleveOptions = eleves.map(eleve => (
    <Option key={eleve.id} value={eleve.id}>
      {eleve.nom} {eleve.prenom}
    </Option>
  ));

  const groupeOptions = groupes.map(groupe => (
    <Option key={groupe.id} value={groupe.id}>
      {groupe.nom}
    </Option>
  ));
  const niveauOptions = niveaux.map(niveau => (
    <Option key={niveau.id} value={niveau.id}>
      {niveau.libelle}
    </Option>
  ));
  const handleEleveChange = value => {
    setSelectedEleve(value);
  };

  const handleGroupeChange = value => {
    setSelectedGroupe(value);
  };

  const handleNiveauChange = value => {
    setSelectedNiveau(value);
  };
  const handleClose = () => {
    toggleModal();
  };

  const saveEntity = values => {
    let entity = {
      ...values,
    };

    if (statusFormAdd) {
      dispatch(addTransmission(entity));
    } else {
      entity = {
        id: transmissionEntity?.id,
        ...values,
      };
      dispatch(updateTransmission(entity));
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });

      navigate(`${pageLocation.pathname}?page=${1}&sort=${paginationState.sort},${paginationState.order}`);
    }
    handleClose();
    form.resetFields();
    setSelectedNiveau(null);
    setSelectedGroupe(null);
  };

  // useEffect(() => {
  //   if(addTransmissionSuccess){
  //     handleClose();
  //     form.resetFields();
  //     setSelectedNiveau(null);
  //     setSelectedGroupe(null);
  //   }
  // }, [addTransmissionSuccess]);

  useEffect(() => {
    if (!statusFormAdd) {
      form.setFieldsValue({
        eleveId: transmissionEntity?.eleve?.id,
        ...transmissionEntity,
      });
    } else {
      form.resetFields();
    }
  }, [transmissionEntity, form, statusFormAdd]);
  return (
    <div style={{ paddingTop: '10px' }}>
      <Row justify="center">
        <Col span={16}>
          <AntForm form={form} layout="vertical" onFinish={saveEntity} disabled={!!transmissionEntity?.prof}>
            <AntForm.Item>
              <Select
                value={selectedNiveau}
                onChange={handleNiveauChange}
                allowClear
                placeholder="Sélectionner un niveau"
                showSearch
                filterOption={(input, option) => {
                  const text = option?.label || option?.children;
                  return text?.toString().toLowerCase().includes(input.toLowerCase());
                }}
              >
                {niveauOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item>
              <Select
                value={selectedGroupe}
                onChange={handleGroupeChange}
                allowClear
                placeholder="Sélectionner un groupe"
                showSearch
                filterOption={(input, option) => {
                  const text = option?.label || option?.children;
                  return text?.toString().toLowerCase().includes(input.toLowerCase());
                }}
              >
                {groupeOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item name="eleveId" rules={[{ required: true, message: 'Élève est requis' }]}>
              <Select
                value={selectedEleve}
                onChange={handleEleveChange}
                allowClear
                showSearch
                placeholder="Sélectionner un élève"
                optionFilterProp="children"
                filterOption={(input, option) => {
                  const text = option?.label || option?.children;
                  return text?.toString().toLowerCase().includes(input.toLowerCase());
                }}
              >
                {eleveOptions}
              </Select>
            </AntForm.Item>
            <AntForm.Item name="description">
              <TextArea placeholder="Transmission" rows={4} />
            </AntForm.Item>
            <AntForm.Item>
              <Space>
                <Button onClick={handleClose} type="default" icon={<ArrowLeftOutlined />}>
                  Retour
                </Button>
                <Button type="primary" htmlType="submit" icon={<SaveOutlined />}>
                  {statusFormAdd ? 'Sauvegarder' : 'Modifier'}
                </Button>
              </Space>
            </AntForm.Item>
          </AntForm>
        </Col>
      </Row>
      {/*{loading && (*/}
      {/*  <Row justify="center">*/}
      {/*    <Col span={16} style={{textAlign: 'center', marginTop: '20px'}}>*/}
      {/*      <Spin tip="Chargement..."/>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*)}*/}
    </div>
  );
};
export default CreateTransmissionModal;
