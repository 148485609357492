import React, {useEffect} from 'react';
import {Link, useParams} from 'react-router-dom';
import {Button, Col, Row} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useAppDispatch, useAppSelector} from 'app/config/store';

import {getEntity} from './etablissement.reducer';

export const EtablissementDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const etablissementEntity = useAppSelector(state => state.etablissement.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="etablissementDetailsHeading">Etablissement</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{etablissementEntity.id}</dd>
          <dt>
            <span id="nom">Nom</span>
          </dt>
          <dd>{etablissementEntity.nom}</dd>
          <dt>
            <span id="step">Step</span>
          </dt>
          <dd>{etablissementEntity.step}</dd>
          <dt>
            <span id="tel">Tel</span>
          </dt>
          <dd>{etablissementEntity.tel}</dd>
          <dt>
            <span id="adresse">Adresse</span>
          </dt>
          <dd>{etablissementEntity.adresse}</dd>
          <dt>
            <span id="cnss">Cnss</span>
          </dt>
          <dd>{etablissementEntity.cnss}</dd>
        </dl>
        <Button tag={Link} to="/etablissement" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/etablissement/${etablissementEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default EtablissementDetail;
