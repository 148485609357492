import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import AuditLog from 'app/entities/audit-log/audit-log';

const AuditLogRoutes = () => {
  return (
    <ErrorBoundaryRoutes>
      <Route index element={<AuditLog />} />
    </ErrorBoundaryRoutes>
  );
};

export default AuditLogRoutes;
