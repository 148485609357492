import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntities as getEleves } from 'app/entities/eleve/eleve.reducer';
import { getEntities as getAnneeScolaires } from 'app/entities/annee-scolaire/annee-scolaire.reducer';
import { Mois } from 'app/shared/model/enumerations/mois.model';
import { createEntity, getEntity, reset, updateEntity } from './mois-eleve.reducer';

export const MoisEleveUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const eleves = useAppSelector(state => state.eleve.entities);
  const anneeScolaires = useAppSelector(state => state.anneeScolaire.entities);
  const moisEleveEntity = useAppSelector(state => state.moisEleve.entity);
  const loading = useAppSelector(state => state.moisEleve.loading);
  const updating = useAppSelector(state => state.moisEleve.updating);
  const updateSuccess = useAppSelector(state => state.moisEleve.updateSuccess);
  const moisValues = Object.keys(Mois);

  const handleClose = () => {
    navigate('/mois-eleve' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getEleves({}));
    dispatch(getAnneeScolaires({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...moisEleveEntity,
      ...values,
      eleve: eleves.find(it => it.id.toString() === values.eleve?.toString()),
      anneeScolaire: anneeScolaires.find(it => it.id.toString() === values.anneeScolaire?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          mois: 'JAN',
          ...moisEleveEntity,
          eleve: moisEleveEntity?.eleve?.id,
          anneeScolaire: moisEleveEntity?.anneeScolaire?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.moisEleve.home.createOrEditLabel" data-cy="MoisEleveCreateUpdateHeading">
            Créer ou éditer un Mois Eleve
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField name="id" required readOnly id="mois-eleve-id" label="Numéro" validate={{ required: true }} />
              ) : null}
              <ValidatedField label="Mois" id="mois-eleve-mois" name="mois" data-cy="mois" type="select">
                {moisValues.map(mois => (
                  <option value={mois} key={mois}>
                    {mois}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField id="mois-eleve-eleve" name="eleve" data-cy="eleve" label="Eleve" type="select">
                <option value="" key="0" />
                {eleves
                  ? eleves.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.nom}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="mois-eleve-anneeScolaire"
                name="anneeScolaire"
                data-cy="anneeScolaire"
                label="Annee Scolaire"
                type="select"
              >
                <option value="" key="0" />
                {anneeScolaires
                  ? anneeScolaires.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.periode}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/mois-eleve" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Retour</span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MoisEleveUpdate;
