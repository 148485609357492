// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.time_table table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
}
.time_table th, .time_table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}
.time_table th {
  background-color: #f2f2f2;
}
.time_table tr:nth-child(even) {
  background-color: #f9f9f9;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/modules/modals/timetable.css"],"names":[],"mappings":"AACE;EACE,UAAA;EACA,iBAAA;EACA,yBAAA;AAAJ;AAGE;EACE,sBAAA;EACA,aAAA;EACA,kBAAA;AADJ;AAIE;EACE,yBAAA;AAFJ;AAKE;EACE,yBAAA;AAHJ","sourcesContent":[".time_table {\n  table {\n    width: 80%;\n    margin: 20px auto;\n    border-collapse: collapse;\n  }\n\n  th, td {\n    border: 1px solid #ddd;\n    padding: 10px;\n    text-align: center;\n  }\n\n  th {\n    background-color: #f2f2f2;\n  }\n\n  tr:nth-child(even) {\n    background-color: #f9f9f9;\n  }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
