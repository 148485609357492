import React, { useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom'; // Import de Link depuis react-router-dom
import { Col, Row } from 'reactstrap';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntity, reset, updateEntity } from './etablissement.reducer';
import { GoLoading } from 'app/shared/loading';
import { Button } from 'antd'; // Import du bouton antd

export const EtablissementUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = { id: 1 };
  const isNew = id === undefined;

  const etablissementEntity = useAppSelector(state => state.etablissement.entity);
  const loading = useAppSelector(state => state.etablissement.loading);
  const updating = useAppSelector(state => state.etablissement.updating);
  const updateSuccess = useAppSelector(state => state.etablissement.updateSuccess);

  const handleClose = () => {
    navigate('/etablissement' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = (values: any) => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...etablissementEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => (isNew ? {} : { ...etablissementEntity });

  const telValidationRules = {
    required: 'Le numéro de téléphone est requis',
    pattern: {
      value: /^\d{10}$/,
      message: 'Veuillez entrer un numéro de téléphone valide',
    },
  };

  const requiredValidation = {
    required: 'Ce champ est requis',
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="goSchoolApp.etablissement.home.createOrEditLabel" data-cy="EtablissementCreateUpdateHeading">
            Etablissement
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <GoLoading loading={loading}></GoLoading>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <ValidatedField label="Nom" id="etablissement-nom" name="nom" data-cy="nom" type="text" validate={requiredValidation} />
              <ValidatedField label="Step" id="etablissement-step" name="step" data-cy="step" type="text" validate={requiredValidation} />
              <ValidatedField label="Tel" id="etablissement-tel" name="tel" data-cy="tel" type="text" validate={telValidationRules} />
              <ValidatedField
                label="Adresse"
                id="etablissement-adresse"
                name="adresse"
                data-cy="adresse"
                type="text"
                validate={requiredValidation}
              />
              <ValidatedField label="Cnss" id="etablissement-cnss" name="cnss" data-cy="cnss" type="text" validate={requiredValidation} />
              <Button type="default" id="cancel-save" data-cy="entityCreateCancelButton">
                <Link to="/etablissement" replace style={{ textDecoration: 'none' }}>
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">Retour</span>
                </Link>
              </Button>
              &nbsp;
              <Button type="primary" id="save-entity" data-cy="entityCreateSaveButton" htmlType="submit" loading={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Sauvegarder
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default EtablissementUpdate;
