// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.entity-details {
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
}

.entity-heading {
  text-align: center;
  margin-bottom: 20px;
}

.entity-details-list {
  padding: 0;
}

.entity-details-item {
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.entity-details-item dt {
  font-weight: bold;
}

.entity-details-value {
  margin-left: 10px;
  margin-bottom: 0;
}

.entity-actions {
  margin-top: 20px;
  text-align: center;
}

.entity-actions .btn {
  margin-right: 10px;
}

.enfants-list {
  display: flex; /* Affiche les enfants horizontalement */
  overflow-x: auto; /* Active le défilement horizontal */
  max-height: 50px; /* Limite la hauteur de la liste */
  padding: 5px 0; /* Ajoute un peu d'espace vertical */
}

.enfant-item {
  margin-right: 15px; /* Espace entre chaque enfant */
  white-space: nowrap; /* Empêche le texte de se casser sur plusieurs lignes */
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/entities/tuteur/tuteur-detail.css"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,wCAAA;EACA,UAAA;EACA,iBAAA;EACA,kBAAA;EACA,yBAAA;AAEF;;AACA;EACE,kBAAA;EACA,mBAAA;AAEF;;AACA;EACE,UAAA;AAEF;;AACA;EACE,aAAA;EACA,kBAAA;EACA,wCAAA;EACA,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;AAEF;;AACA;EACE,iBAAA;AAEF;;AACA;EACE,iBAAA;EACA,gBAAA;AAEF;;AACA;EACE,gBAAA;EACA,kBAAA;AAEF;;AACA;EACE,kBAAA;AAEF;;AAAA;EACE,aAAA,EAAA,wCAAA;EACA,gBAAA,EAAA,oCAAA;EACA,gBAAA,EAAA,kCAAA;EACA,cAAA,EAAA,oCAAA;AAGF;;AAAA;EACE,kBAAA,EAAA,+BAAA;EACA,mBAAA,EAAA,uDAAA;AAGF","sourcesContent":[".entity-details {\n  margin-top: 20px;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  width: 80%;\n  margin-left: auto;\n  margin-right: auto;\n  background-color: #ffffff;\n}\n\n.entity-heading {\n  text-align: center;\n  margin-bottom: 20px;\n}\n\n.entity-details-list {\n  padding: 0;\n}\n\n.entity-details-item {\n  padding: 15px;\n  border-radius: 8px;\n  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\n  background-color: #f9f9f9;\n  margin-bottom: 10px;\n  display: flex;\n  align-items: center;\n}\n\n.entity-details-item dt {\n  font-weight: bold;\n}\n\n.entity-details-value {\n  margin-left: 10px;\n  margin-bottom: 0;\n}\n\n.entity-actions {\n  margin-top: 20px;\n  text-align: center;\n}\n\n.entity-actions .btn {\n  margin-right: 10px;\n}\n.enfants-list {\n  display: flex; /* Affiche les enfants horizontalement */\n  overflow-x: auto; /* Active le défilement horizontal */\n  max-height: 50px; /* Limite la hauteur de la liste */\n  padding: 5px 0; /* Ajoute un peu d'espace vertical */\n}\n\n.enfant-item {\n  margin-right: 15px; /* Espace entre chaque enfant */\n  white-space: nowrap; /* Empêche le texte de se casser sur plusieurs lignes */\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
