import { useEffect, useState } from 'react';
import Pusher from 'pusher-js';
import { useAppSelector } from 'app/config/store';

export const usePusher = () => {
  const account = useAppSelector(state => state.authentication.account);
  const [pusherInstance, setPusherInstance] = useState(null);

  useEffect(() => {
    if (account?.pusherKey) {
      const pusher = new Pusher(account?.pusherKey, {
        cluster: 'eu',
        forceTLS: true,
      });
      setPusherInstance(pusher);
    }
  }, [account]);

  return pusherInstance;
};

export default usePusher;
