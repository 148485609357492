import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './galerie.reducer';
import { openFile } from 'react-jhipster'; // Ensure the correct path

export const GalerieDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, [id]);

  const galerieEntity = useAppSelector(state => state.galerie.entity);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="galerieDetailsHeading">Galerie</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{galerieEntity.id}</dd>
          <dt>
            <span id="date">Date</span>
          </dt>
          <dd>{galerieEntity.date ? <TextFormat value={galerieEntity.date} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}</dd>
          <dt>
            <span id="image">Image</span>
          </dt>
          <dd>
            {galerieEntity.images &&
              galerieEntity.images.length > 0 &&
              galerieEntity.images.map((image, index) => (
                <div key={index}>
                  <a href={`data:${image.photoContentType};base64,${image.photo}`} download={`image_${index}.jpg`}>
                    <img src={`data:${image.photoContentType};base64,${image.photo}`} style={{ maxHeight: '30px' }} alt="Galerie Image" />
                  </a>
                  <Button onClick={openFile(image.photoContentType, image.photo)} color="primary" size="sm">
                    View
                  </Button>
                </div>
              ))}
          </dd>
          <dt>Eleve</dt>
          <dd>
            {galerieEntity.eleves
              ? galerieEntity.eleves.map((val, i) => (
                  <span key={val.id}>
                    <a>
                      {val.nom} {val.prenom} {val.groupe?.nom}
                    </a>
                    {galerieEntity.eleves && i === galerieEntity.eleves.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/galerie" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/galerie/${galerieEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default GalerieDetail;
