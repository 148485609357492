import { IQuestion } from 'app/shared/model/question.model';

export interface IChoice {
  id?: number;
  text?: string;
  isCorrect?: boolean;
  questionId?: number;
  question?: IQuestion;
}

export const defaultValue: Readonly<IChoice> = {};
