import React, { useEffect } from 'react';
import { Modal, Timeline } from 'antd';
import { getEntities } from 'app/entities/emploi-temps-ligne/emploi-temps-ligne.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { GoLoading } from 'app/shared/loading';
import './timetable.css';
import { IEmploiTempsLigne } from 'app/shared/model/emploi-temps-ligne.model';
import _ from 'lodash';
import { TimeTableInfo } from 'app/shared/timeTableInfo';

const TimeLineEmploiTempsLigneModal = ({ show, handleClose, emploiId }) => {
  const emploiTempsLigneList = useAppSelector(state => state.emploiTempsLigne.entities) as IEmploiTempsLigne [];
  const loading = useAppSelector(state => state.emploiTempsLigne.loading);
  const dispatch = useAppDispatch();

  let dictionary = _.groupBy(emploiTempsLigneList, function(b) {
    return b.jour;
  });


  const getAllEntities = () => {
    if (emploiId) {
      dispatch(
        getEntities({
          query: 'emploisTempsId.equals=' + emploiId
        })
      );
    }
  };


  useEffect(() => {
    getAllEntities();
  }, [emploiId]);


  return (
    <Modal width={'60%'} className={'time_table'} title="Emploi de temps" open={show} onOk={handleClose}
           onCancel={handleClose}>
      <GoLoading loading={loading} />
      <table>
        <thead>
        <tr>
          <th>Lundi</th>
          <th>Mardi</th>
          <th>Mercredi</th>
          <th>Jeudi</th>
          <th>Vendredi</th>
          <th>Samedi</th>

        </tr>
        </thead>
        <tbody>
        <tr>


          <td>
            <Timeline
              items={dictionary?.LUNDI?.map(value => ({ children: (<TimeTableInfo data={value} />) }))}
            />
          </td>
          <td>
            <Timeline
              items={dictionary?.MARDI?.map(value => ({ children: (<TimeTableInfo data={value} />) }))}
            />
          </td>
          <td>
            <Timeline
              items={dictionary?.MERCREDI?.map(value => ({ children: (<TimeTableInfo data={value} />) }))}
            />
          </td>
          <td>
            <Timeline
              items={dictionary?.JEUDI?.map(value => ({ children: (<TimeTableInfo data={value} />) }))}
            />
          </td>
          <td>
            <Timeline
              items={dictionary?.VENDREDI?.map(value => ({ children: (<TimeTableInfo data={value} />) }))}
            />
          </td>
          <td>
            <Timeline
              items={dictionary?.SAMEDI?.map(value => ({ children: (<TimeTableInfo data={value} />) }))}
            />
          </td>

        </tr>
        </tbody>
      </table>

    </Modal>
  );
};

export default TimeLineEmploiTempsLigneModal;
