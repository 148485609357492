import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, PayloadAction } from '@reduxjs/toolkit';
import { cleanEntities, cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { defaultValue, IEleve } from 'app/shared/model/eleve.model';
import { IPaiementEleve } from 'app/shared/model/PaiementEleve.model';
import { ITarif } from 'app/shared/model/tarif.model';
import { ITuteur } from 'app/shared/model/tuteur.model';

const initialState: EntityState<IEleve> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  months: ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui'],
  selectAllMonths: true,
  additionalServices: [],
  customEntite: {},
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/eleves';

//sent to backend eleve selected

export const sendEleveAndNavigate = createAsyncThunk(
  'eleve/send_eleve',
  async (eleve: IEleve, thunkAPI) => {
    // Send eleve to the backend
    const result = await axios.post<IEleve>(`${apiUrl}/depart`, cleanEntity(eleve));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// Actions

export const getEntities = createAsyncThunk(
  'eleve/fetch_entity_list',
  async ({ page, size, sort, query, niveauId, groupeId }: IQueryParams) => {
    let requestUrl = `${apiUrl}?${query ? query : ''}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
    if (niveauId) {
      requestUrl += `&niveauId.equals=${niveauId}`;
    }
    if (groupeId) {
      requestUrl += `&groupeId.equals=${groupeId}`;
    }

    return axios.get<IEleve[]>(requestUrl);
  },
);
export const getAllEleves = createAsyncThunk('eleve/fetch_entity_list', async ({ nom }: IQueryParams) => {
  let requestUrl = `${apiUrl}/all?cacheBuster=${new Date().getTime()}`;
  if (nom) {
    requestUrl += `&nom=${encodeURIComponent(nom)}`;
  }
  return axios.get<IEleve[]>(requestUrl);
});

export const getElevesThatHaveToPay = createAsyncThunk('eleve/fetch_entity_list', async () => {
  let requestUrl = `${apiUrl}/elevethathavetopay`;

  return axios.get<IEleve[]>(requestUrl);
});

export const getEntitiesDepart = createAsyncThunk('eleve/fetch_entity_list', async ({ page, size, sort, query }: IQueryParams) => {
  const requestUrl = `${apiUrl}/depart?${query}&${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;

  return axios.get<IEleve[]>(requestUrl);
});

export const updateDepart = createAsyncThunk(
  'eleve/update_depart',
  async ({ id, dateDepart, raisonDepart }: { id: number | string; dateDepart?: string; raisonDepart?: string }, thunkAPI) => {
    const response = await axios.put<IEleve>(`${apiUrl}/depart/${id}`, null, {
      params: {
        dateDepart,
        raisonDepart,
      },
    });

    if (dateDepart) {
      thunkAPI.dispatch(getEntities({}));
    } else {
      thunkAPI.dispatch(getEntitiesDepart({}));
    }

    return response.data;
  },
);

export const getEntity = createAsyncThunk(
  'eleve/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IEleve>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'eleve/create_entity',
  async (entity: IEleve, thunkAPI) => {
    const result = await axios.post<IEleve>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const createEntities = createAsyncThunk(
  'eleve/create_entities',
  async (entities: IEleve[], thunkAPI) => {
    const cleanedEntities = cleanEntities(entities);
    const requestUrl = `${apiUrl}/reinscription`;
    const result = await axios.put<IEleve[]>(requestUrl, cleanedEntities);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'eleve/update_entity',
  async (entity: IEleve, thunkAPI) => {
    const result = await axios.put<IEleve>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'eleve/partial_update_entity',
  async (entity: IEleve, thunkAPI) => {
    const result = await axios.patch<IEleve>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'eleve/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IEleve>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const getPaiementEleve = createAsyncThunk(
  'eleve/fetch_paiement',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/paiement/${id}`;
    return axios.get<IPaiementEleve>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const EleveSlice = createEntitySlice({
  name: 'eleve',
  initialState,
  reducers: {
    updateSelectedMonths: (state, action: PayloadAction<string[]>) => {
      state.months = action.payload;
    },
    setTheCurrentAnneeScolaireId: (state, action: PayloadAction<number>) => {
      state.entity.anneeScolaireId = action.payload;
    },
    addAdditionalTarif: (state, action: PayloadAction<ITarif>) => {
      state.additionalServices.push(action.payload);
    },
    setSelectAllMonths: (state, action: PayloadAction<boolean>) => {
      state.selectAllMonths = !state.selectAllMonths;
      if (action.payload === true) {
        state.months = ['Sep', 'Oct', 'Nov', 'Dec', 'Jan', 'Fev', 'Mar', 'Avr', 'Mai', 'Jui'];
      } else {
        state.months = [];
      }
    },
    addTuteur: (state, action: PayloadAction<ITuteur>) => {
      state.customEntite = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addCase(sendEleveAndNavigate.fulfilled, (state, action) => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(getPaiementEleve.fulfilled, (state, action) => {
        state.months = action.payload.data.listeDesMoisPayée;
        state.entity.anneeScolaireId = action.payload.data.anneeScolaire.id;
        state.moisPaiementStatus = action.payload.data.moisPaiementStatus;
      })
      .addMatcher(isFulfilled(getEntities, getElevesThatHaveToPay, getAllEleves), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { updateSelectedMonths, setSelectAllMonths, setTheCurrentAnneeScolaireId, addTuteur, reset, addAdditionalTarif } =
  EleveSlice.actions;

// Reducer
export default EleveSlice.reducer;
