import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { getUnreadMessages, setSelectedTutorNameForChat, setSelectedUserIdForChat } from 'app/entities/message/message.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IMessage } from 'app/shared/model/message.model';

import { useNavigate } from 'react-router-dom';

// Example messages data structure based on your provided entities
const messages = [
  {
    id: 1,
    contenu: 'Your new assignment is available.',
    dateEnvoi: '2024-08-26',
    user: {
      firstName: 'John',
      lastName: 'Doe',
      imageUrl: 'https://example.com/user-avatar.jpg',
    },
    isRead: false,
    messageSender: 'SYSTEM', // Example enum value
  },
  {
    id: 2,
    contenu: 'Meeting rescheduled to tomorrow at 3 PM.',
    dateEnvoi: '2024-08-25',
    user: {
      firstName: 'Jane',
      lastName: 'Smith',
      imageUrl: 'https://example.com/jane-avatar.jpg',
    },
    isRead: true,
    messageSender: 'USER', // Example enum value
  },
  {
    id: 3,
    contenu: 'Your password will expire in 5 days. Please update it.',
    dateEnvoi: '2024-08-24',
    user: {
      firstName: 'Admin',
      lastName: 'System',
      imageUrl: 'https://example.com/admin-avatar.jpg',
    },
    isRead: false,
    messageSender: 'SYSTEM', // Example enum value
  },
  {
    id: 4,
    contenu: 'The server will be down for maintenance on Saturday.',
    dateEnvoi: '2024-08-23',
    user: {
      firstName: 'Technical',
      lastName: 'Support',
      imageUrl: 'https://example.com/tech-avatar.jpg',
    },
    isRead: true,
    messageSender: 'SYSTEM', // Example enum value
  },
  {
    id: 5,
    contenu: 'New course materials are now available in the library.',
    dateEnvoi: '2024-08-22',
    user: {
      firstName: 'Library',
      lastName: 'Services',
      imageUrl: 'https://example.com/library-avatar.jpg',
    },
    isRead: false,
    messageSender: 'USER', // Example enum value
  },
  {
    id: 6,
    contenu: 'Reminder: Submit your project by the end of the week.',
    dateEnvoi: '2024-08-21',
    user: {
      firstName: 'Professor',
      lastName: 'Miller',
      imageUrl: 'https://example.com/prof-avatar.jpg',
    },
    isRead: true,
    messageSender: 'USER', // Example enum value
  },
  {
    id: 7,
    contenu: 'Your account has been updated successfully.',
    dateEnvoi: '2024-08-20',
    user: {
      firstName: 'Support',
      lastName: 'Team',
      imageUrl: 'https://example.com/support-avatar.jpg',
    },
    isRead: false,
    messageSender: 'SYSTEM', // Example enum value
  },
];

function NotificationsDropdown() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // const fetchMessagesForSelectedUser = async (selectedUserId) => {
  //     await dispatch(getMessages({ query: `userId.equals=${selectedUserId}&paged=false` }));
  //     await dispatch(changeReadStatusAsync({ userId: selectedUserId, type: 'USER' }));
  // };

  const handleNavigateToChat = (message: IMessage) => {
    setAnchorEl(null);
    dispatch(setSelectedUserIdForChat(message?.user?.id));
    dispatch(setSelectedTutorNameForChat(`${message?.user?.firstName}`));
    navigate(`/chat`);
    // fetchMessagesForSelectedUser(message?.user?.id);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMarkAsRead = messageId => {
    console.log('Mark as Read clicked for message ID:', messageId);
    // Implement your "mark as read" functionality here
  };

  useEffect(() => {
    dispatch(getUnreadMessages());
  }, []);

  const unreadMessages = useAppSelector(state => state.message.unreadMessages) as IMessage[];
  console.log(unreadMessages);
  const images = {
    male: '/content/images/male-child-image.png',
    female: '/content/images/female-child-image.jpg',
  };
  return (
    <div>
      <IconButton aria-label="notifications" color="inherit" onClick={handleClick}>
        <Badge badgeContent={unreadMessages?.length} color="error">
          <NotificationsIcon style={{ color: 'whitesmoke' }} />
        </Badge>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {unreadMessages?.length ? (
          unreadMessages?.map(message => (
            <MenuItem
              key={message?.id}
              onClick={() => {
                handleNavigateToChat(message);
                console.log('hello');
              }}
            >
              <List>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar src={images.male} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        <Typography variant="body1">
                          {message?.user?.firstName} {message?.user?.lastName}
                        </Typography>
                        <Typography variant="body2" style={{ opacity: '60%', marginLeft: '10px' }}>
                          {dayjs(message?.dateEnvoi).format('DD MMMM YYYY HH:mm')}
                        </Typography>
                      </div>
                    }
                    secondary={
                      <span
                        style={{
                          display: 'inline-block',
                          width: '250px',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        {message?.contenu}
                      </span>
                    }
                  />
                  {/*<Button*/}
                  {/*  style={{*/}
                  {/*    backgroundColor: message.isRead ? 'grey' : 'green',*/}
                  {/*  }}*/}
                  {/*  onClick={() => handleMarkAsRead(message.id)}*/}
                  {/*  disabled={message.isRead}*/}
                  {/*>*/}
                  {/*  {message.isRead ? 'Read' : 'Mark as Read'}*/}
                  {/*</Button>*/}
                </ListItem>
              </List>
            </MenuItem>
          ))
        ) : (
          <MenuItem onClick={handleClose}>
            <ListItemText primary="No new messages" />
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

export default NotificationsDropdown;
