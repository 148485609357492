import { IGroupe } from 'app/shared/model/groupe.model';
import { IAnneeScolaire } from 'app/shared/model/annee-scolaire.model';
import { INiveau } from 'app/shared/model/niveau.model';
import { ScreenType } from 'app/shared/model/enumerations/screen-status.model';

export interface IScreen {
  id?: number;
  title?: string | null;
  type?: ScreenType | null;
  numEleve?: number | null;
  timeSlide?: number | null;
  niveau?: INiveau | null;
  groupe?: IGroupe | null;
  anneeScolaire?: IAnneeScolaire | null;
}

export const defaultValue: Readonly<IScreen> = {};
