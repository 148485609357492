import dayjs from 'dayjs';
import { IUser } from 'app/shared/model/user.model';
import { Sexe } from 'app/shared/model/enumerations/sexe.model';
import { Role } from 'app/shared/model/enumerations/role.model';

export interface IUtilisateur {
  id?: number;
  nom?: string | null;
  prenom?: string | null;
  dateNaissance?: dayjs.Dayjs | null;
  lieuNaissance?: string | null;
  sexe?: keyof typeof Sexe | null;
  photoContentType?: string | null;
  photo?: string | null;
  cin?: string | null;
  adresse?: string | null;
  rib?: string | null;
  role?: keyof typeof Role | null;
  user?: IUser | null;
}

export const defaultValue: Readonly<IUtilisateur> = {};
