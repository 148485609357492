import dayjs from 'dayjs';
import { IEmploiTemps } from 'app/shared/model/emploi-temps.model';
import { JOUR } from 'app/shared/model/enumerations/jour.model';

export interface IEmploiTempsLigne {
  id?: number;
  heureDebut?: dayjs.Dayjs | null;
  heureFin?: dayjs.Dayjs | null;
  jour?: keyof typeof JOUR | null;
  name?: string | null;
  emploisTemps?: IEmploiTemps | null;
}

export const defaultValue: Readonly<IEmploiTempsLigne> = {};
