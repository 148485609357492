import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faPlus, faEye, faPencilAlt, faTrash, faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getPaginationState, JhiItemCount, JhiPagination } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getEntities } from './etat-service-mois.reducer';

const EtatServiceMois = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(location, ITEMS_PER_PAGE, 'id'), location.search),
  );

  const { entities: etatServiceMoisList, loading, totalItems } = useAppSelector(state => state.etatServiceMois);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      }),
    );
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);

    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState(prevState => ({
        ...prevState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      }));
    }
  }, [location.search]);

  useEffect(() => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  const sort = fieldName => () => {
    setPaginationState(prevState => ({
      ...prevState,
      order: prevState.order === ASC ? DESC : ASC,
      sort: fieldName,
    }));
  };

  const handlePagination = currentPage =>
    setPaginationState(prevState => ({
      ...prevState,
      activePage: currentPage,
    }));

  const handleSyncList = () => {
    getAllEntities();
  };

  const getSortIcon = fieldName => {
    if (paginationState.sort !== fieldName) return faSort;
    return paginationState.order === ASC ? faSortUp : faSortDown;
  };

  const columns = [
    {
      title: (
        <span className="hand" onClick={sort('id')}>
          Code <FontAwesomeIcon icon={getSortIcon('id')} />
        </span>
      ),
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button type="link" onClick={() => navigate(`/etat-service-mois/${record.id}`)}>
          {text}
        </Button>
      ),
    },
    {
      title: (
        <span className="hand" onClick={sort('montant')}>
          Montant <FontAwesomeIcon icon={getSortIcon('montant')} />
        </span>
      ),
      dataIndex: 'montant',
      key: 'montant',
    },
    {
      title: (
        <span className="hand" onClick={sort('avance')}>
          Avance <FontAwesomeIcon icon={getSortIcon('avance')} />
        </span>
      ),
      dataIndex: 'avance',
      key: 'avance',
    },
    {
      title: 'Mois Eleve',
      dataIndex: ['moisEleve', 'id'],
      key: 'moisEleve',
      render: (text, record) => (record.moisEleve ? <Link to={`/mois-eleve/${record.moisEleve.id}`}>{text}</Link> : null),
    },
    {
      title: 'Service Etablissement',
      dataIndex: ['serviceEtablisement', 'id'],
      key: 'serviceEtablisement',
      render: (text, record) =>
        record.serviceEtablisement ? <Link to={`/service-etablisement/${record.serviceEtablisement.id}`}>{text}</Link> : null,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type="link" onClick={() => navigate(`/etat-service-mois/${record.id}`)} icon={<FontAwesomeIcon icon={faEye} />} />
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/etat-service-mois/${record.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
              )
            }
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            style={{ color: 'green' }}
          />
          <Button
            type="link"
            danger
            onClick={() =>
              navigate(
                `/etat-service-mois/${record.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`,
              )
            }
            icon={<FontAwesomeIcon icon={faTrash} />}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <h2 id="etat-service-mois-heading" data-cy="EtatServiceMoisHeading">
        État Service Mois
        <div className="d-flex justify-content-end " style={{ marginTop: '3rem', marginBottom: '3rem' }}>
          <Button className="me-2" type="primary" onClick={handleSyncList} loading={loading}>
            <FontAwesomeIcon icon={faSync} spin={loading} /> Actualiser la liste
          </Button>
          <Link to="/etat-service-mois/new">
            <Button type="primary" icon={<FontAwesomeIcon icon={faPlus} />}>
              &nbsp; Créer un nouveau Etat Service Mois
            </Button>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {etatServiceMoisList.length > 0 ? (
          <>
            <Table dataSource={etatServiceMoisList} columns={columns} rowKey="id" pagination={false} loading={loading} />
            <div className="justify-content-center d-flex mt-2">
              <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
            </div>
            <div className="justify-content-center d-flex">
              <JhiPagination
                activePage={paginationState.activePage}
                onSelect={handlePagination}
                maxButtons={5}
                itemsPerPage={paginationState.itemsPerPage}
                totalItems={totalItems}
              />
            </div>
          </>
        ) : (
          !loading && <div className="alert alert-warning">Aucun Etat Service Mois trouvé</div>
        )}
      </div>
    </div>
  );
};

export default EtatServiceMois;
