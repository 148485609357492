import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import {} from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './etat-service-mois.reducer';

export const EtatServiceMoisDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const etatServiceMoisEntity = useAppSelector(state => state.etatServiceMois.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="etatServiceMoisDetailsHeading">État Service Mois</h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">ID</span>
          </dt>
          <dd>{etatServiceMoisEntity.id}</dd>
          <dt>
            <span id="montant">Montant</span>
          </dt>
          <dd>{etatServiceMoisEntity.montant}</dd>
          <dt>
            <span id="avance">Avance</span>
          </dt>
          <dd>{etatServiceMoisEntity.avance}</dd>
          <dt>Mois Eleve</dt>
          <dd>{etatServiceMoisEntity.moisEleve ? etatServiceMoisEntity.moisEleve.id : ''}</dd>
          <dt>Service Etablisement</dt>
          <dd>{etatServiceMoisEntity.serviceEtablisement ? etatServiceMoisEntity.serviceEtablisement.id : ''}</dd>
        </dl>
        <Button tag={Link} to="/etat-service-mois" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" /> <span className="d-none d-md-inline">Retour</span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/etat-service-mois/${etatServiceMoisEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Editer</span>
        </Button>
      </Col>
    </Row>
  );
};

export default EtatServiceMoisDetail;
