import {IAnneeScolaire} from 'app/shared/model/annee-scolaire.model';

export interface INiveau {
  id?: number;
  libelle?: string | null;
  option?: string | null;
  idMassar?: string | null;
  anneeScolaire?: IAnneeScolaire | null;
}

export const defaultValue: Readonly<INiveau> = {};
