import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createEntity, deleteEntity, getEntities } from 'app/entities/groupe/groupe.reducer';
import { Table } from 'reactstrap';

const CreateGroupeModal = ({ show, handleClose, niveau }) => {
  const [inputValue, setInputValue] = useState('');
  const groupeList = useAppSelector(state => state.groupe.entities);
  const loading = useAppSelector(state => state.groupe.loading);

  const dispatch = useAppDispatch();
  const isNew = niveau == null;
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...values,
      niveau: niveau,
    };
    dispatch(createEntity(entity));
    handleClose();
  };
  const handleDelete = groupId => {
    dispatch(deleteEntity(groupId));
  };

  const getAllEntities = () => {
    dispatch(
      getEntities({
        query: 'niveauId.equals=' + niveau?.id,
        sort: 'asc',
      }),
    );
  };

  useEffect(() => {
    if (niveau?.id) getAllEntities();
  }, [niveau?.id]);

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Créer un nouveau groupe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ValidatedForm defaultValues={{}} onSubmit={saveEntity}>
          <ValidatedField label="Nom" id="groupe-nom" name="nom" data-cy="nom" type="text" />
          <Button id="cancel-save" color="info">
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <span className="d-none d-md-inline">Retour</span>
          </Button>
          &nbsp;
          <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit">
            <FontAwesomeIcon icon="save" />
            &nbsp; Sauvegarder
          </Button>
        </ValidatedForm>

        <div className="table-responsive">
          {groupeList && groupeList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand">ID</th>
                  <th className="hand">Nom</th>
                </tr>
              </thead>
              <tbody>
                {groupeList.map((groupe, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td>{groupe.id}</td>
                    <td>{groupe.nom}</td>
                    <td>
                      <Button variant="danger" onClick={() => handleDelete(groupe.id)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="23"
                          height="23"
                          viewBox="0 0 30 30"
                          style={{ fill: '#FFFFFF' }}
                        >
                          <path d="M 14.984375 2.4863281 A 1.0001 1.0001 0 0 0 14 3.5 L 14 4 L 8.5 4 A 1.0001 1.0001 0 0 0 7.4863281 5 L 6 5 A 1.0001 1.0001 0 1 0 6 7 L 24 7 A 1.0001 1.0001 0 1 0 24 5 L 22.513672 5 A 1.0001 1.0001 0 0 0 21.5 4 L 16 4 L 16 3.5 A 1.0001 1.0001 0 0 0 14.984375 2.4863281 z M 6 9 L 7.7929688 24.234375 C 7.9109687 25.241375 8.7633438 26 9.7773438 26 L 20.222656 26 C 21.236656 26 22.088031 25.241375 22.207031 24.234375 L 24 9 L 6 9 z"></path>
                        </svg>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">Aucun Groupe trouvé</div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CreateGroupeModal;
