import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Row, Form, Input, DatePicker, Checkbox, Select, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { createEntity, getEntity, reset, updateEntity } from './message.reducer';

const { Title } = Typography;
const { Option } = Select;

const MessageUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const messageEntity = useAppSelector(state => state.message.entity);
  const loading = useAppSelector(state => state.message.loading);
  const updating = useAppSelector(state => state.message.updating);
  const updateSuccess = useAppSelector(state => state.message.updateSuccess);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getUsers({}));
  }, [dispatch, id, isNew]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const handleClose = () => {
    navigate('/message' + location.search);
  };

  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...messageEntity,
      ...values,
      user: users.find(it => it.id.toString() === values.user?.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => ({
    ...messageEntity,
    user: messageEntity?.user?.id,
    messageSender: messageEntity?.messageSender || 'USER',
  });

  return (
    <div style={{ padding: '20px', backgroundColor: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Row className="justify-content-center">
        <Col span={16}>
          <Title level={2} style={{ textAlign: 'center' }}>
            {isNew ? 'Créer' : 'Éditer'} un Message
          </Title>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col span={16}>
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form layout="vertical" initialValues={defaultValues()} onFinish={saveEntity}>
              {!isNew ? (
                <Form.Item name="id" label="Numéro" rules={[{ required: true, message: 'Numéro is required' }]}>
                  <Input readOnly />
                </Form.Item>
              ) : null}
              <Form.Item name="contenu" label="Contenu" rules={[{ required: true, message: 'Contenu is required' }]}>
                <Input />
              </Form.Item>
              <Form.Item name="dateEnvoi" label="Date Envoi" rules={[{ required: true, message: 'Date Envoi is required' }]}>
                <DatePicker />
              </Form.Item>
              <Form.Item name="isRead" label="Lu" valuePropName="checked">
                <Checkbox />
              </Form.Item>
              <Form.Item name="messageSender" label="Expéditeur" rules={[{ required: true, message: 'Expéditeur is required' }]}>
                <Select>
                  <Option value="ADMINISTRATION">Administration</Option>
                  <Option value="USER">User</Option>
                </Select>
              </Form.Item>
              <Form.Item name="user" label="User">
                <Select placeholder="Select a user">
                  {users.map(user => (
                    <Option key={user.id} value={user.id}>
                      {user.login}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item style={{ textAlign: 'center' }}>
                <Button type="default" onClick={() => navigate('/message')} style={{ marginRight: '8px' }}>
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp; Retour
                </Button>
                <Button type="primary" htmlType="submit" loading={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp; Sauvegarder
                </Button>
              </Form.Item>
            </Form>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MessageUpdate;
